import React, { useCallback, useEffect, useState } from 'react';

import ValveButton from '../ValveButton';

import useStackLevelDrainValveStatePrompts from '../../hooks/useStackLevelDrainValveStatePrompts';
import { Descriptions } from '../../modules/Descriptions';

interface IProps {
  zoneName: string;
  stackName: string;
  levelName: string;
  levelId: string;
  levelValveState: 'OPEN' | 'CLOSED';
  className?: string;
  needsAttention?: boolean;
  title: string;
  hasHelp: boolean;
}

const LevelValveButton = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  levelValveState,
  className,
  needsAttention = false,
  title = Descriptions.levelValve,
  hasHelp,
  ...props
}: IProps) => {
  const [state, setState] = useState('CLOSED');

  const stateHasChanged = useCallback((next: string) => setState(next), [
    state,
  ]);

  const {
    openStackLevelOpenValvePrompt,
    openStackLevelCloseValvePrompt,
    StackLevelValveStatePrompts,
    stackLevelValveStatePromptsProps,
  } = useStackLevelDrainValveStatePrompts({
    zoneName,
    stackName,
    levelName,
    levelId,
    stateHasChanged,
  });

  useEffect(() => {
    if (levelValveState == null) return;
    setState(levelValveState);
  }, [levelValveState]);

  return (
    <>
      <StackLevelValveStatePrompts {...stackLevelValveStatePromptsProps} />

      <ValveButton
        hasHelp={hasHelp}
        title={title}
        valveState={state == 'OPEN' ? 'OPEN' : 'CLOSED'}
        needsAttention={needsAttention}
        className={`${className}`}
        onClick={
          state === 'OPEN'
            ? openStackLevelCloseValvePrompt
            : openStackLevelOpenValvePrompt
        }
        {...props}
      />
    </>
  );
};

export default LevelValveButton;
