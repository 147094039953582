import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import PromptModal from '../../components/PromptModal';

import style from './style.module.scss';

interface LevelIndicatorProps {
  name: string;
  enabled: boolean;
}

const LevelIndicator = ({ name, enabled }: LevelIndicatorProps) => (
  <div className={style.levelSensor}>
    <div className={style.label}>
      <div
        className={[style.labelName, enabled ? style.enabled : undefined].join(
          ' '
        )}
      >
        {name}
      </div>
      <div className={style.labelInner}>
        {enabled && (
          <>
            <div className={style.labelWaves}></div>
            <div className={style.labelLevel} style={{ height: '20%' }}></div>
          </>
        )}
      </div>
    </div>
    <div className={style.sensorLabelConnector}></div>
    <div
      className={[
        style.indicator,
        enabled ? style.enabled : style.disabled,
      ].join(' ')}
    >
      <FontAwesomeIcon icon={enabled ? faCheck : faTimes} />
    </div>
  </div>
);

interface Input {
  zoneName: string;
  zoneId: string;
  invalidStates: { [key: string]: boolean };
  onCompleted?: () => void;
}

interface Result {
  openZoneTankInvalidWaterSensorStateResetPrompt: () => void;
  ZoneTankInvalidWaterSensorStateResetPrompt: typeof ZoneTankInvalidWaterSensorStateResetPrompt;
  zoneTankInvalidWaterSensorStateResetPromptProps: Props;
}

export const RESET_ZONE_TANK_INVALID_STATE = gql`
  mutation resetZoneTankInvalidState(
    $input: ResetZoneTankInvalidStateInputType!
  ) {
    resetZoneTankInvalidState(input: $input) {
      zone {
        zoneId
        tank {
          invalidWaterLevelSensorStateDetected
        }
      }
    }
  }
`;

interface Props extends Input {
  zoneTankInvalidWaterSensorStateResetPromptIsOpen: boolean;
  closeZoneTankInvalidWaterSensorStateResetPrompt: () => void;
}

const ZoneTankInvalidWaterSensorStateResetPrompt = ({
  zoneName,
  zoneId,
  invalidStates,
  zoneTankInvalidWaterSensorStateResetPromptIsOpen,
  closeZoneTankInvalidWaterSensorStateResetPrompt,
  onCompleted,
}: Props) => {
  const [resetZoneTankInvalidState, { error, loading: saving }] = useMutation(
    RESET_ZONE_TANK_INVALID_STATE,
    {
      onCompleted: () => {
        closeZoneTankInvalidWaterSensorStateResetPrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Reset Invalid Water Level Sensor State Alert for Zone ${zoneName}`}
      danger={true}
      isOpen={zoneTankInvalidWaterSensorStateResetPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        resetZoneTankInvalidState({
          variables: {
            input: {
              zoneId,
            },
          },
        });
      }}
      onComplete={closeZoneTankInvalidWaterSensorStateResetPrompt}
    >
      <p>
        The tank controller for Zone {`${zoneName}`} determined the water level
        sensors to be in the following invalid state:
      </p>
      <div className={style.levelSensorsContainer}>
        <LevelIndicator name="High High" enabled={invalidStates?.HH ?? false} />
        <LevelIndicator name="High" enabled={invalidStates?.H ?? false} />
        <LevelIndicator name="Low" enabled={invalidStates?.L ?? false} />
        <LevelIndicator name="Low Low" enabled={invalidStates?.LL ?? false} />
      </div>
      <p>
        You should only reset the alert if you have physically verified the
        water level sensors are functioning correctly.
      </p>

      <p>
        Are you sure you want to reset the invalid water sensor state alert for
        Zone {`${zoneName}`}?
      </p>
    </PromptModal>
  );
};

const useZoneTankInvalidWaterSensorStateResetPrompt: (
  input: Input
) => Result = ({ zoneName, zoneId, invalidStates, onCompleted }) => {
  const [
    zoneTankInvalidWaterSensorStateResetPromptIsOpen,
    setZoneTankInvalidWaterSensorStateResetPromptIsOpen,
  ] = useState(false);

  return {
    openZoneTankInvalidWaterSensorStateResetPrompt: useCallback(
      () => setZoneTankInvalidWaterSensorStateResetPromptIsOpen(true),
      [setZoneTankInvalidWaterSensorStateResetPromptIsOpen]
    ),
    ZoneTankInvalidWaterSensorStateResetPrompt,
    zoneTankInvalidWaterSensorStateResetPromptProps: {
      zoneName,
      zoneId,
      invalidStates,
      zoneTankInvalidWaterSensorStateResetPromptIsOpen,
      onCompleted,
      closeZoneTankInvalidWaterSensorStateResetPrompt: useCallback(
        () => setZoneTankInvalidWaterSensorStateResetPromptIsOpen(false),
        [setZoneTankInvalidWaterSensorStateResetPromptIsOpen]
      ),
    },
  };
};

export default useZoneTankInvalidWaterSensorStateResetPrompt;
