import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  ozoneSystemName: string;
  ozoneSystemId: string;
  onCompleted?: () => void;
}

interface Result {
  openOzoneEnableAutomaticBypassModePrompt: () => void;
  openOzoneDisableAutomaticBypassModePrompt: () => void;
  OzoneBypassValveModePrompts: typeof OzoneBypassValveModePrompts;
  ozoneBypassValveModePromptsProps: Omit<Props, 'ozoneBypassValveMode'>;
}

export const UPDATE_OZONE_SYSTEM_BYPASS_VALVE_MODE = gql`
  mutation updateOzoneSystemBypassValveMode(
    $input: UpdateOzoneSystemBypassValveModeInputType!
  ) {
    updateOzoneSystemBypassValveMode(input: $input) {
      ozoneSystem {
        ozoneSystemId
        bypassValve {
          mode
        }
      }
    }
  }
`;

interface Props extends Input {
  enableEnableAutomaticModePromptIsOpen: boolean;
  disableAutomaticModePromptIsOpen: boolean;
  closeEnableAutomaticModePrompt: () => void;
  closeDisableAutomaticModePrompt: () => void;
}

const OzoneBypassValveModePrompts = ({
  ozoneSystemName,
  ozoneSystemId,
  enableEnableAutomaticModePromptIsOpen,
  disableAutomaticModePromptIsOpen,
  closeEnableAutomaticModePrompt,
  closeDisableAutomaticModePrompt,
  onCompleted,
}: Props) => {
  const [
    updateOzoneAutomaticBypassValveMode,
    { error, loading: saving },
  ] = useMutation(UPDATE_OZONE_SYSTEM_BYPASS_VALVE_MODE, {
    onCompleted: () => {
      closeEnableAutomaticModePrompt();
      closeDisableAutomaticModePrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <>
      <PromptModal
        title={`Enable Ozone Automatic Bypass for Ozone System ${ozoneSystemName}`}
        isOpen={enableEnableAutomaticModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneAutomaticBypassValveMode({
            variables: {
              input: { ozoneSystemId, bypassValveMode: 'AUTOMATIC' },
            },
          });
        }}
        onComplete={closeEnableAutomaticModePrompt}
      >
        <p>
          Enabling the ozone automatic bypass will allow the ozone bypass valve
          to open and close based on whether the ozone tank has capacity.
        </p>

        <p>
          Enable the ozone automatic bypass for Ozone System {ozoneSystemName}?
        </p>
      </PromptModal>

      <PromptModal
        title={`Disable Ozone Automatic Bypass for Ozone System ${ozoneSystemName}`}
        isOpen={disableAutomaticModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneAutomaticBypassValveMode({
            variables: {
              input: { ozoneSystemId, bypassValveMode: 'MAINTENANCE' },
            },
          });
        }}
        onComplete={closeDisableAutomaticModePrompt}
      >
        <p>
          Disabling the ozone automatic bypass will cause the ozone system to be
          bypassed even if the ozone tank has capacity.
        </p>

        <p>
          Disable the ozone automatic bypass for Ozone System {ozoneSystemName}?
        </p>
      </PromptModal>
    </>
  );
};

const useOzoneBypassValveModePrompts: (input: Input) => Result = ({
  ozoneSystemName,
  ozoneSystemId,
  onCompleted,
}: Input) => {
  const [
    enableEnableAutomaticModePromptIsOpen,
    setEnableAutomaticModePromptIsOpen,
  ] = useState(false);

  const [
    disableAutomaticModePromptIsOpen,
    setDisableAutomaticModePromptIsOpen,
  ] = useState(false);

  return {
    openOzoneEnableAutomaticBypassModePrompt: useCallback(
      () => setEnableAutomaticModePromptIsOpen(true),
      [setEnableAutomaticModePromptIsOpen]
    ),
    openOzoneDisableAutomaticBypassModePrompt: useCallback(
      () => setDisableAutomaticModePromptIsOpen(true),
      [setDisableAutomaticModePromptIsOpen]
    ),
    OzoneBypassValveModePrompts,
    ozoneBypassValveModePromptsProps: {
      ozoneSystemName,
      ozoneSystemId,
      enableEnableAutomaticModePromptIsOpen,
      disableAutomaticModePromptIsOpen,
      onCompleted,
      closeEnableAutomaticModePrompt: useCallback(
        () => setEnableAutomaticModePromptIsOpen(false),
        [setEnableAutomaticModePromptIsOpen]
      ),
      closeDisableAutomaticModePrompt: useCallback(
        () => setDisableAutomaticModePromptIsOpen(false),
        [setDisableAutomaticModePromptIsOpen]
      ),
    },
  };
};

export default useOzoneBypassValveModePrompts;
