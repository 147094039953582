const caseInsensitiveConfig = { sensitivity: 'base' };

export const equalsIgnoringCase = (x, y) => {
  if (!x && !y) {
    return true;
  }

  if (!x) {
    return false;
  }

  return x.localeCompare(y, undefined, caseInsensitiveConfig) === 0;
};

export const getWaterLevel = i => {
  switch (i) {
    case 0:
      return 'EMPTY';
    case 1:
      return 'LOW';
    case 2:
      return 'NOMINAL';
    case 3:
      return 'FULL';
    case 3:
      return 'OVERFLOWING';
  }
};

export const getPumpOnOff = i => {
  switch (i) {
    case 0:
      return 'OFF';
    case 1:
      return 'ON';
  }
};

export const getPumpState = i => {
  switch (i) {
    case 0:
      return 'Disabled';
    case 1:
      return 'EmptyTank';
    case 2:
      return 'WaitingToResume';
    case 3:
      return 'NoWaterRequested';
    case 4:
      return 'Pumping';
  }
};
