import gql from 'graphql-tag';

export const ADD_AUTO_CALIBRATE_ZONE_ATTACHED_COLLECTOR_TANK_PRESSURE_SENSOR = gql`
  mutation autoCalibrateZoneAttachedCollectorTankPressureSensor(
    $input: AutoCalibrateZoneAttachedCollectorTankPressureSensorInputType!
  ) {
    autoCalibrateZoneAttachedCollectorTankPressureSensor(input: $input) {
      zone {
        zoneId
        collectorTank {
          autoCalibrated
          calibrationPoints
        }
      }
    }
  }
`;
