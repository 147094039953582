import gql from 'graphql-tag';

export enum AttentionLevels {
  None = 1,
  Low,
  Critical,
}

export const getStackAttentionLevel = (stack: any): AttentionLevels => {
  switch (true) {
    case stack?.leakDetected:
    case !!stack?.collectorTank?.stackAttachedCollectorTankPumpTimeoutDetected:
      return AttentionLevels.Critical;
    case stack?.valveFailureDetected:
    case stack?.valveOpenFailureDetected:
      return AttentionLevels.Low;
    default:
      return AttentionLevels.None;
  }
};

getStackAttentionLevel.fragment = gql`
  fragment GetStackAttentionLevel on StackType {
    stackId
    leakDetected
    valveFailureDetected
    valveOpenFailureDetected
    collectorTank {
      ... on StackAttachedCollectorTankType {
        stackAttachedCollectorTankPumpTimeoutDetected: pumpTimeoutDetected
      }
    }
  }
`;

export const getZoneAttentionLevel = (zone: any): AttentionLevels => {
  switch (true) {
    case zone.collectorTank?.zoneAttachedCollectorTankPumpTimeoutDetected:
    case zone.strainerClogDetected:
      return AttentionLevels.Critical;
    case zone.collectorTank?.invalidWaterLevelSensorStateDetected:
    case zone.tank?.invalidWaterLevelSensorStateDetected:
      return AttentionLevels.Low;
    default:
      return zone?.stacks
        ?.map(getStackAttentionLevel)
        .reduce((acc: AttentionLevels, cur: AttentionLevels) =>
          Math.max(acc, cur)
        );
  }
};

getZoneAttentionLevel.fragment = gql`
  fragment GetZoneAttentionLevel on ZoneType {
    zoneId
    strainerClogDetected
    collectorTank {
      ... on ZoneAttachedCollectorTankType {
        zoneAttachedCollectorTankPumpTimeoutDetected: pumpTimeoutDetected
        invalidWaterLevelSensorStateDetected
      }
    }
    stacks {
      ...GetStackAttentionLevel
    }
  }
  ${getStackAttentionLevel.fragment}
`;

export const getOzoneSystemAttentionLevel = (
  ozoneSystem: any
): AttentionLevels => {
  switch (true) {
    case ozoneSystem.tank?.invalidWaterSensorStatesDetected:
      return AttentionLevels.Low;
    default:
      return AttentionLevels.None;
  }
};

getOzoneSystemAttentionLevel.fragment = gql`
  fragment GetOzoneSystemAttentionLevel on OzoneSystemType {
    ozoneSystemId
    tank {
      invalidWaterSensorStatesDetected
    }
  }
`;

export const getPumpRoomAttentionLevel = (pumpRoom: any): AttentionLevels =>
  pumpRoom?.ozoneSystems
    ?.map(getOzoneSystemAttentionLevel)
    .reduce((acc: AttentionLevels, cur: AttentionLevels) => Math.max(acc, cur));

getPumpRoomAttentionLevel.fragment = gql`
  fragment GetPumpRoomAttentionLevel on PumpRoomType {
    ozoneSystems {
      ...GetOzoneSystemAttentionLevel
    }
  }
  ${getOzoneSystemAttentionLevel.fragment}
`;
