import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gql from 'graphql-tag';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useQuery, useSubscription } from 'react-apollo';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Gauge from '../../components/Gauge';
import ModalCloseButton from '../../components/ModalCloseButton';
import ModalTitle from '../../components/ModalTitle';
import PageLoadingContainer from '../../components/PageLoadingContainer';
import TimeSeriesChart from '../../components/TimeSeriesChart';
import {
  LevelBeeSensorMeasurements,
  LevelBeeSensorValues,
} from '../../modules/bee-sensors';
import useModalVisible from '../useModalVisible';

import style from './style.module.scss';

interface Input {
  zoneName: string;
  stackName: string;
  levelName: string;
  levelId: string;
}

interface Result {
  openLevelBeeSensorValuesModal: () => void;
  LevelBeeSensorValuesModal: typeof LevelBeeSensorValuesModal;
  levelBeeSensorValuesModalProps: Props;
}

const levelFragment = gql`
  fragment LevelBeeSensorValuesModal on LevelType {
    levelId
    hasBeeSensor
    beeSensor {
      serialNumber
      currentValue {
        ...LevelBeeSensorValues
      }
      aggregatedValues {
        timestamp
        ...LevelBeeSensorAggregatedValues
      }
      thresholds {
        ...LevelBeeSensorThresholds
      }
    }
  }
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorCurrentValue}
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorAggregatedValues}
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorThresholds}
`;

const LEVEL_QUERY = gql`
  query level($levelId: ID!) {
    level(levelId: $levelId) {
      ...LevelBeeSensorValuesModal
    }
  }
  ${levelFragment}
`;

const LEVEL_SUBSCRIPTION = gql`
  subscription levelUpdated($levelId: ID!) {
    levelUpdated(levelId: $levelId) {
      level {
        ...LevelBeeSensorValuesModal
      }
    }
  }
  ${levelFragment}
`;

interface Props extends Input {
  levelBeeSensorValuesModalIsOpen: boolean;
  closeLevelBeeSensorValuesModal: () => void;
}

const LevelBeeSensorValuesModal = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  levelBeeSensorValuesModalIsOpen,
  closeLevelBeeSensorValuesModal,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();

  const result = useQuery(LEVEL_QUERY, {
    variables: { levelId },
    skip: !visible,
  });

  useSubscription(LEVEL_SUBSCRIPTION, {
    variables: { levelId },
    skip: !visible,
  });

  const { refetch } = result;
  const [wasOpen, setWasOpen] = useState(false);

  useEffect(() => {
    const reopened = levelBeeSensorValuesModalIsOpen && !wasOpen;

    if (reopened) {
      refetch();
    }

    setWasOpen(levelBeeSensorValuesModalIsOpen);
  }, [levelBeeSensorValuesModalIsOpen, refetch, wasOpen, setWasOpen]);

  const { data } = result;
  const level = data?.level || null;
  const beeSensorData = level?.beeSensor || null;
  const currentValues = beeSensorData?.currentValue || null;
  const aggregatedValues = beeSensorData?.aggregatedValues || null;
  const thresholds = beeSensorData?.thresholds || null;

  return (
    <Modal
      className={style.modal}
      isOpen={levelBeeSensorValuesModalIsOpen}
      toggle={closeLevelBeeSensorValuesModal}
      {...modalVisibleProps}
    >
      <ModalCloseButton onClick={closeLevelBeeSensorValuesModal} />

      <ModalHeader>
        <ModalTitle
          icon={<FontAwesomeIcon icon={faCog} fixedWidth />}
          title={`Zone ${zoneName} / Stack ${stackName} / Level ${levelName} Sensor History`}
        />
      </ModalHeader>

      <ModalBody className={style.modalBody}>
        <PageLoadingContainer
          resourceTypeName="Level"
          result={result}
          resourceExists={!!level}
          render={() => (
            <div className={style.charts}>
              {LevelBeeSensorMeasurements.all.map(measurement => (
                <div
                  className={style.gaugeChartContainer}
                  key={measurement.label}
                >
                  <TimeSeriesChart<LevelBeeSensorValues>
                    lineColor={measurement.color}
                    aggregatedValues={aggregatedValues}
                    aggregatedValueSelector={measurement.valueSelector}
                    lowCriticalValue={measurement.lowAlertValueSelector(
                      thresholds
                    )}
                    lowValue={measurement.lowValueSelector(thresholds)}
                    highValue={measurement.highValueSelector(thresholds)}
                    highCriticalValue={measurement.highAlertValueSelector(
                      thresholds
                    )}
                  />

                  <Gauge
                    className={style.gauge}
                    label={measurement.label}
                    color={measurement.color}
                    unit={measurement.unit}
                    value={measurement.valueSelector(currentValues)}
                    lowCritical={measurement.lowAlertValueSelector(thresholds)}
                    low={measurement.lowValueSelector(thresholds)}
                    high={measurement.highValueSelector(thresholds)}
                    highCritical={measurement.highAlertValueSelector(
                      thresholds
                    )}
                  />
                </div>
              ))}
            </div>
          )}
        />
      </ModalBody>
    </Modal>
  );
};

const useLevelBeeSensorValuesModal: (input: Input) => Result = ({
  zoneName,
  stackName,
  levelName,
  levelId,
}) => {
  const [
    levelBeeSensorValuesModalIsOpen,
    setLevelBeeSensorValuesModalIsOpen,
  ] = useState(false);

  return {
    openLevelBeeSensorValuesModal: useCallback(
      () => setLevelBeeSensorValuesModalIsOpen(true),
      [setLevelBeeSensorValuesModalIsOpen]
    ),
    LevelBeeSensorValuesModal,
    levelBeeSensorValuesModalProps: {
      zoneName,
      stackName,
      levelName,
      levelId,
      levelBeeSensorValuesModalIsOpen,
      closeLevelBeeSensorValuesModal: useCallback(
        () => setLevelBeeSensorValuesModalIsOpen(false),
        [setLevelBeeSensorValuesModalIsOpen]
      ),
    },
  };
};

export default useLevelBeeSensorValuesModal;
