import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackAttachedCollectorTankFlushPrompt: () => void;
  StackAttachedCollectorTankFlushPrompt: typeof StackAttachedCollectorTankFlushPrompt;
  stackAttachedCollectorTankFlushPromptProps: Props;
}

const FLUSH_STACK_ATTACHED_COLLECTOR_TANK = gql`
  mutation flushStackAttachedCollectorTank(
    $input: FlushStackAttachedCollectorTankInputType!
  ) {
    flushStackAttachedCollectorTank(input: $input) {
      stack {
        stackId
        collectorTank {
          pumpState
        }
      }
    }
  }
`;

interface Props extends Input {
  stackAttachedCollectorTankFlushPromptIsOpen: boolean;
  closeStackAttachedCollectorTankFlushPrompt: () => void;
}

const StackAttachedCollectorTankFlushPrompt = ({
  zoneName,
  stackName,
  stackId,
  stackAttachedCollectorTankFlushPromptIsOpen,
  closeStackAttachedCollectorTankFlushPrompt,
  onCompleted,
}: Props) => {
  const [
    flushStackAttachedCollectorTank,
    { error, loading: saving },
  ] = useMutation(FLUSH_STACK_ATTACHED_COLLECTOR_TANK, {
    onCompleted: () => {
      closeStackAttachedCollectorTankFlushPrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <PromptModal
      title={`Flush Return Tank for Zone ${zoneName} / Stack ${stackName}`}
      isOpen={stackAttachedCollectorTankFlushPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        flushStackAttachedCollectorTank({
          variables: {
            input: {
              stackId,
            },
          },
        });
      }}
      onComplete={closeStackAttachedCollectorTankFlushPrompt}
    >
      {`Are you sure you want to flush the return tank for Zone ${zoneName} / Stack ${stackName}?`}
    </PromptModal>
  );
};

const useStackAttachedCollectorTankFlushPrompt: (input: Input) => Result = ({
  zoneName,
  stackName,
  stackId,
  onCompleted,
}) => {
  const [
    stackAttachedCollectorTankFlushPromptIsOpen,
    setStackAttachedCollectorTankFlushPromptIsOpen,
  ] = useState(false);

  return {
    openStackAttachedCollectorTankFlushPrompt: useCallback(
      () => setStackAttachedCollectorTankFlushPromptIsOpen(true),
      [setStackAttachedCollectorTankFlushPromptIsOpen]
    ),
    StackAttachedCollectorTankFlushPrompt,
    stackAttachedCollectorTankFlushPromptProps: {
      zoneName,
      stackName,
      stackId,
      stackAttachedCollectorTankFlushPromptIsOpen,
      onCompleted,
      closeStackAttachedCollectorTankFlushPrompt: useCallback(
        () => setStackAttachedCollectorTankFlushPromptIsOpen(false),
        [setStackAttachedCollectorTankFlushPromptIsOpen]
      ),
    },
  };
};

export default useStackAttachedCollectorTankFlushPrompt;
