import gql from 'graphql-tag';

export const AUDIT_LOG_QUERY = gql`
  query auditLog($input: AuditLogQueryInputType!, $after: String) {
    currentTimestamp
    auditLog(input: $input, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          timestamp
          action
          user {
            isSystemUser
            firstName
            lastName
            email
          }
          facilityName
          facilityId
          ozoneSystemName
          zoneName
          locationName
          nutrientMixerPumpName
          stackName
          levelName
        }
      }
    }
  }
`;
