import gql from 'graphql-tag';

export const ADD_MANUAL_CALIBRATE_ZONE_ATTACHED_COLLECTOR_TANK_PRESSURE_SENSOR = gql`
  mutation manualCalibrateZoneAttachedCollectorTankPressureSensor(
    $input: ManualCalibrateZoneAttachedCollectorTankPressureSensorInputType!
  ) {
    manualCalibrateZoneAttachedCollectorTankPressureSensor(input: $input) {
      zone {
        zoneId
        collectorTank {
          autoCalibrated
          calibrationPoints
        }
      }
    }
  }
`;
