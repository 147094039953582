import React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { AttentionLevels } from '../../modules/attention-levels';

import HexagonIcon from '../HexagonIcon';

import style from './style.module.scss';

interface Props extends ButtonProps {
  zoneName: string;
  attentionLevel?: AttentionLevels;
}

const getAttentionStatusClass = (attentionLevel: AttentionLevels) => {
  switch (attentionLevel) {
    case AttentionLevels.Critical:
      return style.needsAttentionCritical;
    case AttentionLevels.Low:
      return style.needsAttentionLow;
    default:
      return null;
  }
};

export default ({
  className,
  zoneName,
  attentionLevel = AttentionLevels.None,
  ...props
}: Props) => {
  return (
    <Button
      className={[
        style.button,
        getAttentionStatusClass(attentionLevel),
        className,
      ].join(' ')}
      {...props}
    >
      <HexagonIcon
        className={style.hexagon}
        innerClassName={style.hexagonInner}
      />

      <div className={style.content}>
        <div className={style.label}>Zone</div>
        <div className={style.name}>{zoneName}</div>
      </div>
    </Button>
  );
};
