import gql from 'graphql-tag';

export const USERS_QUERY = gql`
  query usersQuery($input: UserManagementUsersQueryInputType!) {
    settings {
      userManagement {
        users(input: $input) {
          edges {
            node {
              userId
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;
