import gql from 'graphql-tag';
import { FRAGMENT_OZONE_SYSTEM } from './fragmentOzoneSystem';

export const GET_OZONE_SYSTEM = gql`
  query ozoneSystem($ozoneSystemId: ID!) {
    ozoneSystem(ozoneSystemId: $ozoneSystemId) {
      ...CalibrateOzoneSystemTankForm
    }
  }
  ${FRAGMENT_OZONE_SYSTEM}
`;
