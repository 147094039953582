import React, { FC, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useSubscription } from 'react-apollo';
import useModalVisible from '../../useModalVisible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import { isEmpty } from 'lodash';

import { SaveModal } from '../../../components/SaveModal';
import ModalTitle from '../../../components/ModalTitle';
import ModalLoadingContainer from '../../../components/ModalLoadingContainer';
import Switch from '../../../components/Switch';

import { getValidationErrors } from '../../../modules/errors';

import style from '../style.module.scss';
import AutoCalibrationForm from '../../../components/AutoCalibrationForm';
import ManualCalibrationForm from '../../../components/ManualCalibrationForm';

import {
  createAutoCalibrateTankInput,
  createAutoCalibrationPointsFromData,
  createBlankAutoCalibrationPoints,
} from '../../utility/createCalibrationPoints';
import { Input } from '../../types/OzoneSystemName';
import { GET_OZONE_SYSTEM } from '../../gql/getOzoneSystem';
import { SUBSCRIPTION_OZONE_SYSTEM } from '../../gql/subscribeOzoneSystem';
import { ADD_MANUAL_CALIBRATE_OZONE_SYSTEM_TANK_PRESSURE_SENSOR } from '../../gql/addManualCalibrateOzoneSystemTankPressureSensor';
import { ADD_AUTO_CALIBRATE_OZONE_SYSTEM_TANK_PRESSURE_SENSOR } from '../../gql/addAutoCalibrateOzoneSystemTankPressureSensor';
import { AutoCalibrationPoints } from '../../types/AutoCalibrationPoint';

interface Props extends Input {
  calibrateOzoneSystemTankFormIsOpen: boolean;
  closeCalibrateOzoneSystemTankForm: () => void;
}

interface Result {
  openCalibrateOzoneSystemTankForm: () => void;
  CalibrateOzoneSystemTankForm: typeof CalibrateOzoneSystemTankForm;
  calibrateOzoneSystemTankFormProps: Props;
}

const CalibrateOzoneSystemTankForm: FC<Props> = ({
  ozoneSystemName,
  ozoneSystemId,
  calibrateOzoneSystemTankFormIsOpen,
  closeCalibrateOzoneSystemTankForm,
  onCompleted,
}: Props): JSX.Element => {
  const [visible, modalVisibleProps] = useModalVisible();

  const result = useQuery(GET_OZONE_SYSTEM, {
    variables: { ozoneSystemId },
    skip: !visible,
  });

  useSubscription(SUBSCRIPTION_OZONE_SYSTEM, {
    variables: { ozoneSystemId },
    skip: !visible,
  });

  const [
    autoCalibrateOzoneSystemTank,
    { error: autoCalibrateSaveError, loading: autoCalibrateSaving },
  ] = useMutation(ADD_AUTO_CALIBRATE_OZONE_SYSTEM_TANK_PRESSURE_SENSOR, {
    onCompleted: () => {
      onCompleted && onCompleted();
    },
  });

  const [
    manualCalibrateZoneCollectorTank,
    { error: manualCalibrateSaveError, loading: manualCalibrateSaving },
  ] = useMutation(ADD_MANUAL_CALIBRATE_OZONE_SYSTEM_TANK_PRESSURE_SENSOR, {
    onCompleted: () => {
      onCompleted && onCompleted();
    },
  });

  const { refetch } = result;
  const [wasOpen, setWasOpen] = useState<boolean>(false);
  const [saveAttempted, setSaveAttempted] = useState<boolean>(false);

  const isDirtyState = useState<boolean>(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const [autoCalibrate, setAutoCalibrate] = useState<boolean>(false);
  const [manualCalibrationPoints, setManualCalibrationPoints] = useState<
    number[]
  >(new Array<number>());
  const [autoCalibrationPoints, setAutoCalibrationPoints] = useState<
    AutoCalibrationPoints
  >(createBlankAutoCalibrationPoints());
  const [currentWaterDepth, setCurrentWaterDepth] = useState<number>(0);

  useEffect(() => {
    const reopened: boolean = calibrateOzoneSystemTankFormIsOpen && !wasOpen;
    if (reopened) {
      refetch();
    }
    setWasOpen(calibrateOzoneSystemTankFormIsOpen);
  }, [calibrateOzoneSystemTankFormIsOpen, refetch, wasOpen, setWasOpen]);

  const { data, loading } = result;
  const ozoneSystem = data?.ozoneSystem || null;
  const tank = ozoneSystem?.tank || null;

  useEffect(() => {
    if (tank) {
      setAutoCalibrate(tank.autoCalibrated ?? false);
      if (tank.autoCalibrated) {
        setAutoCalibrationPoints(
          createAutoCalibrationPointsFromData(tank.calibrationPoints)
        );
        setAutoCalibrate(true);
      } else {
        setManualCalibrationPoints(
          tank.calibrationPoints
            .map((value: number) => {
              return value.toFixed(2);
            })
            .reverse()
        );
        setAutoCalibrate(false);
      }
    }
  }, [tank]);

  const addCalibrationPoints = async (currentWaterDepth: number) => {
    tank.calibrationPoints.push(currentWaterDepth);
    await manualCalibrateZoneCollectorTank({
      variables: {
        input: {
          ozoneSystemId: ozoneSystemId,
          currentWaterDepth: currentWaterDepth,
          clearPreviousOffsets: false,
        },
      },
    });

    setManualCalibrationPoints(
      tank.calibrationPoints
        .map((value: number) => {
          return value.toFixed(2);
        })
        .reverse()
    );
  };

  const removeCalibrationPoints = async () => {
    setManualCalibrationPoints((tank.calibrationPoints = []));

    await manualCalibrateZoneCollectorTank({
      variables: {
        input: {
          ozoneSystemId: ozoneSystemId,
          currentWaterDepth: -1,
          clearPreviousOffsets: true,
        },
      },
    });
  };

  const saving = autoCalibrateSaving || manualCalibrateSaving;
  const saveError = autoCalibrateSaveError || manualCalibrateSaveError;

  const autoCalibrateValidationErrors = {
    ...(isEmpty(autoCalibrationPoints.lLPressureSensorHeight) && {
      lLSensorHeight: 'LL Sensor height is required',
    }),
    ...(isEmpty(autoCalibrationPoints.lPressureSensorHeight) && {
      lSensorHeight: 'L Sensor height is required',
    }),
    ...(isEmpty(autoCalibrationPoints.hPressureSensorHeight) && {
      hSensorHeight: 'H Sensor height is required',
    }),
    ...(isEmpty(autoCalibrationPoints.hHPressureSensorHeight) && {
      hHSensorHeight: 'HH Sensor height is required',
    }),
    ...(parseFloat(autoCalibrationPoints.lLPressureSensorHeight ?? '') >
      parseFloat(autoCalibrationPoints.lPressureSensorHeight ?? '') && {
      lLSensorHeight: 'LL Sensor height cannot be greater than L Sensor height',
    }),
    ...(parseFloat(autoCalibrationPoints.lPressureSensorHeight ?? '') >
      parseFloat(autoCalibrationPoints.hPressureSensorHeight ?? '') && {
      lSensorHeight: 'L Sensor height cannot be greater than H Sensor height',
    }),
    ...(parseFloat(autoCalibrationPoints.hPressureSensorHeight ?? '') >
      parseFloat(autoCalibrationPoints.hHPressureSensorHeight ?? '') && {
      hSensorHeight: 'H Sensor height cannot be greater than HH Sensor height',
    }),
    ...(!isDirty &&
      saveAttempted &&
      saveError &&
      getValidationErrors(saveError)),
  };

  const clearPreviousOffsets = false;

  const manualCalibrateValidationErrors = {
    ...(manualCalibrationPoints.length > 3 &&
      clearPreviousOffsets === false && {
        clearOldCalibrationData:
          'Calibration data must be cleared before adding more points',
      }),
  };

  const hasValidationErrors =
    (autoCalibrate && !isEmpty(autoCalibrateValidationErrors)) ||
    (!autoCalibrate && !isEmpty(manualCalibrateValidationErrors));

  const isFormValid = !loading && !hasValidationErrors;

  return (
    <SaveModal
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faCog} />}
          title={`Calibration Ozone ${ozoneSystemName} Tank`}
        />
      }
      className={style.modal}
      scrollable
      isOpen={calibrateOzoneSystemTankFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      hideSaveButton={true && !autoCalibrate}
      showCloseButton={true}
      onComplete={() => {
        setAutoCalibrationPoints(createBlankAutoCalibrationPoints());
        setManualCalibrationPoints(new Array<number>());
        setCurrentWaterDepth(0);
        closeCalibrateOzoneSystemTankForm();
      }}
      onSave={async () => {
        setAutoCalibrationPoints(createBlankAutoCalibrationPoints());
        setManualCalibrationPoints(new Array<number>());
        setCurrentWaterDepth(0);
        setIsDirty(false);
        setSaveAttempted(true);
        if (autoCalibrate) {
          autoCalibrateOzoneSystemTank({
            variables: {
              input: createAutoCalibrateTankInput(
                ozoneSystemId,
                'ozoneSystemId',
                autoCalibrationPoints
              ),
            },
          });
        }
      }}
      {...modalVisibleProps}
    >
      <ModalLoadingContainer
        className={style.calibrationContainer}
        resourceTypeName="Ozone System"
        result={result}
        resourceExists={!!ozoneSystem}
      >
        {tank && (
          <>
            <p className={style.calibrationModeLabel}>Auto-Calibration</p>
            <Switch
              id="calibrationModeSwitch"
              className={style.calibrationModeSwitch}
              onChange={(checked: boolean) => setAutoCalibrate(checked)}
              checked={autoCalibrate}
              disabled={false}
            />
          </>
        )}
        {tank &&
          (autoCalibrate ? (
            <>
              <AutoCalibrationForm
                className={style}
                autoCalibrationPoints={autoCalibrationPoints}
                autoCalibrateValidationErrors={autoCalibrateValidationErrors}
                isDirtyState={isDirtyState}
                setAutoCalibrationPoints={setAutoCalibrationPoints}
              />
            </>
          ) : (
            <>
              <ManualCalibrationForm
                className={style}
                manualCalibrationPoints={manualCalibrationPoints}
                isDirtyState={isDirtyState}
                addCalibrationPoints={addCalibrationPoints}
                currentWaterDepth={currentWaterDepth}
                setCurrentWaterDepth={setCurrentWaterDepth}
                removeCalibrationPoints={removeCalibrationPoints}
              />
            </>
          ))}
      </ModalLoadingContainer>
    </SaveModal>
  );
};

const useCalibrateOzoneSystemTankForm: (input: Input) => Result = ({
  ozoneSystemName,
  ozoneSystemId,
  onCompleted,
}) => {
  const [
    calibrateOzoneSystemTankFormIsOpen,
    setCalibrateOzoneSystemTankFormIsOpen,
  ] = useState(false);

  return {
    openCalibrateOzoneSystemTankForm: useCallback(
      () => setCalibrateOzoneSystemTankFormIsOpen(true),
      [setCalibrateOzoneSystemTankFormIsOpen]
    ),
    CalibrateOzoneSystemTankForm,
    calibrateOzoneSystemTankFormProps: {
      ozoneSystemName,
      ozoneSystemId,
      calibrateOzoneSystemTankFormIsOpen,
      onCompleted,
      closeCalibrateOzoneSystemTankForm: useCallback(
        () => setCalibrateOzoneSystemTankFormIsOpen(false),
        [setCalibrateOzoneSystemTankFormIsOpen]
      ),
    },
  };
};

export default useCalibrateOzoneSystemTankForm;
