import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  stackName: string;
  levelName: string;
  levelId: string;
  onCompleted?: () => void;
  stateHasChanged: (state: string) => void;
}

interface Result {
  openStackLevelOpenValvePrompt: () => void;
  openStackLevelCloseValvePrompt: () => void;
  StackLevelValveStatePrompts: typeof StackLevelValveStatePrompts;
  stackLevelValveStatePromptsProps: Props;
}

export const UPDATE_LEVEL_VALVE_STATE = gql`
  mutation updateLevelValveState($levelId: ID!, $valveState: ValveState!) {
    updateLevelValveState(
      levelId: $levelId
      valveState: $valveState
      isDrainValve: true
    ) {
      levelId
      valveState
    }
  }
`;

interface Props extends Input {
  closeValvePromptIsOpen: boolean;
  openValvePromptIsOpen: boolean;
  closeCloseValvePrompt: () => void;
  closeOpenValvePrompt: () => void;
}

const StackLevelValveStatePrompts = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  openValvePromptIsOpen,
  closeValvePromptIsOpen,
  stateHasChanged,
  closeOpenValvePrompt,
  closeCloseValvePrompt,
  onCompleted,
}: Props) => {
  const [updateStackLevelValveState, { error, loading: saving }] = useMutation(
    UPDATE_LEVEL_VALVE_STATE,
    {
      onCompleted: () => {
        closeOpenValvePrompt();
        closeCloseValvePrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <>
      <PromptModal
        title={`Open Drain Valve for Zone ${zoneName} / Stack ${stackName} / Level ${levelName}`}
        isOpen={openValvePromptIsOpen}
        saving={saving}
        error={error}
        danger={true}
        onSave={() => {
          updateStackLevelValveState({
            variables: {
              levelId,
              valveState: 'OPEN',
            },
          });
          stateHasChanged('OPEN');
        }}
        onComplete={closeOpenValvePrompt}
      >
        <p>
          Opening the drain valve will cause it to remain open as long as the
          stack is in maintenance mode irrespective of whether the level is in
          automatic or maintenance mode.
        </p>
        <p>
          {`Open drain valve for Zone ${zoneName} / Stack ${stackName} / Level ${levelName}?`}
        </p>
      </PromptModal>

      <PromptModal
        title={`Close Drain Valve for Zone ${zoneName} / Stack ${stackName} / Level ${levelName}`}
        isOpen={closeValvePromptIsOpen}
        saving={saving}
        error={error}
        danger={true}
        onSave={() => {
          updateStackLevelValveState({
            variables: {
              levelId,
              valveState: 'CLOSED',
            },
          });
          stateHasChanged('CLOSED');
        }}
        onComplete={closeCloseValvePrompt}
      >
        <p>
          Closing the drain valve will cause it to remain closed as long as the
          stack is in maintenance mode irrespective of whether the level is in
          automatic or maintenance mode.
        </p>
        <p>
          {`Close valve for Zone ${zoneName} / Stack ${stackName} / Level ${levelName}?`}
        </p>
      </PromptModal>
    </>
  );
};

const useStackLevelValveStatePrompts: (input: Input) => Result = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  stateHasChanged,
  onCompleted,
}: Input) => {
  const [openValvePromptIsOpen, setOpenValvePromptIsOpen] = useState(false);

  const [closeValvePromptIsOpen, setCloseValvePromptIsOpen] = useState(false);

  return {
    openStackLevelOpenValvePrompt: useCallback(
      () => setOpenValvePromptIsOpen(true),
      [setOpenValvePromptIsOpen]
    ),
    openStackLevelCloseValvePrompt: useCallback(
      () => setCloseValvePromptIsOpen(true),
      [setCloseValvePromptIsOpen]
    ),
    StackLevelValveStatePrompts,
    stackLevelValveStatePromptsProps: {
      zoneName,
      stackName,
      levelName,
      levelId,
      openValvePromptIsOpen,
      closeValvePromptIsOpen,
      stateHasChanged,
      onCompleted,
      closeOpenValvePrompt: useCallback(() => setOpenValvePromptIsOpen(false), [
        setOpenValvePromptIsOpen,
      ]),
      closeCloseValvePrompt: useCallback(
        () => setCloseValvePromptIsOpen(false),
        [setCloseValvePromptIsOpen]
      ),
    },
  };
};

export default useStackLevelValveStatePrompts;
