import React from 'react';
import { Input } from 'reactstrap';

import style from './style.module.scss';

interface Facility {
  facilityId: string;
  name: string;
}

interface Props
  extends Omit<React.HtmlHTMLAttributes<HTMLInputElement>, 'type'> {
  facilities: Array<Facility>;
}

export default ({ className, facilities, ...props }: Props) => {
  const multiple = facilities.length > 1;
  return (
    <Input
      type="select"
      className={[
        'custom-select',
        style.select,
        multiple ? style.multiple : null,
        className,
      ].join(' ')}
      disabled={!multiple}
      {...props}
    >
      {facilities.map(({ facilityId, name }) => (
        <option key={facilityId} value={facilityId}>
          {name}
        </option>
      ))}
    </Input>
  );
};
