import React, { useCallback, useRef, useState } from 'react';

const useReferredState = <T>(
  initialValue: T
): [T, React.MutableRefObject<T>, React.Dispatch<T>] => {
  const [state, setState] = useState<T>(initialValue);
  const stateRef = useRef<T>(state);

  const setReferredState = useCallback(
    (value: T) => {
      stateRef.current = value;
      setState(value);
    },
    [stateRef, setState]
  );

  return [state, stateRef, setReferredState];
};

export default useReferredState;
