import React from 'react';
import style from './style.module.scss';

interface Props extends React.HTMLAttributes<SVGSVGElement> {
  animate: boolean;
}

export default ({ className, animate, ...props }: Props) => {
  return (
    <svg
      className={[style.svg, className].join(' ')}
      enable-background="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      {...props}
    >
      <g transform="translate(-1.0515e-7 .28237)" fill="currentColor">
        <path
          d="m18.937 14.411c-0.29521-0.26769-0.75553-0.25018-1.0658 0l-2.3116 1.8488c-0.2827 0.22766-0.63545 0.35025-1.0007 0.35025h-2.9596c-0.22016 0-0.40028-0.18013-0.40028-0.40028 0-0.22016 0.18013-0.40028 0.40028-0.40028h1.9589c0.39778 0 0.76804-0.27269 0.83309-0.66547 0.08256-0.50036-0.30271-0.93566-0.79056-0.93566h-4.0028c-0.67548 0-1.3284 0.23266-1.8538 0.65796l-1.1633 0.94317h-1.386c-0.22016 0-0.40028 0.18013-0.40028 0.40028v2.4017c0 0.22016 0.18013 0.40028 0.40028 0.40028h8.9263c0.36276 0 0.7155-0.12259 1.0007-0.35025l3.7827-3.0271c0.38027-0.30271 0.41029-0.88312 0.03253-1.2234z"
          stroke-width=".025018"
        />
        <path
          d="m8.315 5.6516h-1.2283c0 2.3741 1.925 4.2991 4.2991 4.2991v2.7637c0 0.16889 0.13818 0.30708 0.30708 0.30708h0.61416c0.16889 0 0.30708-0.13819 0.30708-0.30708v-2.7637c0-2.3741-1.925-4.2991-4.2991-4.2991zm7.3699-1.2283c-1.616 0-3.0209 0.89245-3.756 2.211 0.53163 0.57961 0.92508 1.284 1.1324 2.0651 2.163-0.22455 3.8519-2.0517 3.8519-4.2761z"
          stroke-width=".019193"
        />
      </g>
    </svg>
  );
};
