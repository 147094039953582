import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  zoneId: string;
  onCompleted?: () => void;
}

interface Result {
  openOzoneZoneBypassValveAutomaticModePrompt: () => void;
  openOzoneZoneBypassValveMaintenanceModePrompt: () => void;
  OzoneZoneBypassValveModePrompts: typeof OzoneZoneBypassValveModePrompts;
  ozoneZoneBypassValveModePromptsProps: Props;
}

const UPDATE_OZONE_ZONE_BYPASS_VALVE_MODE = gql`
  mutation updateOzoneSystemZoneBypassValveMode(
    $input: UpdateOzoneSystemZoneBypassValveModeInputType!
  ) {
    updateOzoneSystemZoneBypassValveMode(input: $input) {
      bypassValve {
        state
        ... on OzonePerZoneControlZoneBypassValveType {
          mode
        }
      }
    }
  }
`;

interface Props extends Input {
  maintenanceModePromptIsOpen: boolean;
  automaticModePromptIsOpen: boolean;
  closeMaintenanceModePrompt: () => void;
  closeAutomaticModePrompt: () => void;
}

const OzoneZoneBypassValveModePrompts = ({
  zoneName,
  zoneId,
  automaticModePromptIsOpen,
  maintenanceModePromptIsOpen,
  closeAutomaticModePrompt,
  closeMaintenanceModePrompt,
  onCompleted,
}: Props) => {
  const [
    updateOzoneZoneBypassValveMode,
    { error, loading: saving },
  ] = useMutation(UPDATE_OZONE_ZONE_BYPASS_VALVE_MODE, {
    onCompleted: () => {
      closeAutomaticModePrompt();
      closeMaintenanceModePrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <>
      <PromptModal
        title={`Put Ozone Bypass Valve for Zone ${zoneName} into Automatic Mode`}
        isOpen={automaticModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneZoneBypassValveMode({
            variables: {
              input: { zoneId, mode: 'AUTOMATIC' },
            },
          });
        }}
        onComplete={closeAutomaticModePrompt}
      >
        <p>
          {`Putting the bypass valve for Zone ${zoneName} into automatic mode will allow the
          the ozone bypass valve to open and close based on whether the ozone tank has capacity.`}
        </p>

        <p>{`Put the ozone bypass valve for Zone ${zoneName} into automatic mode?`}</p>
      </PromptModal>

      <PromptModal
        title={`Put Ozone Bypass Valve for Zone ${zoneName} into Maintenance Mode`}
        isOpen={maintenanceModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneZoneBypassValveMode({
            variables: {
              input: {
                zoneId,
                mode: 'MAINTENANCE',
              },
            },
          });
        }}
        onComplete={closeMaintenanceModePrompt}
      >
        <p>
          {`Putting the bypass valve for Zone ${zoneName} into maintenance mode will allow the bypass valve to be manually controlled.`}
        </p>

        <p>{`Put the ozone bypass valve for Zone ${zoneName} into maintenance mode?`}</p>
      </PromptModal>
    </>
  );
};

const useOzoneZoneBypassValveModePrompts: (input: Input) => Result = ({
  zoneName,
  zoneId,
  onCompleted,
}: Input) => {
  const [automaticModePromptIsOpen, setAutomaticModePromptIsOpen] = useState(
    false
  );

  const [
    maintenanceModePromptIsOpen,
    setMaintenanceModePromptIsOpen,
  ] = useState(false);

  return {
    openOzoneZoneBypassValveAutomaticModePrompt: useCallback(
      () => setAutomaticModePromptIsOpen(true),
      [setAutomaticModePromptIsOpen]
    ),
    openOzoneZoneBypassValveMaintenanceModePrompt: useCallback(
      () => setMaintenanceModePromptIsOpen(true),
      [setMaintenanceModePromptIsOpen]
    ),
    OzoneZoneBypassValveModePrompts,
    ozoneZoneBypassValveModePromptsProps: {
      zoneName,
      zoneId,
      automaticModePromptIsOpen,
      maintenanceModePromptIsOpen,
      onCompleted,
      closeAutomaticModePrompt: useCallback(
        () => setAutomaticModePromptIsOpen(false),
        [setAutomaticModePromptIsOpen]
      ),
      closeMaintenanceModePrompt: useCallback(
        () => setMaintenanceModePromptIsOpen(false),
        [setMaintenanceModePromptIsOpen]
      ),
    },
  };
};

export default useOzoneZoneBypassValveModePrompts;
