import React, { useCallback } from 'react';

import style from './style.module.scss';

import Tank from '../Tank';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

interface Props {
  className?: string;
  entityType: string;
  entityName: string;
  waterLevel?: string;
  pumpState?: string;
  hidePump?: boolean;
  dryTankDetected?: boolean;
  gallons?: string;
  hasSensorStateAlert?: boolean;
  levelSensors?: boolean[];
  sensorsOnRight?: boolean;
  children?: any;
  title: string;
  hasHelp: boolean;
  dataPlacement: string;
  biggerTank: boolean;
  pumpMode: any | null;
  zoneId: number | null;
}

const FORCE_PUMP_RESUME = gql`
  mutation forceResume($input: ForceZoneDistributionPumpResumeInputType!) {
    forceZoneDistributionPumpResumeCommand(input: $input) {
      __typename
    }
  }
`;

export default ({
  className,
  entityType,
  entityName,
  waterLevel,
  pumpState,
  dryTankDetected,
  gallons,
  hidePump,
  levelSensors,
  sensorsOnRight,
  children,
  title = '',
  hasHelp,
  dataPlacement = 'top',
  biggerTank = false,
  pumpMode,
  zoneId,
}: Props) => {
  const [forcePumpResume, forcePumpProps] = useMutation(FORCE_PUMP_RESUME);

  const forceZoneTankReset = () => {
    if (zoneId == null) return;

    forcePumpResume({
      variables: {
        input: {
          zoneId: zoneId,
        },
      },
    });
  };

  return (
    <div className={[style.container, className].join(' ')} title={title}>
      <div className={style.identifier}>
        <div className={style.entity}>{entityType}</div>
        <div className={style.name}>{entityName}</div>
      </div>
      <Tank
        dataPlacement={dataPlacement}
        hasHelp={hasHelp}
        title={title}
        unknownIconClassName={style.unknownIcon}
        waterLevel={waterLevel}
        pumpState={pumpState}
        gallons={gallons}
        hidePump={hidePump}
        volumeValueClassName={style.volumeValue}
        volumeUnitClassName={style.volumeUnit}
        levelSensors={levelSensors}
        sensorsOnRight={sensorsOnRight}
        dryTankDetected={dryTankDetected}
        biggerTank={biggerTank}
        pumpMode={pumpMode}
        onForcePumpResume={forceZoneTankReset}
      >
        {children}
      </Tank>
    </div>
  );
};
