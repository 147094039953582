import React, { useEffect, useRef, useState } from 'react';

const emailAddressRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function getInputValueAnd(
  useValue: (value: string) => void
): (e: React.ChangeEvent<HTMLInputElement>) => void;
export function getInputValueAnd(
  useValue: (value: boolean) => void
): (e: React.ChangeEvent<HTMLInputElement>) => void;
export function getInputValueAnd<T>(
  useValue: (value: T) => void
): (e: React.ChangeEvent<HTMLInputElement>) => void;
export function getInputValueAnd(useValue: (value: any) => void) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    useValue(e.target.type === 'checkbox' ? e.target.checked : e.target.value);
  };
}

export const convertValue = <TValue>(
  converter: (v: string) => TValue,
  useValue: (value: TValue) => void
) => (input: string) => {
  return useValue(converter(input));
};

export const updateState = <TValue>({
  isDirtyState: [isDirty, setIsDirty],
  valueState: [currentValue, setValue],
}: {
  isDirtyState: [boolean, (x: boolean) => void];
  valueState: [TValue, (x: TValue) => void];
}) => (newValue: TValue) => {
  setIsDirty(isDirty || currentValue !== newValue);
  setValue(newValue);
};

export const isValidEmailAddress = (emailAddress: string) => {
  return emailAddressRegex.test(String(emailAddress).toLowerCase());
};

export const useWaitForDelay = <TValue>(timeoutMs: number) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(
    () => () => {
      timer && clearTimeout(timer);
    },
    [timer]
  );

  return (fn: (v: TValue) => void) => (value: TValue) => {
    if (timer) {
      clearTimeout(timer);
    }

    setTimer(setTimeout(() => fn(value), timeoutMs));
  };
};

export const useFocus = () => {
  const inputElement = useRef<HTMLInputElement>(null);

  const focusElement = () => {
    if (inputElement?.current !== null) {
      inputElement.current!.focus();
    }
  };

  return {
    inputElement,
    focusElement,
  };
};
