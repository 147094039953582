import React from 'react';
import {
  Router,
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import history from '../modules/history';
import LoginPage from '../pages/LoginPage';
import FacilityPage from '../pages/FacilityPage';
import StackPage from '../pages/StackPage';
import SettingsPage from '../pages/SettingsPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import LevelPage from '../pages/LevelPage';

interface StackPageMatchParams {
  facilityId: string;
  zoneName: string;
  stackName: string;
}

interface StackPageRouteProps
  extends RouteComponentProps<StackPageMatchParams> {}

interface LevelPageMatchParams extends StackPageMatchParams {
  levelName: string;
}

interface LevelPageRouteProps
  extends RouteComponentProps<LevelPageMatchParams> {}

export default () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/facility/:facilityId/zone/:zoneName/stack/:stackName/level/:levelName"
          render={({
            match: {
              params: { facilityId, zoneName, stackName, levelName },
            },
          }: LevelPageRouteProps) => (
            <LevelPage
              facilityId={facilityId}
              zoneName={zoneName}
              stackName={stackName}
              levelName={levelName}
            />
          )}
        />

        <Route
          exact
          path="/facility/:facilityId/zone/:zoneName/stack/:stackName"
          render={({
            match: {
              params: { facilityId, zoneName, stackName },
            },
          }: StackPageRouteProps) => (
            <StackPage
              facilityId={facilityId}
              zoneName={zoneName}
              stackName={stackName}
            />
          )}
        />

        <Route path="/login" component={LoginPage} />

        <Route path="/forgot-password" component={ForgotPasswordPage} />

        <Route path="/reset-password" component={ResetPasswordPage} />

        <Route path="/facility" component={FacilityPage} />

        <Route path="/settings" component={SettingsPage} />

        <Redirect to="/facility" />
      </Switch>
    </Router>
  );
};
