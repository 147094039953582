import React, { useEffect, useState } from 'react';

import style from './style.module.scss';

export interface Sector {
  name: string;
  safename: string;
  color: string;
  min: number;
  max: number;
}

export interface Props {
  className: string | undefined;
  sectors: Sector[] | undefined;
  pointerColor: string | undefined;
  height: number;
  value: number;
  min: number;
  max: number;
  unitOfMeasure: string;
  sectorChangedCallback: (e: string) => void;
  backgroundColor: string | undefined;
  label: string | undefined;
  isMini: boolean | undefined;
  onClick: () => void;
}

function roundToTwo(num: number) {
  // @ts-ignore
  return +(Math.round(num + 'e+2') + 'e-2');
}

export default ({
  className = '',
  sectors = [],
  pointerColor = '',
  height = 5,
  value = 0,
  min = 0,
  max = 100,
  sectorChangedCallback = e => {},
  backgroundColor = 'transparent',
  unitOfMeasure = '%',
  label = '',
  isMini = false,
  onClick = () => {},
}: Props) => {
  const [pointerValue, setPointerValue] = useState(value);
  console.log(
    'Value: ' + value + '  min:' + min + '  max:' + max + ' sectors:'
  );
  console.log({ sectors });
  useEffect(() => {
    if (sectors == null) return;

    for (let i = 0; i < sectors.length; ++i) {
      if (pointerValue >= sectors[i].min && sectors[i].max <= pointerValue) {
        sectorChangedCallback(sectors[i].safename);
        return;
      }
    }

    sectorChangedCallback('default');
  }, [pointerValue]);

  return (
    <div className="row" onClick={onClick}>
      <div className="col-12 px-2">
        <div
          className={`${style.container} ${className != null ? className : ''}`}
          style={{
            height: `${height}em`,
            margin: 'auto',
            backgroundColor: backgroundColor,
          }}
        >
          {max >= 1 && (
            <div
              className={style.pointer}
              style={{
                borderLeft: `1em solid ${pointerColor ?? '#DDDDDD'}`,
                bottom: `${height *
                  ((pointerValue < max ? pointerValue : max - 1) / max) -
                  0.5}em`,
              }}
            ></div>
          )}

          {max < 1 && (
            <div
              className={style.pointer}
              style={{
                borderLeft: `1em solid ${pointerColor ?? '#DDDDDD'}`,
                bottom: `${height *
                  ((pointerValue < max ? pointerValue : max) / max) -
                  0.2}em`,
              }}
            ></div>
          )}
          <div className={style.sectors}>
            {sectors?.map((i, keyValue) => {
              return (
                <div
                  key={keyValue}
                  className={style.sector}
                  style={{
                    bottom: '0',
                    transform: `translate(0, -${height * (i.min / max)}em)`,
                    height: `${height * (i.max / max) -
                      height * (i.min / max) +
                      0.1}em`,
                    backgroundColor: i.color,
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      </div>
      {label != '' && isMini ? (
        <div className="col-12 mt-1 text-center">
          <span className="small">
            {label} - {roundToTwo(value)}
            {unitOfMeasure === '%' ? '%' : ` ${unitOfMeasure}`}
          </span>
        </div>
      ) : (
        <div className="col-12 mt-2 text-center">
          <span className="small">{label}</span>
          <br />
          <span>
            {roundToTwo(value)}
            {unitOfMeasure === '%' ? '%' : ` ${unitOfMeasure}`}
          </span>
        </div>
      )}
    </div>
  );
};
