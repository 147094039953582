import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faSearch,
  faTrashAlt,
  faTrashRestoreAlt,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Label, Input, InputGroup, Table } from 'reactstrap';

import Loading from '../../../../components/Loading';
import PageError from '../../../../components/PageError';

import useRoleDeletePrompt from '../../../../hooks/useRoleDeletePrompt';
import useRoleRestorePrompt from '../../../../hooks/useRoleRestorePrompt';
import useRoleEditForm from '../../../../hooks/useRoleEditForm';
import useRoleNewForm from '../../../../hooks/useRoleNewForm';

import {
  getInputValueAnd,
  useWaitForDelay,
} from '../../../../modules/form-helpers';

import style from './style.module.scss';

const ROLES_QUERY = gql`
  query rolesQuery($input: UserManagementRolesQueryInputType!, $after: String) {
    settings {
      roleManagement {
        roles(input: $input, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              roleId
              name
              deleted
            }
          }
        }
      }
    }
  }
`;

const SEARCH_DELAY_MS = 500;

const SortColumnNames = {
  TITLE: 'TITLE',
  DELETED: 'DELETED',
};

const getSortOrder = ({ name, desc }) =>
  `${name}_${desc ? 'DESCENDING' : 'ASCENDING'}`;

const SortableColumnHeader = ({
  className,
  columnName,
  currentSortColumn,
  onSort,
  children,
}) => {
  return (
    <th
      className={className}
      style={{ cursor: 'pointer' }}
      onClick={() => onSort(columnName)}
    >
      {children}
      {currentSortColumn.name === columnName && (
        <span className={style.sortIconContainer}>
          {currentSortColumn.desc ? (
            <FontAwesomeIcon icon={faCaretDown} />
          ) : (
            <FontAwesomeIcon icon={faCaretUp} />
          )}
        </span>
      )}
    </th>
  );
};

const DeleteRoleButton = ({
  roleName,
  roleId,
  roleDeleted: _,
  onCompleted,
  ...props
}) => {
  const {
    openRoleDeletePrompt,
    RoleDeletePrompt,
    roleDeletePromptProps,
  } = useRoleDeletePrompt({ roleName, roleId, onCompleted });

  return (
    <>
      <RoleDeletePrompt {...roleDeletePromptProps} />

      <Button onClick={openRoleDeletePrompt} {...props}>
        <FontAwesomeIcon icon={faTrashAlt} fixedWidth />
      </Button>
    </>
  );
};

const RestoreRoleButton = ({
  roleName,
  roleId,
  roleDeleted: _,
  onCompleted,
  ...props
}) => {
  const {
    openRoleRestorePrompt,
    RoleRestorePrompt,
    roleRestorePromptProps,
  } = useRoleRestorePrompt({
    roleName,
    roleId,
    onCompleted,
  });

  return (
    <>
      <RoleRestorePrompt {...roleRestorePromptProps} />

      <Button onClick={openRoleRestorePrompt} {...props}>
        <FontAwesomeIcon icon={faTrashRestoreAlt} fixedWidth />
      </Button>
    </>
  );
};

const RoleEditFormElement = ({
  tag: Tag,
  roleName,
  roleId,
  roleDeleted,
  onCompleted,
  children,
  ...props
}) => {
  const { openRoleEditForm, RoleEditForm, roleEditFormProps } = useRoleEditForm(
    {
      roleId,
      onCompleted,
    }
  );

  const {
    openRoleRestorePrompt,
    RoleRestorePrompt,
    roleRestorePromptProps,
  } = useRoleRestorePrompt({
    roleName,
    roleId,
    onCompleted,
  });

  return (
    <>
      <RoleEditForm {...roleEditFormProps} />

      <RoleRestorePrompt {...roleRestorePromptProps} />

      <Tag
        onClick={roleDeleted ? openRoleRestorePrompt : openRoleEditForm}
        {...props}
      >
        {children}
      </Tag>
    </>
  );
};

const RolesPage = () => {
  const waitForSearchDelay = useWaitForDelay(SEARCH_DELAY_MS);
  const [roles, setRoles] = useState(null);
  const [search, setSearch] = useState('');

  const [sortColumn, setSortColumn] = useState({
    name: SortColumnNames.TITLE,
    desc: false,
  });

  const [queryInput, setQueryInput] = useState({
    sortOrder: getSortOrder(sortColumn),
  });

  const [loadingMore, setLoadingMore] = useState(false);

  const { loading, error, data, refetch, fetchMore } = useQuery(ROLES_QUERY, {
    variables: { input: queryInput },
    onCompleted: () => setRoles(data?.settings?.roleManagement?.roles),
  });

  const { openRoleNewForm, RoleNewForm, roleNewFormProps } = useRoleNewForm({
    onCompleted: () => refetch({ variables: { input: queryInput } }),
  });

  const refetchWithSearch = value => {
    const newQueryInput = {
      ...queryInput,
      search: value,
    };

    setQueryInput(newQueryInput);
    refetch({ variables: { input: newQueryInput } });
  };

  const refetchWithToggleIncludeDeleted = () => {
    const newQueryInput = {
      ...queryInput,
      includeDeleted: !queryInput.includeDeleted,
    };

    setQueryInput(newQueryInput);
    refetch({ variables: { input: newQueryInput } });
  };

  const updateSearch = value => {
    setSearch(value);

    if (value.trim() !== (queryInput.search || '').trim()) {
      waitForSearchDelay(refetchWithSearch)(value);
    }
  };

  const isRefreshing = loading && !loadingMore;
  const hasError = !loading && error;
  const hasNextPage =
    !hasError && !isRefreshing && !!roles?.pageInfo.hasNextPage;
  const isEmpty = !hasError && roles?.edges.length === 0;

  const toggleSortColumn = sortColumnName => {
    const newSortColumn =
      sortColumn.name === sortColumnName
        ? {
            ...sortColumn,
            desc: !sortColumn.desc,
          }
        : {
            name: sortColumnName,
            desc: false,
          };

    const newQueryInput = {
      ...queryInput,
      sortOrder: getSortOrder(newSortColumn),
    };

    setSortColumn(newSortColumn);
    setQueryInput(newQueryInput);
    refetch({ variables: { input: newQueryInput } });
  };

  return (
    <div
      className={[style.container, isRefreshing ? style.refreshing : null].join(
        ' '
      )}
    >
      <RoleNewForm {...roleNewFormProps} />

      {isRefreshing && (
        <div className={style.loadingContainer}>
          <Loading />
        </div>
      )}

      <div className={style.rolesTableContainer}>
        <Button color="success" onClick={openRoleNewForm}>
          <FontAwesomeIcon icon={faUserPlus} fixedWidth /> Create Role
        </Button>

        <InputGroup className={style.searchInput}>
          <Input
            type="search"
            value={search}
            onChange={getInputValueAnd(updateSearch)}
            placeholder="Search"
          />
        </InputGroup>

        <div className={style.filterContainer}>
          <Input
            id="includeDeleted"
            addon
            type="checkbox"
            checked={queryInput.includeDeleted}
            onChange={refetchWithToggleIncludeDeleted}
          />

          <Label for="includeDeleted">Include Deleted</Label>
        </div>

        {!isEmpty && (
          <Table responsive dark>
            <thead>
              <tr>
                <th className={style.editColumn}></th>

                <SortableColumnHeader
                  className={style.activeColumn}
                  columnName={'TITLE'}
                  currentSortColumn={sortColumn}
                  onSort={toggleSortColumn}
                >
                  Title
                </SortableColumnHeader>

                {queryInput.includeDeleted && (
                  <SortableColumnHeader
                    className={style.activeColumn}
                    columnName={'DELETED'}
                    currentSortColumn={sortColumn}
                    onSort={toggleSortColumn}
                  >
                    Active
                  </SortableColumnHeader>
                )}
              </tr>
            </thead>

            {!hasError && roles && (
              <tbody>
                {roles.edges.map(({ node: role }) => {
                  const roleModalProps = {
                    roleName: role.name,
                    roleId: role.roleId,
                    roleDeleted: role.deleted,
                    onCompleted: () =>
                      refetch({ variables: { input: queryInput } }),
                  };

                  return (
                    <tr key={role.roleId}>
                      <td className={style.editColumn}>
                        {role.deleted ? (
                          <RestoreRoleButton color="link" {...roleModalProps} />
                        ) : (
                          <DeleteRoleButton color="link" {...roleModalProps} />
                        )}
                      </td>

                      <RoleEditFormElement tag="td" {...roleModalProps}>
                        {role.name}
                      </RoleEditFormElement>

                      {queryInput.includeDeleted && (
                        <RoleEditFormElement
                          tag="td"
                          className={style.activeColumn}
                          {...roleModalProps}
                        >
                          {!role.deleted && (
                            <FontAwesomeIcon icon={faCheck} fixedWidth />
                          )}
                        </RoleEditFormElement>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
        )}

        {hasError && <PageError error={error} showIcon={false} />}

        {isEmpty && !isRefreshing && (
          <div className={style.noResultsContainer}>
            <FontAwesomeIcon icon={faSearch} fixedWidth /> No Results
          </div>
        )}

        {hasNextPage && (
          <div className={style.moreButtonContainer}>
            <Button
              className={style.moreButton}
              disabled={loadingMore}
              onClick={() => {
                setLoadingMore(true);
                fetchMore({
                  variables: {
                    after: roles.pageInfo.endCursor,
                    input: queryInput,
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                    setLoadingMore(false);
                    const newEdges =
                      fetchMoreResult.settings.roleManagement.roles.edges;
                    const pageInfo =
                      fetchMoreResult.settings.roleManagement.roles.pageInfo;

                    const data = newEdges.length
                      ? {
                          settings: {
                            __typename: previousResult.settings.__typename,
                            roleManagement: {
                              __typename:
                                previousResult.settings.roleManagement
                                  .__typename,
                              roles: {
                                __typename:
                                  previousResult.settings.roleManagement.roles
                                    .__typename,
                                edges: [
                                  ...previousResult.settings.roleManagement
                                    .roles.edges,
                                  ...newEdges,
                                ],
                                pageInfo,
                              },
                            },
                          },
                        }
                      : previousResult;

                    setRoles(data?.settings?.roleManagement?.roles);
                    return data;
                  },
                });
              }}
            >
              {loadingMore ? 'Loading more...' : 'More'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RolesPage;
