import history from './history';

export interface MonikerProvider {
  instance: any;
  predicate: boolean;
}

export interface AuthenticationInterchange extends MonikerProvider {
  moniker: string;
  bailPolicy: () => void;
}

export function homebrewInterchange(): AuthenticationInterchange {
  return {
    moniker: '',
    instance: {},
    predicate: true,
    bailPolicy: () => {
      history.push('/login', { from: window.location.pathname });
    },
  };
}

export function msalInterchange(
  provider: MonikerProvider
): AuthenticationInterchange {
  return {
    moniker: 'msal',
    ...provider,
    bailPolicy: () => provider.instance.logoutRedirect(),
  };
}
