import gql from 'graphql-tag';

export const FRAGMENT_ZONE_SYSTEM = gql`
  fragment CalibrateZoneTankForm on ZoneType {
    zoneId
    tank {
      autoCalibrated
      calibrationPoints
    }
  }
`;
