import React, { useCallback, useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Loading from '../../components/Loading';
import { loginRequest } from '../../authConfig';
import Button from 'react-bootstrap/Button';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

//Force 'signed-in' state on user when MSAL is authenticated.
const FORCE_MSAL_AUTH = true;

interface Tokens {
  username: string;
  accessToken: string;
}

const handleLogin = (instance: any, exReporter: any) => {
  instance.loginRedirect(loginRequest).catch((e: any) => {
    console.error(e);
    exReporter({
      variables: {
        input: {
          id: 0,
          name: e?.name,
          cause: e?.cause,
          message: e?.message,
          fileName: e?.fileName,
          stackTrace: e?.stack,
          lineNumber: e?.lineNumber,
          colNumber: e?.columnNumber,
        },
      },
    })
      .then(() => {
        alert(`Fatal Authentication Error (Reported to server): ${e.message}`);
      })
      .catch(() => {
        alert(
          `Fatal Authentication Error (Failed to report to server): ${e.message}`
        );
      });
  });
};

const getTokensAsync = async (instance: any): Promise<Tokens | null> => {
  const accounts = instance.getAllAccounts();
  if (accounts.length === 0) return null;

  const accessTokenRequest = {
    scopes: ['user.read'],
    account: accounts[0],
  };

  const tokens = await instance.acquireTokenSilent(accessTokenRequest);
  return {
    username: accessTokenRequest.account.username,
    accessToken: tokens.accessToken,
  };
};

const EXCEPTION_REPORT = gql`
  mutation exceptionReporting($input: ExceptionReportCommandType!) {
    reportException(input: $input) {
      __typename
    }
  }
`;

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const MsalSignInButton = ({
  className,
  postAuth,
}: {
  className: string;
  postAuth: (tokens: Tokens) => void;
}) => {
  const [exceptionReporter, _] = useMutation(EXCEPTION_REPORT);
  const { instance } = useMsal();
  const authenticated = useIsAuthenticated();
  const [tokens, setTokens] = useState<Tokens | null>(null);

  //Token propagation to parent
  useEffect(() => {
    if (tokens == null) return;
    postAuth(tokens);
  }, [tokens]);

  useEffect(() => {
    if (authenticated) aquireTokens();
  }, [authenticated]);

  if (FORCE_MSAL_AUTH) {
    //Warning: This is for debugging purposes only.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => aquireTokens(), []);
  }

  //State-level handlers
  const aquireTokens = useCallback(() => {
    getTokensAsync(instance)
      .catch()
      .then((next: Tokens | null) => setTokens(prev => next));
  }, [instance]);

  const authWorkflow = () => {
    handleLogin(instance, exceptionReporter);
    aquireTokens();
  };

  if (authenticated) return <Loading />;

  return (
    <Button
      variant="secondary"
      className={`ml-auto ${className}`}
      onClick={authWorkflow}
    >
      Sign in using Microsoft
    </Button>
  );
};
