import React, { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useSubscription } from 'react-apollo';

import style from './style.module.scss';

import useOzoneRestrictionPrompts from '../../../../../../hooks/useOzoneRestrictionPrompts';
import useOzoneSystemModePrompts from '../../../../../../hooks/useOzoneSystemModePrompts';
import useOzoneZoneSupplyPumpModePrompts from '../../../../../../hooks/useOzoneZoneSupplyPumpModePrompts';
import useOzoneZoneSupplyPumpStatePrompts from '../../../../../../hooks/useOzoneZoneSupplyPumpStatePrompts';
import useOzoneBypassValveModePrompts from '../../../../../../hooks/useOzoneBypassValveModePrompts';
import useOzoneBypassValveStatePrompts from '../../../../../../hooks/useOzoneBypassValveStatePrompts';
import useOzoneZoneBypassValveModePrompts from '../../../../../../hooks/useOzoneZoneBypassValveModePrompts';
import useOzoneZoneBypassValveStatePrompts from '../../../../../../hooks/useOzoneZoneBypassValveStatePrompts';
import useOzoneTankInvalidWaterSensorStateResetPrompt from '../../../../../../hooks/useOzoneTankResetInvalidWaterSensorStatePrompt';
import useReverseOsmosisZoneSupplyValveModePrompts from '../../../../../../hooks/useReverseOsmosisZoneSupplyValveModePrompts';
import useReverseOsmosisZoneSupplyValveStatePrompts from '../../../../../../hooks/useReverseOsmosisZoneSupplyValveStatePrompts';

import OzoneSystemSelector from './components/OzoneSystemSelector';
import StacksImage from '../../../../../../assets/images/stacks.svg';
import PageLoadingContainer from '../../../../../../components/PageLoadingContainer';
import Pipe from '../../../../../../components/Pipe';
import Pump from '../../../../../../components/Pump';
import PumpButton from '../../../../../../components/PumpButton';
import Valve from '../../../../../../components/Valve';
import ValveButton from '../../../../../../components/ValveButton';
import ZoneTank from '../ZoneTank';
import ZoneDistributionTank from '../ZoneDistributionTank';
import Tank from '../../../../../../components/Tank';
import Switch from '../../../../../../components/Switch';
import InvalidWaterSensorStateIcon from '../../../../../../components/InvalidWaterSensorStateIcon';
import LinearGauge from '../../../../../../components/LinearGauge';
import { Descriptions } from '../../../../../../modules/Descriptions';
import { at } from 'lodash';

const ozoneSystemDetailFragment = gql`
  fragment OzoneSystemDetail on OzoneSystemType {
    ozoneSystemId
    isHidden
    name
    mode
    overrideRestriction
    updateOzoneSystemModeCommand {
      canExecute
    }
    updateOzoneSystemOverrideRestrictionCommand {
      canExecute
    }
    tank {
      waterLevel
      invalidWaterSensorStatesDetected
      invalidTankLevelSwitches {
        hh
        h
        l
        ll
      }
      resetOzoneTankInvalidStateAlertCommand {
        canExecute
      }
    }
    bypassValve {
      mode
      state
      updateOzoneSystemBypassValveModeCommand {
        systemStateSatisfied
        canExecute
      }
      updateOzoneSystemBypassValveStateCommand {
        canExecute
      }
    }

    zoneDistributionTanks {
      pump {
        state
        mode
        updateOzonePumpModeCommand {
          canExecute
        }
        flushOzoneTankIntoZoneSupplyCommand {
          canExecute
        }
      }
      zoneDistributionTank {
        zoneDistributionTankId
        name
        index
        waterLevel
        pumpState
        gallons
        zones {
          bypassValve {
            state
            ... on OzonePerZoneControlZoneBypassValveType {
              mode
              updateOzoneSystemZoneBypassValveModeCommand {
                systemStateSatisfied
                canExecute
              }
              updateOzoneSystemZoneBypassValveStateCommand {
                canExecute
              }
            }
          }
          zone {
            zoneId
            name
            isHidden
            isOzoneSystemHidden
            isDistributionTankHidden
            index
            tank {
              pumpState
            }
            collectorTank {
              pumpState
            }
            stacks {
              hasOpenLevelValves
              collectorTank {
                pumpState
              }
            }
            ...ZoneTank
          }
        }
      }
    }
    zones {
      bypassValve {
        state
        ... on OzonePerZoneControlZoneBypassValveType {
          mode
          updateOzoneSystemZoneBypassValveModeCommand {
            systemStateSatisfied
            canExecute
          }
          updateOzoneSystemZoneBypassValveStateCommand {
            canExecute
          }
        }
      }
      zone {
        zoneId
        name
        index
        tank {
          pumpState
        }
        collectorTank {
          pumpState
        }
        stacks {
          hasOpenLevelValves
          collectorTank {
            pumpState
          }
        }
        ...ZoneTank
      }
    }
  }
  ${ZoneTank.fragment}
`;

const ZONE_DIST_TANK_MODE = gql`
  {
    zoneDistributionTankStatuses {
      index
      state
      stateTimeout
    }
  }
`;

export const OZONE_SYSTEM_QUERY = gql`
  query ozoneSystem($ozoneSystemId: ID!) {
    ozoneSystem(ozoneSystemId: $ozoneSystemId) {
      ...OzoneSystemDetail
    }
  }
  ${ozoneSystemDetailFragment}
`;

const OZONE_SYSTEM_SUBSCRIPTION = gql`
  subscription ozoneSystemUpdated($ozoneSystemId: ID!) {
    ozoneSystemUpdated(ozoneSystemId: $ozoneSystemId) {
      ozoneSystem {
        ...OzoneSystemDetail
      }
    }
  }
  ${ozoneSystemDetailFragment}
`;

const ReverseOsmosisZoneSupplyValveButton = ({
  zoneSupplyTankName,
  zoneSupplyTankId,
  valveState,
  hasHelp,
  ...props
}) => {
  const [state, setState] = useState(valveState);
  const onCompleted = useCallback(
    isOpen => {
      setState(isOpen ? 'OPEN' : 'CLOSED');
    },
    [state]
  );

  const {
    openReverseOsmosisZoneSupplyValveOpenStatePrompt,
    openReverseOsmosisZoneSupplyValveCloseStatePrompt,
    ReverseOsmosisZoneSupplyValveStatePrompts,
    reverseOsmosisZoneSupplyValveStatePromptsProps,
  } = useReverseOsmosisZoneSupplyValveStatePrompts({
    zoneSupplyTankName,
    zoneSupplyTankId,
    onCompleted,
  });

  useEffect(() => {
    if (valveState != null) setState(valveState);
  }, [valveState]);

  return (
    <>
      <ReverseOsmosisZoneSupplyValveStatePrompts
        {...reverseOsmosisZoneSupplyValveStatePromptsProps}
      />

      <ValveButton
        hasHelp={hasHelp}
        data-toggle="tooltip"
        data-placement="top"
        title={Descriptions.reverseOsmosisValve}
        valveState={state}
        onClick={
          state === 'OPEN'
            ? openReverseOsmosisZoneSupplyValveCloseStatePrompt
            : openReverseOsmosisZoneSupplyValveOpenStatePrompt
        }
        {...props}
      />
    </>
  );
};

const ReverseOsmosisZoneSupplyPumpButton = ({
  zoneSupplyName,
  zoneSupplyId,
  pumpState,
  title = '',
  hasHelp,
  ...props
}) => {
  const {
    openOzoneZoneSupplyPumpStartStatePrompt,
    openOzoneZoneSupplyPumpStopStatePrompt,
    OzoneZoneSupplyPumpStatePrompts,
    ozoneZoneSupplyPumpStatePromptsProps,
  } = useOzoneZoneSupplyPumpStatePrompts({
    zoneSupplyName,
    zoneSupplyId,
  });

  return (
    <>
      <OzoneZoneSupplyPumpStatePrompts
        {...ozoneZoneSupplyPumpStatePromptsProps}
      />

      <PumpButton
        hasHelp={hasHelp}
        pumpState={pumpState}
        title={title}
        onClick={
          pumpState === 'ON'
            ? openOzoneZoneSupplyPumpStopStatePrompt
            : openOzoneZoneSupplyPumpStartStatePrompt
        }
        {...props}
      />
    </>
  );
};

const ReverseOsmosisZoneSupplyValveSwitch = ({
  zoneSupplyTankName,
  zoneSupplyTankId,
  valveMode,
  updateReverseOsmosisZoneSupplyValveModeCommand,
  ...props
}) => {
  const {
    openReverseOsmosisZoneSupplyValveAutomaticModePrompt,
    openReverseOsmosisZoneSupplyValveMaintenanceModePrompt,
    ReverseOsmosisZoneSupplyValveModePrompts,
    reverseOsmosisZoneSupplyValveModePromptsProps,
  } = useReverseOsmosisZoneSupplyValveModePrompts({
    zoneSupplyTankName,
    zoneSupplyTankId,
  });

  return (
    <>
      <ReverseOsmosisZoneSupplyValveModePrompts
        {...reverseOsmosisZoneSupplyValveModePromptsProps}
      />

      <Switch
        checked={valveMode === 'AUTOMATIC'}
        disabled={!updateReverseOsmosisZoneSupplyValveModeCommand.canExecute}
        onChange={
          valveMode === 'AUTOMATIC'
            ? openReverseOsmosisZoneSupplyValveMaintenanceModePrompt
            : openReverseOsmosisZoneSupplyValveAutomaticModePrompt
        }
        {...props}
      />
    </>
  );
};

const OzoneZoneSupplyPumpSwitch = ({
  zoneSupplyName,
  zoneSupplyId,
  pumpMode,
  updateOzonePumpModeCommand,
  ...props
}) => {
  const {
    openOzoneZoneSupplyPumpAutomaticModePrompt,
    openOzoneZoneSupplyPumpMaintenanceModePrompt,
    OzoneZoneSupplyPumpModePrompts,
    ozoneZoneSupplyPumpModePromptsProps,
  } = useOzoneZoneSupplyPumpModePrompts({
    zoneSupplyName,
    zoneSupplyId,
  });

  return (
    <>
      <OzoneZoneSupplyPumpModePrompts
        {...ozoneZoneSupplyPumpModePromptsProps}
      />

      <Switch
        checked={pumpMode === 'AUTOMATIC'}
        disabled={!updateOzonePumpModeCommand.canExecute}
        onChange={
          pumpMode === 'AUTOMATIC'
            ? openOzoneZoneSupplyPumpMaintenanceModePrompt
            : openOzoneZoneSupplyPumpAutomaticModePrompt
        }
        {...props}
      />
    </>
  );
};

const OzoneZoneBypassValveSwitch = ({
  zoneName,
  zoneId,
  valveMode,
  updateOzoneSystemZoneBypassValveModeCommand,
  ...props
}) => {
  const {
    openOzoneZoneBypassValveAutomaticModePrompt,
    openOzoneZoneBypassValveMaintenanceModePrompt,
    OzoneZoneBypassValveModePrompts,
    ozoneZoneBypassValveModePromptsProps,
  } = useOzoneZoneBypassValveModePrompts({
    zoneName,
    zoneId,
  });

  return (
    <>
      <OzoneZoneBypassValveModePrompts
        {...ozoneZoneBypassValveModePromptsProps}
      />

      <Switch
        checked={valveMode === 'AUTOMATIC'}
        disabled={!updateOzoneSystemZoneBypassValveModeCommand.canExecute}
        onChange={
          valveMode === 'AUTOMATIC'
            ? openOzoneZoneBypassValveMaintenanceModePrompt
            : openOzoneZoneBypassValveAutomaticModePrompt
        }
        {...props}
      />
    </>
  );
};

const OzoneZoneBypassValveButton = ({
  zoneName,
  zoneId,
  valveState,
  hasHelp,
  ...props
}) => {
  const {
    openActivateBypassValveStatePrompt,
    openDeactivateBypassValveStatePrompt,
    OzoneZoneBypassValveStatePrompts,
    ozoneZoneBypassValveStatePromptsProps,
  } = useOzoneZoneBypassValveStatePrompts({
    zoneName,
    zoneId,
  });

  return (
    <>
      <OzoneZoneBypassValveStatePrompts
        {...ozoneZoneBypassValveStatePromptsProps}
      />

      <ValveButton
        hasHelp={hasHelp}
        valveState={valveState}
        data-toggle="tooltip"
        data-placement="top"
        title={Descriptions.ozoneBypassValve}
        onClick={
          valveState === 'OPEN'
            ? openActivateBypassValveStatePrompt
            : openDeactivateBypassValveStatePrompt
        }
        {...props}
      />
    </>
  );
};

const OzoneTankResetInvalidStateButton = ({
  ozoneSystem,
  ozoneSystemName,
  ozoneSystemId,
  ...props
}) => {
  const {
    openOzoneTankInvalidWaterSensorStateResetPrompt,
    OzoneTankInvalidWaterSensorStateResetPrompt,
    ozoneTankInvalidWaterSensorStateResetPromptProps,
  } = useOzoneTankInvalidWaterSensorStateResetPrompt({
    ozoneSystemName,
    ozoneSystemId,
    invalidStates: {
      HH: ozoneSystem.tank?.invalidTankLevelSwitches?.hh,
      H: ozoneSystem.tank?.invalidTankLevelSwitches?.h,
      L: ozoneSystem.tank?.invalidTankLevelSwitches?.l,
      LL: ozoneSystem.tank?.invalidTankLevelSwitches?.ll,
    },
  });

  return (
    <>
      <OzoneTankInvalidWaterSensorStateResetPrompt
        {...ozoneTankInvalidWaterSensorStateResetPromptProps}
      />

      <InvalidWaterSensorStateIcon
        className={style.invalidWaterSensorStateIcon}
        onClick={
          ozoneSystem.tank.resetOzoneTankInvalidStateAlertCommand.canExecute
            ? openOzoneTankInvalidWaterSensorStateResetPrompt
            : undefined
        }
        style={{
          cursor: ozoneSystem.tank.resetOzoneTankInvalidStateAlertCommand
            .canExecute
            ? 'pointer'
            : undefined,
        }}
      />
    </>
  );
};

const FeederPipes = ({ zoneIndex, pipeData }) => {
  const {
    isFirst,
    isLast,
    leftPipeIsFlowing,
    rightPipeIsFlowing,
    mainJunctionLocation,
    isAdjacentToMainJunction,
    topPipeIsFlowing,
    bottomPipeIsFlowing,
  } = pipeData[zoneIndex] || {};

  let mainJunctionLocationStyle;
  let waterFlowIsTerminal;
  switch (mainJunctionLocation) {
    case FeederPipes.MainJunctionLocation.LEFT:
      mainJunctionLocationStyle = style.mainJunctionIsLeft;
      waterFlowIsTerminal =
        isAdjacentToMainJunction &&
        leftPipeIsFlowing &&
        !pipeData[zoneIndex - 1]?.rightPipeIsFlowing;
      break;
    case FeederPipes.MainJunctionLocation.CENTER:
      mainJunctionLocationStyle = style.mainJunctionIsCenter;
      waterFlowIsTerminal = false;
      break;
    default:
      mainJunctionLocationStyle = style.mainJunctionIsRight;
      waterFlowIsTerminal =
        isAdjacentToMainJunction &&
        rightPipeIsFlowing &&
        !pipeData[zoneIndex + 1]?.leftPipeIsFlowing;
      break;
  }

  return (
    <div
      className={[
        style.feederPipeContainer,
        mainJunctionLocationStyle,
        isAdjacentToMainJunction ? style.isAdjacentToMainJunction : null,
        waterFlowIsTerminal ? style.waterFlowIsTerminal : null,
        isFirst ? style.isFirst : null,
        isLast ? style.isLast : null,
      ].join(' ')}
    >
      <Pipe
        className={[
          style.feederPipe,
          style.left,
          leftPipeIsFlowing ? style.waterIsFlowing : null,
        ].join(' ')}
        waterIsFlowing={leftPipeIsFlowing}
      />

      <Pipe
        className={[
          style.feederPipe,
          style.top,
          topPipeIsFlowing ? style.waterIsFlowing : null,
        ].join(' ')}
        waterIsFlowing={topPipeIsFlowing}
      />

      <Pipe
        className={[
          style.feederPipe,
          style.right,
          rightPipeIsFlowing ? style.waterIsFlowing : null,
        ].join(' ')}
        waterIsFlowing={rightPipeIsFlowing}
      />

      <Pipe
        className={[
          style.feederPipe,
          style.bottom,
          bottomPipeIsFlowing ? style.waterIsFlowing : null,
        ].join(' ')}
        waterIsFlowing={bottomPipeIsFlowing}
      />
    </div>
  );
};

FeederPipes.MainJunctionLocation = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
};

const OzoneSystem = ({
  facilityId,
  ozoneSystemName,
  ozoneSystemId,
  pumpRoom,
  className,
}) => {
  const zoneDistTankState = useQuery(ZONE_DIST_TANK_MODE, {
    pollInterval: 30000,
  });

  //I have no idea why the subscription ever worked, the code was not designed for it. Use polling instead.
  const result = useQuery(OZONE_SYSTEM_QUERY, {
    variables: {
      ozoneSystemId,
    },
    pollInterval: 1000,
  });

  //Use a state to cache the previous generation to stop flicker.
  const [data, setData] = useState(null);

  //A faked result is need to keep the UI from flickering a loading screen.
  const [pseudoResult, setPseudoResult] = useState({
    loading: true,
    error: null,
  });

  //When a new generation of data is provided update the error and data state.
  useEffect(() => {
    setPseudoResult({
      loading: false,
      error: result.error,
    });

    //If the next generation's data is ready, clone it into the state (stop flickering).
    if (result.data) setData({ ...result.data });
  }, [result]);

  const [distTankMode, setDistTankMode] = useState(null);
  useEffect(() => {
    if (!!zoneDistTankState.error || zoneDistTankState.loading) return;
    if (zoneDistTankState.data?.zoneDistributionTankStatuses != null)
      setDistTankMode(zoneDistTankState.data.zoneDistributionTankStatuses);
  }, [zoneDistTankState.data]);

  //Hipster way to just set the state when the query changes.

  const [macroIndexes, setMacroIndexes] = useState([]);

  const {
    openOzoneRestrictionEnablePrompt,
    openOzoneRestrictionDisablePrompt,
    OzoneRestrictionPrompts,
    ozoneRestrictionPromptsProps,
  } = useOzoneRestrictionPrompts({
    ozoneSystemName,
    ozoneSystemId,
  });

  const {
    openOzoneSystemAutomaticModePrompt,
    openOzoneSystemMaintenanceModePrompt,
    OzoneSystemModePrompts,
    ozoneSystemModePromptsProps,
  } = useOzoneSystemModePrompts({
    ozoneSystemName,
    ozoneSystemId,
  });

  const {
    openOzoneEnableAutomaticBypassModePrompt,
    openOzoneDisableAutomaticBypassModePrompt,
    OzoneBypassValveModePrompts,
    ozoneBypassValveModePromptsProps,
  } = useOzoneBypassValveModePrompts({
    ozoneSystemName,
    ozoneSystemId,
  });

  const {
    openOzoneActivateBypassValvePrompt,
    openOzoneDeactivateBypassPrompt,
    OzoneBypassValveStatePrompts,
    ozoneBypassValveStatePromptsProps,
  } = useOzoneBypassValveStatePrompts({ ozoneSystemName, ozoneSystemId });

  const { ozoneSystem } = data ? data : {};
  const {
    tank,
    zones,
    zoneDistributionTanks: zoneSupplyTanks,
    mode,
    bypassValve: systemWideBypassValve,
    overrideRestriction,
    updateOzoneSystemModeCommand,
    updateOzoneSystemOverrideRestrictionCommand,
  } = ozoneSystem ? ozoneSystem : {};
  const { reverseOsmosisSystem, ozoneSystems } = pumpRoom ? pumpRoom : {};

  const reverseOsmosisSystemZoneValves = (
    reverseOsmosisSystem?.zoneValves || []
  ).filter(({ zone: { index: zoneValveIndex } }) =>
    zones?.some(({ zone }) => zone.index === zoneValveIndex)
  );

  const reverseOsmosisSystemZoneSupplyValves = (
    reverseOsmosisSystem?.zoneDistributionTankValves || []
  ).filter(({ zoneDistributionTank: { index: zoneSupplyTankIndex } }) =>
    zoneSupplyTanks?.some(
      ({ zoneDistributionTank: zoneSupplyTank }) =>
        zoneSupplyTank.index === zoneSupplyTankIndex
    )
  );

  const getPipeData = ({ zones, zoneIndex, isWaterFlowing }) => {
    const centerZoneIndex = zones?.length
      ? zoneSupplyTanks.length % 2
        ? Math.floor(zoneSupplyTanks.length / 2)
        : Math.floor(zoneSupplyTanks.length / 2) - 0.5
      : 0;

    const isFirst = zoneIndex === 0;
    const isLast = zoneIndex === zones.length - 1;
    const isAdjacentToMainJunction =
      zoneIndex === centerZoneIndex ||
      zoneIndex === centerZoneIndex - 0.5 ||
      zoneIndex === centerZoneIndex + 0.5;

    const bottomPipeIsFlowing = zones.some(
      (_, i) => i > zoneIndex && isWaterFlowing(i)
    );

    const topPipeIsFlowing = zones.some(
      (_, i) => bottomPipeIsFlowing || (i === zoneIndex && isWaterFlowing(i))
    );

    if (zoneIndex === centerZoneIndex) {
      const mainJunctionLocation = FeederPipes.MainJunctionLocation.CENTER;

      const leftPipeIsFlowing = zones.some(
        (_, i) => i < zoneIndex && isWaterFlowing(i)
      );

      const rightPipeIsFlowing = zones.some(
        (_, i) => i > zoneIndex && isWaterFlowing(i)
      );

      return {
        isFirst,
        isLast,
        leftPipeIsFlowing,
        rightPipeIsFlowing,
        mainJunctionLocation,
        isAdjacentToMainJunction,
        topPipeIsFlowing,
        bottomPipeIsFlowing,
      };
    }

    if (zoneIndex < centerZoneIndex) {
      const mainJunctionLocation = FeederPipes.MainJunctionLocation.RIGHT;

      const leftPipeIsFlowing = zones.some(
        (_, i) => i < zoneIndex && isWaterFlowing(i)
      );

      const rightPipeIsFlowing = zones.some(
        (_, i) => leftPipeIsFlowing || (i === zoneIndex && isWaterFlowing(i))
      );

      return {
        isFirst,
        isLast,
        leftPipeIsFlowing,
        rightPipeIsFlowing,
        mainJunctionLocation,
        isAdjacentToMainJunction,
        topPipeIsFlowing,
        bottomPipeIsFlowing,
      };
    }

    const mainJunctionLocation = FeederPipes.MainJunctionLocation.LEFT;

    const rightPipeIsFlowing = zones.some(
      (_, i) => i > zoneIndex && isWaterFlowing(i)
    );

    const leftPipeIsFlowing = zones.some(
      (_, i) =>
        rightPipeIsFlowing || (i === zoneIndex && isWaterFlowing(zoneIndex))
    );

    return {
      isFirst,
      isLast,
      leftPipeIsFlowing,
      rightPipeIsFlowing,
      mainJunctionLocation,
      isAdjacentToMainJunction,
      topPipeIsFlowing,
      bottomPipeIsFlowing,
    };
  };

  const isWaterFlowingFromReverseOsmosis = zoneIndex =>
    reverseOsmosisSystem.isWaterFlowingFromSkid &&
    reverseOsmosisSystemZoneValves[zoneIndex]?.state === 'OPEN';

  const isWaterFlowingToZoneBypassValve = zoneIndex => {
    const ozoneZone = zones[zoneIndex];
    return (
      ozoneZone?.zone.collectorTank?.pumpState === 'ON' ||
      ozoneZone?.zone.stacks
        .map(x => x.collectorTank)
        .some(x => x.pumpState === 'ON')
    );
  };

  const isWaterFlowingToOzoneTank = zoneIndex =>
    !overrideRestriction &&
    zones[zoneIndex]?.bypassValve.state === 'BYPASS_INACTIVE' &&
    isWaterFlowingToZoneBypassValve(zoneIndex);

  const isWaterFlowingFromOzoneTank = zoneSupplyTankIndex =>
    zoneSupplyTanks[zoneSupplyTankIndex]?.pump.state === 'ON';

  const waterIsFlowingToOzoneTank = zones?.some((_, i) =>
    isWaterFlowingToOzoneTank(i)
  );

  const waterIsFlowingFromOzoneTank = zones?.some((_, i) =>
    isWaterFlowingFromOzoneTank(i)
  );

  const zoneReverseOsmosisPipeData = zoneSupplyTanks?.map((_, zoneIndex) =>
    getPipeData({
      zones: zoneSupplyTanks,
      zoneIndex,
      isWaterFlowing: isWaterFlowingFromReverseOsmosis,
    })
  );

  const zoneToOzonePipeData = zoneSupplyTanks?.map((_, zoneIndex) =>
    getPipeData({
      zones: zoneSupplyTanks,
      zoneIndex,
      isWaterFlowing: isWaterFlowingToOzoneTank,
    })
  );

  const ozoneToZonePipeData = zoneSupplyTanks?.map((_, zoneIndex) =>
    getPipeData({
      zones: zoneSupplyTanks,
      zoneIndex,
      isWaterFlowing: isWaterFlowingFromOzoneTank,
    })
  );
  let visibleIndex = 0;

  return (
    <div className={[style.container, className].join(' ')}>
      <OzoneRestrictionPrompts {...ozoneRestrictionPromptsProps} />

      <OzoneSystemModePrompts {...ozoneSystemModePromptsProps} />

      <OzoneBypassValveModePrompts {...ozoneBypassValveModePromptsProps} />

      <OzoneBypassValveStatePrompts {...ozoneBypassValveStatePromptsProps} />

      <PageLoadingContainer
        resourceTypeName="Ozone System"
        result={pseudoResult}
        resourceExists={!!ozoneSystem}
        render={() => (
          <>
            <div className={style.ozoneSystem}>
              <Pipe
                className={style.reverseOsmosisPipe}
                waterIsFlowing={zones.some((_, i) =>
                  isWaterFlowingFromReverseOsmosis(i)
                )}
              />

              {ozoneSystems.length > 1 && (
                <OzoneSystemSelector
                  className={style.ozoneSystemSelectorLandscape}
                  facilityId={facilityId}
                  ozoneSystems={ozoneSystems}
                  currentOzoneSystemId={ozoneSystemId}
                />
              )}

              <div className={style.zonesContainer}>
                {zoneSupplyTanks.map(
                  (
                    { zoneDistributionTank: zoneSupplyTank },
                    zoneSupplyTankIndex
                  ) => {
                    const { zones: zoneSupplyTankZones } = zoneSupplyTank;

                    // Note: individual zone bypass valve control on the UI has been
                    // delayed in order to prioritize getting the Denver facility online
                    const ozoneZoneBypassValve =
                      zoneSupplyTankZones[0]?.bypassValve;
                    const zoneName = zoneSupplyTankZones[0]?.zone?.name;
                    const zoneId = zoneSupplyTankZones[0]?.zone?.zoneId;
                    const ozoneZonePump =
                      zoneSupplyTanks[zoneSupplyTankIndex].pump;

                    if (
                      zoneSupplyTankZones[0]?.zone?.isHidden ||
                      zoneSupplyTankZones[0]?.zone?.isOzoneSystemHidden
                    )
                      return <></>;

                    ++visibleIndex;

                    const {
                      updateOzonePumpModeCommand,
                      flushOzoneTankIntoZoneSupplyCommand,
                    } = ozoneZonePump;

                    const zoneSupplyTankValve =
                      reverseOsmosisSystemZoneSupplyValves[zoneSupplyTankIndex];

                    const updateReverseOsmosisZoneSupplyValveStateCommand =
                      zoneSupplyTankValve?.updateReverseOsmosisZoneDistributionTankValveStateCommand;

                    const waterIsFlowingFromReverseOsmosis = isWaterFlowingFromReverseOsmosis(
                      zoneSupplyTankIndex
                    );

                    const waterIsFlowingToStacks =
                      zoneSupplyTank.pumpState === 'ON' &&
                      zoneSupplyTankZones.some(({ zone }) =>
                        zone.stacks.some(y => y.hasOpenLevelValves)
                      );

                    const waterIsFlowingToBypassValve = isWaterFlowingToZoneBypassValve(
                      zoneSupplyTankIndex
                    );

                    const waterIsFlowingBackToZone =
                      (overrideRestriction ||
                        zoneSupplyTankZones.some(
                          x => x.bypassValve.state === 'BYPASS_ACTIVE'
                        )) &&
                      waterIsFlowingToBypassValve;

                    const waterIsFlowingToOzoneTank = isWaterFlowingToOzoneTank(
                      zoneSupplyTankIndex
                    );

                    const waterIsFlowingFromOzoneTank = isWaterFlowingFromOzoneTank(
                      zoneSupplyTankIndex
                    );

                    return (
                      <div
                        key={zoneSupplyTank.name}
                        className={style.zoneContainer}
                      >
                        <div className={style.zoneReverseOsmosisComponents}>
                          <FeederPipes
                            zoneIndex={zoneSupplyTankIndex}
                            pipeData={zoneReverseOsmosisPipeData}
                          />

                          {zoneSupplyTankValve?.mode ? (
                            <>
                              <Pipe
                                className={style.zoneValvePipe}
                                waterIsFlowing={
                                  waterIsFlowingFromReverseOsmosis
                                }
                              />

                              <ReverseOsmosisZoneSupplyValveSwitch
                                className={style.zoneValveSwitch}
                                width={35}
                                height={15}
                                zoneSupplyTankName={zoneSupplyTank.name}
                                zoneSupplyTankId={
                                  zoneSupplyTank.zoneDistributionTankId
                                }
                                valveMode={zoneSupplyTankValve?.mode}
                                updateReverseOsmosisZoneSupplyValveModeCommand={
                                  zoneSupplyTankValve?.updateReverseOsmosisZoneDistributionTankValveModeCommand
                                }
                              />

                              <Pipe
                                className={style.zoneValvePipe}
                                waterIsFlowing={
                                  waterIsFlowingFromReverseOsmosis
                                }
                              />
                            </>
                          ) : (
                            <Pipe
                              className={style.zoneValvePipe}
                              waterIsFlowing={waterIsFlowingFromReverseOsmosis}
                            />
                          )}

                          {updateReverseOsmosisZoneSupplyValveStateCommand?.canExecute ? (
                            <ReverseOsmosisZoneSupplyValveButton
                              hasHelp={visibleIndex == 5}
                              className={style.zoneValve}
                              unknownIconClassName={style.zoneValveUnknownIcon}
                              zoneSupplyTankName={zoneSupplyTank.name}
                              zoneSupplyTankId={
                                zoneSupplyTank.zoneDistributionTankId
                              }
                              valveState={zoneSupplyTankValve?.state}
                            />
                          ) : (
                            <Valve
                              hasHelp={visibleIndex == 2}
                              className={style.zoneValve}
                              unknownIconClassName={style.zoneValveUnknownIcon}
                              valveState={zoneSupplyTankValve?.state}
                            />
                          )}

                          <Pipe
                            className={style.zoneValvePipe}
                            waterIsFlowing={waterIsFlowingFromReverseOsmosis}
                          />
                        </div>

                        <div className={style.zoneTankContainer}>
                          <ZoneDistributionTank
                            className={style.zoneTank}
                            zoneDistributionTank={zoneSupplyTank}
                            zoneId={zoneId}
                            pumpMode={
                              distTankMode?.filter(
                                i => i.index == Number(zoneName) - 1
                              )[0]
                            }
                          />

                          <div className={style.zoneStacksContainer}>
                            <Pipe
                              className={style.zoneToStacksPipe}
                              waterIsFlowing={waterIsFlowingToStacks}
                            />

                            <div className={style.stacks}>
                              <img
                                className={`${style.stacksIcon} ${
                                  visibleIndex == 3 ? 'nav-help-tooltip' : ''
                                }`}
                                src={StacksImage}
                                alt="Stacks"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={Descriptions.miniStack}
                              />

                              <div className={style.zoneOzoneBypass}>
                                <Pipe
                                  className={style.stacksOutPipe}
                                  waterIsFlowing={waterIsFlowingToBypassValve}
                                />

                                <div className={style.bypassValveContainer}>
                                  {overrideRestriction && (
                                    <div
                                      className={style.manualBypassIndicator}
                                    ></div>
                                  )}

                                  {ozoneZoneBypassValve
                                    .updateOzoneSystemZoneBypassValveStateCommand
                                    ?.canExecute ? (
                                    <OzoneZoneBypassValveButton
                                      hasHelp={visibleIndex == 4}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={Descriptions.ozoneBypassValve}
                                      className={style.bypassValve}
                                      unknownIconClassName={
                                        style.bypassValveUnknownIcon
                                      }
                                      zoneName={zoneName}
                                      zoneId={zoneId}
                                      valveState={
                                        zoneSupplyTankValve.state &&
                                        (zoneSupplyTankValve.state ===
                                        'BYPASS_ACTIVE'
                                          ? 'CLOSED'
                                          : 'OPEN')
                                      }
                                    />
                                  ) : (
                                    <Valve
                                      hasHelp={visibleIndex == 4}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={Descriptions.ozoneBypassValve}
                                      className={style.bypassValve}
                                      unknownIconClassName={
                                        style.bypassValveUnknownIcon
                                      }
                                      valveState={
                                        zoneSupplyTankValve.state &&
                                        (zoneSupplyTankValve.state ===
                                        'BYPASS_ACTIVE'
                                          ? 'CLOSED'
                                          : 'OPEN')
                                      }
                                    />
                                  )}

                                  {ozoneZoneBypassValve
                                    .updateOzoneSystemZoneBypassValveModeCommand
                                    ?.systemStateSatisfied && (
                                    <div
                                      className={
                                        style.bypassValveSwitchContainer
                                      }
                                    >
                                      <OzoneZoneBypassValveSwitch
                                        width={35}
                                        height={15}
                                        zoneName={zoneName}
                                        zoneId={zoneId}
                                        valveMode={ozoneZoneBypassValve.mode}
                                        updateOzoneSystemZoneBypassValveModeCommand={
                                          ozoneZoneBypassValve.updateOzoneSystemZoneBypassValveModeCommand
                                        }
                                      />
                                    </div>
                                  )}
                                </div>

                                <Pipe
                                  className={style.zoneReturnPipe}
                                  waterIsFlowing={waterIsFlowingBackToZone}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={style.zoneToOzoneComponents}>
                          <Pipe
                            className={[
                              style.zoneBypassValveOutPipe,
                              waterIsFlowingToOzoneTank
                                ? style.waterIsFlowing
                                : null,
                            ].join(' ')}
                            waterIsFlowing={waterIsFlowingToOzoneTank}
                          />

                          <FeederPipes
                            zoneIndex={zoneSupplyTankIndex}
                            pipeData={zoneToOzonePipeData}
                          />
                        </div>

                        <div className={style.ozoneToZoneComponents}>
                          <Pipe
                            className={style.zonePumpOutPipe}
                            waterIsFlowing={waterIsFlowingFromOzoneTank}
                          />

                          {flushOzoneTankIntoZoneSupplyCommand.canExecute ? (
                            <ReverseOsmosisZoneSupplyPumpButton
                              hasHelp={visibleIndex == 2}
                              className={style.zonePump}
                              unknownIconClassName={style.zonePumpUnknownIcon}
                              zoneSupplyName={zoneSupplyTank.name}
                              zoneSupplyId={
                                zoneSupplyTank.zoneDistributionTankId
                              }
                              pumpState={ozoneZonePump.state}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={Descriptions.flushOzoneTankValve}
                            />
                          ) : (
                            <Pump
                              hasHelp={visibleIndex == 2}
                              className={style.zonePump}
                              unknownIconClassName={style.zonePumpUnknownIcon}
                              pumpState={ozoneZonePump.state}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={Descriptions.flushOzoneTankValve}
                            />
                          )}

                          <Pipe
                            className={style.zonePumpInPipe}
                            waterIsFlowing={waterIsFlowingFromOzoneTank}
                          />

                          <OzoneZoneSupplyPumpSwitch
                            className={style.zonePumpSwitch}
                            width={35}
                            height={15}
                            zoneSupplyName={zoneSupplyTank.name}
                            zoneSupplyId={zoneSupplyTank.zoneDistributionTankId}
                            pumpMode={ozoneZonePump.mode}
                            updateOzonePumpModeCommand={
                              updateOzonePumpModeCommand
                            }
                          />

                          <Pipe
                            className={style.zonePumpSwitchInPipe}
                            waterIsFlowing={waterIsFlowingFromOzoneTank}
                          />

                          <FeederPipes
                            zoneIndex={zoneSupplyTankIndex}
                            pipeData={ozoneToZonePipeData}
                          />
                        </div>
                      </div>
                    );
                  }
                )}
              </div>

              <div className={style.ozoneTankContainer}>
                <div className={style.switchesContainer}>
                  <div className={style.switchContainer}>
                    <label
                      htmlFor="ozone-restriction-switch"
                      className={style.switchLabel}
                    >
                      Ozone Restriction
                    </label>
                    <Switch
                      id="ozone-restriction-switch"
                      checked={!overrideRestriction}
                      disabled={
                        !updateOzoneSystemOverrideRestrictionCommand.canExecute
                      }
                      onChange={
                        overrideRestriction
                          ? openOzoneRestrictionEnablePrompt
                          : openOzoneRestrictionDisablePrompt
                      }
                    />
                  </div>

                  <div className={style.switchContainer}>
                    <label
                      htmlFor="ozone-mode-switch"
                      className={style.switchLabel}
                    >
                      Ozone Auto Mode
                    </label>
                    <Switch
                      id="ozone-mode-switch"
                      checked={mode === 'AUTOMATIC'}
                      disabled={!updateOzoneSystemModeCommand.canExecute}
                      onChange={
                        mode === 'AUTOMATIC'
                          ? openOzoneSystemMaintenanceModePrompt
                          : openOzoneSystemAutomaticModePrompt
                      }
                    />
                  </div>

                  {systemWideBypassValve
                    ?.updateOzoneSystemBypassValveModeCommand
                    .systemStateSatisfied && (
                    <div className={style.switchContainer}>
                      <label
                        htmlFor="ozone-bypass-switch"
                        className={style.switchLabel}
                      >
                        Ozone Auto Bypass
                      </label>
                      <Switch
                        id="ozone-bypass-switch"
                        checked={systemWideBypassValve.mode === 'AUTOMATIC'}
                        disabled={
                          !systemWideBypassValve
                            .updateOzoneSystemBypassValveModeCommand.canExecute
                        }
                        onChange={
                          systemWideBypassValve.mode === 'AUTOMATIC'
                            ? openOzoneDisableAutomaticBypassModePrompt
                            : openOzoneEnableAutomaticBypassModePrompt
                        }
                      />
                    </div>
                  )}
                </div>
                <Tank
                  className={`${style.ozoneTank} nav-help-tooltip`}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={Descriptions.ozoneTank}
                  waterLevel={tank.waterLevel}
                  levelSensors={[
                    tank.invalidTankLevelSwitches?.hh,
                    tank.invalidTankLevelSwitches?.h,
                    tank.invalidTankLevelSwitches?.l,
                    tank.invalidTankLevelSwitches?.ll,
                  ].filter(x => x !== undefined && x !== null)}
                  sensorsOnRight={false}
                  hidePump
                  pumpState={
                    zoneSupplyTanks.some(({ pump }) => pump.state === 'ON')
                      ? 'ON'
                      : 'OFF'
                  }
                >
                  {tank.invalidWaterSensorStatesDetected && (
                    <OzoneTankResetInvalidStateButton
                      ozoneSystem={ozoneSystem}
                      ozoneSystemName={ozoneSystemName}
                      ozoneSystemId={ozoneSystemId}
                    />
                  )}
                </Tank>
                <div
                  className={[
                    style.zonesToOzoneTankPipeContainer,
                    systemWideBypassValve
                      ?.updateOzoneSystemBypassValveStateCommand.canExecute
                      ? style.hasValve
                      : null,
                  ].join(' ')}
                >
                  {systemWideBypassValve
                    ?.updateOzoneSystemBypassValveStateCommand.canExecute && (
                    <ValveButton
                      hasHelp={true}
                      data-toggle="tooltip"
                      data-placement="left"
                      title={Descriptions.ozoneBypassValve}
                      className={style.bypassValve}
                      unknownIconClassName={style.bypassValveUnknownIcon}
                      valveState={
                        systemWideBypassValve.state &&
                        (systemWideBypassValve.state === 'BYPASS_ACTIVE'
                          ? 'CLOSED'
                          : 'OPEN')
                      }
                      onClick={
                        systemWideBypassValve.state === 'BYPASS_ACTIVE'
                          ? openOzoneDeactivateBypassPrompt
                          : openOzoneActivateBypassValvePrompt
                      }
                    />
                  )}

                  <Pipe
                    className={style.zonesToOzoneTankPipe}
                    waterIsFlowing={waterIsFlowingToOzoneTank}
                  />
                </div>
                <Pipe
                  className={style.ozoneTankInPipe}
                  waterIsFlowing={waterIsFlowingToOzoneTank}
                />
                <Pipe
                  className={style.ozoneTankOutPipe}
                  waterIsFlowing={waterIsFlowingFromOzoneTank}
                />
                {ozoneSystems.length > 1 && (
                  <OzoneSystemSelector
                    className={style.ozoneSystemSelectorPortrait}
                    facilityId={facilityId}
                    ozoneSystems={ozoneSystems}
                    currentOzoneSystemId={ozoneSystemId}
                  />
                )}
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

OzoneSystem.fragment = gql`
  fragment OzoneSystem on FacilityType {
    pumpRoom {
      ...OzoneSystemSelector
      reverseOsmosisSystem {
        isWaterFlowingFromSkid
        zoneDistributionTankValves {
          state
          updateReverseOsmosisZoneDistributionTankValveStateCommand {
            canExecute
          }
          zoneDistributionTank {
            zoneDistributionTankId
            name
            index
          }

          ... on ReverseOsmosisPerZoneModeZoneSupplyValveType {
            mode
            updateReverseOsmosisZoneDistributionTankValveModeCommand {
              canExecute
            }
          }
        }
        zoneValves {
          state
          updateReverseOsmosisZoneValveStateCommand {
            canExecute
          }
          zone {
            zoneId
            name
            index
          }

          ... on ReverseOsmosisPerZoneModeZoneValveType {
            mode
            updateReverseOsmosisZoneValveModeCommand {
              canExecute
            }
          }
        }
      }
    }
  }
  ${OzoneSystemSelector.fragment}
`;

export default OzoneSystem;
