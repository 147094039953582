export const firstOrNull = (arr: any[]): any | null => {
  if (arr.length > 0) return arr[0];
  return null;
};

export const tempPolling = 10000;

export default (tempCelsius: number | null) => {
  const hexRatio = 2.55;

  if (tempCelsius == null) {
    //return `rgb(127, 127, 127)`;
    tempCelsius = Math.floor(Math.random() * 100);
  }
  const heat = Math.floor(tempCelsius * hexRatio);
  const cool = Math.floor(Math.abs(100 - tempCelsius) * 2.55);

  return `rgb(${heat}, 0, ${cool})`;
};
