import React, { FC } from 'react';
import { Location } from './Location';

interface Props {
  activityItem: any;
}

const PerformedAction: FC<Props> = ({ activityItem }: Props): JSX.Element => {
  switch (activityItem.action) {
    case 'FACILITY_RECIPES_UPDATED':
      return (
        <span>
          updated the GrowWise recipes for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_LIGHT_ON':
      return (
        <span>
          turned on the stack light for <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_LIGHT_OFF':
      return (
        <span>
          turned off the stack light for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_LIGHT_USE_SCHEDULE':
      return (
        <span>
          updated the stack light schedule for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_GROW_WISE_RECIPE_UPDATED':
      return (
        <span>
          updated the stack light recipe for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_MODE_AUTOMATIC':
      return (
        <span>
          put stack <Location activityItem={activityItem} /> into automatic mode
        </span>
      );
    case 'STACK_MODE_MAINTENANCE':
      return (
        <span>
          put stack <Location activityItem={activityItem} /> into maintenance
          mode
        </span>
      );
    case 'STACK_MODE_HARVEST':
      return (
        <span>
          put stack <Location activityItem={activityItem} /> into harvest mode
        </span>
      );
    case 'STACK_MODE_VALVE_TEST':
      return (
        <span>
          put stack <Location activityItem={activityItem} /> into valve test
          mode
        </span>
      );
    case 'STACK_MODE_CLEAN_IN_PLACE':
      return (
        <span>
          put stack <Location activityItem={activityItem} /> into clean-in-place
          mode
        </span>
      );
    case 'STACK_ATTACHED_COLLECTOR_TANK_PUMP_MODE_AUTOMATIC':
      return (
        <span>
          put the return tank pump for <Location activityItem={activityItem} />{' '}
          into automatic mode
        </span>
      );
    case 'STACK_ATTACHED_COLLECTOR_TANK_PUMP_MODE_MAINTENANCE':
      return (
        <span>
          put the return tank pump for <Location activityItem={activityItem} />{' '}
          into maintenance mode
        </span>
      );
    case 'STACK_HARVEST_SCHEDULED':
      return (
        <span>
          scheduled a harvest for <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_HARVEST_FUTURE_OCCURRENCE_CANCELLED':
      return (
        <span>
          cancelled the scheduled harvest for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_HARVEST_OCCURRENCE_CANCELLED_DUE_TO_HARVEST_MODE_STARTED':
      return (
        <span>
          removed the harvest schedule for{' '}
          <Location activityItem={activityItem} /> due to the stack
          transitioning to harvest mode
        </span>
      );
    case 'STACK_LEAK_RESET':
      return (
        <span>
          cleared the leak alert for <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_ATTACHED_COLLECTOR_TANK_PUMP_TIMEOUT_RESET':
      return (
        <span>
          cleared the return tank pump halted alert for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_VALVE_FAILURE_RESET':
      return (
        <span>
          cleared the stack valve failure alert for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_VALVE_TEST':
      return (
        <span>
          started a stack valve test for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_CLEAN_IN_PLACE_REQUESTED':
      return (
        <span>
          initiated a clean-in-place operation for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'STACK_CANCEL_CLEAN_IN_PLACE':
      return (
        <span>
          canceled the clean-in-place operation for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'LEVEL_MODE_AUTOMATIC':
      return (
        <span>
          put level <Location activityItem={activityItem} /> into automatic mode
        </span>
      );
    case 'LEVEL_MODE_MAINTENANCE':
      return (
        <span>
          put level <Location activityItem={activityItem} /> into maintenance
          mode
        </span>
      );
    case 'OZONE_MODE_AUTOMATIC':
      return (
        <span>
          put the ozone system into automatic mode for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'OZONE_MODE_MAINTENANCE':
      return (
        <span>
          put the ozone system into maintenance mode for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'OZONE_BYPASS_MODE_AUTOMATIC':
      return (
        <span>
          enabled the Automatic Ozone Bypass for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'OZONE_BYPASS_MODE_BYPASS_ACTIVE':
      return (
        <span>
          manually activated the Ozone Bypass for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'OZONE_BYPASS_MODE_BYPASS_INACTIVE':
      return (
        <span>
          manually deactivated the Ozone Bypass for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'OZONE_ZONE_BYPASS_MODE_AUTOMATIC':
      return (
        <span>
          enabled the Automatic Ozone Bypass for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'OZONE_ZONE_BYPASS_MODE_BYPASS_ACTIVE':
      return (
        <span>
          manually activated the Ozone Bypass for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'OZONE_ZONE_BYPASS_MODE_BYPASS_INACTIVE':
      return (
        <span>
          manually deactivated the Ozone Bypass for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'OVERRIDE_OZONE_RESTRICTION_ENABLED':
      return (
        <span>
          disabled the Ozone Restriction for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      ); // note: "enabled override" means "disabled" ozone the restriction"
    case 'OVERRIDE_OZONE_RESTRICTION_DISABLED': // note: "disabled override" means "enabled" ozone the restriction"
      return (
        <span>
          enabled the Ozone Restriction for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'OZONE_ZONE_PUMP_MODE_AUTOMATIC':
      return (
        <span>
          put the ozone pump for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} /> into
          automatic mode
        </span>
      );
    case 'OZONE_ZONE_PUMP_MODE_MAINTENANCE':
      return (
        <span>
          put the ozone pump for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} /> into
          maintenance mode
        </span>
      );
    case 'OZONE_TANK_FLUSH_INTO_ZONE_PUMP_ON':
      return (
        <span>
          started flushing the ozone tank into{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'OZONE_TANK_FLUSH_INTO_ZONE_PUMP_OFF':
      return (
        <span>
          stopped flushing the ozone tank into{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'STACK_ATTACHED_COLLECTOR_TANK_FLUSHED':
      return (
        <span>
          flushed the return tank for <Location activityItem={activityItem} />
        </span>
      );
    case 'ZONE_TANK_CALIBRATED':
      return (
        <span>
          calibrated the return tank for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'DISTRIBUTION_TANK_CALIBRATED':
      return (
        <span>
          calibrated the distribution tank for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'OZONE_SYSTEM_TANK_CALIBRATED':
      return (
        <span>
          calibrated the ozone system tank for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'ZONE_MODE_AUTOMATIC':
      return (
        <span>
          put zone <Location activityItem={activityItem} /> into automatic mode
        </span>
      );
    case 'ZONE_MODE_MAINTENANCE':
      return (
        <span>
          put zone <Location activityItem={activityItem} /> into maintenance
          mode
        </span>
      );
    case 'ZONE_MODE_PAUSED_FOR_HARVEST':
      return (
        <span>
          stopped zone <Location activityItem={activityItem} /> due to a stack
          entering harvest mode
        </span>
      );
    case 'ZONE_MODE_WAITING_ON_OZONE':
      return (
        <span>
          stopped zone <Location activityItem={activityItem} /> due to a
          communication pause with the ozone system
        </span>
      );
    case 'ZONE_ATTACHED_COLLECTOR_TANK_PUMP_MODE_AUTOMATIC':
      return (
        <span>
          put the return tank pump for <Location activityItem={activityItem} />{' '}
          into automatic mode
        </span>
      );
    case 'ZONE_ATTACHED_COLLECTOR_TANK_PUMP_MODE_MAINTENANCE':
      return (
        <span>
          put the return tank pump for <Location activityItem={activityItem} />{' '}
          into maintenance mode
        </span>
      );
    case 'ZONE_ATTACHED_COLLECTOR_TANK_FLUSHED':
      return (
        <span>
          flushed the return tank for <Location activityItem={activityItem} />
        </span>
      );
    case 'ZONE_ATTACHED_COLLECTOR_TANK_PUMP_TIMEOUT_RESET':
      return (
        <span>
          cleared the return tank pump halted alert for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'ZONE_ATTACHED_COLLECTOR_TANK_INVALID_STATE_RESET':
      return (
        <span>
          cleared the return tank invalid state alert for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'NUTRIENT_MIXER_THRESHOLDS_SET':
      return (
        <span>
          set the nutrient mixer thresholds for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'NUTRIENT_MIXER_PUMPS_CONFIGURED':
      return (
        <span>
          configured the nutrient mixer pumps for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'NUTRIENT_MIXER_PUMP_ACID_RESISTANCE_ENABLED':
      return (
        <span>
          enabled the acid resistance for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'NUTRIENT_MIXER_PUMP_ACID_RESISTANCE_DISABLED':
      return (
        <span>
          disabled the acid resistance for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'NUTRIENT_MIXER_PUMP_PRIMED_TEN_SECONDS':
      return (
        <span>
          primed <Location activityItem={activityItem} /> for 10 seconds
        </span>
      );
    case 'NUTRIENT_MIXER_PUMP_PRIMED_THIRTY_SECONDS':
      return (
        <span>
          primed <Location activityItem={activityItem} /> for 30 seconds
        </span>
      );
    case 'REVERSE_OSMOSIS_MODE_AUTOMATIC':
      return (
        <span>
          put the reverse osmosis system into automatic mode for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'REVERSE_OSMOSIS_MODE_MAINTENANCE':
      return (
        <span>
          put the reverse osmosis system into maintenance mode for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'REVERSE_OSMOSIS_ZONE_VALVE_MODE_AUTOMATIC':
      return (
        <span>
          put the reverse osmosis zone valve for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} /> into
          automatic mode
        </span>
      );
    case 'REVERSE_OSMOSIS_ZONE_VALVE_MODE_MAINTENANCE':
      return (
        <span>
          put the reverse osmosis zone valve for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} /> into
          maintenance mode
        </span>
      );
    case 'REVERSE_OSMOSIS_PUMP_REGULAR':
      return (
        <span>put the reverse osmosis pump into default operations mode.</span>
      );
    case 'REVERSE_OSMOSIS_PUMP_SAFETY_STOP':
      return <span>put the reverse osmosis pump into safety stop mode.</span>;
    case 'REVERSE_OSMOSIS_ZONE_VALVE_OPEN':
      return (
        <span>
          opened the reverse osmosis zone valve for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'REVERSE_OSMOSIS_ZONE_VALVE_CLOSED':
      return (
        <span>
          closed the reverse osmosis zone valve for{' '}
          <Location biasPumpRoomNavigation activityItem={activityItem} />
        </span>
      );
    case 'LEVEL_VALVE_OPEN':
      return (
        <span>
          opened the level valve for <Location activityItem={activityItem} />
        </span>
      );
    case 'LEVEL_VALVE_CLOSED':
      return (
        <span>
          closed the level valve for <Location activityItem={activityItem} />
        </span>
      );
    case 'OZONE_SYSTEM_TANK_INVALID_STATE_ALERT_RESET':
      return (
        <span>
          cleared the ozone tank invalid state alert for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'ZONE_TANK_INVALID_STATE_ALERT_RESET':
      return (
        <span>
          cleared the zone tank invalid state alert for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'LEVEL_BME280_SENSOR_CONFIGURED':
      return (
        <span>
          changed a level sensor configuration for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'BEE_SENSOR_THRESHOLDS_SET':
      return (
        <span>
          updated level sensor thresholds for{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'SUBZONE_CREATED':
      return (
        <span>
          created subzone <strong>{activityItem.locationName}</strong> in{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'SUBZONE_MODIFIED':
      return (
        <span>
          modified subzone <strong>{activityItem.locationName}</strong> in{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'SUBZONE_DELETED':
      return (
        <span>
          deleted subzone <strong>{activityItem.locationName}</strong> in{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case 'SUBZONE_SCHEDULE_MODIFIED':
      return (
        <span>
          modified the schedule for <strong>{activityItem.locationName}</strong>{' '}
          in <Location activityItem={activityItem} />
        </span>
      );
    case `SCHEDULE_STACK_IRRIGATION`:
      return (
        <span>
          modified the irrigation schedule in{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case `MICROGREEN_STACK_RO_VALVE_OPEN`:
      return (
        <span>
          opened the RO valve in <Location activityItem={activityItem} />
        </span>
      );
    case `MICROGREEN_STACK_RO_VALVE_CLOSED`:
      return (
        <span>
          closed the RO valve in <Location activityItem={activityItem} />
        </span>
      );
    case `MICROGREEN_STACK_TANK_PUMP_ON`:
      return (
        <span>
          enabled the stack tank pump in{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    case `MICROGREEN_STACK_TANK_PUMP_OFF`:
      return (
        <span>
          disabled the stack tank pump in{' '}
          <Location activityItem={activityItem} />
        </span>
      );
    default:
      console.error(
        `Missing case for activity item action: ${activityItem.action}`
      );
      return (
        <span>
          performed an action on <Location activityItem={activityItem} />
        </span>
      );
  }
};

export { PerformedAction };
