import React from 'react';
import DisplayHyCubeApiVersion from '../../../DisplayHyCubeAPIVersion';
import DisplayHyCubeUiVersion from '../../../DisplayHyCubeUIVersion';
import Kalera from '../../../Kalera';

import style from './style.module.scss';

export default ({ className }) => (
  <div className={className}>
    <div className={style.container}>
      <Kalera className={style.kalera} />
      <DisplayHyCubeApiVersion className={style.version} />
      <DisplayHyCubeUiVersion className={style.version} />
    </div>
  </div>
);
