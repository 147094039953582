import React, { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';

import { useQuery, useSubscription } from '@apollo/react-hooks';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import useZoneAttachedCollectorTankPumpTimeoutResetPrompt from '../../../../hooks/useZoneAttachedCollectorTankPumpTimeoutResetPrompt';
import useZoneLightsBulkEditForm from '../../../../hooks/useZoneLightsBulkEditForm';
import useZoneModePrompts from '../../../../hooks/useZoneModePrompts';
import useZoneTankInvalidWaterSensorStateResetPrompt from '../../../../hooks/useZoneTankInvalidWaterSensorStateResetPrompt';
import useZoneResetCloggedStrainerPrompt from '../../../../hooks/useZoneResetCloggedStrainerPrompt';

import PumpTimeoutIcon from '../../../../components/PumpTimeoutIcon';
import CloggedStrainerIcon from '../../../../components/CloggedStrainerIcon';
import PageLoadingContainer from '../../../../components/PageLoadingContainer';
import NamedTank from '../../../../components/NamedTank';
import CollectorTank from '../../../../components/CollectorTank';
import Switch from '../../../../components/Switch';
import NutrientMixerGauges from './components/NutrientMixerGauges';
import NutrientMixerPumps from './components/NutrientMixerPumps';
import InvalidWaterSensorStateIcon from '../../../../components/InvalidWaterSensorStateIcon';
import ZoneStatus from './components/ZoneStatus';
import Stacks from '../Stacks';

import zonesIcon from '../../../../assets/images/zones.svg';

import style from './style.module.scss';
import useModifySubzonesForm from '../../../../hooks/useModifySubzonesForm';
import useModifyScheduleForm from '../../../../hooks/useModifyScheduleForm';
import useModifyMicrogreenScheduleForm from '../../../../hooks/useModifyMicrogreenScheduleForm';
import LinearGauge from '../../../../components/LinearGauge';
import { Descriptions } from '../../../../modules/Descriptions';
import { tempPolling } from '../../../../modules/temp-map';

import {
  getWaterLevel,
  getPumpState,
  getPumpOnOff,
} from '../../../../modules/string';

const zoneFragment = gql`
  fragment ZoneDetail on ZoneType {
    zoneId
    name
    isDistributionTankHidden
    isMicrogreen
    isNewMicrogreen
    mode
    enabled
    strainerClogDetected
    bulkUpdateZoneStackLightsCommand {
      isAuthorized
      canExecute
    }
    updateZoneModeCommand {
      isAuthorized
      canExecute
    }
    resetZoneCloggedStrainerAlertCommand {
      canExecute
    }
    pausedForHarvestSchedule {
      localTimezone
      pausedForHarvestResumeTime
    }
    tank {
      resetZoneTankInvalidStateAlertCommand {
        canExecute
      }
      invalidWaterLevelSensorStateDetected
      invalidTankLevelSwitches {
        hh
        h
        l
        ll
      }
      pumpState
      gallons
      waterLevel
    }
    collectorTank {
      ...CollectorTank
      pumpTimeoutDetected
      resetPumpTimeoutCommand {
        canExecute
      }
    }
    stacks {
      ...Stacks
    }
    nutrientMixer {
      ...NutrientMixerGauges
      ...NutrientMixerPumps
    }
  }
  ${Stacks.fragment}
  ${CollectorTank.fragment}
  ${NutrientMixerGauges.fragment}
  ${NutrientMixerPumps.fragment}
`;

const ZONE_QUERY = gql`
  query zone($zoneId: ID!) {
    zone(zoneId: $zoneId) {
      ...ZoneDetail
    }
  }
  ${zoneFragment}
`;

const ZONE_SUBSCRIPTION = gql`
  subscription zoneUpdated($zoneId: ID!) {
    zoneUpdated(zoneId: $zoneId) {
      zone {
        ...ZoneDetail
      }
    }
  }
  ${zoneFragment}
`;

const GET_SUBZONES = gql`
  query readStacks($zoneId: ID!, $facilityId: ID!) {
    subzones(zoneId: $zoneId, facilityId: $facilityId) {
      zoneSubzoneId
      name
      stacks
      color
      isMutexed
    }
    subzonePermissionEdit
    subzonePermissionSchedule
  }
`;

const ZONE_TEMPS = gql`
  query stackTemperaturesCelsius($id: ID!) {
    stackTemperaturesCelsius(zoneId: $id) {
      temperatureMapId
      celsius
    }
  }
`;

const ZONE_DIST_TANK_MODE = gql`
  {
    zoneDistributionTankStatuses {
      index
      state
      stateTimeout
    }
  }
`;

export default ({ facilityId, zoneId, zoneName, className }) => {
  const result = useQuery(ZONE_QUERY, {
    variables: { zoneId },
  });

  const subzonesState = useQuery(GET_SUBZONES, {
    variables: { zoneId, facilityId },
    pollInterval: 5000,
  });

  const tempMapState = useQuery(ZONE_TEMPS, {
    variables: {
      id: zoneId,
    },
    pollInterval: typeof window === 'undefined' ? null : tempPolling,
    fetchPolicy: 'network-only',
  });

  const zoneDistTankState = useQuery(ZONE_DIST_TANK_MODE, {
    pollInterval: 30000,
  });

  const [tempMap, setTempMap] = useState();
  useEffect(() => {
    if (!!tempMapState.error || tempMapState.loading) return;
    setTempMap(tempMapState.data.stackTemperaturesCelsius);
  }, [tempMapState.data]);

  const [distTankMode, setDistTankMode] = useState(null);
  useEffect(() => {
    if (!!zoneDistTankState.error || zoneDistTankState.loading) return;
    if (zoneDistTankState.data?.zoneDistributionTankStatuses != null)
      setDistTankMode(zoneDistTankState.data.zoneDistributionTankStatuses);
  }, [zoneDistTankState.data]);

  const isMicrogreen = result.data?.zone?.isMicrogreen ?? false;
  const isModernMicrogreen = result.data?.zone?.isNewMicrogreen ?? false;
  const isDistributionTankHidden =
    result.data?.zone?.isDistributionTankHidden ?? false;

  const subzones =
    !subzonesState.error &&
    subzonesState.data &&
    subzonesState.data.subzones &&
    isMicrogreen === false
      ? subzonesState.data.subzones
      : null;

  const canEditSubzones =
    !subzonesState.error &&
    subzonesState.data &&
    subzonesState.data.subzonePermissionEdit;

  const canScheduleSubzones =
    !subzonesState.error &&
    subzonesState.data &&
    subzonesState.data.subzonePermissionSchedule;

  useSubscription(ZONE_SUBSCRIPTION, {
    variables: { zoneId },
  });

  const {
    openZoneAttachedCollectorTankPumpTimeoutResetPrompt,
    ZoneAttachedCollectorTankPumpTimeoutResetPrompt,
    zoneAttachedCollectorTankPumpTimeoutResetPromptProps,
  } = useZoneAttachedCollectorTankPumpTimeoutResetPrompt({
    zoneName: zoneName,
    zoneId: zoneId,
  });

  const {
    openZoneLightsBulkEditForm,
    ZoneLightsBulkEditForm,
    zoneLightsBulkEditFormProps,
  } = useZoneLightsBulkEditForm({ zoneName, zoneId });

  const {
    openZoneAutomaticModePrompt,
    openZoneMaintenanceModePrompt,
    ZoneModePrompts,
    zoneModePromptsProps,
  } = useZoneModePrompts({
    zoneName,
    zoneId,
  });

  const data = result.data || {};
  const zone = data.zone || {};
  const {
    enabled,
    tank,
    stacks,
    nutrientMixer,
    bulkUpdateZoneStackLightsCommand,
    updateZoneModeCommand,
  } = zone;

  const {
    openModifySubzonesPrompt,
    ModifySubzonesPrompt,
    modifySubzonesPromptProps,
  } = useModifySubzonesForm({
    facilityId,
    zoneName,
    zoneId,
    stacks,
    onCompleted: () => {},
  });

  const {
    openModifyScheduleSubzonePrompt,
    ModifyScheduleSubzonePrompt,
    modifyScheduleSubzonePromptProps,
  } = useModifyScheduleForm({
    facilityId,
    zoneId,
    onCompleted: () => {},
  });

  const {
    openModifyScheduleStackPrompt,
    ModifyScheduleStackPrompt,
    modifyScheduleStackPromptProps,
  } = useModifyMicrogreenScheduleForm({
    zoneId,
    onCompleted: () => {},
  });

  const {
    openZoneTankInvalidWaterSensorStateResetPrompt,
    ZoneTankInvalidWaterSensorStateResetPrompt,
    zoneTankInvalidWaterSensorStateResetPromptProps,
  } = useZoneTankInvalidWaterSensorStateResetPrompt({
    zoneName: zoneName,
    zoneId: zoneId,
    invalidStates: {
      HH: zone?.tank?.invalidTankLevelSwitches?.hh,
      H: zone?.tank?.invalidTankLevelSwitches?.h,
      L: zone?.tank?.invalidTankLevelSwitches?.l,
      LL: zone?.tank?.invalidTankLevelSwitches?.ll,
    },
  });

  const {
    openZoneResetCloggedStrainerPrompt,
    ZoneResetCloggedStrainerPrompt,
    zoneResetCloggedStrainerPromptProps,
  } = useZoneResetCloggedStrainerPrompt({
    zoneName: zoneName,
    zoneId: zoneId,
  });
  return (
    <div className={className}>
      <ModifySubzonesPrompt {...modifySubzonesPromptProps} />
      <ModifyScheduleSubzonePrompt {...modifyScheduleSubzonePromptProps} />
      <ModifyScheduleStackPrompt {...modifyScheduleStackPromptProps} />

      <ZoneAttachedCollectorTankPumpTimeoutResetPrompt
        {...zoneAttachedCollectorTankPumpTimeoutResetPromptProps}
      />

      <ZoneTankInvalidWaterSensorStateResetPrompt
        {...zoneTankInvalidWaterSensorStateResetPromptProps}
      />

      <ZoneResetCloggedStrainerPrompt
        {...zoneResetCloggedStrainerPromptProps}
      />

      <ZoneLightsBulkEditForm {...zoneLightsBulkEditFormProps} />

      <ZoneModePrompts {...zoneModePromptsProps} />

      <PageLoadingContainer
        resourceTypeName="Zone"
        result={result}
        resourceExists={!!data.zone}
        render={() => (
          <div className={style.activeZoneContainer}>
            <div
              className={`${style.stacksPanel} ${isMicrogreen ? 'pb-5' : ''}`}
            >
              <div className={style.stacksControlPanel}>
                {bulkUpdateZoneStackLightsCommand.isAuthorized && (
                  <Button
                    className="rounded nav-help-tooltip mx-2"
                    disabled={!bulkUpdateZoneStackLightsCommand.canExecute}
                    onClick={openZoneLightsBulkEditForm}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={Descriptions.bulkUpdateLights}
                  >
                    <FontAwesomeIcon
                      className="btn-icon"
                      icon={faLightbulb}
                      fixedWidth={true}
                    />{' '}
                    Lights
                  </Button>
                )}

                {canEditSubzones &&
                  isMicrogreen === false &&
                  zone.isNewMicrogreen === false && (
                    <Button
                      className="rounded nav-help-tooltip"
                      onClick={openModifySubzonesPrompt}
                      data-toggle="tooltip"
                      data-placement="top"
                      title={Descriptions.subzonesButton}
                    >
                      <FontAwesomeIcon
                        className="btn-icon"
                        icon={faLayerGroup}
                        fixedWidth={true}
                      />{' '}
                      Subzones
                    </Button>
                  )}
              </div>
              <div
                className={[
                  style.stacksContainer,
                  zone.collectorTank
                    ? style.hasZoneAttachedCollectorTank
                    : style.hasStackAttachedCollectorTanks,
                ].join(' ')}
              >
                <div className={style.stackControlsContainer}></div>

                {isDistributionTankHidden === false && isMicrogreen === false && (
                  <div className="row pr-3 pt-3">
                    <div className="col-12 text-center">
                      <div className={style.zoneModeLabel}>Auto Mode</div>
                      <Switch
                        checked={enabled}
                        disabled={!updateZoneModeCommand.canExecute}
                        onChange={
                          enabled
                            ? openZoneMaintenanceModePrompt
                            : openZoneAutomaticModePrompt
                        }
                      />
                    </div>
                    <div className="col-12">
                      <NamedTank
                        biggerTank={true}
                        hasHelp={true}
                        dataPlacement="left"
                        entityType="Zone"
                        entityName={zone.name}
                        className={style.zoneTank}
                        waterLevel={tank.waterLevel}
                        pumpState={tank.pumpState}
                        pumpMode={
                          distTankMode?.filter(
                            i => i.index == Number(zone.name) - 1
                          )[0]
                        }
                        gallons={tank.gallons}
                        title={Descriptions.zoneSupplyTank}
                        levelSensors={[
                          tank.invalidTankLevelSwitches?.hh,
                          tank.invalidTankLevelSwitches?.h,
                          tank.invalidTankLevelSwitches?.l,
                          tank.invalidTankLevelSwitches?.ll,
                        ].filter(x => x !== undefined && x !== null)}
                        zoneId={zoneId}
                      >
                        {tank.invalidWaterLevelSensorStateDetected && (
                          <InvalidWaterSensorStateIcon
                            className={style.invalidWaterLevelSensorStateIcon}
                            onClick={
                              tank.resetZoneTankInvalidStateAlertCommand
                                .canExecute
                                ? openZoneTankInvalidWaterSensorStateResetPrompt
                                : undefined
                            }
                            style={{
                              cursor: tank.resetZoneTankInvalidStateAlertCommand
                                .canExecute
                                ? 'pointer'
                                : undefined,
                            }}
                          />
                        )}
                      </NamedTank>
                    </div>
                  </div>
                )}

                <Stacks
                  facilityId={facilityId}
                  zoneName={zone.name}
                  stacks={stacks}
                  isMicrogreen={zone.isMicrogreen}
                  temperatureMap={tempMap}
                  subzones={subzones}
                  onSubzoneSelected={
                    canScheduleSubzones
                      ? e => openModifyScheduleSubzonePrompt(e)
                      : _ => {}
                  }
                  scheduleMicrogreenStackCallback={
                    openModifyScheduleStackPrompt
                  }
                  hasPerStackCollectorTanks={
                    !zone.collectorTank || zone.isMicrogreen
                  }
                />

                <div
                  className={[
                    style.stackControlsContainer,
                    zone.collectorTank ? style.collectorTankContainer : null,
                  ].join(' ')}
                >
                  {zone.collectorTank && !zone.isMicrogreen && (
                    <>
                      <CollectorTank
                        className={style.collectorTank}
                        zoneReturnLabelClassName={style.zoneReturnLabel}
                        zoneName={zone.name}
                        pumpSwitchProps={{ width: 40, height: 18 }}
                        collectorTank={zone.collectorTank}
                        sensorsOnRight={true}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={Descriptions.zoneCollectorTank}
                      />

                      <div className={style.collectorTankAlertsContainer}>
                        {zone.collectorTank?.pumpTimeoutDetected && (
                          <div
                            className={style.pumpTimeoutIcon}
                            onClick={
                              zone.collectorTank?.resetPumpTimeoutCommand
                                ?.canExecute
                                ? openZoneAttachedCollectorTankPumpTimeoutResetPrompt
                                : undefined
                            }
                            style={{
                              cursor: zone.collectorTank
                                ?.resetPumpTimeoutCommand?.canExecute
                                ? 'pointer'
                                : undefined,
                            }}
                          >
                            <PumpTimeoutIcon
                              className={`${style.alertIcon} nav-help-tooltip`}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={Descriptions.zonePumpTimeout}
                            />
                          </div>
                        )}

                        {zone.strainerClogDetected && (
                          <div
                            ClassName={style.pumpTiemoutIcon}
                            onClick={
                              zone.resetZoneCloggedStrainerAlertCommand
                                ?.canExecute
                                ? openZoneResetCloggedStrainerPrompt
                                : undefined
                            }
                            style={{
                              cursor: zone.resetZoneCloggedStrainerAlertCommand
                                ?.canExecute
                                ? 'pointer'
                                : undefined,
                            }}
                          >
                            <CloggedStrainerIcon
                              className={`${style.alertIcon} nav-help-tooltip`}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={Descriptions.strainerClogDetected}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {isMicrogreen === false && isModernMicrogreen === false && (
              <div className={style.zoneTankAndNutrientMixerPanel}>
                <NutrientMixerGauges
                  className={`${style.nutrientMixerGauges} nav-help-tooltip`}
                  zoneId={zoneId}
                  zoneName={zone.name}
                  nutrientMixer={nutrientMixer}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={Descriptions.hyMixerGauges}
                />

                <div className={style.zoneTankContainer}>
                  <ZoneStatus
                    zoneMode={zone.mode}
                    harvestSchedule={zone.pausedForHarvestSchedule}
                  />
                </div>

                <NutrientMixerPumps
                  className={style.nutrientMixerPumps}
                  zoneId={zoneId}
                  zoneName={zone.name}
                  nutrientMixer={nutrientMixer}
                />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};
