import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useSubscription } from '@apollo/react-hooks';

import StackStatus from '../../components/StackStatus';
import Levels from './components/Levels';
import LightSchedule from './components/LightSchedule';
import LightRecipe from './components/LightRecipe';
import PageLayout from '../../components/PageLayout';
import FacilityHeading from '../../components/PageLayout/FacilityHeading';
import PageLoadingContainer from '../../components/PageLoadingContainer';
import PageNotFound from '../../components/PageNotFound';
import ModalCloseButton from '../../components/ModalCloseButton';
import history from '../../modules/history';

import defaultFacilityId from '../../modules/defaultFacilityId';
import style from './style.module.scss';
import { Descriptions } from '../../modules/Descriptions';
import { tempPolling } from '../../modules/temp-map';
import Photoperiods from './components/Photoperiods';
import ReverseOsmosisSystem from '../FacilityPage/components/PumpRoom/components/ReverseOsmosisSystem';
import OzoneSystem, {
  OZONE_SYSTEM_QUERY,
} from '../FacilityPage/components/PumpRoom/components/OzoneSystem';
import Pipe from '../../components/Pipe';
import Valve from '../../components/Valve';
import ValveButton from '../../components/ValveButton';
import { useMutation } from 'react-apollo';

const stackFragment = gql`
  fragment StackPageStack on StackType {
    stackId
    isMicrogreen
    modernCipStatus
    modernCipIsEnabled
    modernCipCurrentCycle
    modernCipCurrentLevel
    modernCipDurationSeconds
    modernCipDurationSecondsConfig
    modernCipCycles
    modernCipCyclesConfig
    modernCipDosingValveA
    modernCipDrainValveB
    modernCipDrainValveC
    tankStatus
    name
    ...StackStatus
    ...LightSchedule
    ...LevelsStack
    growWise {
      ...LightRecipe
    }
  }
  ${StackStatus.fragment}
  ${LightSchedule.fragment}
  ${LightRecipe.fragment}
  ${Levels.stackFragment}
`;

const zoneFragment = gql`
  fragment StackPageZone on ZoneType {
    zoneId
    isMicrogreen
    isNewMicrogreen
    isDistributionTankHidden
    name
    tank {
      gallons
      waterLevel
      pumpState
    }
    ...LevelsZone
  }
  ${Levels.zoneFragment}
`;

const pumpRoomDetailsFragment = gql`
  fragment pumpRoomDetails on FacilityType {
    facilityId
    pumpRoom {
      ozoneSystems {
        ozoneSystemId
        isHidden
        name
      }
    }
    ...ReverseOsmosisSystem
    ...OzoneSystem
  }
  ${ReverseOsmosisSystem.fragment}
  ${OzoneSystem.fragment}
`;

const QUERY = gql`
  query facility($facilityId: ID!, $zoneName: String!, $stackName: String!) {
    facilities {
      ...FacilityHeading
    }

    facility(facilityId: $facilityId) {
      facilityId
      ...FacilityHeading
      ...pumpRoomDetails
      zone(zoneName: $zoneName) {
        name
        ...StackPageZone
        stack(stackName: $stackName) {
          ...StackPageStack
        }
      }
    }
  }
  ${FacilityHeading.fragment}
  ${pumpRoomDetailsFragment}
  ${zoneFragment}
  ${stackFragment}
`;

const ZONE_SUBSCRIPTION = gql`
  subscription zoneUpdated($zoneId: ID!, $stackName: String!) {
    zoneUpdated(zoneId: $zoneId) {
      zone {
        ...StackPageZone
        stack(stackName: $stackName) {
          ...StackPageStack
        }
      }
    }
  }
  ${zoneFragment}
  ${stackFragment}
`;

const LEVEL_TEMPS = gql`
  query levelTemperaturesCelsius($id: ID!) {
    levelTemperaturesCelsius(stackId: $id) {
      temperatureMapId
      celsius
    }
  }
`;

const GET_MGREEN_STACK_RO_VALVE_STATE = gql`
  query microgreenStackRoValveIsOpenQuery($input: ID!) {
    microgreenStackRoValveIsOpen(stackId: $input)
    permissionMicrogreenStackRoValveSet
    permissionMicrogreenStackTankPumpSet
  }
`;

const SET_MGREEN_STACK_RO_VALVE_STATE = gql`
  mutation microgreenRoValveToggle($input: MicrogreenRoValveCommandInputType!) {
    microgreenRoValve(input: $input) {
      __typename
    }
  }
`;

const ZONE_SUPPLY_TANK = gql`
  query zoneSupplyTanks($input: ID!) {
    getZoneSupplyTanks(zoneIndex: $input)
  }
`;

const PageContent = ({ facilityId, zone }) => {
  useSubscription(ZONE_SUBSCRIPTION, {
    variables: { zoneId: zone.zoneId, stackName: zone.stack.name },
  });

  const zoneSupplyTankState = useQuery(ZONE_SUPPLY_TANK, {
    variables: {
      input: Number(zone.name) - 1,
    },
    pollInterval: typeof window === 'undefined' ? null : tempPolling,
    fetchPolicy: 'network-only',
  });

  const [zoneSupplyState, setZoneSupplyState] = useState(null);
  useEffect(() => {
    if (!!zoneSupplyTankState.error || zoneSupplyTankState.loading) return;
    if (
      zoneSupplyTankState.data.getZoneSupplyTanks == null ||
      zoneSupplyTankState.data.getZoneSupplyTanks == ''
    )
      return;
    try {
      setZoneSupplyState(
        JSON.parse(zoneSupplyTankState.data.getZoneSupplyTanks)
      );
    } catch (error) {
      console.log(error);
    }
  }, [zoneSupplyTankState.data]);

  const [lightReadOnly, setLightReadOnly] = useState(false);
  const [enablePhotoperiod, setEnablePhotoperiod] = useState(false);

  const onPhotoperiodToggle = useCallback(
    e => {
      setLightReadOnly(e != null);
    },
    [lightReadOnly]
  );

  const onLightModeIsAutoChanged = useCallback(
    e => {
      setEnablePhotoperiod(e);
    },
    [setEnablePhotoperiod, enablePhotoperiod]
  );

  const { stack } = zone;
  const { growWise } = stack;

  const [setRoValve, _] = useMutation(SET_MGREEN_STACK_RO_VALVE_STATE);
  const prevRoValveState = useQuery(GET_MGREEN_STACK_RO_VALVE_STATE, {
    variables: {
      input: stack.stackId,
    },
  });

  const [roValveState, setRoValveState] = useState('CLOSED');
  const [roValveSetPerm, setRoValveSetPerm] = useState(false);
  const [stackPumpSetPerm, setStackPumpSetPerm] = useState(false);
  useEffect(() => {
    if (prevRoValveState.data?.microgreenStackRoValveIsOpen == null) return;
    setRoValveState(
      prevRoValveState.data.microgreenStackRoValveIsOpen ? 'OPEN' : 'CLOSED'
    );
    setRoValveSetPerm(
      prevRoValveState.data.permissionMicrogreenStackRoValveSet
    );
    setStackPumpSetPerm(
      prevRoValveState.data.permissionMicrogreenStackTankPumpSet
    );
  }, [prevRoValveState, roValveSetPerm, stackPumpSetPerm]);

  const toggleRoValve = useCallback(() => {
    setRoValve({
      variables: {
        input: {
          stackId: stack.stackId,
          openValve: roValveState != 'OPEN',
        },
      },
    });
    setRoValveState(prev => (prev == 'OPEN' ? 'CLOSED' : 'OPEN'));
  }, [roValveState]);

  const tempMapState = useQuery(LEVEL_TEMPS, {
    variables: {
      id: stack.stackId,
    },
  });

  const [tempMap, setTempMap] = useState(null);
  useEffect(() => {
    if (!!tempMapState.error || tempMapState.loading) return;
    setTempMap(tempMapState.data.levelTemperaturesCelsius);
  }, [tempMapState.data]);

  const [roFlow, setRowFlow] = useState(false);
  const roFlowDetected = useCallback(next => setRowFlow(prev => next), [
    roFlow,
  ]);

  return (
    <div className={style.container}>
      <ModalCloseButton
        className={style.closeButton}
        onClick={() =>
          history.push(`/facility/${facilityId}/zone/${zone.name}`)
        }
      />

      <div
        className={`${style.lightScheduleContainer} nav-help-tooltip`}
        data-toggle="tooltip"
        data-placement="top"
        title={Descriptions.growLightModeSelector}
      >
        <LightSchedule
          stack={stack}
          disabled={lightReadOnly}
          isAutoEvent={e => onLightModeIsAutoChanged(e)}
        />

        {growWise && (
          <LightRecipe
            className={style.lightRecipe}
            zoneName={zone.name}
            stackName={stack.name}
            stackId={stack.stackId}
            growWise={growWise}
          />
        )}
      </div>

      <div className={style.photoperiodContainer}>
        <Photoperiods
          stackId={stack.stackId}
          stack={stack}
          onChange={onPhotoperiodToggle}
          disableOptions={false}
          disable={false}
        />
      </div>

      <div className={style.levelStatusContainer}>
        <div className={style.roSkidContainer}>
          {stack.isMicrogreen && (
            <>
              <ReverseOsmosisSystem
                className={style.roSystem}
                facility={facilityId}
                isPumping={roFlowDetected}
              />
              <Pipe
                className={style.reverseOsmosisPipe}
                waterIsFlowing={roFlow}
              />
              <div className={style.roSystemValve}>
                {roValveSetPerm ? (
                  <ValveButton
                    valveState={roValveState}
                    onClick={toggleRoValve}
                  />
                ) : (
                  <Valve
                    className={style.zoneValve}
                    unknownIconClassName={style.zoneValveUnknownIcon}
                    valveState={roValveState}
                  />
                )}
              </div>
              <Pipe
                className={style.roSystemValvePipe}
                waterIsFlowing={roFlow && roValveState == 'OPEN'}
              />
              <Pipe
                className={style.stackTankInletPipe}
                waterIsFlowing={roFlow && roValveState == 'OPEN'}
              />
            </>
          )}
        </div>
        <div className={style.statusContainer}>
          <StackStatus
            hasHelp={true}
            className={style.stackStatus}
            tankClassName={style.stackTank}
            hideCollectorTank={false}
            isMicrogreen={stack.isMicrogreen}
            tankStatusOverride={stack?.tankStatus}
            zoneName={zone.name}
            stack={stack}
            hasPumpPermission={stackPumpSetPerm}
            showStackActions={false}
          />
        </div>
        <></>
        <div className={style.levelsContainer}>
          <Levels
            zone={zone}
            stack={stack}
            tank={zoneSupplyState}
            isMicrogreen={zone.isMicrogreen}
            temperatureMap={tempMap}
            collectionTankHidden={
              zone.isDistributionTankHidden || zone.isMicrogreen
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ({ facilityId, zoneName, stackName }) => {
  const result = useQuery(QUERY, {
    variables: { facilityId, zoneName, stackName },
  });

  const { data } = result;
  const { facility, facilities } = data || {};
  const { zone } = facility || {};

  useEffect(() => {
    if (facility) {
      defaultFacilityId.set(facility.facilityId);
    }
  }, [facility]);

  return (
    <PageLayout
      heading={
        <FacilityHeading facility={facility} facilities={facilities || []} />
      }
      subHeading={
        zone &&
        zone.stack && (
          <span>
            <Link to={`/facility/${facilityId}/zone/${zone.name}`}>
              Zone {zone.name}
            </Link>
            <span> / Stack {zone.stack.name}</span>
          </span>
        )
      }
    >
      <PageLoadingContainer
        resourceTypeName="Facility"
        result={result}
        resourceExists={!!facility}
        render={() =>
          !zone ? (
            <PageNotFound resourceTypeName={`Zone ${zoneName}`} />
          ) : !zone.stack ? (
            <PageNotFound resourceTypeName={`Stack ${stackName}`} />
          ) : (
            <PageContent facilityId={facilityId} zone={zone} />
          )
        }
      />
    </PageLayout>
  );
};
