import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import gql from 'graphql-tag';
import authentication from '../../modules/authentication';
import { homebrewInterchange } from '../../modules/interchange';
import { useMutation } from '@apollo/react-hooks';
import { Button, FormGroup, Label, Input } from 'reactstrap';

import { MsalSignInButton } from '../../components/MsalSignInButton';

import { getInputValueAnd, updateState } from '../../modules/form-helpers';

import LoginForm from '../../components/LoginForm';
import Loading from '../../components/Loading';
import ResultAlert from '../../components/ResultAlert';
import ErrorMessages from '../../components/ErrorMessages';

import style from './style.module.scss';

const AUTHENTICATE = gql`
  mutation authenticate($username: String!, $password: String!) {
    authenticate(username: $username, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

const MSAL_AUTHENTICATE = gql`
  mutation msalAuthenticateUser($username: String!, $accessToken: String!) {
    msalAuthenticate(username: $username, accessToken: $accessToken) {
      accessToken
      refreshToken
    }
  }
`;

export default () => {
  const [
    msalAuthenticate,
    { data: msalData, error: msalError, loading: msalLoading },
  ] = useMutation(MSAL_AUTHENTICATE);
  const [authenticate, { data, error, loading }] = useMutation(AUTHENTICATE);
  const [msalHandshake, setMsalHandshake] = useState(null);
  const [msalProcessing, setMsalProcessing] = useState(false);
  const [authMoniker, setAuthMoniker] = useState('');

  //MSAL callback and negotiation
  useEffect(() => {
    authentication.setTokens({ ...msalHandshake });

    if (msalData && !msalError) {
      setMsalHandshake({
        accessToken: msalData.msalAuthenticate.accessToken,
        refreshToken: msalData.msalAuthenticate.refreshToken,
      });
      setAuthMoniker('msal');
    }
  }, [msalData, msalError, msalHandshake]);

  //UI state management logic.
  const { state } = useLocation();
  const authorizedRedirect = '/facility';
  const from = (state && state.from) || authorizedRedirect;

  const usernameState = useState('');
  const [username] = usernameState;

  const passwordState = useState('');
  const [password] = passwordState;

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const formIsValid = !!username && !!password;

  //Change authentication escalation workflow based on moniker
  switch (authMoniker) {
    case 'msal':
      if (authentication.getAccessToken() != null) {
        return <Redirect to={from || authorizedRedirect} />;
      }
      break;
    default:
      if (!error && data) {
        authentication.setTokens({
          accessToken: data.authenticate.accessToken,
          refreshToken: data.authenticate.refreshToken,
        });
        return <Redirect to={from || authorizedRedirect} />;
      }
  }

  //Callbacks
  const msalCallback = tokens => {
    setMsalProcessing(true);
    msalAuthenticate({
      variables: { ...tokens },
    });
  };

  if (msalProcessing)
    return (
      <div
        className="container-fluid h-100"
        style={{ backgroundImage: 'url(/static/media/login-bg.fa2e6b35.jpg)' }}
      >
        <div className={style.loadingModal}>
          <Loading />
        </div>

        <MsalSignInButton postAuth={msalCallback} className="d-none" />
      </div>
    );

  return (
    <LoginForm onSubmit={e => {}}>
      <div
        style={{
          width: '100%',
          marginTop: '2.5rem',
        }}
      >
        <MsalSignInButton postAuth={msalCallback} className="w-100" />
      </div>
    </LoginForm>
  );
  //Default view logic.
  /*
  return (
    <LoginForm
      onSubmit={e => {
        setIsDirty(false);
        authenticate({
          variables: { username, password },
        });
      }}
    >
      <fieldset disabled={loading || msalProcessing}>
        <FormGroup>
          <Label for="username">Username</Label>
          <Input
            id="username"
            type="email"
            required
            value={username}
            onChange={getInputValueAnd(
              updateState({ valueState: usernameState, isDirtyState })
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label for="password">Password</Label>
          <Input
            id="password"
            type="password"
            required
            value={password}
            onChange={getInputValueAnd(
              updateState({ valueState: passwordState, isDirtyState })
            )}
          />
        </FormGroup>
      </fieldset>

      {error && !isDirty && (
        <ResultAlert className={style.errorAlert} success={false}>
          <ErrorMessages error={error} />
        </ResultAlert>
      )}

      <div className={style.buttonGroup}>
        <Button color="primary" disabled={!formIsValid} type="submit">
          {loading && <Loading />}
          <span style={{ visibility: loading ? 'hidden' : null }}>Login</span>
        </Button>

        <a className={style.forgotPassword} href="/forgot-password">
          Forgot Password
        </a>
      </div>

      <hr style={{ backgroundColor: '#555555' }} />
      <MsalSignInButton postAuth={msalCallback} className="w-100" />
    </LoginForm>
  );*/
};
