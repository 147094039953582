import React, { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';

import useStackAttachedCollectorTankFlushPrompt from '../../hooks/useStackAttachedCollectorTankFlushPrompt';
import useStackAttachedCollectorTankPumpModePrompts from '../../hooks/useStackAttachedCollectorTankPumpModePrompts';

import useZoneAttachedCollectorTankFlushPrompt from '../../hooks/useZoneAttachedCollectorTankFlushPrompt';
import useZoneAttachedCollectorTankPumpModePrompts from '../../hooks/useZoneAttachedCollectorTankPumpModePrompts';
import useZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt from '../../hooks/useZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt';

import PumpButton from '../PumpButton';
import Tank from '../Tank';
import NamedTank from '../NamedTank';
import Switch from '../Switch';
import InvalidWaterSensorStateIcon from '../InvalidWaterSensorStateIcon';

import style from './style.module.scss';
import { Descriptions } from '../../modules/Descriptions';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'react-apollo';

const ZoneAttachedCollectorTankResetInvalidStateButton = ({
  collectorTank,
  ...props
}) => {
  const {
    resetInvalidWaterLevelSensorStateCommand,
    invalidTankLevelSwitches,
  } = collectorTank;

  const { zoneId, name: zoneName } = collectorTank.zone;

  const {
    openZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt,
    ZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt,
    zoneAttachedCollectorTankInvalidWaterSensorStateResetPromptProps,
  } = useZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt({
    zoneName: zoneName,
    zoneId: zoneId,
    invalidStates: {
      HH: invalidTankLevelSwitches?.hh,
      H: invalidTankLevelSwitches?.h,
      LL: invalidTankLevelSwitches?.ll,
    },
  });

  return (
    <>
      <ZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt
        {...zoneAttachedCollectorTankInvalidWaterSensorStateResetPromptProps}
      />

      <InvalidWaterSensorStateIcon
        className={style.invalidWaterSensorStateIcon}
        onClick={
          resetInvalidWaterLevelSensorStateCommand.canExecute
            ? openZoneAttachedCollectorTankInvalidWaterSensorStateResetPrompt
            : undefined
        }
        style={{
          cursor: resetInvalidWaterLevelSensorStateCommand.canExecute
            ? 'pointer'
            : undefined,
        }}
      />
    </>
  );
};

const GET_MGREEN_STACK_TANK_PUMP = gql`
  query microgreenStackTankPumpQuery($input: ID!) {
    microgreenStackTankPumpIsOn(stackId: $input)
  }
`;

const SET_MGREEN_STACK_TANK_PUMP = gql`
  mutation microgreenStackTankPumpToggle(
    $input: MicrogreenStackTankPumpCommandInputType!
  ) {
    microgreenStackTankPump(input: $input) {
      __typename
    }
  }
`;

const StackAttachedCollectorTankPumpButton = ({
  zoneName,
  collectorTank,
  stackId,
  state,
  stateHasChanged,
  ...props
}) => {
  const { stack, pumpMode, pumpState, flushCommand } = collectorTank;

  const {
    openStackAttachedCollectorTankFlushPrompt,
    StackAttachedCollectorTankFlushPrompt,
    stackAttachedCollectorTankFlushPromptProps,
  } = useStackAttachedCollectorTankFlushPrompt({
    zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  return (
    <>
      <StackAttachedCollectorTankFlushPrompt
        {...stackAttachedCollectorTankFlushPromptProps}
      />

      <PumpButton
        {...props}
        pumpState={state}
        manualMode={pumpMode !== 'AUTOMATIC'}
        disabled={!flushCommand.canExecute}
        onClick={stateHasChanged}
      />
    </>
  );
};

const ZoneAttachedCollectorTankPumpButton = ({ collectorTank, ...props }) => {
  const { zone, pumpMode, pumpState, flushCommand } = collectorTank;

  const {
    openZoneAttachedCollectorTankFlushPrompt,
    ZoneAttachedCollectorTankFlushPrompt,
    zoneAttachedCollectorTankFlushPromptProps,
  } = useZoneAttachedCollectorTankFlushPrompt({
    zoneName: zone.name,
    zoneId: zone.zoneId,
  });

  return (
    <>
      <ZoneAttachedCollectorTankFlushPrompt
        {...zoneAttachedCollectorTankFlushPromptProps}
      />

      <PumpButton
        {...props}
        pumpState={pumpState}
        manualMode={pumpMode !== 'AUTOMATIC'}
        disabled={!flushCommand.canExecute}
        onClick={openZoneAttachedCollectorTankFlushPrompt}
      />
    </>
  );
};

const StackAttachedCollectorTankPumpModeSwitch = ({
  zoneName,
  collectorTank,
  ...props
}) => {
  const { stack, pumpMode, updatePumpModeCommand } = collectorTank;

  const {
    openStackAttachedCollectorTankPumpAutomaticModePrompt,
    openStackAttachedCollectorTankPumpMaintenanceModePrompt,
    StackAttachedCollectorTankPumpModePrompts,
    stackAttachedCollectorTankPumpModePromptsProps,
  } = useStackAttachedCollectorTankPumpModePrompts({
    zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  return (
    <>
      <StackAttachedCollectorTankPumpModePrompts
        {...stackAttachedCollectorTankPumpModePromptsProps}
      />

      <Switch
        height={25}
        width={52}
        {...props}
        id="pump-mode-switch"
        disabled={!updatePumpModeCommand.canExecute}
        checked={pumpMode === 'AUTOMATIC'}
        onChange={
          pumpMode === 'AUTOMATIC'
            ? openStackAttachedCollectorTankPumpMaintenanceModePrompt
            : openStackAttachedCollectorTankPumpAutomaticModePrompt
        }
      />
    </>
  );
};

const ZoneAttachedCollectorTankPumpModeSwitch = ({
  collectorTank,
  ...props
}) => {
  const { zone, pumpMode, updatePumpModeCommand } = collectorTank;

  const {
    openZoneAttachedCollectorTankPumpAutomaticModePrompt,
    openZoneAttachedCollectorTankPumpMaintenanceModePrompt,
    ZoneAttachedCollectorTankPumpModePrompts,
    zoneAttachedCollectorTankPumpModePromptsProps,
  } = useZoneAttachedCollectorTankPumpModePrompts({
    zoneName: zone.name,
    zoneId: zone.zoneId,
  });

  return (
    <>
      <ZoneAttachedCollectorTankPumpModePrompts
        {...zoneAttachedCollectorTankPumpModePromptsProps}
      />

      <Switch
        height={25}
        width={52}
        {...props}
        id="pump-mode-switch"
        disabled={!updatePumpModeCommand.canExecute}
        checked={pumpMode === 'AUTOMATIC'}
        onChange={
          pumpMode === 'AUTOMATIC'
            ? openZoneAttachedCollectorTankPumpMaintenanceModePrompt
            : openZoneAttachedCollectorTankPumpAutomaticModePrompt
        }
      />
    </>
  );
};

const CollectorTank = ({
  className,
  zoneReturnLabelClassName,
  zoneName,
  collectorTank,
  stackId,
  pumpSwitchProps,
  hidePumpCommands = false,
  sensorsOnRight = false,
  hasHelp,
  dataPlacement = 'bottom',
  tankStatusOverride = null,
  label = 'Zone Return',
}) => {
  const [setPump, _] = useMutation(SET_MGREEN_STACK_TANK_PUMP);
  const prevPumpState = useQuery(GET_MGREEN_STACK_TANK_PUMP, {
    variables: { input: stackId },
  });

  const [state, setState] = useState('OFF');
  useEffect(() => {
    if (prevPumpState.data?.microgreenStackTankPumpIsOn == null) return;
    setState(prevPumpState.data.microgreenStackTankPumpIsOn ? 'ON' : 'OFF');
  }, [prevPumpState.data?.microgreenStackTankPumpIsOn]);

  const {
    waterLevel,
    gallons,
    pumpState,
    pumpMode,
    flushCommand,
    updatePumpModeCommand,
    dryTankDetected,
  } = collectorTank;

  const showPumpButton = !hidePumpCommands;
  if (dryTankDetected && !!className) {
    className += ' dryTank';
  }

  const togglePump = useCallback(() => {
    setPump({
      variables: {
        input: {
          stackId: stackId,
          pumpIsOn: state == 'ON' ? false : true,
        },
      },
    });
    setState(prev => (prev == 'ON' ? 'OFF' : 'ON'));
  }, [state]);

  return (
    <>
      <div className={[style.container, className].join(' ')}>
        {collectorTank.stack ? (
          <Tank
            dataPlacement={dataPlacement}
            className={`${style.tank} ${hasHelp ? 'nav-help-tooltip' : ''}`}
            waterLevel={
              tankStatusOverride != null ? tankStatusOverride : waterLevel
            }
            gallons={gallons}
            pumpState={state}
            hidePump={showPumpButton}
            sensorsOnRight={sensorsOnRight}
            dryTankDetected={dryTankDetected}
            data-toggle="tooltip"
            data-placement="bottom"
            title={Descriptions.zoneCollectorTank}
          >
            {showPumpButton && (
              <StackAttachedCollectorTankPumpButton
                className={[
                  style.flushTankButton,
                  pumpMode !== 'AUTOMATIC' ? style.manual : style.automatic,
                  state === 'ON' ? style.on : style.off,
                ].join(' ')}
                zoneName={zoneName}
                state={state}
                stackId={stackId}
                isMicrogreen={true}
                stateHasChanged={togglePump}
                collectorTank={collectorTank}
              />
            )}
          </Tank>
        ) : (
          <NamedTank
            dataPlacement={dataPlacement}
            entityType={
              <span
                className={[
                  style.zoneReturnLabel,
                  zoneReturnLabelClassName,
                ].join(' ')}
              >
                {label}
              </span>
            }
            className={style.tank}
            waterLevel={
              tankStatusOverride != null ? tankStatusOverride : waterLevel
            }
            gallons={gallons}
            pumpState={pumpState}
            hidePump={showPumpButton}
            levelSensors={[
              collectorTank?.invalidTankLevelSwitches?.hh,
              collectorTank?.invalidTankLevelSwitches?.h,
              collectorTank?.invalidTankLevelSwitches?.ll,
            ].filter(x => x !== undefined && x !== null)}
            sensorsOnRight={sensorsOnRight}
            dryTankDetected={dryTankDetected}
            biggerTank={true}
          >
            {collectorTank.invalidWaterLevelSensorStateDetected && (
              <ZoneAttachedCollectorTankResetInvalidStateButton
                collectorTank={collectorTank}
              />
            )}
            {showPumpButton && (
              <ZoneAttachedCollectorTankPumpButton
                className={[
                  style.flushTankButton,
                  pumpMode !== 'AUTOMATIC' ? style.manual : style.automatic,
                  pumpState === 'ON' ? style.on : style.off,
                ].join(' ')}
                zone={collectorTank.zone}
                collectorTank={collectorTank}
              />
            )}
          </NamedTank>
        )}
      </div>
    </>
  );
};

CollectorTank.fragment = gql`
  fragment CollectorTank on CollectorTankInterface {
    waterLevel
    gallons
    pumpState
    pumpMode
    flushCommand {
      isAuthorized
      canExecute
    }
    updatePumpModeCommand {
      systemStateSatisfied
      canExecute
    }

    ... on StackAttachedCollectorTankType {
      stack {
        stackId
        name
      }
      dryTankDetected
    }

    ... on ZoneAttachedCollectorTankType {
      zone {
        zoneId
        name
      }
      dryTankDetected
      invalidWaterLevelSensorStateDetected
      invalidTankLevelSwitches {
        hh
        h
        l
        ll
      }
      resetInvalidWaterLevelSensorStateCommand {
        isAuthorized
        canExecute
      }
    }
  }
`;

export default CollectorTank;
