import gql from 'graphql-tag';

export const FRAGMENT_OZONE_SYSTEM = gql`
  fragment CalibrateOzoneSystemTankForm on OzoneSystemType {
    ozoneSystemId
    tank {
      autoCalibrated
      calibrationPoints
    }
  }
`;
