import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import gql from 'graphql-tag';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faArrowCircleRight,
  faClock,
  faArrowRight,
  faEdit,
  faSave,
} from '@fortawesome/free-solid-svg-icons';

import useStackLevelValveModePrompts from '../../../../hooks/useStackLevelValveModePrompts';

import plantsIconGray from '../../../../assets/images/plantsIconGray.svg';
import plantsIconGreen from '../../../../assets/images/plantsIconGreen.svg';
import { getBorderStatusClass } from '../../../../components/ticket-status-summary';

import StackCommands from './components/StackCommands';
import NamedTank from '../../../../components/NamedTank';
import CollectorTank from '../../../../components/CollectorTank';
import Pipe from '../../../../components/Pipe';
import Valve from '../../../../components/Valve';
import Switch from '../../../../components/Switch';
import BeeSensorDisplay from '../../../../components/BeeSensorDisplay';
import LevelValveButton from '../../../../components/LevelValveButton';

import { LevelBeeSensorMeasurements } from '../../../../modules/bee-sensors';

import style from './style.module.scss';
import { Descriptions } from '../../../../modules/Descriptions';
import { useQuery } from '@apollo/react-hooks';
import TempMap from '../../../../modules/temp-map';
import { firstOrNull } from '../../../../modules/temp-map';

import {
  getWaterLevel,
  getPumpState,
  getPumpOnOff,
} from '../../../../modules/string';
import LevelDrainValveButton from '../../../../components/LevelDrainValveButton';
import { useMutation } from 'react-apollo';
import { UPDATE_LEVEL_VALVE_STATE } from '../../../../hooks/useStackLevelValveStatePrompts';
import ValveButton from '../../../../components/ValveButton';
import { number } from 'prop-types';

const FACILITY_MAX_TRAYS = gql`
  {
    facilityMaxTrays
  }
`;

const ZONE_DIST_TANK_MODE = gql`
  {
    zoneDistributionTankStatuses {
      index
      state
      stateTimeout
    }
  }
`;

const LevelSwitch = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  levelMode,
  updateLevelModeCommand,
  ...props
}) => {
  const {
    openStackLevelAutomaticModePrompt,
    openStackLevelMaintenanceModePrompt,
    StackLevelValveModePrompts,
    stackLevelValveModePromptsProps,
  } = useStackLevelValveModePrompts({
    zoneName,
    stackName,
    levelName,
    levelId,
  });

  return (
    <>
      <StackLevelValveModePrompts {...stackLevelValveModePromptsProps} />
      <Switch
        checked={levelMode === 'AUTOMATIC'}
        disabled={!updateLevelModeCommand.canExecute}
        onChange={
          levelMode === 'AUTOMATIC'
            ? openStackLevelMaintenanceModePrompt
            : openStackLevelAutomaticModePrompt
        }
        {...props}
      />
    </>
  );
};

const LevelConfigureButton = ({
  facilityId,
  zoneName,
  stackName,
  levelName,
  index,
}) => (
  <Link
    to={`/facility/${facilityId}/zone/${zoneName}/stack/${stackName}/level/${levelName}`}
    data-toggle="tooltip"
    data-placement="top"
    className={`${index == 2 ? 'nav-help-tooltip' : ''}`}
    title={Descriptions.levelSettings}
  >
    <Button className={style.configureSensorButton}>
      <FontAwesomeIcon icon={faCog} fixedWidth />
    </Button>
  </Link>
);

export const SET_STACK_MODE = gql`
  mutation SetStackMode($type: StackModeInputType!) {
    setStackMode(input: $type) {
      stackId
    }
  }
`;

export const SET_CIP = gql`
  mutation setPerformStackCleanInPlace(
    $value: PerformStackCleanInPlaceInputType!
  ) {
    performStackCleanInPlace(input: $value) {
      stackId
    }
  }
`;

export const SET_CIP_VALVES = gql`
  mutation modernCipSetValves($value: SetCleanInPlaceValvesInputType!) {
    modernCipSetValves(input: $value) {
      stackId
    }
  }
`;

const Levels = ({
  zone,
  stack,
  collectionTankHidden,
  tank,
  temperatureMap,
  isMicrogreen,
}) => {
  const [
    setStackMode,
    { error: setStackModeError, loading: setStackModeLoading },
  ] = useMutation(SET_STACK_MODE);
  const [
    setCipConfiguration,
    { error: setCipConfigurationError, loading: setCipConfigurationLoading },
  ] = useMutation(SET_CIP);
  const zoneDistTankState = useQuery(ZONE_DIST_TANK_MODE, {
    pollInterval: 30000,
  });
  const [
    setCipValves,
    { error: setCipValvesError, loading: setCipValvesLoading },
  ] = useMutation(SET_CIP_VALVES);
  const { loading, error, data } = useQuery(FACILITY_MAX_TRAYS);

  const [distTankMode, setDistTankMode] = useState(null);
  useEffect(() => {
    if (!!zoneDistTankState.error || zoneDistTankState.loading) return;
    if (zoneDistTankState.data?.zoneDistributionTankStatuses != null)
      setDistTankMode(zoneDistTankState.data.zoneDistributionTankStatuses);
  }, [zoneDistTankState.data]);

  const [cipEditMode, setCipEditMode] = useState(false);
  const toggleEditMode = () => {
    if (cipEditMode) {
      setCipConfiguration({
        variables: {
          value: {
            stackId: Number(stack.stackId),
            secondsPerValve: cipState.durationSeconds,
            cycles: cipState.cycles,
            isEnabled: false,
          },
        },
      });
    }
    setCipEditMode(!cipEditMode);
  };

  const trayCount =
    !!loading || error != null || !!!data ? 0 : data.facilityMaxTrays;

  const { mode: stackMode } = stack;

  const showCleanInPlaceMode = stack.mode === 'CLEAN_IN_PLACE';

  const [isCleanInPlace, setIsCleanInPlace] = useState(
    stack.mode === 'CLEAN_IN_PLACE'
  );

  const [cipState, setCipState] = useState({
    status: stack.modernCipStatus,
    isRunning: stack.modernCipIsEnabled,
    currentCycle: stack.modernCipCurrentCycle,
    currentLevel: stack.modernCipCurrentLevel,
    durationSeconds: stack.modernCipDurationSecondsConfig,
    cycles: stack.modernCipCycles,
    valveA: stack.modernCipDosingValveA,
    valveB: stack.modernCipDrainValveB,
    valveC: stack.modernCipDrainValveC,
  });

  useEffect(() => {
    setCipState({
      status: stack.modernCipStatus,
      isRunning: stack.modernCipIsEnabled,
      currentCycle: stack.modernCipCurrentCycle,
      currentLevel: stack.modernCipCurrentLevel,
      durationSeconds: stack.modernCipDurationSecondsConfig,
      cycles: stack.modernCipCycles,
      valveA: stack.modernCipDosingValveA === 'open',
      valveB: stack.modernCipDrainValveB === 'open',
      valveC: stack.modernCipDrainValveC === 'open',
    });
  }, [stack]);

  const cleanInPlaceToggle = () => {
    setStackMode({
      variables: {
        type: {
          stackId: `${stack.stackId}`,
          isCleanInPlace: !isCleanInPlace,
        },
      },
    }).catch(r => console.log(r));
    setIsCleanInPlace(!isCleanInPlace);
  };
  useEffect(() => {
    if (!!setStackModeError?.error || !!setStackModeLoading?.loading) return;
    setIsCleanInPlace(stack.mode === 'CLEAN_IN_PLACE');
  }, [stack.mode]);

  const cleanInPlacePerform = isCleaning => {
    setCipConfiguration({
      variables: {
        value: {
          cycles: cipState.cycles,
          isEnabled: isCleaning,
          secondsPerValve: cipState?.durationSeconds ?? 0,
          stackId: Number(stack.stackId),
        },
      },
    }).catch(err => console.log(err));
    setCipState(prev => {
      return {
        status: cipState.status,
        isRunning: isCleaning,
        currentCycle: cipState.currentCycle,
        currentLevel: cipState.currentLevel,
        durationSeconds: cipState.durationSeconds,
        cycles: cipState.cycles,
        valveA: prev.valveA,
        valveB: prev.valveB,
        valveC: prev.valveC,
      };
    });
  };

  const toggleValve = tag => {
    setCipValves({
      variables: {
        value: {
          stackId: Number(stack.stackId),
          valveAOpen: tag === 'A' ? !cipState.valveA : cipState.valveA,
          valveBOpen: tag === 'B' ? !cipState.valveB : cipState.valveB,
          valveCOpen: tag === 'C' ? !cipState.valveC : cipState.valveC,
        },
      },
    });
    setCipState(prev => {
      return {
        status: cipState.status,
        isRunning: cipState.isRunning,
        currentCycle: cipState.currentCycle,
        currentLevel: cipState.currentLevel,
        durationSeconds: cipState.durationSeconds,
        cycles: cipState.cycles,
        valveA: tag === 'A' ? !cipState.valveA : cipState.valveA,
        valveB: tag === 'B' ? !cipState.valveB : cipState.valveB,
        valveC: tag === 'C' ? !cipState.valveC : cipState.valveC,
      };
    });
  };

  const waterFlowDetected = stack?.waterFlowDetected ?? false;
  const waterDrainFlowDetected = stack?.waterDrainFlowDetected ?? false;

  const drainLevel = stack.levels.find(x => x.drainValveState === 'OPEN');
  const openLevel = stack.levels.find(x => x.valveState === 'OPEN');

  const waterIsFlowingOutOfCollectorTank =
    stack.collectorTank?.pumpState === 'ON' ||
    zone.collectorTank?.pumpState === 'ON';

  const isLevelBeingIrrigated = level => {
    if (showCleanInPlaceMode) {
      return waterIsFlowingOutOfCollectorTank && level.valveState === 'OPEN';
    }

    return level.valveState === 'OPEN' && waterFlowDetected;
  };

  const isLevelBeingDrained = level => {
    if (showCleanInPlaceMode) {
      return waterIsFlowingOutOfCollectorTank && level.valveState === 'OPEN';
    }

    return level.valveState === 'OPEN' && waterDrainFlowDetected;
  };

  const isMobile = useMediaQuery({ query: `(max-width: 899px)` });

  const modifyCipCycle = e => {
    const input = e.target.value.replace(/\D/, '');

    setCipState(prev => {
      return {
        status: prev.status,
        isRunning: prev.isRunning,
        currentCycle: prev.currentCycle,
        currentLevel: prev.currentLevel,
        durationSeconds: prev.durationSeconds,
        cycles: Number(input),
        valveA: prev.valveA,
        valveB: prev.valveB,
        valveC: prev.valveC,
      };
    });
  };

  const modifyCipSeconds = e => {
    const input = e.target.value.replace(/\D/, '');

    setCipState(prev => {
      return {
        status: prev.status,
        isRunning: prev.isRunning,
        currentCycle: prev.currentCycle,
        currentLevel: prev.currentLevel,
        durationSeconds: Number(input),
        cycles: prev.cycles,
        valveA: prev.valveA,
        valveB: prev.valveB,
        valveC: prev.valveC,
      };
    });
  };

  return (
    <div className={style.container}>
      <StackCommands
        className={style.stackCommands}
        zoneName={zone.name}
        stack={stack}
        isMicrogreen={isMicrogreen || zone.isNewMicrogreen}
        zoneId={zone.zoneId}
        isCleanInPlace={isCleanInPlace}
        cleanInPlaceToggleClicked={cleanInPlaceToggle}
      />
      <></>
      {isCleanInPlace ? (
        <>
          <div className={style.zoneAndStackContainer}>
            <div
              style={{
                backgroundColor: '#3c3b3b',
                borderRadius: '0.75em',
                minWidth: '10em',
                minHeight: '5em',
                padding: '1em',
                marginBottom: '1em',
              }}
            >
              <table className={style.formCip} style={{ zIndex: '5000' }}>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faArrowCircleRight} fixedWidth />
                  </td>
                  <td>
                    {cipEditMode ? (
                      <input
                        value={cipState.cycles}
                        onChange={event => modifyCipCycle(event)}
                      />
                    ) : (
                      <span>
                        {cipState.currentCycle ?? 0} of {cipState.cycles ?? 0}
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faClock} fixedWidth />
                  </td>
                  <td>
                    {cipEditMode ? (
                      <input
                        value={cipState.durationSeconds}
                        onChange={event => modifyCipSeconds(event)}
                      />
                    ) : (
                      <span>
                        {stack.modernCipDurationSeconds ?? 0} of{' '}
                        {cipState.durationSeconds ?? 0} s
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                  </td>
                  <td>
                    Level <span>{cipState.currentLevel ?? '0'}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ textAlign: 'right' }}>
                    {!cipState.isRunning && (
                      <>
                        <button
                          style={{ zIndex: '5000' }}
                          className={style.EditButton}
                          onClick={() => toggleEditMode()}
                        >
                          {cipEditMode ? (
                            <FontAwesomeIcon
                              style={{
                                textAlign: 'right',
                                position: 'absolute',
                                transform: 'translate(-1em, -1em)',
                              }}
                              icon={faSave}
                              fixedWidth
                            />
                          ) : (
                            <FontAwesomeIcon
                              style={{
                                textAlign: 'right',
                                position: 'absolute',
                                transform: 'translate(-1em, -1em)',
                              }}
                              icon={faEdit}
                              fixedWidth
                            />
                          )}
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              </table>
              {cipState.isRunning ? (
                <button
                  style={{ zIndex: '5000' }}
                  className={style.actionButton}
                  onClick={() => cleanInPlacePerform(false)}
                >
                  ■
                </button>
              ) : (
                <button
                  style={{ zIndex: '5000' }}
                  className={style.actionButton}
                  onClick={() => cleanInPlacePerform(true)}
                >
                  ▶
                </button>
              )}
            </div>
            <div
              className={[
                style.collectorTankInPipeContainer,
                stackMode === 'MAINTENANCE' ? style.stackModeMaintenance : null,
                isCleanInPlace ? style.slideDown : null,
              ].join(' ')}
            >
              <Pipe
                className={style.collectorTankInPipeVertical}
                waterIsFlowing={waterDrainFlowDetected}
              />
            </div>
            <h5>Stack {stack.name}</h5>

            <div
              style={{
                marginLeft: 'auto',
                marginRight: '-1.25em',
              }}
            >
              <ValveButton
                label={'A'}
                inverted={true}
                className={style.levelInValve}
                valveState={cipState.valveA ? 'OPEN' : 'CLOSED'}
                onClick={() => toggleValve('A')}
              />
            </div>

            {stack.levels
              .map((level, index) => {
                const borderStatusClass = getBorderStatusClass('NONE');
                const {
                  levelId,
                  name,
                  updateLevelModeCommand,
                  updateLevelValveStateCommand,
                } = level;
                const levelIsBeingIrrigated = isLevelBeingIrrigated(level);
                const levelIsBeingDrained = isLevelBeingDrained(level);

                const mapping =
                  temperatureMap != null &&
                  firstOrNull(temperatureMap.filter(i => i.id === levelId));

                return (
                  <div
                    id={style[stack.levels.length - 1 - index]}
                    key={levelId}
                    className={[
                      style.levelContainer,
                      index === 0 ? style.firstLevel : null,
                      index === stack.levels.length - 1
                        ? style.lastLevel
                        : null,
                      levelIsBeingIrrigated
                        ? style.levelIsBeingIrrigated
                        : null,
                    ].join(' ')}
                  >
                    <div
                      className={[
                        style.levelFeederPipeContainer,
                        style.levelWaterOut,
                      ].join(' ')}
                    >
                      <Pipe
                        className={style.levelFeederPipe}
                        waterIsFlowing={level.index < drainLevel?.index ?? 0}
                      />
                    </div>

                    {zone.isNewMicrogreen ? (
                      <>
                        <Pipe
                          className={style.levelOutPipe}
                          waterIsFlowing={levelIsBeingDrained}
                        />
                        {updateLevelValveStateCommand.canExecute ? (
                          <LevelDrainValveButton
                            hasHelp={index === 1}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={Descriptions.levelDrainValve}
                            zoneName={zone.name}
                            stackName={stack.name}
                            levelName={level.name}
                            levelId={level.levelId}
                            className={style.levelInValve}
                            levelValveState={level.drainValveState}
                            needsAttention={level.drainValveOpenFailureDetected}
                          />
                        ) : (
                          <Valve
                            data-toggle="tooltip"
                            data-placement="top"
                            title={Descriptions.levelDrainValve}
                            className={style.levelInValve}
                            valveState={level.drainValveState}
                            needsAttention={level.drainValveOpenFailureDetected}
                          />
                        )}
                        <Pipe
                          className={style.levelOutPipe}
                          waterIsFlowing={levelIsBeingDrained}
                        />
                      </>
                    ) : (
                      <>
                        <Pipe
                          className={style.levelOutPipeLong}
                          waterIsFlowing={levelIsBeingIrrigated}
                        />
                      </>
                    )}

                    <div
                      className={`${style.level} position-relative border text-light border${borderStatusClass}`}
                      style={{
                        boxShadow:
                          mapping != null
                            ? `0 0 0.25em ${TempMap(mapping)}`
                            : '',
                      }}
                    >
                      <div
                        className={`${style.levelTitle} position-absolute rounded-circle text-center bg${borderStatusClass}`}
                      >
                        {name}
                      </div>

                      <div className={style.levelSwitchContainer}>
                        <LevelSwitch
                          zoneName={zone.name}
                          stackName={stack.name}
                          levelName={level.name}
                          levelId={level.levelId}
                          levelMode={level.mode}
                          updateLevelModeCommand={updateLevelModeCommand}
                        />
                      </div>

                      {level.hasBeeSensor ? (
                        <BeeSensorDisplay
                          sensorValues={level.beeSensor?.currentValue}
                          zoneName={zone.name}
                          stackName={stack.name}
                          levelName={level.name}
                          levelId={level.levelId}
                        />
                      ) : (
                        <>
                          {[...Array(trayCount)].map((_, i) => {
                            return (
                              <img
                                data-toggle="tooltip"
                                data-placement="top"
                                title={Descriptions.levelPlants}
                                className={`${style.levelPlant} ${
                                  index == 0 ? 'nav-help-tooltip' : ''
                                }`}
                                src={
                                  stack.mode === 'AUTOMATIC' &&
                                  level.mode === 'AUTOMATIC'
                                    ? plantsIconGreen
                                    : plantsIconGray
                                }
                                alt=""
                              />
                            );
                          })}
                        </>
                      )}

                      <div className={style.sensorOptionsContainer}>
                        <LevelConfigureButton
                          index={index}
                          facilityId={zone.facilityId}
                          zoneName={zone.name}
                          stackName={stack.name}
                          levelName={level.name}
                        />
                      </div>
                    </div>

                    <Pipe
                      className={style.levelInPipe}
                      waterIsFlowing={levelIsBeingIrrigated}
                    />

                    {updateLevelValveStateCommand.canExecute ? (
                      <LevelValveButton
                        hasHelp={index === 1}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={Descriptions.levelValve}
                        zoneName={zone.name}
                        stackName={stack.name}
                        levelName={level.name}
                        levelId={level.levelId}
                        className={style.levelInValve}
                        levelValveState={level.valveState}
                        needsAttention={level.valveOpenFailureDetected}
                      />
                    ) : (
                      <Valve
                        data-toggle="tooltip"
                        data-placement="top"
                        title={Descriptions.levelValve}
                        className={style.levelInValve}
                        valveState={level.valveState}
                        needsAttention={level.valveOpenFailureDetected}
                      />
                    )}

                    <Pipe
                      className={style.levelInPipe}
                      waterIsFlowing={levelIsBeingIrrigated}
                    />

                    <div
                      className={[
                        style.levelFeederPipeContainer,
                        style.levelWaterIn,
                      ].join(' ')}
                    >
                      <Pipe
                        className={style.levelFeederPipe}
                        waterIsFlowing={level.index >= openLevel?.index ?? 0}
                      />
                    </div>
                  </div>
                );
              })
              .reverse()}

            <div
              style={{
                position: 'absolute',
                bottom: '2.5em',
                left: 0,
                marginLeft: '-2em',
              }}
            >
              <ValveButton
                label={'B'}
                className={style.levelInValve}
                valveState={cipState.valveB ? 'OPEN' : 'CLOSED'}
                onClick={() => toggleValve('B')}
              />
            </div>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                marginLeft: '-2em',
              }}
            >
              <ValveButton
                label={'C'}
                className={style.levelInValve}
                valveState={cipState.valveC ? 'OPEN' : 'CLOSED'}
                onClick={() => toggleValve('B')}
              />
            </div>

            {stack.collectorTank.zone ? (
              <Link
                style={{ zIndex: 2 }}
                to={`/facility/${stack.collectorTank.zone?.facilityId}/zone/${zone.name}`}
              >
                <CollectorTank
                  className={style.stackTank}
                  zoneName={zone.name}
                  collectorTank={stack.collectorTank}
                  sensorsOnRight={false}
                  hidePumpCommands={true}
                />
              </Link>
            ) : (
              <CollectorTank
                className={style.stackTank}
                zoneName={zone.name}
                collectorTank={stack.collectorTank}
                hidePumpCommands={false}
                sensorsOnRight={false}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className={style.zoneAndStackContainer}>
            <div
              className={[
                style.collectorTankInPipeContainer,
                stackMode === 'MAINTENANCE' ? style.stackModeMaintenance : null,
              ].join(' ')}
            >
              <Pipe
                className={style.collectorTankInPipeHorizontal}
                waterIsFlowing={waterDrainFlowDetected || drainLevel != null}
              />

              <Pipe
                className={style.collectorTankInPipeVertical}
                waterIsFlowing={waterDrainFlowDetected || drainLevel != null}
              />

              <Pipe
                className={[
                  style.collectorTankOutPipeHorizontal,
                  showCleanInPlaceMode ? style.cleanInPlace : undefined,
                ].join(' ')}
                waterIsFlowing={waterIsFlowingOutOfCollectorTank}
              />

              <Pipe
                className={[
                  style.collectorTankOutPipeVertical,
                  showCleanInPlaceMode ? style.cleanInPlace : undefined,
                ].join(' ')}
                waterIsFlowing={waterIsFlowingOutOfCollectorTank}
              />
            </div>

            <div
              className={style.zoneTankContainer}
              title={Descriptions.zoneSupplyTank}
            >
              {showCleanInPlaceMode && (
                <Pipe
                  className={style.cleanInPlaceDetourPipe}
                  waterIsFlowing={waterIsFlowingOutOfCollectorTank}
                />
              )}

              <div className={style.controlContainer}>
                {!showCleanInPlaceMode && (
                  <Pipe
                    className={style.zoneTankInPipeHorizontal}
                    waterIsFlowing={waterIsFlowingOutOfCollectorTank}
                  />
                )}

                {collectionTankHidden && !isMobile && (
                  <div className="py-5">
                    <Pipe
                      className={style.zoneTankFullPipeHorizontal}
                      waterIsFlowing={waterIsFlowingOutOfCollectorTank}
                    />
                  </div>
                )}
              </div>

              {collectionTankHidden === false && (
                <>
                  {tank != null &&
                  tank.length > 0 &&
                  tank[0].ZoneStatusSummaries.length > 0 ? (
                    <Link to={`/facility/${zone.facilityId}/zone/${zone.name}`}>
                      <NamedTank
                        hasHelp={true}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={Descriptions.zoneSupplyTank}
                        entityType="Zone"
                        entityName={String(
                          tank[0].ZoneStatusSummaries[0].Zone + 1
                        )}
                        className={`${style.zoneTank} nav-help-tooltip`}
                        gallons={zone.tank.gallons}
                        waterLevel={getWaterLevel(tank[0].Tank.WaterLevel)}
                        pumpState={getPumpOnOff(tank[0].Pump)}
                        pumpMode={
                          distTankMode?.filter(
                            i => i.index == Number(zone.name) - 1
                          )[0]
                        }
                        biggerTank={false}
                        zoneId={zone.zoneId}
                      />
                    </Link>
                  ) : (
                    <Link to={`/facility/${zone.facilityId}/zone/${zone.name}`}>
                      <NamedTank
                        hasHelp={true}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={Descriptions.zoneSupplyTank}
                        entityType="Zone"
                        entityName={zone.name}
                        className={`${style.zoneTank} nav-help-tooltip`}
                        waterLevel={zone.tank.waterLevel}
                        gallons={zone.tank.gallons}
                        pumpState={zone.tank.pumpState}
                        biggerTank={false}
                        pumpMode={
                          distTankMode?.filter(
                            i => i.index == Number(zone.name) - 1
                          )[0]
                        }
                        zoneId={zone.zoneId}
                      />
                    </Link>
                  )}
                </>
              )}

              {collectionTankHidden && isMobile ? (
                <Pipe
                  className={style.zoneTankFullMobilePipeHorizontal}
                  waterIsFlowing={waterIsFlowingOutOfCollectorTank}
                />
              ) : (
                <div className={style.controlContainer}>
                  {!showCleanInPlaceMode && (
                    <Pipe
                      className={style.zoneTankOutPipeHorizontal}
                      waterIsFlowing={waterFlowDetected && openLevel != null}
                    />
                  )}
                  <Pipe
                    className={[
                      style.zoneTankOutPipeVertical,
                      showCleanInPlaceMode ? style.cleanInPlace : undefined,
                    ].join(' ')}
                    waterIsFlowing={waterFlowDetected && openLevel != null}
                  />
                  {!showCleanInPlaceMode && (
                    <Pipe
                      className={style.zoneTankInPipeHorizontalMobile}
                      waterIsFlowing={waterFlowDetected && openLevel != null}
                    />
                  )}
                </div>
              )}
            </div>

            <h5>Stack {stack.name}</h5>

            {stack.levels
              .map((level, index) => {
                const borderStatusClass = getBorderStatusClass('NONE');
                const {
                  levelId,
                  name,
                  updateLevelModeCommand,
                  updateLevelValveStateCommand,
                } = level;
                const levelIsBeingIrrigated = isLevelBeingIrrigated(level);
                const levelIsBeingDrained = isLevelBeingDrained(level);

                const mapping =
                  temperatureMap != null &&
                  firstOrNull(temperatureMap.filter(i => i.id === levelId));

                return (
                  <div
                    id={style[stack.levels.length - 1 - index]}
                    key={levelId}
                    className={[
                      style.levelContainer,
                      index === 0 ? style.firstLevel : null,
                      index === stack.levels.length - 1
                        ? style.lastLevel
                        : null,
                      levelIsBeingIrrigated
                        ? style.levelIsBeingIrrigated
                        : null,
                    ].join(' ')}
                  >
                    <div
                      className={[
                        style.levelFeederPipeContainer,
                        style.levelWaterOut,
                      ].join(' ')}
                    >
                      <Pipe
                        className={style.levelFeederPipe}
                        waterIsFlowing={level.index < drainLevel?.index ?? 0}
                      />
                    </div>

                    {zone.isNewMicrogreen ? (
                      <>
                        <Pipe
                          className={style.levelOutPipe}
                          waterIsFlowing={levelIsBeingDrained}
                        />
                        {updateLevelValveStateCommand.canExecute ? (
                          <LevelDrainValveButton
                            hasHelp={index === 1}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={Descriptions.levelDrainValve}
                            zoneName={zone.name}
                            stackName={stack.name}
                            levelName={level.name}
                            levelId={level.levelId}
                            className={style.levelInValve}
                            levelValveState={level.drainValveState}
                            needsAttention={level.drainValveOpenFailureDetected}
                          />
                        ) : (
                          <Valve
                            data-toggle="tooltip"
                            data-placement="top"
                            title={Descriptions.levelDrainValve}
                            className={style.levelInValve}
                            valveState={level.drainValveState}
                            needsAttention={level.drainValveOpenFailureDetected}
                          />
                        )}
                        <Pipe
                          className={style.levelOutPipe}
                          waterIsFlowing={levelIsBeingDrained}
                        />
                      </>
                    ) : (
                      <>
                        <Pipe
                          className={style.levelOutPipeLong}
                          waterIsFlowing={levelIsBeingIrrigated}
                        />
                      </>
                    )}

                    <div
                      className={`${style.level} position-relative border text-light border${borderStatusClass}`}
                      style={{
                        boxShadow:
                          mapping != null
                            ? `0 0 0.25em ${TempMap(mapping)}`
                            : '',
                      }}
                    >
                      <div
                        className={`${style.levelTitle} position-absolute rounded-circle text-center bg${borderStatusClass}`}
                      >
                        {name}
                      </div>

                      <div className={style.levelSwitchContainer}>
                        <LevelSwitch
                          zoneName={zone.name}
                          stackName={stack.name}
                          levelName={level.name}
                          levelId={level.levelId}
                          levelMode={level.mode}
                          updateLevelModeCommand={updateLevelModeCommand}
                        />
                      </div>

                      {level.hasBeeSensor ? (
                        <BeeSensorDisplay
                          sensorValues={level.beeSensor?.currentValue}
                          zoneName={zone.name}
                          stackName={stack.name}
                          levelName={level.name}
                          levelId={level.levelId}
                        />
                      ) : (
                        <>
                          {[...Array(trayCount)].map((_, i) => {
                            return (
                              <img
                                data-toggle="tooltip"
                                data-placement="top"
                                title={Descriptions.levelPlants}
                                className={`${style.levelPlant} ${
                                  index == 0 ? 'nav-help-tooltip' : ''
                                }`}
                                src={
                                  stack.mode === 'AUTOMATIC' &&
                                  level.mode === 'AUTOMATIC'
                                    ? plantsIconGreen
                                    : plantsIconGray
                                }
                                alt=""
                              />
                            );
                          })}
                        </>
                      )}

                      <div className={style.sensorOptionsContainer}>
                        <LevelConfigureButton
                          index={index}
                          facilityId={zone.facilityId}
                          zoneName={zone.name}
                          stackName={stack.name}
                          levelName={level.name}
                        />
                      </div>
                    </div>

                    <Pipe
                      className={style.levelInPipe}
                      waterIsFlowing={levelIsBeingIrrigated}
                    />

                    {updateLevelValveStateCommand.canExecute ? (
                      <LevelValveButton
                        hasHelp={index === 1}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={Descriptions.levelValve}
                        zoneName={zone.name}
                        stackName={stack.name}
                        levelName={level.name}
                        levelId={level.levelId}
                        className={style.levelInValve}
                        levelValveState={level.valveState}
                        needsAttention={level.valveOpenFailureDetected}
                      />
                    ) : (
                      <Valve
                        data-toggle="tooltip"
                        data-placement="top"
                        title={Descriptions.levelValve}
                        className={style.levelInValve}
                        valveState={level.valveState}
                        needsAttention={level.valveOpenFailureDetected}
                      />
                    )}

                    <Pipe
                      className={style.levelInPipe}
                      waterIsFlowing={levelIsBeingIrrigated}
                    />

                    <div
                      className={[
                        style.levelFeederPipeContainer,
                        style.levelWaterIn,
                      ].join(' ')}
                    >
                      <Pipe
                        className={style.levelFeederPipe}
                        waterIsFlowing={level.index >= openLevel?.index ?? 0}
                      />
                    </div>
                  </div>
                );
              })
              .reverse()}

            {stack.collectorTank.zone ? (
              <Link
                style={{ zIndex: 2 }}
                to={`/facility/${stack.collectorTank.zone?.facilityId}/zone/${zone.name}`}
              >
                <CollectorTank
                  className={style.stackTank}
                  zoneName={zone.name}
                  collectorTank={stack.collectorTank}
                  sensorsOnRight={false}
                  hidePumpCommands={true}
                />
              </Link>
            ) : (
              <CollectorTank
                className={style.stackTank}
                zoneName={zone.name}
                collectorTank={stack.collectorTank}
                hidePumpCommands={false}
                sensorsOnRight={false}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

Levels.stackFragment = gql`
  fragment LevelsStack on StackType {
    name
    stackId
    mode
    waterFlowDetected
    collectorTank {
      ...CollectorTank

      ... on ZoneAttachedCollectorTankType {
        zone {
          zoneId
          facilityId
        }
      }
    }
    levels {
      levelId
      name
      index
      mode
      hasBeeSensor
      drainValveState
      beeSensor {
        serialNumber
        currentValue {
          ...LevelBeeSensorValues
        }
      }
      updateLevelModeCommand {
        canExecute
      }
      updateLevelValveStateCommand {
        canExecute
      }
      updateLevelBeeSensorConfigurationCommand {
        canExecute
      }
      valveState
      valveOpenFailureDetected
    }
    ...StackCommands
  }
  ${CollectorTank.fragment}
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorCurrentValue}
  ${StackCommands.fragment}
`;

Levels.zoneFragment = gql`
  fragment LevelsZone on ZoneType {
    name
    facilityId
    tank {
      pumpState
    }
  }
`;

export default Levels;
