import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneSupplyName: string;
  zoneSupplyId: string;
  onCompleted?: () => void;
}

interface Result {
  openOzoneZoneSupplyPumpAutomaticModePrompt: () => void;
  openOzoneZoneSupplyPumpMaintenanceModePrompt: () => void;
  OzoneZoneSupplyPumpModePrompts: typeof OzoneZoneSupplyPumpModePrompts;
  ozoneZoneSupplyPumpModePromptsProps: Props;
}

export const UPDATE_OZONE_SYSTEM_ZONE_SUPPLY_PUMP_MODE = gql`
  mutation updateOzoneSystemZoneSupplyPumpMode(
    $input: UpdateOzoneSystemZoneSupplyPumpModeInputType!
  ) {
    updateOzoneSystemZoneSupplyPumpMode(input: $input) {
      zoneDistributionTank {
        zoneDistributionTankId
      }
    }
  }
`;

interface Props extends Input {
  maintenanceModePromptIsOpen: boolean;
  automaticModePromptIsOpen: boolean;
  closeMaintenanceModePrompt: () => void;
  closeAutomaticModePrompt: () => void;
}

const OzoneZoneSupplyPumpModePrompts = ({
  zoneSupplyName,
  zoneSupplyId,
  automaticModePromptIsOpen,
  maintenanceModePromptIsOpen,
  closeAutomaticModePrompt,
  closeMaintenanceModePrompt,
  onCompleted,
}: Props) => {
  const [
    updateOzoneSystemZoneSupplyPumpMode,
    { error, loading: saving },
  ] = useMutation(UPDATE_OZONE_SYSTEM_ZONE_SUPPLY_PUMP_MODE, {
    onCompleted: () => {
      closeAutomaticModePrompt();
      closeMaintenanceModePrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <>
      <PromptModal
        title={`Put Ozone Pump for Zone Supply ${zoneSupplyName} Into Automatic Mode`}
        isOpen={automaticModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneSystemZoneSupplyPumpMode({
            variables: {
              input: {
                zoneDistributionTankId: zoneSupplyId,
                mode: 'AUTOMATIC',
              },
            },
          });
        }}
        onComplete={closeAutomaticModePrompt}
      >
        <p>
          {`Putting the ozone pump for zone supply tank ${zoneSupplyName} into automatic mode will allow
            the ozone tank to automatically flush into the zone supply tank as needed
            once the ozone system is put into automatic mode.`}
        </p>

        <p>{`Put the ozone pump for zone supply tank ${zoneSupplyName} into automatic mode?`}</p>
      </PromptModal>

      <PromptModal
        title={`Put Ozone Pump for Zone Supply ${zoneSupplyName} Into Maintenance Mode`}
        isOpen={maintenanceModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneSystemZoneSupplyPumpMode({
            variables: {
              input: {
                zoneDistributionTankId: zoneSupplyId,
                mode: 'MAINTENANCE',
              },
            },
          });
        }}
        onComplete={closeMaintenanceModePrompt}
      >
        <p>
          {`Putting the ozone pump for zone supply tank ${zoneSupplyName} into maintenance mode will prevent the
            ozone tank from automatically flushing into the zone.`}
        </p>

        <p>
          {`Put the ozone pump for zone supply tank ${zoneSupplyName} into maintenance mode?`}
        </p>
      </PromptModal>
    </>
  );
};

const useOzoneZoneSupplyPumpModePrompts: (input: Input) => Result = ({
  zoneSupplyName,
  zoneSupplyId,
  onCompleted,
}: Input) => {
  const [automaticModePromptIsOpen, setAutomaticModePromptIsOpen] = useState(
    false
  );

  const [
    maintenanceModePromptIsOpen,
    setMaintenanceModePromptIsOpen,
  ] = useState(false);

  return {
    openOzoneZoneSupplyPumpAutomaticModePrompt: useCallback(
      () => setAutomaticModePromptIsOpen(true),
      [setAutomaticModePromptIsOpen]
    ),
    openOzoneZoneSupplyPumpMaintenanceModePrompt: useCallback(
      () => setMaintenanceModePromptIsOpen(true),
      [setMaintenanceModePromptIsOpen]
    ),
    OzoneZoneSupplyPumpModePrompts,
    ozoneZoneSupplyPumpModePromptsProps: {
      zoneSupplyName,
      zoneSupplyId,
      automaticModePromptIsOpen,
      maintenanceModePromptIsOpen,
      onCompleted,
      closeAutomaticModePrompt: useCallback(
        () => setAutomaticModePromptIsOpen(false),
        [setAutomaticModePromptIsOpen]
      ),
      closeMaintenanceModePrompt: useCallback(
        () => setMaintenanceModePromptIsOpen(false),
        [setMaintenanceModePromptIsOpen]
      ),
    },
  };
};

export default useOzoneZoneSupplyPumpModePrompts;
