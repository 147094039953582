import React from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faCog } from '@fortawesome/free-solid-svg-icons';

import useZoneNutrientMixerHistoryModal from '../../../../../../hooks/useZoneNutrientMixerHistoryModal';
import useZoneNutrientMixerConfigureThresholdsForm from '../../../../../../hooks/useZoneNutrientMixerConfigureThresholdsForm';

import { NutrientMixerMeasurements } from '../../../../../../modules/nutrient-mixer';

import Gauge from '../../../../../../components/Gauge';
import Pipe from '../../../../../../components/Pipe';

import style from './style.module.scss';
import LinearGauge from '../../../../../../components/LinearGauge';

const NutrientMixerGauges = ({
  className,
  zoneId,
  zoneName,
  nutrientMixer,
  title,
  ...props
}) => {
  const {
    openZoneNutrientMixerHistoryModal,
    ZoneNutrientMixerHistoryModal,
    zoneNutrientMixerHistoryModalProps,
  } = useZoneNutrientMixerHistoryModal({ zoneId, zoneName });

  const {
    openZoneNutrientMixerConfigureThresholdsForm,
    ZoneNutrientMixerConfigureThresholdsForm,
    zoneNutrientMixerConfigureThresholdsFormProps,
  } = useZoneNutrientMixerConfigureThresholdsForm({ zoneId, zoneName });

  const {
    samplerWaterFlowDetected,
    currentValue,
    thresholds,
    updateNutrientMixerThresholdsCommand,
  } = nutrientMixer || {};

  const { timestamp } = currentValue || {};
  const time = timestamp != null ? moment(timestamp) : null;

  return (
    <div
      className={[style.container, className].join(' ')}
      title={title}
      {...props}
    >
      <ZoneNutrientMixerHistoryModal {...zoneNutrientMixerHistoryModalProps} />

      <ZoneNutrientMixerConfigureThresholdsForm
        {...zoneNutrientMixerConfigureThresholdsFormProps}
      />

      <div className={style.gauges}>
        {NutrientMixerMeasurements.all.map(measurement => {
          return (
            <Gauge
              className={style.gauge}
              label={measurement.label}
              color={measurement.color}
              value={Number(measurement.valueSelector(currentValue))}
              unit={measurement.unit}
              lowCritical={measurement.lowAlertValueSelector(thresholds)}
              low={measurement.lowValueSelector(thresholds)}
              high={measurement.highValueSelector(thresholds)}
              highCritical={measurement.highAlertValueSelector(thresholds)}
            />
          );
        })}
      </div>

      <div className={style.samplerPipeContainer}>
        <div className={style.updatedAtTimestamp}>
          {time && time.from(moment())}
        </div>
        <Pipe
          className={style.samplerMainPipeHorizontal}
          waterIsFlowing={samplerWaterFlowDetected}
        />
      </div>

      <div className={style.nutrientMixerCommandsContainer}>
        <Button className="rounded" onClick={openZoneNutrientMixerHistoryModal}>
          <FontAwesomeIcon className="btn-icon" icon={faHistory} fixedWidth />{' '}
          History
        </Button>

        {updateNutrientMixerThresholdsCommand?.canExecute && (
          <Button
            className="rounded"
            onClick={openZoneNutrientMixerConfigureThresholdsForm}
          >
            <FontAwesomeIcon className="btn-icon" icon={faCog} fixedWidth />{' '}
            Thresholds
          </Button>
        )}
      </div>
    </div>
  );
};

NutrientMixerGauges.fragment = gql`
  fragment NutrientMixerGauges on NutrientMixerType {
    samplerWaterFlowDetected
    currentValue {
      timestamp
      ...NutrientMixerCurrentValue
    }
    thresholds {
      ...NutrientMixerThresholds
    }
    updateNutrientMixerThresholdsCommand {
      canExecute
    }
  }
  ${NutrientMixerMeasurements.fragments.NutrientMixerCurrentValue}
  ${NutrientMixerMeasurements.fragments.NutrientMixerThresholds}
`;

export default NutrientMixerGauges;
