import React from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import {
  AttentionLevels,
  getPumpRoomAttentionLevel,
  getZoneAttentionLevel,
} from '../../../../modules/attention-levels';
import style from './style.module.scss';

import ZoneButton from '../../../../components/ZoneButton';
import { Descriptions } from '../../../../modules/Descriptions';
import TempMap from '../../../../modules/temp-map';
import { firstOrNull } from '../../../../modules/temp-map';

const SelectionType = {
  ZONE: 'zone',
  PUMP_ROOM: 'pumpRoom',
};

const pumpRoomButtonStyle = attentionLevel => {
  switch (attentionLevel) {
    case AttentionLevels.Critical:
      return style.attentionLevelCritical;
    case AttentionLevels.Low:
      return style.attentionLevelLow;
    default:
      return undefined;
  }
};

const FacilityNavigation = ({
  facility,
  zones,
  currentSelection,
  temperatureMap,
}) => {
  const { facilityId, pumpRoom } = facility;
  const pumpRoomAttentionLevel = getPumpRoomAttentionLevel(pumpRoom);

  return (
    <div className={style.container}>
      <div className={style.pumpRoomNavigationContainer}>
        <Link
          to={`/facility/${facilityId}`}
          className={[
            style.button,
            style.pumpRoom,
            pumpRoomButtonStyle(pumpRoomAttentionLevel),
            currentSelection &&
            currentSelection.type === FacilityNavigation.SelectionType.PUMP_ROOM
              ? style.active
              : null,
          ].join(' ')}
        >
          <div
            className={`${style.buttonLabel} nav-help-tooltip`}
            data-toggle="tooltip"
            data-placement="top"
            title={Descriptions.pumpRoomButton}
          >
            Pump Room
          </div>
        </Link>
      </div>

      <div className={style.zoneNavigationContainer}>
        {zones
          .filter(i => !i.isHidden)
          .map((zone, index) => {
            const { zoneId, name } = zone;
            const attentionLevel = getZoneAttentionLevel(zone);

            const mapping =
              temperatureMap != null &&
              firstOrNull(temperatureMap.filter(i => i.id == zoneId));
            return (
              <ZoneButton
                className={`${style.button} ${
                  index == 3 ? 'nav-help-tooltip' : ''
                }`}
                style={{
                  filter:
                    mapping != null
                      ? `drop-shadow(0 0 0.25em ${TempMap(mapping)})`
                      : '',
                }}
                tag={Link}
                to={`/facility/${facilityId}/zone/${name}`}
                key={zoneId}
                zoneName={name}
                attentionLevel={attentionLevel}
                data-toggle="tooltip"
                data-placement="top"
                title={Descriptions.zoneButton}
                active={
                  currentSelection &&
                  currentSelection.type ===
                    FacilityNavigation.SelectionType.ZONE &&
                  zoneId === currentSelection.zone.zoneId
                }
              />
            );
          })}
      </div>

      <div className={style.pumpRoomNavigationContainer}></div>
    </div>
  );
};

FacilityNavigation.fragment = gql`
  fragment FacilityNavigation on FacilityType {
    facilityId
    zones {
      name
      isHidden
      zoneId
      ...GetZoneAttentionLevel
    }
    pumpRoom {
      ...GetPumpRoomAttentionLevel
    }
  }
  ${getZoneAttentionLevel.fragment}
  ${getPumpRoomAttentionLevel.fragment}
`;

FacilityNavigation.SelectionType = SelectionType;

export default FacilityNavigation;
