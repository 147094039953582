import React from 'react';
import style from './style.module.scss';

import MenuButton from '../MenuButton';

export default ({
  className,
  ...props
}: React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <MenuButton
      className={[style.button, className].join(' ')}
      isOpen={true}
      {...props}
    />
  );
};
