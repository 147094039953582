import React from 'react';

import Switch from '../Switch';

import useStackLevelValveModePrompts from '../../hooks/useStackLevelValveModePrompts';

interface Props {
  id: string;
  zoneName: string;
  stackName: string;
  levelName: string;
  levelId: string;
  levelMode: string;
  updateLevelModeCommand: any;
  className?: string;
}

const LevelSwitch = ({
  id,
  zoneName,
  stackName,
  levelName,
  levelId,
  levelMode,
  updateLevelModeCommand,
  className,
  ...props
}: Props) => {
  const {
    openStackLevelAutomaticModePrompt,
    openStackLevelMaintenanceModePrompt,
    StackLevelValveModePrompts,
    stackLevelValveModePromptsProps,
  } = useStackLevelValveModePrompts({
    zoneName,
    stackName,
    levelName,
    levelId,
  });

  return (
    <>
      <StackLevelValveModePrompts {...stackLevelValveModePromptsProps} />

      <Switch
        id={id}
        className={className}
        checked={levelMode === 'AUTOMATIC'}
        disabled={!updateLevelModeCommand.canExecute}
        onChange={
          levelMode === 'AUTOMATIC'
            ? openStackLevelMaintenanceModePrompt
            : openStackLevelAutomaticModePrompt
        }
        {...props}
      />
    </>
  );
};

export default LevelSwitch;
