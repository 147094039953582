// Port of https://github.com/bealearts/css-animation-sync/blob/442deaacfa4aa2748ab6b6b676c406214b5b3730/src/sync.mjs
// in order to make some customizations

export default function sync(
  shouldSyncAnimation: string | string[] | ((animationName: string) => boolean),
  animationDelayProperty = 'animation-delay'
) {
  const elements = new Set<HTMLElement>();
  let paused = false;

  let eventTime: number;
  let lastIterationTS = now();
  const shouldSync = Array.isArray(shouldSyncAnimation)
    ? (event: AnimationEvent) =>
        shouldSyncAnimation.indexOf(event.animationName) > -1
    : typeof shouldSyncAnimation === 'string'
    ? (event: AnimationEvent) => event.animationName === shouldSyncAnimation
    : (event: AnimationEvent) => shouldSyncAnimation(event.animationName);

  const animationStart = (event: AnimationEvent) => {
    if (shouldSync(event)) {
      const el = event.target as HTMLElement;
      const passed = now() - lastIterationTS;
      el.style.setProperty(animationDelayProperty, `${-passed}ms`);
      if (paused) {
        el.style.setProperty('animation-play-state', 'paused');
      }
      elements.add(el);
    }
  };

  const animationIteration = (event: AnimationEvent) => {
    if (shouldSync(event)) {
      elements.add(event.target as HTMLElement);
      requestAnimationFrame(frameTime => {
        if (frameTime !== eventTime) {
          lastIterationTS = now();
          restart(elements);
        }
        eventTime = frameTime;
      });
    }
  };

  window.addEventListener('animationstart', animationStart, true);
  window.addEventListener('animationiteration', animationIteration, true);

  return {
    getElements() {
      return elements;
    },

    free() {
      window.removeEventListener('animationstart', animationStart);
      window.removeEventListener('animationiteration', animationIteration);

      this.start();
      elements.clear();
    },

    start() {
      elements.forEach(el => {
        if (validate(elements, el)) {
          el.style.removeProperty('animation');
        }
      });
      paused = false;
    },

    stop() {
      elements.forEach(el => {
        if (validate(elements, el)) {
          el.style.setProperty('animation', 'none');
        }
      });
    },

    pause() {
      elements.forEach(el => {
        if (validate(elements, el)) {
          el.style.setProperty('animation-play-state', 'paused');
        }
      });
      paused = true;
    },
  };
}

const restart = (elements: Set<HTMLElement>) => {
  elements.forEach(el => {
    if (window.getComputedStyle(el).animationPlayState !== 'paused') {
      if (validate(elements, el)) {
        el.style.setProperty('animation', 'none');
      }
    }
  });

  requestAnimationFrame(() => {
    elements.forEach(el => {
      if (window.getComputedStyle(el).animationPlayState !== 'paused') {
        if (validate(elements, el)) {
          el.style.removeProperty('animation');
        }
      }
    });
  });
};

const now = () => new Date().getTime();

const validate = (elements: Set<HTMLElement>, el: HTMLElement) => {
  const isValid = document.body.contains(el);
  if (!isValid) {
    elements.delete(el);
  }
  return isValid;
};
