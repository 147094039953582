import React, { FC } from 'react';
import { Moment } from 'moment';

import { User } from './User';
import { PerformedAction } from './PerformedAction';
import { Timestamp } from './Timestamp';

import style from './style.module.scss';

interface Props {
  activityItem: any;
  currentServerDate: () => Moment;
}

const ActivityItem: FC<Props> = ({
  activityItem,
  currentServerDate,
}: Props): JSX.Element => {
  return (
    <div className={style.activityItem}>
      <div className={style.message}>
        <User activityItem={activityItem} />{' '}
        <PerformedAction activityItem={activityItem} />.
      </div>
      <Timestamp
        timestamp={activityItem.timestamp}
        currentServerDate={currentServerDate}
      />
    </div>
  );
};

export { ActivityItem };
