import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  zoneId: string;
  onCompleted?: () => void;
}

interface Result {
  openZoneAutomaticModePrompt: () => void;
  openZoneMaintenanceModePrompt: () => void;
  ZoneModePrompts: typeof ZoneModePrompts;
  zoneModePromptsProps: Props;
}

export const UPDATE_ZONE_MODE = gql`
  mutation updateZoneMode($input: UpdateZoneModeInputType!) {
    updateZoneMode(input: $input) {
      zone {
        zoneId
        mode
      }
    }
  }
`;

interface Props extends Input {
  maintenanceModePromptIsOpen: boolean;
  automaticModePromptIsOpen: boolean;
  closeMaintenanceModePrompt: () => void;
  closeAutomaticModePrompt: () => void;
}

const ZoneModePrompts = ({
  zoneName,
  zoneId,
  automaticModePromptIsOpen,
  maintenanceModePromptIsOpen,
  closeAutomaticModePrompt,
  closeMaintenanceModePrompt,
  onCompleted,
}: Props) => {
  const [updateZoneMode, { error, loading: saving }] = useMutation(
    UPDATE_ZONE_MODE,
    {
      onCompleted: () => {
        closeAutomaticModePrompt();
        closeMaintenanceModePrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <>
      <PromptModal
        title={`Put Zone ${zoneName} Into Automatic Mode`}
        isOpen={automaticModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateZoneMode({
            variables: {
              input: {
                zoneId,
                mode: 'AUTOMATIC',
              },
            },
          });
        }}
        onComplete={closeAutomaticModePrompt}
      >
        <p>
          {`Putting Zone ${zoneName} into automatic mode will allow
            the zone to irrigate its associated stacks as needed.`}
        </p>

        <p>{`Put the Zone ${zoneName} into automatic mode?`}</p>
      </PromptModal>

      <PromptModal
        title={`Put Zone ${zoneName} Into Maintenance Mode`}
        danger
        isOpen={maintenanceModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateZoneMode({
            variables: {
              input: {
                zoneId: zoneId,
                mode: 'MAINTENANCE',
              },
            },
          });
        }}
        onComplete={closeMaintenanceModePrompt}
      >
        <p>
          {`Putting the Zone ${zoneName} into maintenance will cause it to stop irrigating its associated stacks.`}
        </p>

        <p>{`Put Zone ${zoneName} into maintenance mode?`}</p>
      </PromptModal>
    </>
  );
};

const useZoneModePrompts: (input: Input) => Result = ({
  zoneName,
  zoneId,
  onCompleted,
}: Input) => {
  const [automaticModePromptIsOpen, setAutomaticModePromptIsOpen] = useState(
    false
  );

  const [
    maintenanceModePromptIsOpen,
    setMaintenanceModePromptIsOpen,
  ] = useState(false);

  return {
    openZoneAutomaticModePrompt: useCallback(
      () => setAutomaticModePromptIsOpen(true),
      [setAutomaticModePromptIsOpen]
    ),
    openZoneMaintenanceModePrompt: useCallback(
      () => setMaintenanceModePromptIsOpen(true),
      [setMaintenanceModePromptIsOpen]
    ),
    ZoneModePrompts,
    zoneModePromptsProps: {
      zoneName,
      zoneId,
      automaticModePromptIsOpen,
      maintenanceModePromptIsOpen,
      onCompleted,
      closeAutomaticModePrompt: useCallback(
        () => setAutomaticModePromptIsOpen(false),
        [setAutomaticModePromptIsOpen]
      ),
      closeMaintenanceModePrompt: useCallback(
        () => setMaintenanceModePromptIsOpen(false),
        [setMaintenanceModePromptIsOpen]
      ),
    },
  };
};

export default useZoneModePrompts;
