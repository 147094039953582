const DEFAULT_FACILITY_ID_LOCAL_STORAGE_KEY = 'hycubeos.defaultFacilityId';

const get = (): string | null =>
  localStorage.getItem(DEFAULT_FACILITY_ID_LOCAL_STORAGE_KEY);

const set = (facilityId: string) =>
  localStorage.setItem(DEFAULT_FACILITY_ID_LOCAL_STORAGE_KEY, facilityId);

export default {
  get,
  set,
};
