/*
  A subcomponent which offers an atomized and modifiable representation of a subzone.
 */

import { MultiSelect } from '../../../../components/MultiSelect';
import React, { useCallback, useEffect, useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import modalStyle from '../../../../components/CrudModal/style.module.scss';
import style from './style.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Item } from '../../../usePhotoperiodsCrudForm';
import LightScheduleComponent from '../../../../components/LightSchedule';
import { useMediaQuery } from 'react-responsive';

export default (props: {
  item: Item;
  onChange: (e: Item) => void;
  canDelete: boolean;
}) => {
  //Create local state using parent state or default out.
  const [state, setState] = useState(props.item);

  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  //Update parent when local state changes.
  useEffect(() => {
    props.onChange(state);
  }, [state]);

  //State callback management.
  const callback = (next: any) => {
    setState(prev => {
      return { ...prev, ...next };
    });
  };

  //Subzone name callback.
  const textboxCallback = (e: any) => {
    let value = e?.target?.value; //Bug Fix: State is volatile and must be cached immediately.
    if (value == null) return; //Bug Fix: React debounce.

    //Update name only.
    callback({ name: value });
  };
  //Delete callback.
  const deletedCallback = useCallback(
    e => {
      callback({ isDeleted: true });
    },
    [state]
  );

  const toggleTimeBlock = useCallback(
    e => {
      state.timeBlocks[e] = !state.timeBlocks[e];
      callback({ timeBlocks: state.timeBlocks });
    },
    [state]
  );

  const isMobile = useMediaQuery({ query: `(max-width: 899px)` });

  //Hide self if local state is set to deleted.
  if (!!state.isDeleted === true) {
    return <></>;
  }

  return (
    <>
      <div className="row py-2">
        <div className={`${isMobile ? 'col-12' : 'col-2'} px-2`}>
          <div className="input-group">
            <div className="input-group-prepend">
              {props.canDelete ? (
                <button
                  onClick={deletedCallback}
                  className="btn btn-danger"
                  type="button"
                  style={{ width: '4em' }}
                >
                  {' '}
                  <FontAwesomeIcon
                    style={{
                      position: 'absolute',
                      margin: '-0.4em 0 0 -0.65em',
                    }}
                    className={modalStyle.icon}
                    icon={faTrash}
                    fixedWidth={true}
                  />{' '}
                </button>
              ) : (
                <button
                  className="btn btn-danger disabled"
                  type="button"
                  style={{ width: '4em' }}
                >
                  {' '}
                  <FontAwesomeIcon
                    style={{
                      position: 'absolute',
                      margin: '-0.4em 0 0 -0.65em',
                    }}
                    className={modalStyle.icon}
                    icon={faTrash}
                    fixedWidth={true}
                  />{' '}
                </button>
              )}
            </div>

            <input
              type="text"
              title="Name"
              pattern="(.|\s)*\S(.|\s)*"
              className="form-control form-control-lg d-inline-block"
              value={state.name}
              onChange={textboxCallback}
            />
          </div>
        </div>
        <div className={`${isMobile ? 'col-12' : 'col-9'} mx-auto text-center`}>
          <LightScheduleComponent
            className="mt-1 "
            allowTimeBlockCollapse={false}
            scheduleIsEnabled={true}
            lightIsOn={true}
            onLightScheduleStateChange={e => {}}
            timeBlocks={state.timeBlocks}
            designerMode={true}
            onToggleTimeBlock={toggleTimeBlock}
            disabled={false}
          />
        </div>
      </div>
    </>
  );
};
