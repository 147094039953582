import React from 'react';

import style from './style.module.scss';
import UnknownValueOverlay from '../UnknownValueOverlay';

interface IProps {
  valveState: 'OPEN' | 'CLOSED';
  className?: string;
  needsAttention?: boolean;
  inverted?: boolean;
  unknownIconClassName?: string;
  title: string | undefined;
  hasHelp: boolean;
}

export default ({
  valveState,
  className,
  unknownIconClassName,
  inverted = false,
  needsAttention = false,
  title = undefined,
  hasHelp = false,
}: IProps) => {
  if (title == undefined) title = '';

  const hasUnknownValue = valveState !== 'OPEN' && valveState !== 'CLOSED';
  const open = !inverted ? valveState === 'OPEN' : valveState !== 'OPEN';
  return (
    <UnknownValueOverlay
      dataPlacement="top"
      hasHelp={hasHelp}
      title={title}
      hasUnknownValue={hasUnknownValue}
      className={[
        style.container,
        open ? style.valveOpen : style.valveClosed,
        hasUnknownValue ? style.hasUnknownValue : null,
        needsAttention ? style.needsAttention : null,
        className,
      ].join(' ')}
      iconClassName={[style.unknownIcon, unknownIconClassName].join(' ')}
    >
      <svg
        className={style.valveIcon}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 6.6666664 24"
        version="1.1"
      >
        <path
          transform="translate(-8)"
          d="M 12.333333,13.723 V 17 c 0,0.553 -0.447,1 -1,1 -0.553,0 -1,-0.447 -1,-1 V 13.723 C 9.7383332,13.377 9.3333332,12.739 9.3333332,12 c 0,-0.738 0.405,-1.376 0.9999998,-1.722 V 7 c 0,-0.552 0.447,-1 1,-1 0.553,0 1,0.448 1,1 v 3.278 c 0.595,0.346 1,0.984 1,1.722 0,0.739 -0.405,1.377 -1,1.723 m 2,-13.723 H 8.3333332 c -0.553,0 -1,0.448 -1,1 v 22 c 0,0.553 0.447,1 1,1 h 5.9999998 c 0.553,0 1,-0.447 1,-1 V 1 c 0,-0.552 -0.447,-1 -1,-1"
        />
      </svg>
    </UnknownValueOverlay>
  );
};
