import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-apollo';
import PromptModal from '../components/PromptModal';
import { UPDATE_LEVEL_SENSOR_CONFIGURATION } from './useLevelAddSensorForm';

interface Input {
  zoneName: string;
  stackName: string;
  levelName: string;
  levelId: number;
  onCompleted?: () => void;
}

interface Result {
  openLevelRemoveSensorPrompt: () => void;
  LevelRemoveSensorPrompt: typeof LevelRemoveSensorPrompt;
  levelRemoveSensorPromptProps: Props;
}

interface Props extends Input {
  levelRemoveSensorPromptIsOpen: boolean;
  closeLevelRemoveSensorPrompt: () => void;
}

const LevelRemoveSensorPrompt = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  levelRemoveSensorPromptIsOpen,
  closeLevelRemoveSensorPrompt,
  onCompleted,
}: Props) => {
  const [
    updateLevelSensorConfiguration,
    { error, loading: saving },
  ] = useMutation(UPDATE_LEVEL_SENSOR_CONFIGURATION, {
    onCompleted: () => {
      closeLevelRemoveSensorPrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <PromptModal
      title={`Remove sensor at Zone ${zoneName} / Stack ${stackName} / Level ${levelName}`}
      isOpen={levelRemoveSensorPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        updateLevelSensorConfiguration({
          variables: {
            input: {
              levelId,
            },
          },
        });
      }}
      onComplete={closeLevelRemoveSensorPrompt}
    >
      <p>
        Removing a sensor will cause HyCubeOS to stop recording its data and
        remove its display.
      </p>

      <p>{`Remove the sensor from Level ${levelName} on Zone ${zoneName} / Stack ${stackName}?`}</p>
    </PromptModal>
  );
};

const useLevelRemoveSensorPrompt: (input: Input) => Result = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  onCompleted,
}) => {
  const [
    levelRemoveSensorPromptIsOpen,
    setLevelRemoveSensorPromptIsOpen,
  ] = useState(false);

  return {
    openLevelRemoveSensorPrompt: useCallback(
      () => setLevelRemoveSensorPromptIsOpen(true),
      [setLevelRemoveSensorPromptIsOpen]
    ),
    LevelRemoveSensorPrompt,
    levelRemoveSensorPromptProps: {
      zoneName,
      stackName,
      levelName,
      levelId,
      levelRemoveSensorPromptIsOpen,
      onCompleted,
      closeLevelRemoveSensorPrompt: useCallback(
        () => setLevelRemoveSensorPromptIsOpen(false),
        [setLevelRemoveSensorPromptIsOpen]
      ),
    },
  };
};

export default useLevelRemoveSensorPrompt;
