import React, { useCallback, useEffect, useState } from 'react';
import Icon32 from '../../../../assets/images/Light-BulbLeaf-icon-32.png';
import Icon from '../../../../assets/images/Light-BulbLeaf-icon.png';
import style from './style.module.scss';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'react-apollo';
import useModifySubzonesForm from '../../../../hooks/useModifySubzonesForm';
import usePhotoperiodsCrudForm from '../../../../hooks/usePhotoperiodsCrudForm';
import { ApolloClient } from 'apollo-client';

export interface Props {
  stackId: number;
  stack: any;
  onChange: (e: Recipe | null) => void;
  disableOptions: boolean;
  disable: boolean;
}

export interface Recipe {
  id: number;
  timeBlocks: boolean[];
  name: string;
}

export interface Permissions {
  set: boolean;
  modify: boolean;
  delete: boolean;
}

const GET_STATE = gql`
  query lightScheduleRecipe($stackId: ID!) {
    lightScheduleRecipe(stackId: $stackId) {
      id
      toTimeBlockArray
      displayName
    }
    photorecipePermissionSet
    photorecipePermissionModify
    photorecipePermissionDelete
  }
`;

const ALL_RECIPES = gql`
  {
    stackLightScheduleRecipes {
      id
      toTimeBlockArray
      displayName
    }
  }
`;

const SET_STACK_RECIPE = gql`
  mutation x($recipeId: SetStackLightScheduleRecipeInputType!) {
    setStackLightScheduleRecipe(input: $recipeId) {
      stackId
    }
  }
`;

const toRecipe = (data: any): Recipe | null => {
  if (data === null || data === undefined) return null;
  return {
    name: data.displayName,
    timeBlocks: data.toTimeBlockArray,
    id: data.id,
  };
};

export default (props: Props) => {
  const getRecipesResult = useQuery(ALL_RECIPES, {});
  const getStateResult = useQuery(GET_STATE, {
    variables: {
      stackId: props.stackId,
    },
  });

  const usingServerState = () => {
    if (getRecipesResult.loading || !!getRecipesResult.error) return;
    setRecipes(
      getRecipesResult.data.stackLightScheduleRecipes
        .map((i: any) => toRecipe(i))
        .filter((i: Recipe) => !deleted.includes(i.id))
    );
  };

  const [setStackRecipe, {}] = useMutation(SET_STACK_RECIPE);

  const [selection, setSelection] = useState<Recipe | null>(null);
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [perms, setPerms] = useState<Permissions>({
    delete: false,
    modify: false,
    set: false,
  });
  const [deleted, setDeleted] = useState<number[]>([]);

  useEffect(() => {
    if (getStateResult.loading || !!getStateResult.error) return;
    setSelection(toRecipe(getStateResult.data.lightScheduleRecipe));
    setPerms({
      delete: !!getStateResult.data.photorecipePermissionDelete,
      modify: !!getStateResult.data.photorecipePermissionModify,
      set: !!getStateResult.data.photorecipePermissionSet,
    });
  }, [getStateResult.data]);

  useEffect(() => {
    props.onChange(selection);
  }, [selection]);

  useEffect(usingServerState, [getRecipesResult.data]);

  useEffect(() => {
    if (props.disable) activateRecipe(null);
  }, [props.disable]);

  const {
    photoperiodsCrudFormProps,
    PhotoperiodsCrudForm,
    openPhotoperiodsCrudForm,
  } = usePhotoperiodsCrudForm({
    stackId: props.stackId,
    permissions: perms,
    onCompleted: isSaved => {
      if (isSaved) usingServerState();
    },
    recipes,
    onRecipeAdded: added => setRecipes(added),
    currentActive: selection,
    onRecipeDeleted: useCallback(
      (deleted: Recipe) => {
        setRecipes(recipes.filter(i => i.id != deleted.id));
        setDeleted(prev => [...prev, deleted.id]);
      },
      [recipes, deleted]
    ),
  });

  const activateRecipe = useCallback(
    (item: any) => {
      setSelection(item);
      setStackRecipe({
        variables: {
          recipeId: {
            stackId: Number(props.stackId),
            recipeId: item?.id ?? 0,
          },
        },
      });
    },
    [selection]
  );

  if (recipes.length == 0) return <></>;
  return (
    <>
      <PhotoperiodsCrudForm {...photoperiodsCrudFormProps} />
      {perms.set ? (
        <>
          <div
            className={
              selection === null ? style.thumbKey : style.activeThumbKey
            }
            onClick={() => {
              if (props.disable) return;
              selection == null
                ? activateRecipe(recipes[0])
                : activateRecipe(null);
            }}
          >
            <img src={Icon32} />
          </div>

          <div
            className={
              selection === null
                ? style.lightContainer
                : style.activeLightContainer
            }
          >
            <div className="btn-group">
              {perms.modify && (
                <button
                  type="button"
                  className={`${style.btnSemilight} btn btn-sm`}
                  style={{
                    backgroundColor: '#5C5C5C !important',
                    visibility: props.disableOptions ? 'hidden' : 'visible',
                  }}
                  onClick={openPhotoperiodsCrudForm}
                  disabled={props.disableOptions}
                >
                  <FontAwesomeIcon icon={faCog} />
                </button>
              )}

              <button
                className={`${style.btnSemilight} btn btn-sm dropdown-toggle`}
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Recipe: <b>{selection?.name ?? ''}</b>
              </button>
              <div className="dropdown-menu" style={{ zIndex: 9999 }}>
                {recipes != null &&
                  recipes
                    .filter(i => !deleted.includes(i.id))
                    .map((item, key) => {
                      return (
                        <div
                          key={key}
                          className="dropdown-item"
                          onClick={() => activateRecipe(item)}
                        >
                          {item.name}
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={
              selection == null
                ? style.readonlyThumbKeyInactive
                : style.readonlyThumbKeyActive
            }
          >
            <img src={Icon32} />
          </div>

          {selection != null && (
            <div className={style.readonlyLightContainerActive}>
              <div className="btn-group">
                <div className="btn btn-sm text-dark disabled">
                  Recipe: <b>{selection?.name ?? ''}</b>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
