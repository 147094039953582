import React, { useEffect, useState } from 'react';

import { Option, SelectProps } from '../lib/interfaces';

const defaultStrings = {
  allItemsAreSelected: 'All items are selected.',
  clearSearch: 'Clear Search',
  clearSelected: 'Clear Selected',
  noOptions: 'No options',
  search: 'Search',
  selectAll: 'Select All',
  selectAllFiltered: 'Select All (Filtered)',
  selectSomeItems: 'Select...',
  create: 'Create',
};

const defaultProps: Partial<SelectProps> = {
  value: [],
  hasSelectAll: true,
  className: 'multi-select',
  debounceDuration: 200,
  options: [] as Option[],
};

interface MultiSelectContextProps extends SelectProps {
  t: (key: string) => string;
  // @ts-ignore
  setOptions?;
}

interface MultiSelectProviderProps {
  props: SelectProps;
  // @ts-ignore
  children;
}

const MultiSelectContext = React.createContext<MultiSelectContextProps>(
  {} as MultiSelectContextProps
);

export const MultiSelectProvider = ({
  props,
  children,
}: MultiSelectProviderProps) => {
  const [options, setOptions] = useState(props.options);
  // @ts-ignore
  const t = key => props.overrideStrings?.[key] || defaultStrings[key];

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  return (
    <MultiSelectContext.Provider
      value={{ t, ...defaultProps, ...props, options, setOptions }}
    >
      {children}
    </MultiSelectContext.Provider>
  );
};

export const useMultiSelect = () => React.useContext(MultiSelectContext);
