import gql from 'graphql-tag';

export const ADD_AUTO_CALIBRATE_ZONE_TANK_PRESSURE_SENSOR = gql`
  mutation autoCalibrateZoneTankPressureSensor(
    $input: AutoCalibrateZoneTankPressureSensorInputType!
  ) {
    autoCalibrateZoneTankPressureSensor(input: $input) {
      zone {
        zoneId
        tank {
          autoCalibrated
          calibrationPoints
        }
      }
    }
  }
`;
