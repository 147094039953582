import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneSupplyTankName: string;
  zoneSupplyTankId: string;
  onCompleted?: (isOpen: boolean) => void;
}

interface Result {
  openReverseOsmosisZoneSupplyValveOpenStatePrompt: () => void;
  openReverseOsmosisZoneSupplyValveCloseStatePrompt: () => void;
  ReverseOsmosisZoneSupplyValveStatePrompts: typeof ReverseOsmosisZoneSupplyValveStatePrompts;
  reverseOsmosisZoneSupplyValveStatePromptsProps: Props;
}

const UPDATE_REVERSE_OSMOSIS_ZONE_SUPPLY_VALVE_STATE = gql`
  mutation updateReverseOsmosisZoneSupplyValveState(
    $input: UpdateReverseOsmosisZoneSupplyValveStateInputType!
  ) {
    updateReverseOsmosisZoneSupplyValveState(input: $input) {
      zoneDistributionTank {
        zoneDistributionTankId
      }
    }
  }
`;

interface Props extends Input {
  closeValveStatePromptIsOpen: boolean;
  openValveStatePromptIsOpen: boolean;
  closeCloseValveStatePrompt: () => void;
  closeOpenValveStatePrompt: () => void;
}

const ReverseOsmosisZoneSupplyValveStatePrompts = ({
  zoneSupplyTankName,
  zoneSupplyTankId,
  openValveStatePromptIsOpen,
  closeValveStatePromptIsOpen,
  closeOpenValveStatePrompt,
  closeCloseValveStatePrompt,
  onCompleted,
}: Props) => {
  const [
    updateReverseOsmosisZoneSupplyValveState,
    { error, loading: saving },
  ] = useMutation(UPDATE_REVERSE_OSMOSIS_ZONE_SUPPLY_VALVE_STATE, {
    onCompleted: () => {
      onCompleted && onCompleted(openValveStatePromptIsOpen);
      closeOpenValveStatePrompt();
      closeCloseValveStatePrompt();
    },
  });

  return (
    <>
      <PromptModal
        title={`Open Reverse Osmosis Valve for Zone Supply Tank ${zoneSupplyTankName}`}
        isOpen={openValveStatePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateReverseOsmosisZoneSupplyValveState({
            variables: {
              input: { zoneSupplyId: zoneSupplyTankId, valveState: 'OPEN' },
            },
          });
        }}
        onComplete={closeOpenValveStatePrompt}
      >
        <p>
          {`Opening the reverse osmosis valve for Zone Supply Tank ${zoneSupplyTankName} will cause water to flow through the reverse osmosis system if it is not already flowing.`}
        </p>

        <p>{`Open the reverse osmosis valve for Zone Supply Tank ${zoneSupplyTankName}?`}</p>
      </PromptModal>

      <PromptModal
        title={`Close Reverse Osmosis Valve for Zone Supply Tank ${zoneSupplyTankName}`}
        isOpen={closeValveStatePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateReverseOsmosisZoneSupplyValveState({
            variables: {
              input: {
                zoneSupplyId: zoneSupplyTankId,
                valveState: 'CLOSED',
              },
            },
          });
        }}
        onComplete={closeCloseValveStatePrompt}
      >
        <p>
          {`The valve for Zone Supply Tank ${zoneSupplyTankName} will close. If no other zone valves are open, water will stop flowing through the reverse osmosis system.`}
        </p>

        <p>{`Close the reverse osmosis valve for Zone Supply Tank ${zoneSupplyTankName}?`}</p>
      </PromptModal>
    </>
  );
};

const useReverseOsmosisZoneSupplyValveStatePrompts: (
  input: Input
) => Result = ({
  zoneSupplyTankName,
  zoneSupplyTankId,
  onCompleted,
}: Input) => {
  const [openValveStatePromptIsOpen, setOpenValveStatePromptIsOpen] = useState(
    false
  );

  const [
    closeValveStatePromptIsOpen,
    setCloseValveStatePromptIsOpen,
  ] = useState(false);

  return {
    openReverseOsmosisZoneSupplyValveOpenStatePrompt: useCallback(
      () => setOpenValveStatePromptIsOpen(true),
      [setOpenValveStatePromptIsOpen]
    ),
    openReverseOsmosisZoneSupplyValveCloseStatePrompt: useCallback(
      () => setCloseValveStatePromptIsOpen(true),
      [setCloseValveStatePromptIsOpen]
    ),
    ReverseOsmosisZoneSupplyValveStatePrompts,
    reverseOsmosisZoneSupplyValveStatePromptsProps: {
      zoneSupplyTankName,
      zoneSupplyTankId,
      openValveStatePromptIsOpen,
      closeValveStatePromptIsOpen,
      onCompleted,
      closeOpenValveStatePrompt: useCallback(
        () => setOpenValveStatePromptIsOpen(false),
        [setOpenValveStatePromptIsOpen]
      ),
      closeCloseValveStatePrompt: useCallback(
        () => setCloseValveStatePromptIsOpen(false),
        [setCloseValveStatePromptIsOpen]
      ),
    },
  };
};

export default useReverseOsmosisZoneSupplyValveStatePrompts;
