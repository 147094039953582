import React from 'react';

import style from './style.module.scss';

interface Props extends React.HTMLAttributes<HTMLElement> {
  title: any;
  icon: any;
}

export default ({ className, title, icon, ...props }: Props) => {
  return (
    <div className={[style.container, className].join(' ')} {...props}>
      <div className={style.icon}>{icon}</div>
      <div className={style.title}>{title}</div>
    </div>
  );
};
