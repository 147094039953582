import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import {
  FormGroup,
  Input as FormInput,
  Label,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { getValidationErrors } from '../../modules/errors';
import {
  getInputValueAnd,
  updateState,
  isValidEmailAddress,
} from '../../modules/form-helpers';

import useModalVisible from '../../hooks/useModalVisible';

import FormMultiFacilitySelector from '../../components/FormMultiFacilitySelector';
import ModalTitle from '../../components/ModalTitle';
import { SaveModal } from '../../components/SaveModal';
import ModalLoadingContainer from '../../components/ModalLoadingContainer';

import style from './style.module.scss';

interface Input {
  onCompleted?: () => void;
}

interface Result {
  openUserNewForm: () => void;
  UserNewForm: typeof UserNewForm;
  userNewFormProps: Props;
}

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInputType!) {
    createUser(input: $input) {
      message
    }
  }
`;

const SETTINGS_QUERY = gql`
  query {
    facilities {
      facilityId
      name
    }
    settings {
      userManagement {
        roles {
          roleId
          name
          isAdministrator
        }
      }
    }
  }
`;

interface Props extends Input {
  userNewFormIsOpen: boolean;
  closeUserNewForm: () => void;
}

const createBlankUserData = () => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    roleId: '',
    facilityIds: [] as Array<string>,
  };
};

const UserNewForm = ({
  userNewFormIsOpen,
  closeUserNewForm,
  onCompleted,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();

  const loadingResult = useQuery(SETTINGS_QUERY, {
    skip: !visible,
  });

  const { data, loading, refetch } = loadingResult;

  const [
    createUser,
    { error: saveError, loading: saving, data: successData },
  ] = useMutation(CREATE_USER, {
    onCompleted: () => {
      setUser(createBlankUserData());
      onCompleted && onCompleted();
    },
  });

  const [user, setUser] = useState(createBlankUserData());
  const [saveAttempted, setSaveAttempted] = useState(false);
  const [wasOpen, setWasOpen] = useState(false);

  useEffect(() => {
    const reopened = userNewFormIsOpen && !wasOpen;

    if (reopened) {
      setUser(createBlankUserData());
      refetch();
    }

    setWasOpen(userNewFormIsOpen);
  }, [userNewFormIsOpen, refetch, wasOpen, setWasOpen]);

  useEffect(() => {
    const availableFacilities = data?.facilities;
    if (availableFacilities?.length === 1) {
      setUser(user => {
        return {
          ...user,
          facilityIds: availableFacilities.map(
            (x: { facilityId: string }) => x.facilityId
          ),
        };
      });
    }
  }, [data]);

  const roles = data?.settings?.userManagement?.roles;
  const availableFacilities = data?.facilities;

  const isAdministratorRole = (roleId: string) =>
    !!roles?.find(
      (x: { roleId: string; isAdministrator: boolean }) =>
        x.roleId === roleId && x.isAdministrator
    );

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const validationErrors = {
    ...(isEmpty(user.firstName?.trim()) && {
      firstName: 'First name is required',
    }),
    ...(isEmpty(user.lastName?.trim()) && {
      lastName: 'Last name is required',
    }),
    ...(!isValidEmailAddress(user.email) && {
      email: 'Invalid email address',
    }),
    ...(isEmpty(user.email?.trim()) && { email: 'Email address is required' }),
    ...(isEmpty(user.roleId) && { roleId: 'Role is required' }),
    ...(isEmpty(user.facilityIds) &&
      !isAdministratorRole(user.roleId) && {
        facilityIds: 'At least one facility is required',
      }),
    ...(!isDirty &&
      saveAttempted &&
      saveError &&
      getValidationErrors(saveError)),
  };

  const isFormValid = !loading && isEmpty(validationErrors);

  return (
    <SaveModal
      className={style.modal}
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faUserPlus} />}
          title="New User"
        />
      }
      isOpen={userNewFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      isDirty={isDirty}
      onComplete={closeUserNewForm}
      saveButtonContent="Create User"
      successContent={
        successData && (
          <>
            <p>Success!</p>
            <p>{successData.createUser.message}</p>
          </>
        )
      }
      onSave={() => {
        setIsDirty(false);
        setSaveAttempted(true);
        createUser({
          variables: {
            input: user,
          },
        });
      }}
      {...modalVisibleProps}
    >
      <ModalLoadingContainer
        className={style.modalContentContainer}
        resourceTypeName="Roles"
        resourceExists={!!user}
        result={loadingResult}
      >
        <>
          <Row form>
            <Col sm={6}>
              <FormGroup>
                <Label for="firstName">First Name</Label>

                <FormInput
                  id="firstName"
                  type="text"
                  required
                  value={user.firstName}
                  onChange={getInputValueAnd(
                    updateState({
                      valueState: [
                        user.firstName,
                        (value: string) =>
                          setUser({ ...user, firstName: value }),
                      ],
                      isDirtyState,
                    })
                  )}
                  invalid={!!validationErrors.firstName}
                />

                <FormFeedback invalid={validationErrors.firstName}>
                  {validationErrors.firstName}
                </FormFeedback>
              </FormGroup>
            </Col>

            <Col sm={6}>
              <FormGroup>
                <Label for="lastName">Last Name</Label>

                <FormInput
                  id="lastName"
                  type="text"
                  required
                  value={user.lastName}
                  onChange={getInputValueAnd(
                    updateState({
                      valueState: [
                        user.lastName,
                        (value: string) =>
                          setUser({ ...user, lastName: value }),
                      ],
                      isDirtyState,
                    })
                  )}
                  invalid={!!validationErrors.lastName}
                />

                <FormFeedback invalid={validationErrors.lastName}>
                  {validationErrors.lastName}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col sm={6}>
              <FormGroup>
                <Label for="email">Email Address / Username</Label>

                <FormInput
                  id="email"
                  type="email"
                  required
                  value={user.email}
                  onChange={getInputValueAnd(
                    updateState({
                      valueState: [
                        user.email,
                        (value: string) => setUser({ ...user, email: value }),
                      ],
                      isDirtyState,
                    })
                  )}
                  invalid={!!validationErrors.email}
                />

                <FormFeedback invalid={validationErrors.email}>
                  {validationErrors.email}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label for="role">Role</Label>

                <FormInput
                  id="role"
                  type="select"
                  className="custom-select"
                  required
                  value={user.roleId}
                  onChange={getInputValueAnd(
                    updateState({
                      valueState: [
                        user.roleId,
                        (value: string) =>
                          setUser({
                            ...user,
                            roleId: value,
                          }),
                      ],
                      isDirtyState,
                    })
                  )}
                  invalid={!!validationErrors.roleId}
                >
                  {user.roleId === '' && <option value={''}></option>}

                  {roles?.map(
                    ({ name, roleId }: { name: string; roleId: string }) => (
                      <option key={roleId} value={roleId}>
                        {name}
                      </option>
                    )
                  )}
                </FormInput>

                <FormFeedback invalid={validationErrors.roleId}>
                  {validationErrors.roleId}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col sm={12}>
              <FormGroup>
                <Label for="facilityIds">Facilities</Label>

                <FormMultiFacilitySelector
                  id="facilityIds"
                  selectedFacilityIds={user.facilityIds}
                  availableFacilities={availableFacilities}
                  isAdministratorRole={isAdministratorRole(user.roleId)}
                  invalid={!!validationErrors.facilityIds}
                  onFacilityIdSelectionChange={({ facilityId, selected }) =>
                    updateState({
                      valueState: [
                        user.facilityIds.includes(facilityId),
                        (value: boolean) =>
                          setUser({
                            ...user,
                            facilityIds: value
                              ? [...user.facilityIds, facilityId]
                              : user.facilityIds.filter(x => x !== facilityId),
                          }),
                      ],
                      isDirtyState,
                    })(selected)
                  }
                />

                <FormFeedback invalid={validationErrors.facilityIds}>
                  {validationErrors.facilityIds}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </>
      </ModalLoadingContainer>
    </SaveModal>
  );
};

const useUserNewForm: (input: Input) => Result = ({ onCompleted }) => {
  const [userNewFormIsOpen, setUserNewFormIsOpen] = useState(false);

  return {
    openUserNewForm: useCallback(() => setUserNewFormIsOpen(true), [
      setUserNewFormIsOpen,
    ]),
    UserNewForm,
    userNewFormProps: {
      userNewFormIsOpen,
      onCompleted,
      closeUserNewForm: useCallback(() => setUserNewFormIsOpen(false), [
        setUserNewFormIsOpen,
      ]),
    },
  };
};

export default useUserNewForm;
