import gql from 'graphql-tag';

export const ADD_MANUAL_CALIBRATE_ZONE_TANK_PRESSURE_SENSOR = gql`
  mutation manualCalibrateZoneTankPressureSensor(
    $input: ManualCalibrateZoneTankPressureSensorInputType!
  ) {
    manualCalibrateZoneTankPressureSensor(input: $input) {
      zone {
        zoneId
        tank {
          autoCalibrated
          calibrationPoints
        }
      }
    }
  }
`;
