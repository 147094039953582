import React from 'react';
import Switch from 'react-switch';

export default ({
  id,
  className,
  checked,
  onChange,
  width = 45,
  height = 20,
  offColor = '#dc3545',
  disabled,
}) => (
  <Switch
    id={id}
    className={['toggle-switch', className].join(' ')}
    onColor="#627753"
    offColor={offColor}
    onHandleColor="#ccc"
    offHandleColor="#ccc"
    checked={checked}
    onChange={onChange}
    width={width}
    height={height}
    disabled={disabled}
  />
);
