import React from 'react';
import gql from 'graphql-tag';
import { Button } from 'reactstrap';

import useStackHarvestScheduleForm from '../../../../../../hooks/useStackHarvestScheduleForm';
import useStackValveTestPrompt from '../../../../../../hooks/useStackValveTestPrompt';
import useStackCleanInPlaceForm from '../../../../../../hooks/useStackCleanInPlaceForm';

import HarvestScheduleIcon from '../../../../../../components/HarvestScheduleIcon';
import ValveTestIcon from '../../../../../../components/ValveTestIcon';
import CleanInPlaceIcon from '../../../../../../components/CleanInPlaceIcon';

import style from './style.module.scss';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useModifyMicrogreenScheduleForm from '../../../../../../hooks/useModifyMicrogreenScheduleForm';

const StackCommands = ({
  zoneName,
  zoneId,
  stack,
  className,
  isCleanInPlace,
  cleanInPlaceToggleClicked = () => {},
}) => {
  const {
    openScheduleHarvestForm,
    StackHarvestScheduleForm,
    stackHarvestScheduleFormProps,
  } = useStackHarvestScheduleForm({
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
    enablePostHarvestActions: stack.collectorTank.zone != null,
  });

  const {
    openStackValveTestPrompt,
    StackValveTestPrompt,
    stackValveTestPromptProps,
  } = useStackValveTestPrompt({
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  const {
    openModifyScheduleStackPrompt,
    ModifyScheduleStackPrompt,
    modifyScheduleStackPromptProps,
  } = useModifyMicrogreenScheduleForm({
    zoneId,
    onCompleted: () => {},
  });

  const {
    updateStackHarvestScheduleTimeCommand,
    harvestSchedule,
    performStackValveTestCommand,
    performStackCleanInPlaceCommand,
  } = stack;

  return (
    <div className={[style.container, className].join(' ')}>
      <StackHarvestScheduleForm {...stackHarvestScheduleFormProps} />

      <StackValveTestPrompt {...stackValveTestPromptProps} />

      <ModifyScheduleStackPrompt {...modifyScheduleStackPromptProps} />

      {!isCleanInPlace && (
        <>
          {!harvestSchedule &&
            stack.stackKind === 'STANDARD' &&
            updateStackHarvestScheduleTimeCommand.canExecute && (
              <Button onClick={openScheduleHarvestForm}>
                <HarvestScheduleIcon />

                <span>Schedule Harvest</span>
              </Button>
            )}

          {performStackValveTestCommand.canExecute && (
            <Button onClick={openStackValveTestPrompt}>
              <ValveTestIcon />

              <span>Test Valves</span>
            </Button>
          )}
        </>
      )}

      {performStackCleanInPlaceCommand.canExecute && (
        <Button onClick={cleanInPlaceToggleClicked}>
          {isCleanInPlace ? (
            <>
              <CleanInPlaceIcon />

              <span>
                Exit
                <br />
                Clean In Place
              </span>
            </>
          ) : (
            <>
              <CleanInPlaceIcon />

              <span>Clean In Place</span>
            </>
          )}
        </Button>
      )}

      {!isCleanInPlace && stack.stackKind !== 'STANDARD' && (
        <Button onClick={() => openModifyScheduleStackPrompt(stack.stackId)}>
          <FontAwesomeIcon
            icon={faClock}
            style={{ fontSize: '3em' }}
            fixedWidth
          />
          <span>Schedule</span>
        </Button>
      )}
    </div>
  );
};

StackCommands.fragment = gql`
  fragment StackCommands on StackType {
    name
    stackId
    harvestSchedule {
      __typename
    }
    updateStackHarvestScheduleTimeCommand {
      canExecute
    }
    performStackValveTestCommand {
      canExecute
    }
    performStackCleanInPlaceCommand {
      canExecute
    }
  }
`;

export default StackCommands;
