import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  nutrientMixerPumpName: string;
  nutrientMixerPumpId: string;
  onCompleted?: () => void;
}

interface Result {
  openNutrientMixerPumpEnableMutexPrompt: () => void;
  openNutrientMixerPumpDisableMutexPrompt: () => void;
  NutrientMixerPumpConfigureMutexPrompts: typeof NutrientMixerPumpConfigureMutexPrompts;
  nutrientMixerPumpConfigureMutexPromptProps: Omit<
    Props,
    'nutrientMixerPumpConfigureMutex'
  >;
}

const ENABLE_NUTRIENT_MIXER_PUMP_MUTEX = gql`
  mutation enableNutrientMixerPumpMutualExclusivity(
    $input: EnableNutrientMixerPumpMutualExclusivityInputType!
  ) {
    enableNutrientMixerPumpMutualExclusivity(input: $input) {
      pump {
        nutrientMixerPumpId
        isMutuallyExclusive
      }
    }
  }
`;

const DISABLE_NUTRIENT_MIXER_PUMP_MUTEX = gql`
  mutation disableNutrientMixerPumpMutualExclusivity(
    $input: DisableNutrientMixerPumpMutualExclusivityInputType!
  ) {
    disableNutrientMixerPumpMutualExclusivity(input: $input) {
      pump {
        nutrientMixerPumpId
        isMutuallyExclusive
      }
    }
  }
`;

interface Props extends Input {
  enableMutexPromptIsOpen: boolean;
  disableMutexPromptIsOpen: boolean;
  closeEnableMutexPrompt: () => void;
  closeDisableMutexPrompt: () => void;
}

const NutrientMixerPumpConfigureMutexPrompts = ({
  zoneName,
  nutrientMixerPumpName,
  nutrientMixerPumpId,
  enableMutexPromptIsOpen,
  disableMutexPromptIsOpen,
  closeEnableMutexPrompt,
  closeDisableMutexPrompt,
  onCompleted,
}: Props) => {
  const [
    enableNutrientMixerPumpMutex,
    {
      error: errorEnableNutrientMixerMutex,
      loading: savingEnableNutrientMixerMutex,
    },
  ] = useMutation(ENABLE_NUTRIENT_MIXER_PUMP_MUTEX, {
    onCompleted: () => {
      closeEnableMutexPrompt();
      closeDisableMutexPrompt();
      onCompleted && onCompleted();
    },
  });

  const [
    disableNutrientMixerPumpMutex,
    {
      error: errorDisableNutrientMixerMutex,
      loading: savingDisableNutrientMixerMutex,
    },
  ] = useMutation(DISABLE_NUTRIENT_MIXER_PUMP_MUTEX, {
    onCompleted: () => {
      closeEnableMutexPrompt();
      closeDisableMutexPrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <>
      <PromptModal
        title={`Enable Mutual Exclusivity for Zone ${zoneName} / Nutrient Mixer Pump ${nutrientMixerPumpName}`}
        danger
        isOpen={enableMutexPromptIsOpen}
        saving={savingEnableNutrientMixerMutex}
        error={errorEnableNutrientMixerMutex}
        onSave={() => {
          enableNutrientMixerPumpMutex({
            variables: {
              input: { nutrientMixerPumpId },
            },
          });
        }}
        onComplete={closeEnableMutexPrompt}
      >
        <p>{`Add Nutrient Mixer Pump ${nutrientMixerPumpName} to the mutually exclusive group for Zone ${zoneName}?`}</p>
      </PromptModal>

      <PromptModal
        title={`Disable Mutual Exclusivity for Zone ${zoneName} / Nutrient Mixer Pump ${nutrientMixerPumpName}`}
        danger
        isOpen={disableMutexPromptIsOpen}
        saving={savingDisableNutrientMixerMutex}
        error={errorDisableNutrientMixerMutex}
        onSave={() => {
          disableNutrientMixerPumpMutex({
            variables: {
              input: { nutrientMixerPumpId },
            },
          });
        }}
        onComplete={closeDisableMutexPrompt}
      >
        <p>{`Remove Nutrient Mixer Pump ${nutrientMixerPumpName} from the mutually exclusive group for Zone ${zoneName}?`}</p>
      </PromptModal>
    </>
  );
};

const useNutrientMixerPumpConfigureMutexPrompts: (input: Input) => Result = ({
  zoneName,
  nutrientMixerPumpName,
  nutrientMixerPumpId,
  onCompleted,
}: Input) => {
  const [enableMutexPromptIsOpen, setEnableMutexPromptIsOpen] = useState(false);

  const [disableMutexPromptIsOpen, setDisableMutexPromptIsOpen] = useState(
    false
  );

  return {
    openNutrientMixerPumpEnableMutexPrompt: useCallback(
      () => setEnableMutexPromptIsOpen(true),
      [setEnableMutexPromptIsOpen]
    ),
    openNutrientMixerPumpDisableMutexPrompt: useCallback(
      () => setDisableMutexPromptIsOpen(true),
      [setDisableMutexPromptIsOpen]
    ),
    NutrientMixerPumpConfigureMutexPrompts,
    nutrientMixerPumpConfigureMutexPromptProps: {
      zoneName,
      nutrientMixerPumpName,
      nutrientMixerPumpId,
      enableMutexPromptIsOpen,
      disableMutexPromptIsOpen,
      onCompleted,
      closeEnableMutexPrompt: useCallback(
        () => setEnableMutexPromptIsOpen(false),
        [setEnableMutexPromptIsOpen]
      ),
      closeDisableMutexPrompt: useCallback(
        () => setDisableMutexPromptIsOpen(false),
        [setDisableMutexPromptIsOpen]
      ),
    },
  };
};

export default useNutrientMixerPumpConfigureMutexPrompts;
