import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup, Button, Input } from 'reactstrap';
import gql from 'graphql-tag';

import style from './style.module.scss';

import history from '../../../../../../../../modules/history';

const SelectionType = {
  OZONE_SYSTEM: 'OZONE_SYSTEM',
};

const OzoneSystemSelector = ({
  className,
  facilityId,
  ozoneSystems,
  currentOzoneSystemId,
}) => {
  if (ozoneSystems == null) return <></>;
  const ozoneSystemLookup = ozoneSystems.reduce((acc, ozoneSystem) => {
    acc[ozoneSystem.ozoneSystemId] = ozoneSystem;
    return acc;
  }, {});

  return (
    <div className={[style.container, className].join(' ')}>
      <Input
        type="select"
        className={['custom-select', style.select].join(' ')}
        value={currentOzoneSystemId}
        onChange={e =>
          history.push(
            `/facility/${facilityId}/ozone-system/${
              ozoneSystemLookup[e.target.value].name
            }`
          )
        }
      >
        {ozoneSystems
          .filter(i => !i.isHidden)
          .map(({ ozoneSystemId, name }) => (
            <option key={ozoneSystemId} value={ozoneSystemId}>
              Macrozone&nbsp;{name}
            </option>
          ))}
      </Input>

      <ButtonGroup className={['rounded', style.buttonGroup].join(' ')}>
        {ozoneSystems
          .filter(i => !i.isHidden)
          .map(ozoneSystem => {
            const { ozoneSystemId, name } = ozoneSystem;

            const isActive = ozoneSystemId === currentOzoneSystemId;

            return (
              <Button
                tag={Link}
                to={`/facility/${facilityId}/ozone-system/${name}`}
                key={ozoneSystemId}
                active={isActive}
              >
                Macrozone&nbsp;{name}
              </Button>
            );
          })}
      </ButtonGroup>
    </div>
  );
};

OzoneSystemSelector.fragment = gql`
  fragment OzoneSystemSelector on PumpRoomType {
    ozoneSystems {
      ozoneSystemId
      name
    }
  }
`;

OzoneSystemSelector.SelectionType = SelectionType;

export default OzoneSystemSelector;
