import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import qs from 'qs';
import { useMutation } from '@apollo/react-hooks';
import { Button, FormGroup, Label, Input } from 'reactstrap';

import { getInputValueAnd, updateState } from '../../modules/form-helpers';

import LoginForm from '../../components/LoginForm';
import Loading from '../../components/Loading';
import ResultAlert from '../../components/ResultAlert';
import ErrorMessages from '../../components/ErrorMessages';

import style from './style.module.scss';

const INITIATE_PASSWORD_RESET = gql`
  mutation initiatePasswordReset($username: String!) {
    initiatePasswordReset(username: $username) {
      message
    }
  }
`;

export default ({ location }) => {
  const usernameState = useState(
    qs.parse(location && location.search, { ignoreQueryPrefix: true }).u || ''
  );
  const [username] = usernameState;

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const [initiatePasswordReset, { data, error, loading }] = useMutation(
    INITIATE_PASSWORD_RESET
  );

  const formIsValid = !!username;

  const initiatePasswordResetSucceeded = !error && data;

  return (
    <LoginForm
      onSubmit={e => {
        setIsDirty(false);
        initiatePasswordReset({
          variables: { username },
        });
      }}
    >
      {initiatePasswordResetSucceeded && (
        <div className={style.initiatePasswordResetSucceeded}>
          <ResultAlert className={style.alert} success={true}>
            {data.initiatePasswordReset.message}
          </ResultAlert>

          <Link to="/login">Go back to login</Link>
        </div>
      )}
      <div className={initiatePasswordResetSucceeded && style.formComplete}>
        <h3 className={style.title}>Reset Password</h3>
        <fieldset disabled={loading}>
          <FormGroup>
            <Label for="username">Username</Label>
            <Input
              id="username"
              type="email"
              required
              value={username}
              onChange={getInputValueAnd(
                updateState({ valueState: usernameState, isDirtyState })
              )}
            />
          </FormGroup>
        </fieldset>

        {error && !isDirty && (
          <ResultAlert className={style.alert} success={false}>
            <ErrorMessages error={error} />
          </ResultAlert>
        )}

        <div className={style.buttonGroup}>
          <Button color="primary" disabled={!formIsValid} type="submit">
            {loading && <Loading />}
            <span style={{ visibility: loading ? 'hidden' : null }}>
              Submit
            </span>
          </Button>
        </div>
      </div>
    </LoginForm>
  );
};
