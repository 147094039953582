import gql from 'graphql-tag';
import { FRAGMENT_ZONE_COLLECTOR } from './fragmentZoneCollector';

export const SUBSCRIPTION_ZONE_COLLECTOR = gql`
  subscription zoneUpdated($zoneId: ID!) {
    zoneUpdated(zoneId: $zoneId) {
      zone {
        ...CalibrateZoneCollectorTankForm
      }
    }
  }
  ${FRAGMENT_ZONE_COLLECTOR}
`;
