import React from 'react';
import { QueryResult } from 'react-apollo';

import Loading from '../Loading';
import PageError from '../PageError';
import PageNotFound from '../PageNotFound';

import style from './style.module.scss';

interface Props extends React.HtmlHTMLAttributes<HTMLElement> {
  result: QueryResult;
  resourceTypeName: string;
  resourceExists: boolean;
}

export default ({
  result,
  resourceTypeName,
  resourceExists,
  className,
  children,
}: Props) => {
  return (
    <div
      className={[
        style.container,
        result.loading ? style.loading : undefined,
        result.error && !result.loading ? style.error : undefined,
        !resourceExists && !result.loading && !result.error
          ? style.resourceNotFound
          : undefined,
        className,
      ].join(' ')}
    >
      {result.loading && <Loading className={style.loadingIcon} />}

      {result.error && <PageError error={result.error} showIcon={false} />}

      {!result.loading && result.error && (
        <PageError
          className={style.errorInfo}
          error={result.error}
          showIcon={false}
        />
      )}

      {!result.loading && !resourceExists && (
        <PageNotFound
          className={style.resourceNotFoundInfo}
          resourceTypeName={resourceTypeName}
        />
      )}

      {result.data && resourceExists && children}
    </div>
  );
};
