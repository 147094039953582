/*
  A form and form hook for providing a user with the means to create, read, update, and modify subzones.
 */

import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';

import PromptModal from '../../components/PromptModal';
import ModalLoadingContainer from '../../components/ModalLoadingContainer';
import style from '../useModifySubzonesForm/style.module.scss';

import { Option } from '../useModifySubzonesForm/components/SubzoneLine';
import CrudModal from '../../components/CrudModal';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import {
  Recipe,
  Permissions,
} from '../../pages/StackPage/components/Photoperiods';
import ItemLine from './components/ItemLine';
import { useMediaQuery } from 'react-responsive';

/* Definitions */

export interface Item extends Recipe {
  isDeleted: boolean;
  isNew: boolean;
}

interface Result {
  openPhotoperiodsCrudForm: () => void;
  PhotoperiodsCrudForm: typeof PhotoperiodsCrudForm;
  photoperiodsCrudFormProps: Props;
}

interface Input {
  stackId: number;
  recipes: Recipe[];
  currentActive: Recipe | null;
  onRecipeAdded: (added: Recipe[]) => void;
  onRecipeDeleted: (deleted: Recipe) => void;
  permissions: Permissions;
  onCompleted?: (saved: boolean) => void;
}

interface Props extends Input {
  photoperiodsCrudFormIsOpen: boolean;
  closePhotoperiodsCrudForm: () => void;
}

/* Global Methods */

export function newItem(id: number): Item {
  return {
    id: id,
    name: '',
    isNew: true,
    isDeleted: false,
    // @ts-ignore
    timeBlocks: [...Array(48).keys()].map(_ => false),
  };
}

export function promoteToItem(obj: Recipe): Item {
  return {
    name: obj.name,
    timeBlocks: obj.timeBlocks,
    id: obj.id,
    isNew: false,
    isDeleted: false,
  };
}

function isEqual(self: Item, other: Item): boolean {
  return (
    Boolean(self.isNew) === Boolean(other.isNew) &&
    Boolean(self.isDeleted) === Boolean(other.isDeleted) &&
    String(self.name) === String(other.name) &&
    JSON.stringify(self.timeBlocks) === JSON.stringify(other.timeBlocks) &&
    Number(self.id) === Number(other.id)
  );
}

/* GQL Queries */

const UPSERT_ITEM = gql`
  mutation upsert($item: UpsertStackLightScheduleRecipeInputType!) {
    upsertStackLightScheduleRecipe(input: $item) {
      id
      displayName
      scheduleBlocks
    }
  }
`;

const DEL_ITEM = gql`
  mutation remove($item: RemoveStackLightScheduleRecipeInputType!) {
    removeStackLightScheduleRecipe(input: $item) {
      id
    }
  }
`;

/* React Components */

const PhotoperiodsCrudForm = ({
  photoperiodsCrudFormIsOpen,
  closePhotoperiodsCrudForm,
  onCompleted,
  permissions,
  recipes,
  stackId,
  onRecipeAdded,
  onRecipeDeleted,
  currentActive,
}: Props) => {
  const [upsertItem, {}] = useMutation(UPSERT_ITEM);
  const [deleteItem, {}] = useMutation(DEL_ITEM);

  const [items, setItems] = useState<Item[]>([]);
  const [uid, setUid] = useState(-1);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setItems(recipes.filter(i => i != null).map(i => promoteToItem(i)));
  }, [recipes]);

  useEffect(
    () =>
      setIsValid(items != null && items.filter(i => !i.isDeleted).length > 0),
    [items]
  );

  const createItem = () => {
    //Stop React bouncing.
    if (items == null) return;

    //Push new item into current local state.
    items.push(newItem(uid));
    setUid(uid - 1);
    setItems(items);
  };

  const updateItem = useCallback(
    (newState: Item) => {
      //Stop React bouncing.
      if (items == null) return;

      //Match new state to current state by ID.
      for (let i = 0; i < items.length; i++) {
        if (items[i] === undefined) continue;
        if (items[i].id != newState.id) continue;

        //Insert new state into current state and update the current local state.
        items[i] = newState;
        setItems(items);

        //Short circuit when IDs match.
        break;
      }
    },
    [items]
  );

  const saveItems = () => {
    //Stop React bouncing.
    if (items == null) return;

    //Map subzones to server-side actions.
    items.map(i => {
      if (i.isDeleted && i.isNew) return;

      //Separate locally deleted items into another branch.
      if (!!i.isDeleted) {
        //Remove subzone server-side.
        deleteItem({
          variables: {
            item: {
              id: i.id,
            },
          },
        });
        const next = items.filter(j => j.id !== i.id);
        setItems(next);
        onRecipeDeleted(i);
      } else {
        //Check if current mapping item is different from the server state.
        const isModified = () => {
          return recipes.some((j: Recipe) => !isEqual(promoteToItem(j), i));
        };

        //Upsert item server side if it is new or different from the last server-state.
        if (i.isNew || isModified()) {
          upsertItem({
            variables: {
              item: {
                id: i.id,
                displayName: i.name,
                schedule: i.timeBlocks,
              },
            },
          }).then((r: any) => {
            setItems(prev => {
              const next: Item = {
                id: r.data.upsertStackLightScheduleRecipe.id,
                name: r.data.upsertStackLightScheduleRecipe.displayName,
                timeBlocks:
                  r.data.upsertStackLightScheduleRecipe.scheduleBlocks,
                isDeleted: false,
                isNew: false,
              };

              for (let i = 0; i < prev.length; i++) {
                if (prev[i] === undefined) continue;
                if (prev[i].id === next.id) {
                  delete prev[i];
                  break;
                }
              }
              prev.push(next);

              onRecipeAdded(prev);
              return prev;
            });
          });
        }
      }
    });

    //Close and call parent.
    closePhotoperiodsCrudForm();
    onCompleted && onCompleted(true);
  };

  return (
    <CrudModal
      title={`Photoperiods for Stack`}
      isOpen={photoperiodsCrudFormIsOpen}
      saving={false}
      error={undefined}
      onSave={saveItems}
      canCreate={true}
      onComplete={isSave => {
        closePhotoperiodsCrudForm();
        onCompleted && onCompleted(isSave);
        setItems([]);
      }}
      onCreate={createItem}
      saveButtonContent="Save"
      fullWidth={true}
      isFormValid={isValid}
    >
      {items != null &&
        items
          .filter(i => !i.isDeleted)
          .map((i: Item, key: number) => {
            return (
              <ItemLine
                key={key}
                item={i}
                onChange={updateItem}
                canDelete={permissions.delete && i.id !== currentActive?.id}
              />
            );
          })}
    </CrudModal>
  );
};

/* React Hooks */

const usePhotoperiodsCrudForm: (input: Input) => Result = ({
  stackId,
  recipes,
  permissions,
  onCompleted,
  onRecipeAdded,
  onRecipeDeleted,
  currentActive,
}) => {
  const [modifySubzonesPromptIsOpen, setModifySubzonesPromptIsOpen] = useState(
    false
  );

  return {
    openPhotoperiodsCrudForm: useCallback(
      () => setModifySubzonesPromptIsOpen(true),
      [setModifySubzonesPromptIsOpen]
    ),
    PhotoperiodsCrudForm: PhotoperiodsCrudForm,
    photoperiodsCrudFormProps: {
      stackId,
      recipes: [...recipes],
      permissions,
      photoperiodsCrudFormIsOpen: modifySubzonesPromptIsOpen,
      onCompleted,
      onRecipeDeleted,
      closePhotoperiodsCrudForm: useCallback(
        () => setModifySubzonesPromptIsOpen(false),
        [setModifySubzonesPromptIsOpen]
      ),
      onRecipeAdded,
      currentActive,
    },
  };
};

export default usePhotoperiodsCrudForm;
