import React from 'react';
import gql from 'graphql-tag';

const GrowWiseRecipe = ({ className, growWiseRecipe }) => {
  return (
    <span className={className}>
      {GrowWiseRecipe.format(growWiseRecipe, {
        showExperimentalIndicator: false,
      })}
      {growWiseRecipe.isExperimental && <i>(experimental)</i>}
    </span>
  );
};

GrowWiseRecipe.format = (growWiseRecipe, opt) => {
  opt = {
    showExperimentalIndicator: true,
    ...opt,
  };

  const recipeNumber =
    growWiseRecipe.number.toString().padStart(3, '0') +
    (growWiseRecipe.name ? ':' : '');
  const recipeName = growWiseRecipe.name;
  const experimentalIndicator =
    opt.showExperimentalIndicator && growWiseRecipe.isExperimental
      ? '(experimental)'
      : '';

  return `${recipeNumber} ${recipeName} ${experimentalIndicator}`;
};

GrowWiseRecipe.fragment = gql`
  fragment GrowWiseRecipe on GrowWiseRecipeType {
    id
    number
    name
    isExperimental
  }
`;

export default GrowWiseRecipe;
