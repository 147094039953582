import gql from 'graphql-tag';
import { FRAGMENT_ZONE_SYSTEM } from './fragmentZoneSystem';

export const SUBSCRIPTION_ZONE = gql`
  subscription zoneUpdated($zoneId: ID!) {
    zoneUpdated(zoneId: $zoneId) {
      zone {
        ...CalibrateZoneTankForm
      }
    }
  }
  ${FRAGMENT_ZONE_SYSTEM}
`;
