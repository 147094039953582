import React from 'react';
import { Form } from 'reactstrap';

import Kalera from '../Kalera';
import HyCubeOs from '../HyCubeOs';

import style from './style.module.scss';

export default ({ onSubmit, children, className }) => {
  return (
    <div className={[style.container, className].join(' ')}>
      <div className={style.formContainer}>
        <HyCubeOs className={style.logo} />

        <Form
          noValidate
          className={style.form}
          onSubmit={e => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          {children}
        </Form>
      </div>

      <Kalera className={style.kalera} />
    </div>
  );
};
