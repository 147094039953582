import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  zoneId: string;
  onCompleted?: () => void;
}

interface Result {
  openZoneAttachedCollectorTankPumpTimeoutResetPrompt: () => void;
  ZoneAttachedCollectorTankPumpTimeoutResetPrompt: typeof ZoneAttachedCollectorTankPumpTimeoutResetPrompt;
  zoneAttachedCollectorTankPumpTimeoutResetPromptProps: Props;
}

export const RESET_ZONE_ATTACHED_COLLECTOR_TANK_PUMP_TIMEOUT = gql`
  mutation resetZoneAttachedCollectorTankPumpTimeout(
    $input: ResetZoneAttachedCollectorTankPumpTimeoutInputType!
  ) {
    resetZoneAttachedCollectorTankPumpTimeout(input: $input) {
      zone {
        zoneId
        collectorTank {
          pumpTimeoutDetected
        }
      }
    }
  }
`;

interface Props extends Input {
  zoneAttachedCollectorTankPumpTimeoutResetPromptIsOpen: boolean;
  closeZoneAttachedCollectorTankPumpTimeoutResetPrompt: () => void;
}

const ZoneAttachedCollectorTankPumpTimeoutResetPrompt = ({
  zoneName,
  zoneId,
  zoneAttachedCollectorTankPumpTimeoutResetPromptIsOpen,
  closeZoneAttachedCollectorTankPumpTimeoutResetPrompt,
  onCompleted,
}: Props) => {
  const [
    resetZoneAttachedCollectorTankPumpTimeout,
    { error, loading: saving },
  ] = useMutation(RESET_ZONE_ATTACHED_COLLECTOR_TANK_PUMP_TIMEOUT, {
    onCompleted: () => {
      closeZoneAttachedCollectorTankPumpTimeoutResetPrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <PromptModal
      title={`Reset Return Tank Pump Halted Alert for Zone ${zoneName}`}
      danger={true}
      isOpen={zoneAttachedCollectorTankPumpTimeoutResetPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        resetZoneAttachedCollectorTankPumpTimeout({
          variables: {
            input: {
              zoneId,
            },
          },
        });
      }}
      onComplete={closeZoneAttachedCollectorTankPumpTimeoutResetPrompt}
    >
      <p>
        The return tank pump was halted while attempting to prevent a tank
        overflow at Zone {`${zoneName}`}.
      </p>

      <p>
        You should only reset the alert if you have physically verified the pump
        is functioning correctly.
      </p>

      <p>{`Are you sure you want to reset the return tank pump halted alert for Zone ${zoneName}?`}</p>
    </PromptModal>
  );
};

const useZoneAttachedCollectorTankPumpTimeoutResetPrompt: (
  input: Input
) => Result = ({ zoneName, zoneId, onCompleted }) => {
  const [
    zoneAttachedCollectorTankPumpTimeoutResetPromptIsOpen,
    setZoneAttachedCollectorTankPumpTimeoutResetPromptIsOpen,
  ] = useState(false);

  return {
    openZoneAttachedCollectorTankPumpTimeoutResetPrompt: useCallback(
      () => setZoneAttachedCollectorTankPumpTimeoutResetPromptIsOpen(true),
      [setZoneAttachedCollectorTankPumpTimeoutResetPromptIsOpen]
    ),
    ZoneAttachedCollectorTankPumpTimeoutResetPrompt,
    zoneAttachedCollectorTankPumpTimeoutResetPromptProps: {
      zoneName,
      zoneId,
      zoneAttachedCollectorTankPumpTimeoutResetPromptIsOpen,
      onCompleted,
      closeZoneAttachedCollectorTankPumpTimeoutResetPrompt: useCallback(
        () => setZoneAttachedCollectorTankPumpTimeoutResetPromptIsOpen(false),
        [setZoneAttachedCollectorTankPumpTimeoutResetPromptIsOpen]
      ),
    },
  };
};

export default useZoneAttachedCollectorTankPumpTimeoutResetPrompt;
