import gql from 'graphql-tag';

export const ADD_MANUAL_CALIBRATE_OZONE_SYSTEM_TANK_PRESSURE_SENSOR = gql`
  mutation manualCalibrateOzoneSystemTankPressureSensor(
    $input: ManualCalibrateOzoneSystemTankPressureSensorInputType!
  ) {
    manualCalibrateOzoneSystemTankPressureSensor(input: $input) {
      ozoneSystem {
        ozoneSystemId
        tank {
          autoCalibrated
          calibrationPoints
        }
      }
    }
  }
`;
