/*
  A subcomponent which offers an atomized and modifiable representation of a subzone.
 */

import { MultiSelect } from '../../../../components/MultiSelect';
import React, { useCallback, useEffect, useState } from 'react';
import { Subzone } from '../../index';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import modalStyle from '../../../../components/CrudModal/style.module.scss';
import style from '../SubzoneLine/style.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Definitions */

export interface Option {
  label: string;
  value: string;
}

/* Global Methods */

//Generates a random CSS formatted hex color.
const randomColor = () => {
  const byte_size = 8;
  //3 bytes are needed in the RGB color space, bitshift by one and multiply by random decimal between 0-1.
  let rand = Math.floor(Math.random() * (1 << (3 * byte_size))).toString(16);
  if (rand.length < 6) rand += '0'.repeat(6 - rand.length); //Floating point errors can produce 5 digit hex codes.
  return `#${rand}`;
};

export default (props: {
  stackOptions: Option[];
  selectedStacks: Option[];
  onChange: (e: Subzone) => void;
  isDeleted: boolean;
  name: string;
  color: string;
  id: number;
  isMutexed: boolean;
}) => {
  //Create local state using parent state or default out.
  const [data, setData] = useState({
    name: props.name ?? '',
    stacks: props.selectedStacks ?? [],
    isDeleted: false,
    color: !!props.color ? props.color : randomColor(),
    isMutexed: props.isMutexed,
  });

  //Update parent when local state changes.
  useEffect(() => {
    props.onChange({
      zoneSubzoneId: props.id,
      name: data.name,
      stacks: data.stacks.map(i => Number(i.value)),
      isDeleted: data.isDeleted,
      color: data.color,
      isMutexed: data.isMutexed,
    });
  }, [data]);

  //State callback management.
  const callback = (next: any) => {
    setData(prev => {
      return { ...prev, ...next };
    });
  };

  //Subzone name callback.
  const textboxCallback = (e: any) => {
    let value = e?.target?.value; //Bug Fix: State is volatile and must be cached immediately.
    if (value == null) return; //Bug Fix: React debounce.

    //Update name only.
    callback({ name: value });
  };

  //Stack selector callback.
  const selectorCallback = (e: any) => callback({ stacks: e });

  //Delete callback.
  const deletedCallback = (e: any) => callback({ isDeleted: true });

  //Color selector callback.
  const pickerCallback = (e: any) => callback({ color: e.target.value });

  const mutexCallback = (e: any) => callback({ isMutexed: !data.isMutexed });

  //Hide self if local state is set to deleted.
  if (!!data.isDeleted === true) {
    return <></>;
  }

  console.log(data);
  return (
    <>
      <div className="row py-2">
        <div className="col-2">
          <div
            onClick={deletedCallback}
            style={{
              padding: '1em',
              width: '2.5em',
              height: '2.5em',
              borderRadius: '15em',
              marginTop: '0.5em',
              display: 'inline-block',
              cursor: 'pointer',
            }}
            className="btn btn-danger text-light"
          >
            <FontAwesomeIcon
              style={{
                position: 'absolute',
                margin: '-0.4em 0 0 -0.65em',
              }}
              className={modalStyle.icon}
              icon={faTrash}
              fixedWidth={true}
            />
          </div>
        </div>
        <div className="col-4 px-2">
          <input
            type="text"
            title="Name"
            pattern="(.|\s)*\S(.|\s)*"
            className="form-control form-control-lg"
            style={{ height: '40px' }}
            value={data.name}
            onChange={textboxCallback}
          />
        </div>
        <div className="col-4 px-2">
          <MultiSelect
            value={data.stacks}
            onChange={selectorCallback}
            options={props.stackOptions}
            labelledBy="Stacks"
          />
        </div>
        <div className="col-1 pl-1" style={{ marginTop: '1.25em' }}>
          {data.isMutexed ? (
            <input
              type="checkbox"
              value="_"
              onClick={mutexCallback}
              checked={true}
            />
          ) : (
            <input type="checkbox" value="_" onClick={mutexCallback} />
          )}
        </div>
        <div className="col-1 pl-1">
          <div className={style.blip} style={{ marginTop: '1em' }}>
            <input type="color" value={data.color} onChange={pickerCallback} />
            <div style={{ backgroundColor: data.color }}></div>
          </div>
        </div>
      </div>
    </>
  );
};
