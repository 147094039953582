import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneSupplyName: string;
  zoneSupplyId: string;
  onCompleted?: () => void;
}

interface Result {
  openOzoneZoneSupplyPumpStartStatePrompt: () => void;
  openOzoneZoneSupplyPumpStopStatePrompt: () => void;
  OzoneZoneSupplyPumpStatePrompts: typeof OzoneZoneSupplyPumpStatePrompts;
  ozoneZoneSupplyPumpStatePromptsProps: Props;
}

export const FLUSH_OZONE_TANK_INTO_ZONE_SUPPLY = gql`
  mutation flushOzoneTankIntoZoneSupply(
    $input: FlushOzoneTankIntoZoneSupplyInputType!
  ) {
    flushOzoneTankIntoZoneSupply(input: $input) {
      zoneDistributionTank {
        zoneDistributionTankId
      }
    }
  }
`;

interface Props extends Input {
  stopPumpPromptIsOpen: boolean;
  startPumpPromptIsOpen: boolean;
  closeStopPumpPrompt: () => void;
  closeStartPumpPrompt: () => void;
}

const OzoneZoneSupplyPumpStatePrompts = ({
  zoneSupplyName,
  zoneSupplyId,
  startPumpPromptIsOpen,
  stopPumpPromptIsOpen,
  closeStartPumpPrompt,
  closeStopPumpPrompt,
  onCompleted,
}: Props) => {
  const [
    flushOzoneTankIntoZoneSupply,
    { error, loading: saving },
  ] = useMutation(FLUSH_OZONE_TANK_INTO_ZONE_SUPPLY, {
    onCompleted: () => {
      closeStartPumpPrompt();
      closeStopPumpPrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <>
      <PromptModal
        title={`Flush Ozone Tank Into Zone Supply ${zoneSupplyName}`}
        isOpen={startPumpPromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          flushOzoneTankIntoZoneSupply({
            variables: {
              input: {
                zoneDistributionTankId: zoneSupplyId,
                runPump: true,
              },
            },
          });
        }}
        onComplete={closeStartPumpPrompt}
      >
        <p>
          {`Flushing the ozone tank into Zone Supply ${zoneSupplyName} will start the ozone pump for Zone Supply ${zoneSupplyName}.`}
        </p>

        <p>{`Flush the ozone tank into Zone Supply ${zoneSupplyName}?`}</p>
      </PromptModal>

      <PromptModal
        title={`Stop Flushing Ozone Tank Into Zone Supply ${zoneSupplyName}`}
        isOpen={stopPumpPromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          flushOzoneTankIntoZoneSupply({
            variables: {
              input: {
                zoneDistributionTankId: zoneSupplyId,
                runPump: false,
              },
            },
          });
        }}
        onComplete={closeStopPumpPrompt}
      >
        <p>{`The ozone pump for Zone Supply ${zoneSupplyName} will stop running.`}</p>

        <p>{`Stop flushing the ozone tank into Zone Supply ${zoneSupplyName}?`}</p>
      </PromptModal>
    </>
  );
};

const useOzoneZoneSupplyPumpStatePrompts: (input: Input) => Result = ({
  zoneSupplyName,
  zoneSupplyId,
  onCompleted,
}: Input) => {
  const [startPumpPromptIsOpen, setStartPumpPromptIsOpen] = useState(false);

  const [stopPumpPromptIsOpen, setStopPumpPromptIsOpen] = useState(false);

  return {
    openOzoneZoneSupplyPumpStartStatePrompt: useCallback(
      () => setStartPumpPromptIsOpen(true),
      [setStartPumpPromptIsOpen]
    ),
    openOzoneZoneSupplyPumpStopStatePrompt: useCallback(
      () => setStopPumpPromptIsOpen(true),
      [setStopPumpPromptIsOpen]
    ),
    OzoneZoneSupplyPumpStatePrompts,
    ozoneZoneSupplyPumpStatePromptsProps: {
      zoneSupplyName,
      zoneSupplyId,
      startPumpPromptIsOpen,
      stopPumpPromptIsOpen,
      onCompleted,
      closeStartPumpPrompt: useCallback(() => setStartPumpPromptIsOpen(false), [
        setStartPumpPromptIsOpen,
      ]),
      closeStopPumpPrompt: useCallback(() => setStopPumpPromptIsOpen(false), [
        setStopPumpPromptIsOpen,
      ]),
    },
  };
};

export default useOzoneZoneSupplyPumpStatePrompts;
