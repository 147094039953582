import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import PromptModal from '../../components/PromptModal';

import style from './style.module.scss';

interface LevelIndicatorProps {
  name: string;
  enabled: boolean;
}

const LevelIndicator = ({ name, enabled }: LevelIndicatorProps) => (
  <div className={style.levelSensor}>
    <div className={style.label}>
      <div
        className={[style.labelName, enabled ? style.enabled : undefined].join(
          ' '
        )}
      >
        {name}
      </div>
      <div className={style.labelInner}>
        {enabled && (
          <>
            <div className={style.labelWaves}></div>
            <div className={style.labelLevel} style={{ height: '20%' }}></div>
          </>
        )}
      </div>
    </div>
    <div className={style.sensorLabelConnector}></div>
    <div
      className={[
        style.indicator,
        enabled ? style.enabled : style.disabled,
      ].join(' ')}
    >
      <FontAwesomeIcon icon={enabled ? faCheck : faTimes} />
    </div>
  </div>
);

interface Input {
  ozoneSystemName: string;
  ozoneSystemId: string;
  invalidStates: { [key: string]: boolean };
  onCompleted?: () => void;
}

interface Result {
  openOzoneTankInvalidWaterSensorStateResetPrompt: () => void;
  OzoneTankInvalidWaterSensorStateResetPrompt: typeof OzoneTankInvalidWaterSensorStateResetPrompt;
  ozoneTankInvalidWaterSensorStateResetPromptProps: Props;
}

export const RESET_OZONE_TANK_INVALID_STATE = gql`
  mutation resetOzoneTankInvalidState(
    $input: ResetOzoneTankInvalidStateAlertCommandInputType!
  ) {
    resetOzoneTankInvalidState(input: $input) {
      ozoneSystem {
        ozoneSystemId
        tank {
          invalidWaterSensorStatesDetected
        }
      }
    }
  }
`;

interface Props extends Input {
  ozoneTankInvalidWaterSensorStateResetPromptIsOpen: boolean;
  closeOzoneTankInvalidWaterSensorStateResetPrompt: () => void;
}

const OzoneTankInvalidWaterSensorStateResetPrompt = ({
  ozoneSystemName,
  ozoneSystemId,
  invalidStates,
  ozoneTankInvalidWaterSensorStateResetPromptIsOpen,
  closeOzoneTankInvalidWaterSensorStateResetPrompt,
  onCompleted,
}: Props) => {
  const [resetOzoneTankInvalidState, { error, loading: saving }] = useMutation(
    RESET_OZONE_TANK_INVALID_STATE,
    {
      onCompleted: () => {
        closeOzoneTankInvalidWaterSensorStateResetPrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Reset Invalid Water Level Sensor State Alert for Ozone ${ozoneSystemName}`}
      danger={true}
      isOpen={ozoneTankInvalidWaterSensorStateResetPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        resetOzoneTankInvalidState({
          variables: {
            input: {
              ozoneSystemId,
            },
          },
        });
      }}
      onComplete={closeOzoneTankInvalidWaterSensorStateResetPrompt}
    >
      <p>
        The tank controller for Ozone {`${ozoneSystemName}`} determined the
        water level sensors to be in the following invalid state:
      </p>
      <div className={style.levelSensorsContainer}>
        <LevelIndicator name="High High" enabled={invalidStates?.HH ?? false} />
        <LevelIndicator name="High" enabled={invalidStates?.H ?? false} />
        <LevelIndicator name="Low" enabled={invalidStates?.L ?? false} />
        <LevelIndicator name="Low Low" enabled={invalidStates?.LL ?? false} />
      </div>
      <p>
        You should only reset the alert if you have physically verified the
        water level sensors are functioning correctly.
      </p>

      <p>
        Are you sure you want to reset invalid water sensor state alert for
        Ozone {`${ozoneSystemName}`}?
      </p>
    </PromptModal>
  );
};

const useOzoneTankInvalidWaterSensorStateResetPrompt: (
  input: Input
) => Result = ({
  ozoneSystemName,
  ozoneSystemId,
  invalidStates,
  onCompleted,
}) => {
  const [
    ozoneTankInvalidWaterSensorStateResetPromptIsOpen,
    setOzoneTankInvalidWaterSensorStateResetPromptIsOpen,
  ] = useState(false);

  return {
    openOzoneTankInvalidWaterSensorStateResetPrompt: useCallback(
      () => setOzoneTankInvalidWaterSensorStateResetPromptIsOpen(true),
      [setOzoneTankInvalidWaterSensorStateResetPromptIsOpen]
    ),
    OzoneTankInvalidWaterSensorStateResetPrompt,
    ozoneTankInvalidWaterSensorStateResetPromptProps: {
      ozoneSystemName,
      ozoneSystemId,
      invalidStates,
      ozoneTankInvalidWaterSensorStateResetPromptIsOpen,
      onCompleted,
      closeOzoneTankInvalidWaterSensorStateResetPrompt: useCallback(
        () => setOzoneTankInvalidWaterSensorStateResetPromptIsOpen(false),
        [setOzoneTankInvalidWaterSensorStateResetPromptIsOpen]
      ),
    },
  };
};

export default useOzoneTankInvalidWaterSensorStateResetPrompt;
