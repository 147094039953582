import React from 'react';

import style from './style.module.scss';

interface Props {
  className?: string;
}

export default ({ className }: Props) => {
  return (
    <svg
      className={[style.svg, className].join(' ')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 17"
      version="1.1"
      x="0px"
      y="0px"
    >
      <path d="m 21,8 h 1 V 2 H 21 Z M 2,8 H 3 V 2 H 2 Z M 23,0 H 20 C 19.448,0 19,0.448 19,1 H 5 C 5,0.448 4.553,0 4,0 H 1 C 0.448,0 0,0.448 0,1 v 8 c 0,0.552 0.448,1 1,1 H 4 C 4.553,10 5,9.552 5,9 H 8 V 5 C 8,4.448 8.448,4 9,4 h 2 c 0.553,0 1,0.448 1,1 0,0.552 -0.447,1 -1,1 h -1 v 3 h 9 c 0,0.552 0.448,1 1,1 h 3 c 0.553,0 1,-0.448 1,-1 V 1 C 24,0.448 23.553,0 23,0 Z" />
      <path d="m 9.7807,10.3745 c -0.379,-0.474 -1.182,-0.474 -1.561,0 l -1.408,1.757 c -1.12,1.368 -1.074,3.055 0.112,4.101 0.583,0.514 1.33,0.771 2.077,0.771 0.747,0 1.494,-0.257 2.076,-0.771 0.558,-0.491 1.69,-1.87 0.119,-4.093 z" />
    </svg>
  );
};
