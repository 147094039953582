/*
  A subcomponent which offers an atomized and modifiable representation of an scheduled subzone stack row.
 */

import React, { useCallback, useEffect, useState } from 'react';
import { SubzoneSchedule, newSubzoneSchedule } from '../../index';

/* React Components */

export default (props: {
  onChange: (e: SubzoneSchedule) => void;
  checkbox: boolean;
  schedule: SubzoneSchedule;
  label: string | null;
  className: string | null;
  onCheckChange: (e: boolean) => void;
}) => {
  //A local state that cascades from a parent.
  const [data, setData] = useState<SubzoneSchedule>(props.schedule);
  const [checked, setChecked] = useState<boolean>(false);

  //Notify parent when the local state changes.
  useEffect(() => {
    props.onChange(data);
  }, [data]);

  //Local state callback management.
  const callback = (next: any) => {
    setData(prev => {
      return { ...prev, ...next };
    });
  };

  const daysCallback = (e: any) => {
    const value = e?.target?.value; //Bug Fix: State is volatile and must be cached immediately.
    if (value == null) return; //Bug Fix: React debounce.

    //Update days only.
    callback({ days: value });
  };

  const timesPerDayCallback = (e: any) => {
    const value = e?.target?.value; //Bug Fix: State is volatile and must be cached immediately.
    if (value == null) return; //Bug Fix: React debounce.

    //Update times per day only.
    callback({ timesPerDay: value });
  };

  const durationCallback = (e: any) => {
    const value = e?.target?.value; //Bug Fix: State is volatile and must be cached immediately.
    if (value == null) return; //Bug Fix: React debounce.

    //Update duration only.
    callback({ duration: value });
  };

  return (
    <>
      <div className={`row ${props.className ?? ''}`}>
        <div className="col-12 col-sm-2 px-3 py-2">
          <div className="input-group">
            {props.checkbox ? (
              <div className="input-group-text border-0 bg-transparent custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customControlAutosizing"
                  checked={checked}
                  onChange={() => {
                    props.onCheckChange(!checked);
                    setChecked(!checked);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customControlAutosizing"
                >
                  {props.label == null
                    ? `Level ${data.rowIndex + 1}`
                    : props.label}
                </label>
              </div>
            ) : (
              <span
                className="input-group-text pt-2 pl-0"
                style={{ backgroundColor: 'transparent', border: 'none' }}
              >
                {props.label == null
                  ? `Level ${data.rowIndex + 1}`
                  : props.label}
              </span>
            )}
          </div>
        </div>
        <div className="col-12 col-sm-3 px-1 py-2">
          <div className="input-group">
            <input
              type="number"
              title="Days"
              min="0"
              max="65535"
              value={data.days}
              onChange={daysCallback}
              className={`form-control ${
                props.checkbox && !checked ? 'disabled' : ''
              }`}
            />
            <span className="input-group-text">days</span>
          </div>
        </div>
        <div className="col-12 col-sm-3 px-1 py-2">
          <div className="input-group">
            <input
              type="number"
              title="Times per Day"
              min="0"
              max="255"
              value={data.timesPerDay}
              onChange={timesPerDayCallback}
              className={`form-control ${
                props.checkbox && !checked ? 'disabled' : ''
              }`}
            />
            <span className="input-group-text">times</span>
          </div>
        </div>
        <div className="col-12 col-sm-4 pl-1 pr-3 py-2">
          <div className="input-group">
            <input
              type="number"
              title="Duration"
              min="0"
              max="1439"
              value={data.duration}
              onChange={durationCallback}
              className={`form-control ${
                props.checkbox && !checked ? 'disabled' : ''
              }`}
            />
            <span className="input-group-text">minutes</span>
          </div>
        </div>
      </div>
    </>
  );
};
