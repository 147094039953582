export const msalConfig = {
  auth: {
    clientId: '190cd83f-337d-4e06-9743-60bcb98bc5a6', //The Application (client) ID of the application you registered.
    authority:
      'https://login.microsoftonline.com/81b1f598-ecb1-4df9-ae16-9c38e2d55ef3', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: `${window.location.origin.toString()}/login`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'], //Get basic user info like name, avatar, email.
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: null, //We are not using MS Graph, for now...
};
