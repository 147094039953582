import gql from 'graphql-tag';

export const ADD_AUTO_CALIBRATE_OZONE_SYSTEM_TANK_PRESSURE_SENSOR = gql`
  mutation autoCalibrateOzoneSystemTankPressureSensor(
    $input: AutoCalibrateOzoneSystemTankPressureSensorInputType!
  ) {
    autoCalibrateOzoneSystemTankPressureSensor(input: $input) {
      ozoneSystem {
        ozoneSystemId
        tank {
          autoCalibrated
          calibrationPoints
        }
      }
    }
  }
`;
