import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackCancelCleanInPlacePrompt: () => void;
  StackCancelCleanInPlacePrompt: typeof StackCancelCleanInPlacePrompt;
  stackCancelCleanInPlacePromptProps: Props;
}

const CANCEL_STACK_CLEAN_IN_PLACE = gql`
  mutation cancelStackCleanInPlace($input: CancelStackCleanInPlaceInputType!) {
    cancelStackCleanInPlace(input: $input) {
      stack {
        stackId
        mode
      }
    }
  }
`;

interface Props extends Input {
  stackCancelCleanInPlacePromptIsOpen: boolean;
  closeStackCancelCleanInPlacePrompt: () => void;
}

const StackCancelCleanInPlacePrompt = ({
  zoneName,
  stackName,
  stackId,
  stackCancelCleanInPlacePromptIsOpen,
  closeStackCancelCleanInPlacePrompt,
  onCompleted,
}: Props) => {
  const [cancelStackCleanInPlace, { error, loading: saving }] = useMutation(
    CANCEL_STACK_CLEAN_IN_PLACE,
    {
      onCompleted: () => {
        closeStackCancelCleanInPlacePrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Cancel Clean in Place for Zone ${zoneName} / Stack ${stackName}`}
      isOpen={stackCancelCleanInPlacePromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        cancelStackCleanInPlace({
          variables: {
            input: {
              stackId,
            },
          },
        });
      }}
      onComplete={closeStackCancelCleanInPlacePrompt}
    >
      {`Are you sure you want to cancel the clean-in-place operation for Zone ${zoneName} / Stack ${stackName}?`}
    </PromptModal>
  );
};

const useStackCancelCleanInPlacePrompt: (input: Input) => Result = ({
  zoneName,
  stackName,
  stackId,
  onCompleted,
}) => {
  const [
    stackCancelCleanInPlacePromptIsOpen,
    setStackCancelCleanInPlacePromptIsOpen,
  ] = useState(false);

  return {
    openStackCancelCleanInPlacePrompt: useCallback(
      () => setStackCancelCleanInPlacePromptIsOpen(true),
      [setStackCancelCleanInPlacePromptIsOpen]
    ),
    StackCancelCleanInPlacePrompt,
    stackCancelCleanInPlacePromptProps: {
      zoneName,
      stackName,
      stackId,
      stackCancelCleanInPlacePromptIsOpen,
      onCompleted,
      closeStackCancelCleanInPlacePrompt: useCallback(
        () => setStackCancelCleanInPlacePromptIsOpen(false),
        [setStackCancelCleanInPlacePromptIsOpen]
      ),
    },
  };
};

export default useStackCancelCleanInPlacePrompt;
