import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  facilityId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackLeakResetPrompt: () => void;
  StackLeakResetPrompt: typeof SafetyStopResetPrompt;
  stackLeakResetPromptProps: Props;
}

export const RESET_SAFETY_STOP = gql`
  mutation resetSafetyStop($facilityId: SilenceSafetyStopCommandType!) {
    silenceSafetyStop(input: $facilityId) {
      __typename
    }
  }
`;

interface Props extends Input {
  stackLeakResetPromptIsOpen: boolean;
  closeStackLeakResetPrompt: () => void;
}

const SafetyStopResetPrompt = ({
  facilityId,
  stackLeakResetPromptIsOpen,
  closeStackLeakResetPrompt,
  onCompleted,
}: Props) => {
  const [safetyStopReset, { error, loading: saving }] = useMutation(
    RESET_SAFETY_STOP,
    {
      onCompleted: () => {
        closeStackLeakResetPrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Clear Safety Stop`}
      danger={true}
      isOpen={stackLeakResetPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        safetyStopReset({
          variables: {
            facilityId: {
              facilityId: Number(facilityId),
            },
          },
        });
      }}
      onComplete={closeStackLeakResetPrompt}
    >
      <p>
        The system has detected commands which could lead to permanent damage.
      </p>

      <p>Are you sure you want to reset the safety stopped supply pump?</p>
    </PromptModal>
  );
};

const useStackLeakResetPrompt: (input: Input) => Result = ({
  facilityId,
  onCompleted,
}) => {
  const [stackLeakResetPromptIsOpen, setStackLeakResetPromptIsOpen] = useState(
    false
  );

  return {
    openStackLeakResetPrompt: useCallback(
      () => setStackLeakResetPromptIsOpen(true),
      [setStackLeakResetPromptIsOpen]
    ),
    StackLeakResetPrompt: SafetyStopResetPrompt,
    stackLeakResetPromptProps: {
      facilityId: facilityId,
      stackLeakResetPromptIsOpen,
      onCompleted,
      closeStackLeakResetPrompt: useCallback(
        () => setStackLeakResetPromptIsOpen(false),
        [setStackLeakResetPromptIsOpen]
      ),
    },
  };
};

export default useStackLeakResetPrompt;
