import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import moment from 'moment';
import { Input as FormInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

import { SaveModal } from '../../components/SaveModal';

import {
  getInputValueAnd,
  convertValue,
  updateState,
} from '../../modules/form-helpers';

import style from './style.module.scss';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  enablePostHarvestActions?: boolean;
  onCompleted?: () => void;
}

interface Result {
  openScheduleHarvestForm: () => void;
  StackHarvestScheduleForm: typeof StackHarvestScheduleForm;
  stackHarvestScheduleFormProps: Props;
}

export const UPDATE_STACK_HARVEST_SCHEDULE = gql`
  mutation updateStackHarvestScheduleTime(
    $input: UpdateStackHarvestScheduleTimeInputType!
  ) {
    updateStackHarvestScheduleTime(input: $input) {
      stack {
        stackId
        mode
        harvestSchedule {
          harvestBeginLocalTime
        }
      }
    }
  }
`;

const validTimeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/;

const defaultHarvestStartTime = '05:00';
const defaultHarvestStartPeriod = 'am';

const defaultIrrigationResumeTime = '12:00';
const defaultIrrigationResumePeriod = 'pm';

enum ZonePostHarvestAction {
  CONTINUE_IRRIGATION = 0,
  PAUSE,
  STOP,
}

const zonePostHarvestActionOptions = [
  {
    text: 'Continue Irrigation',
    action: ZonePostHarvestAction.CONTINUE_IRRIGATION,
  },
  {
    text: 'Pause',
    action: ZonePostHarvestAction.PAUSE,
  },
  {
    text: 'Stop',
    action: ZonePostHarvestAction.STOP,
  },
];

const parseHHmm = (value: string) => {
  const [val1, val2] = value.split(':').map((s: string) => Number(s));

  const num1 = isNaN(val1) ? 12 : val1;
  const num2 = isNaN(val2) ? 0 : val2;

  return [Math.min(num1, 12), Math.min(num2, 59)]
    .map((n: number) => Math.max(0, n))
    .map((n: number) => (n < 10 ? `0${n}` : n))
    .join(':');
};

interface Props extends Input {
  scheduleHarvestFormIsOpen: boolean;
  closeScheduleHarvestForm: () => void;
}

const StackHarvestScheduleForm = ({
  zoneName,
  stackName,
  stackId,
  scheduleHarvestFormIsOpen,
  enablePostHarvestActions = false,
  closeScheduleHarvestForm,
  onCompleted,
}: Props) => {
  const [updateStackHarvestSchedule, { error, loading: saving }] = useMutation(
    UPDATE_STACK_HARVEST_SCHEDULE,
    {
      onCompleted: () => {
        closeScheduleHarvestForm();
        onCompleted && onCompleted();
      },
    }
  );

  const [wasOpen, setWasOpen] = useState(false);

  const isDirtyState = useState(false);

  const harvestStartTimeState = useState(defaultHarvestStartTime);
  const [harvestStartTime, setHarvestStartTime] = harvestStartTimeState;

  const harvestStartPeriodState = useState(defaultHarvestStartPeriod);
  const [harvestStartPeriod, setHarvestStartPeriod] = harvestStartPeriodState;

  const irrigationResumeTimeState = useState(defaultIrrigationResumeTime);
  const [
    irrigationResumeTime,
    setIrrigationResumeTime,
  ] = irrigationResumeTimeState;

  const irrigationResumePeriodState = useState(defaultIrrigationResumePeriod);
  const [
    irrigationResumePeriod,
    setIrrigationResumePeriod,
  ] = irrigationResumePeriodState;

  const zonePostHarvestActionState = useState(
    ZonePostHarvestAction.CONTINUE_IRRIGATION
  );
  const [
    zonePostHarvestAction,
    setZonePostHarvestAction,
  ] = zonePostHarvestActionState;

  const isFormValid =
    validTimeRegex.test(harvestStartTime) &&
    (zonePostHarvestAction !== ZonePostHarvestAction.PAUSE ||
      validTimeRegex.test(irrigationResumeTime));

  useEffect(() => {
    const reopened = scheduleHarvestFormIsOpen && !wasOpen;

    if (reopened) {
      setHarvestStartTime(defaultHarvestStartTime);
      setHarvestStartPeriod(defaultHarvestStartPeriod);

      setIrrigationResumeTime(defaultIrrigationResumeTime);
      setIrrigationResumePeriod(defaultIrrigationResumePeriod);

      setZonePostHarvestAction(ZonePostHarvestAction.CONTINUE_IRRIGATION);
    }

    setWasOpen(scheduleHarvestFormIsOpen);
  }, [
    scheduleHarvestFormIsOpen,
    setHarvestStartTime,
    setHarvestStartPeriod,
    setIrrigationResumeTime,
    setIrrigationResumePeriod,
    setZonePostHarvestAction,
    wasOpen,
  ]);

  return (
    <SaveModal
      className={style.modal}
      title={`Schedule Harvest for Zone ${zoneName} / Stack ${stackName}`}
      isOpen={scheduleHarvestFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={error}
      saveButtonContent="OK"
      onSave={() => {
        updateStackHarvestSchedule({
          variables: {
            input: {
              stackId,
              harvestStartTime: moment(
                `${harvestStartTime} ${harvestStartPeriod}`,
                'HH:mm a'
              ).format('HH:mm:ss'),
              zonePostHarvestAction:
                ZonePostHarvestAction[zonePostHarvestAction],
              zoneIrrigationResumeTime:
                ZonePostHarvestAction[zonePostHarvestAction] ===
                ZonePostHarvestAction[ZonePostHarvestAction.PAUSE]
                  ? moment(
                      `${irrigationResumeTime} ${irrigationResumePeriod}`,
                      'HH:mm a'
                    ).format('HH:mm:ss')
                  : null,
            },
          },
        });
      }}
      onComplete={closeScheduleHarvestForm}
    >
      <p>
        Scheduling a harvest will stop the stack's irrigation at the chosen time
        in the facility's timezone.
      </p>

      <p>
        Once the stack is in harvest mode, stack irrigation will not resume
        until you put the stack back into automatic mode.
      </p>

      <p
        className={style.inputSection}
      >{`Schedule a harvest for Zone ${zoneName} / Stack ${stackName} at:`}</p>

      <div className={style.inputSection}>
        <div className={style.timeContainer}>
          <FontAwesomeIcon icon={faClock} fixedWidth size="lg" />

          <FormInput
            id="harvestStartTime"
            className={style.harvestStartTime}
            type="text"
            value={harvestStartTime}
            onBlur={getInputValueAnd(
              convertValue(
                parseHHmm,
                updateState({
                  valueState: harvestStartTimeState,
                  isDirtyState,
                })
              )
            )}
            onChange={getInputValueAnd(
              updateState({
                valueState: harvestStartTimeState,
                isDirtyState,
              })
            )}
            autoFocus
          />

          <FormInput
            id="harvestStartPeriod"
            className={style.period}
            type="select"
            value={harvestStartPeriod}
            onChange={getInputValueAnd(
              updateState({
                valueState: harvestStartPeriodState,
                isDirtyState,
              })
            )}
          >
            <option value="am">AM</option>
            <option value="pm">PM</option>
          </FormInput>
        </div>
      </div>

      {enablePostHarvestActions && (
        <>
          <p>
            You may specify what actions the zone should take when this stack
            enters harvest mode. If you choose to stop the zone, irrigation will
            not resume until the zone is put back into automatic mode.
          </p>

          <p className={style.inputSection}>
            {`When Stack ${stackName} enters harvest mode, Zone ${zoneName} should:`}
          </p>

          <div className={style.inputSection}>
            <FormInput
              id="zonePostHarvestAction"
              className={style.zonePostHarvestAction}
              type="select"
              value={zonePostHarvestAction}
              onChange={getInputValueAnd(
                updateState({
                  valueState: [
                    zonePostHarvestAction,
                    (value: ZonePostHarvestAction) =>
                      setZonePostHarvestAction(value),
                  ],
                  isDirtyState,
                })
              )}
            >
              {zonePostHarvestActionOptions.map((val, idx) => (
                <option value={val.action} key={idx}>
                  {val.text}
                </option>
              ))}
            </FormInput>
          </div>

          {ZonePostHarvestAction[zonePostHarvestAction] ===
            ZonePostHarvestAction[ZonePostHarvestAction.PAUSE] && (
            <>
              <div className={style.inputSection}>
                {`Resume irrigation for Zone ${zoneName} at:`}
              </div>

              <div className={style.inputSection}>
                <div className={style.timeContainer}>
                  <FontAwesomeIcon icon={faClock} fixedWidth size="lg" />

                  <FormInput
                    id="irrigationResumeTime"
                    className={style.harvestStartTime}
                    type="text"
                    value={irrigationResumeTime}
                    onBlur={getInputValueAnd(
                      convertValue(
                        parseHHmm,
                        updateState({
                          valueState: irrigationResumeTimeState,
                          isDirtyState,
                        })
                      )
                    )}
                    onChange={getInputValueAnd(
                      updateState({
                        valueState: irrigationResumeTimeState,
                        isDirtyState,
                      })
                    )}
                  />

                  <FormInput
                    id="irrigationResumePeriod"
                    className={style.period}
                    type="select"
                    value={irrigationResumePeriod}
                    onChange={getInputValueAnd(
                      updateState({
                        valueState: irrigationResumePeriodState,
                        isDirtyState,
                      })
                    )}
                  >
                    <option value="am">AM</option>
                    <option value="pm">PM</option>
                  </FormInput>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </SaveModal>
  );
};

const useStackHarvestScheduleForm: (input: Input) => Result = ({
  zoneName,
  stackName,
  stackId,
  enablePostHarvestActions,
  onCompleted,
}) => {
  const [scheduleHarvestFormIsOpen, setScheduleHarvestFormIsOpen] = useState(
    false
  );
  return {
    openScheduleHarvestForm: useCallback(
      () => setScheduleHarvestFormIsOpen(true),
      [setScheduleHarvestFormIsOpen]
    ),
    StackHarvestScheduleForm,
    stackHarvestScheduleFormProps: {
      zoneName,
      stackName,
      stackId,
      scheduleHarvestFormIsOpen,
      enablePostHarvestActions,
      onCompleted,
      closeScheduleHarvestForm: useCallback(
        () => setScheduleHarvestFormIsOpen(false),
        [setScheduleHarvestFormIsOpen]
      ),
    },
  };
};

export default useStackHarvestScheduleForm;
