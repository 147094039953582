import React from 'react';

import style from './style.module.scss';

export default ({ resourceTypeName, className = null }) => {
  return (
    <div className={[style.container, className].join(' ')}>
      <svg
        className={style.icon}
        fill="#000000"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
      >
        <g>
          <path d="M9,53H5.534c-1.083-1.303-1.965-2.771-2.605-4.372l-1.857,0.743C1.791,51.171,2.783,52.82,4,54.283V56c0,3.86,3.14,7,7,7h5   v-3C16,56.14,12.86,53,9,53z M14,61h-3c-2.633,0-4.774-2.052-4.963-4.638c0.597,0.521,1.223,1.012,1.89,1.457l1.519,1.013   l1.109-1.664l-1.519-1.013C8.503,55.8,8.003,55.409,7.521,55H9c2.757,0,5,2.243,5,5V61z"></path>
          <path d="M60,1c-3.86,0-7,3.14-7,7v3.466c-1.303,1.083-2.771,1.965-4.372,2.605l0.743,1.857c1.799-0.72,3.448-1.711,4.911-2.929H56   c3.86,0,7-3.14,7-7V1H60z M61,6c0,2.633-2.052,4.774-4.638,4.963c0.521-0.597,1.012-1.223,1.457-1.89l1.013-1.519l-1.664-1.109   l-1.013,1.519C55.8,8.497,55.409,8.997,55,9.479V8c0-2.757,2.243-5,5-5h1V6z"></path>
          <path d="M2.929,15.372c0.64-1.601,1.522-3.069,2.605-4.372H9c3.86,0,7-3.14,7-7V1h-5C7.14,1,4,4.14,4,8v1.717   c-1.217,1.463-2.209,3.112-2.929,4.911L2.929,15.372z M11,3h3v1c0,2.757-2.243,5-5,5H7.521c0.482-0.409,0.982-0.8,1.514-1.155   l1.519-1.013L9.445,5.168L7.926,6.181c-0.667,0.445-1.293,0.936-1.89,1.457C6.226,5.052,8.367,3,11,3z"></path>
          <path d="M27.923,26.93l0.153,1.994c3.705-0.285,6.32-1.371,7.774-3.227c1.281-1.636,1.195-3.332,1.148-3.761   C36.965,19.208,34.736,17,32,17c-1.654,0-3,1.346-3,3s1.346,3,3,3h1v-2h-1c-0.551,0-1-0.449-1-1s0.449-1,1-1c1.654,0,3,1.346,3,3   l0.012,0.153c0.007,0.048,0.157,1.2-0.765,2.348C33.159,25.855,30.972,26.695,27.923,26.93z"></path>
          <path d="M9.126,36.514l1.747,0.973c0.045-0.08,4.578-7.978,14.178-8.464l-0.102-1.997C14.215,27.569,9.329,36.149,9.126,36.514z"></path>
          <path d="M52.769,21C47.934,21,44,24.934,44,29.769V30.5c0,3.033,2.467,5.5,5.5,5.5s5.5-2.467,5.5-5.5h-2c0,1.93-1.57,3.5-3.5,3.5   S46,32.43,46,30.5v-0.731C46,26.037,49.037,23,52.77,23c4.538,0,8.23,3.692,8.23,8.231c0,1.467-0.379,2.889-1.097,4.111   C58.655,37.467,56.271,40,52,40c-3.471,0-6.522-0.772-10.056-1.667c-1.502-0.38-3.057-0.774-4.737-1.129l-0.414,1.957   c1.642,0.347,3.176,0.735,4.66,1.111C44.964,41.16,48.28,42,52,42c5.216,0,8.115-3.07,9.627-5.645   C62.525,34.826,63,33.054,63,31.23C63,25.589,58.411,21,52.769,21z"></path>
          <path d="M6.136,48.496l1.727,1.008C7.931,49.39,14.731,38,26,38c2.009,0,3.934,0.112,5.883,0.342l0.234-1.986   C30.089,36.116,28.088,36,26,36C13.568,36,6.434,47.986,6.136,48.496z"></path>
          <path d="M48,45c-2.757,0-5,2.243-5,5h2c0-1.654,1.346-3,3-3s3,1.346,3,3c0,0.204-0.068,5-6,5c-4.323,0-6.13-1.553-12.116-6.7   l-1.233-1.059c-7.388-6.332-14.735-3.292-15.045-3.16l0.785,1.839c0.265-0.111,6.546-2.658,12.958,2.839l1.231,1.057   C37.66,55.044,39.936,57,45,57c7.909,0,8-6.93,8-7C53,47.243,50.757,45,48,45z"></path>
          <rect x="44" y="15" width="2" height="2"></rect>
          <rect x="7" y="39" width="2" height="2"></rect>
          <rect x="54" y="59" width="2" height="2"></rect>
          <rect x="59" y="43" width="2" height="2"></rect>
          <rect x="15" y="17" width="2" height="2"></rect>
          <rect x="31" y="1" width="2" height="2"></rect>
          <rect x="1" y="28" width="2" height="2"></rect>
        </g>
      </svg>

      <div className={style.message}>{resourceTypeName} Not Found</div>
    </div>
  );
};
