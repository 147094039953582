import { AutoCalibrationPoints } from '../types/AutoCalibrationPoint';

export const createBlankAutoCalibrationPoints = (): AutoCalibrationPoints => ({
  lLPressureSensorHeight: '',
  lPressureSensorHeight: '',
  hPressureSensorHeight: '',
  hHPressureSensorHeight: '',
});

export const createAutoCalibrationPointsFromData = (
  data: string[]
): AutoCalibrationPoints => ({
  lLPressureSensorHeight: parseFloat(data[0])
    .toFixed(2)
    .toString(),
  lPressureSensorHeight: parseFloat(data[1])
    .toFixed(2)
    .toString(),
  hPressureSensorHeight: parseFloat(data[2])
    .toFixed(2)
    .toString(),
  hHPressureSensorHeight: parseFloat(data[3])
    .toFixed(2)
    .toString(),
});

export const createAutoCalibrateTankInput = (
  id: string,
  idName: string,
  calibrationPoints: AutoCalibrationPoints
) => ({
  [idName]: id,
  lLPressureSensorHeight: parseFloat(calibrationPoints.lLPressureSensorHeight),
  lPressureSensorHeight: parseFloat(calibrationPoints.lPressureSensorHeight),
  hPressureSensorHeight: parseFloat(calibrationPoints.hPressureSensorHeight),
  hHPressureSensorHeight: parseFloat(calibrationPoints.hHPressureSensorHeight),
});
