import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  ozoneSystemName: string;
  ozoneSystemId: string;
  onCompleted?: () => void;
}

interface Result {
  openOzoneSystemAutomaticModePrompt: () => void;
  openOzoneSystemMaintenanceModePrompt: () => void;
  OzoneSystemModePrompts: typeof OzoneSystemModePrompts;
  ozoneSystemModePromptsProps: Omit<Props, 'ozoneSystemMode'>;
}

export const UPDATE_OZONE_SYSTEM_MODE = gql`
  mutation updateOzoneSystemMode($input: UpdateOzoneSystemModeInputType!) {
    updateOzoneSystemMode(input: $input) {
      ozoneSystem {
        ozoneSystemId
        mode
        bypassValve {
          mode
          state
        }
      }
    }
  }
`;

interface Props extends Input {
  automaticModePromptIsOpen: boolean;
  maintenanceModePromptIsOpen: boolean;
  closeAutomaticModePrompt: () => void;
  closeMaintenanceModePrompt: () => void;
}

const OzoneSystemModePrompts = ({
  ozoneSystemName,
  ozoneSystemId,
  automaticModePromptIsOpen,
  maintenanceModePromptIsOpen,
  closeAutomaticModePrompt,
  closeMaintenanceModePrompt,
  onCompleted,
}: Props) => {
  const [updateOzoneSystemMode, { error, loading: saving }] = useMutation(
    UPDATE_OZONE_SYSTEM_MODE,
    {
      onCompleted: () => {
        closeAutomaticModePrompt();
        closeMaintenanceModePrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <>
      <PromptModal
        title={`Put Ozone System ${ozoneSystemName} into Automatic Mode`}
        isOpen={automaticModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneSystemMode({
            variables: { input: { ozoneSystemId, mode: 'AUTOMATIC' } },
          });
        }}
        onComplete={closeAutomaticModePrompt}
      >
        <p>
          Putting the ozone system into automatic mode will allow the ozone tank
          to automatically flush into the zone tanks when necessary.
        </p>

        <p>Put the Ozone System {ozoneSystemName} into automatic mode?</p>
      </PromptModal>

      <PromptModal
        title={`Put Ozone System ${ozoneSystemName} into Maintenance Mode`}
        isOpen={maintenanceModePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneSystemMode({
            variables: { input: { ozoneSystemId, mode: 'MAINTENANCE' } },
          });
        }}
        onComplete={closeMaintenanceModePrompt}
      >
        <p>
          Putting the ozone system into maintenance mode will prevent the ozone
          tank from automatically flushing into the zone tanks.
        </p>

        <p>Put Ozone System {ozoneSystemName} into maintenance mode?</p>
      </PromptModal>
    </>
  );
};

const useOzoneSystemModePrompts: (input: Input) => Result = ({
  ozoneSystemName,
  ozoneSystemId,
  onCompleted,
}: Input) => {
  const [automaticModePromptIsOpen, setAutomaticModePromptIsOpen] = useState(
    false
  );

  const [
    maintenanceModePromptIsOpen,
    setMaintenanceModePromptIsOpen,
  ] = useState(false);

  return {
    openOzoneSystemAutomaticModePrompt: useCallback(
      () => setAutomaticModePromptIsOpen(true),
      [setAutomaticModePromptIsOpen]
    ),
    openOzoneSystemMaintenanceModePrompt: useCallback(
      () => setMaintenanceModePromptIsOpen(true),
      [setMaintenanceModePromptIsOpen]
    ),
    OzoneSystemModePrompts,
    ozoneSystemModePromptsProps: {
      ozoneSystemName,
      ozoneSystemId,
      automaticModePromptIsOpen,
      maintenanceModePromptIsOpen,
      onCompleted,
      closeAutomaticModePrompt: useCallback(
        () => setAutomaticModePromptIsOpen(false),
        [setAutomaticModePromptIsOpen]
      ),
      closeMaintenanceModePrompt: useCallback(
        () => setMaintenanceModePromptIsOpen(false),
        [setMaintenanceModePromptIsOpen]
      ),
    },
  };
};

export default useOzoneSystemModePrompts;
