import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useSubscription } from '@apollo/react-hooks';

import StackStatus from '../../components/StackStatus';
import ModalCloseButton from '../../components/ModalCloseButton';
import PageLayout from '../../components/PageLayout';
import PageLoadingContainer from '../../components/PageLoadingContainer';
import PageNotFound from '../../components/PageNotFound';
import FacilityHeading from '../../components/PageLayout/FacilityHeading';
import NamedTank from '../../components/NamedTank';
import CollectorTank from '../../components/CollectorTank';
import Light from '../../components/Light';
import Pipe from '../../components/Pipe';
import Valve from '../../components/Valve';
import LevelValveButton from '../../components/LevelValveButton';
import LevelBeeSensorGauges from './components/LevelBeeSensorGauges';
import LevelCommands from './components/LevelCommands';
import LevelModeSwitch from '../../components/LevelModeSwitch';

import defaultFacilityId from '../../modules/defaultFacilityId';
import history from '../../modules/history';

import StacksImage from '../../assets/images/stacks.svg';

import style from './style.module.scss';
import { Descriptions } from '../../modules/Descriptions';

import {
  getWaterLevel,
  getPumpState,
  getPumpOnOff,
} from '../../modules/string';
import { tempPolling } from '../../modules/temp-map';
import LevelDrainValveButton from '../../components/LevelDrainValveButton';

const zoneFragment = gql`
  fragment LevelPageZone on ZoneType {
    zoneId
    isNewMicrogreen
    name
    tank {
      waterLevel
      gallons
      pumpState
    }
  }
`;

const stackFragment = gql`
  fragment LevelPageStack on StackType {
    stackId
    name
    mode
    waterFlowDetected
    ...StackStatus
  }
  ${StackStatus.fragment}
`;

const levelFragment = gql`
  fragment LevelPageLevel on LevelType {
    levelId
    name
    mode
    hasBeeSensor
    valveState
    ...LevelCommands
    beeSensor {
      serialNumber
      ...LevelBeeSensorGauges
    }
    updateLevelModeCommand {
      canExecute
    }
    updateLevelValveStateCommand {
      canExecute
    }
  }
  ${LevelCommands.fragment}
  ${LevelBeeSensorGauges.fragment}
`;

const QUERY = gql`
  query facility(
    $facilityId: ID!
    $zoneName: String!
    $stackName: String!
    $levelName: String!
  ) {
    facilities {
      ...FacilityHeading
    }

    facility(facilityId: $facilityId) {
      facilityId
      ...FacilityHeading
      zone(zoneName: $zoneName) {
        ...LevelPageZone
        stack(stackName: $stackName) {
          ...LevelPageStack
          tankStatus
          isMicrogreen
          level(levelName: $levelName) {
            ...LevelPageLevel
          }
        }
      }
    }
  }
  ${FacilityHeading.fragment}
  ${zoneFragment}
  ${stackFragment}
  ${levelFragment}
`;

const ZONE_SUBSCRIPTION = gql`
  subscription zoneUpdated(
    $zoneId: ID!
    $stackName: String!
    $levelName: String!
  ) {
    zoneUpdated(zoneId: $zoneId) {
      zone {
        ...LevelPageZone
        stack(stackName: $stackName) {
          ...LevelPageStack
          tankStatus
          isMicrogreen
          level(levelName: $levelName) {
            ...LevelPageLevel
          }
        }
      }
    }
  }
  ${zoneFragment}
  ${stackFragment}
  ${levelFragment}
`;

const ZONE_DIST_TANK_MODE = gql`
  {
    zoneDistributionTankStatuses {
      index
      state
      stateTimeout
    }
  }
`;

const ZONE_SUPPLY_TANK = gql`
  query zoneSupplyTanks($input: ID!) {
    getZoneSupplyTanks(zoneIndex: $input)
  }
`;

const PageContent = ({ facilityId, zone }: { facilityId: any; zone: any }) => {
  const zoneDistTankState = useQuery(ZONE_DIST_TANK_MODE, {
    pollInterval: 30000,
  });
  const { name: zoneName, zoneId, isNewMicrogreen, stack } = zone;
  const { name: stackName, light, level } = stack || {};
  const { isOn: lightIsOn, schedule: lightSchedule } = light || {};
  const { enabled: lightScheduleIsEnabled } = lightSchedule || {};
  const {
    levelId,
    name: levelName,
    mode: levelMode,
    updateLevelModeCommand,
    updateLevelValveStateCommand,
  } = level || {};
  const waterDrainFlowDetected = stack?.waterDrainFlowDetected ?? false;

  const levelIsBeingDrained =
    level.valveState === 'OPEN' && waterDrainFlowDetected;

  const zoneSupplyTankState = useQuery(ZONE_SUPPLY_TANK, {
    variables: {
      input: Number(zoneName) - 1,
    },
    // @ts-ignore
    pollInterval: typeof window === 'undefined' ? null : tempPolling,
    fetchPolicy: 'network-only',
  });

  const [zoneSupplyState, setZoneSupplyState] = useState(null);
  useEffect(() => {
    if (!!zoneSupplyTankState.error || zoneSupplyTankState.loading) return;
    if (
      zoneSupplyTankState.data.getZoneSupplyTanks == null ||
      zoneSupplyTankState.data.getZoneSupplyTanks == ''
    )
      return;
    try {
      setZoneSupplyState(
        JSON.parse(zoneSupplyTankState.data.getZoneSupplyTanks)
      );
    } catch (error) {
      console.log(error);
    }
  }, [zoneSupplyTankState.data]);

  const [distTankMode, setDistTankMode] = useState(null);
  useEffect(() => {
    if (!!zoneDistTankState.error || zoneDistTankState.loading) return;
    if (zoneDistTankState.data?.zoneDistributionTankStatuses != null)
      setDistTankMode(zoneDistTankState.data.zoneDistributionTankStatuses);
  }, [zoneDistTankState.data]);

  useSubscription(ZONE_SUBSCRIPTION, {
    variables: {
      zoneId,
      stackName,
      levelName,
    },
  });

  const stackIsCleaningInPlace = stack?.mode === 'CLEAN_IN_PLACE';
  const levelValveIsOpen = level.valveState === 'OPEN';
  const zonePumpIsOn = zone.tank?.pumpState === 'ON';
  const waterFlowDetected = stack.waterFlowDetected;

  const waterIsFlowingOutOfCollectorTank =
    stack?.collectorTank?.pumpState === 'ON' ||
    zone?.collectorTank?.pumpState === 'ON';

  const levelIsBeingIrrigated =
    levelValveIsOpen &&
    (stackIsCleaningInPlace
      ? waterIsFlowingOutOfCollectorTank
      : zonePumpIsOn && waterFlowDetected);

  // @ts-ignore
  return (
    <div className={style.container}>
      <ModalCloseButton
        className={style.closeButton}
        onClick={() =>
          history.push(
            `/facility/${facilityId}/zone/${zoneName}/stack/${stackName}`
          )
        }
      />
      <div className={style.levelModeContainer}>
        <div className={style.levelModeLabel}>Auto Mode</div>
        <LevelModeSwitch
          id={'level-mode-switch'}
          zoneName={zoneName}
          stackName={stackName}
          levelName={levelName}
          levelId={levelId}
          levelMode={levelMode}
          updateLevelModeCommand={updateLevelModeCommand}
        />
      </div>

      <div
        className={`${style.stackLight} nav-help-tooltip`}
        data-toggle="tooltip"
        data-placement="top"
        title={Descriptions.growLightMode}
      >
        <Light on={lightIsOn} error={!lightScheduleIsEnabled} />
      </div>

      <div className={style.levelContainer}>
        {zoneSupplyState != null &&
        // @ts-ignore
        zoneSupplyState.length > 0 &&
        // @ts-ignore
        zoneSupplyState[0].ZoneStatusSummaries.length > 0 ? (
          <Link to={`/facility/${facilityId}/zone/${zoneName}`}>
            <NamedTank
              dataPlacement="top"
              data-toggle="tooltip"
              data-placement="top"
              title={Descriptions.zoneSupplyTank}
              hasHelp={true}
              entityType="Zone"
              entityName={String(
                // @ts-ignore
                zoneSupplyState[0].ZoneStatusSummaries[0].Zone + 1
              )}
              className={style.zoneTank}
              gallons={zone.tank.gallons}
              biggerTank={true}
              waterLevel={
                // @ts-ignore
                getWaterLevel(zoneSupplyState[0].Tank.WaterLevel)
              }
              pumpState={
                // @ts-ignore
                getPumpOnOff(zoneSupplyState[0].Pump)
              }
              pumpMode={
                // @ts-ignore
                distTankMode?.filter(i => i.index == Number(zone.name) - 1)[0]
              }
              zoneId={zoneId}
            />
          </Link>
        ) : (
          <Link to={`/facility/${facilityId}/zone/${zoneName}`}>
            <NamedTank
              dataPlacement="top"
              data-toggle="tooltip"
              data-placement="top"
              title={Descriptions.zoneSupplyTank}
              hasHelp={true}
              entityType="Zone"
              entityName={zoneName}
              className={style.zoneTank}
              waterLevel={zone.tank.waterLevel}
              gallons={zone.tank.gallons}
              pumpState={zone.tank.pumpState}
              biggerTank={true}
              pumpMode={
                distTankMode == null
                  ? null
                  : //@ts-ignore
                    distTankMode.filter(i => i.index == Number(zoneName) - 1)[0]
              }
              zoneId={zoneId}
            />
          </Link>
        )}

        <Pipe
          className={[
            style.levelPipe,
            style.levelInOutPipe,
            style.levelWaterIn,
          ].join(' ')}
          waterIsFlowing={levelIsBeingIrrigated}
        />

        {updateLevelValveStateCommand.canExecute ? (
          <LevelValveButton
            label=""
            inverted={false}
            hasHelp={true}
            data-toggle="tooltip"
            data-placement="top"
            title={Descriptions.levelValve}
            zoneName={zoneName}
            stackName={stackName}
            levelName={levelName}
            levelId={levelId}
            className={style.levelInValve}
            levelValveState={level.valveState}
            needsAttention={level.valveOpenFailureDetected}
          />
        ) : (
          <Valve
            hasHelp={true}
            data-toggle="tooltip"
            data-placement="top"
            title={Descriptions.levelValve}
            className={style.levelInValve}
            valveState={level.valveState}
            needsAttention={level.valveOpenFailureDetected}
            unknownIconClassName={undefined}
            inverted
          />
        )}

        <Pipe
          className={[
            style.levelPipe,
            style.levelFeederPipe,
            style.levelWaterIn,
          ].join(' ')}
          waterIsFlowing={levelIsBeingIrrigated}
        />

        <div
          className={`${style.level} nav-help-tooltip`}
          data-toggle="tooltip"
          data-placement="right"
          title={Descriptions.levelPlants}
        >
          <img className={style.levelIcon} src={StacksImage} alt="Level" />
        </div>

        {zone.isNewMicrogreen ? (
          <>
            <Pipe
              className={[
                style.levelPipe,
                style.levelFeederPipe,
                style.levelWaterIn,
              ].join(' ')}
              waterIsFlowing={levelIsBeingIrrigated}
            />
            {updateLevelValveStateCommand.canExecute ? (
              <LevelDrainValveButton
                hasHelp={true}
                data-toggle="tooltip"
                data-placement="top"
                title={Descriptions.levelDrainValve}
                zoneName={zone.name}
                stackName={stack.name}
                levelName={level.name}
                levelId={level.levelId}
                className={style.levelInValve}
                levelValveState={level.drainValveState}
                needsAttention={level.drainValveOpenFailureDetected}
              />
            ) : (
              <Valve
                hasHelp={true}
                data-toggle="tooltip"
                data-placement="top"
                title={Descriptions.levelDrainValve}
                className={style.levelInValve}
                valveState={level.drainValveState}
                needsAttention={level.drainValveOpenFailureDetected}
              />
            )}
            <Pipe
              className={[
                style.levelPipe,
                style.levelInOutPipe,
                style.levelWaterOut,
              ].join(' ')}
              waterIsFlowing={levelIsBeingDrained}
            />
          </>
        ) : (
          <>
            <Pipe
              className={style.levelOutPipeLong}
              waterIsFlowing={levelIsBeingIrrigated}
            />
          </>
        )}

        {stack.isMicrogreen && !isNewMicrogreen && (
          <CollectorTank
            dataPlacement="bottom"
            hasHelp={true}
            className={style.stackTank}
            zoneName={zoneName}
            collectorTank={stack.collectorTank}
            tankStatusOverride={stack.tankStatus}
            hidePumpCommands={true}
            sensorsOnRight={true}
            zoneReturnLabelClassName={undefined}
            pumpSwitchProps={undefined}
            label=""
          />
        )}
        {(!stack.isMicrogreen || isNewMicrogreen) && (
          <>
            {stack.collectorTank.zone ? (
              <Link
                to={`/facility/${stack.collectorTank.zone?.facilityId}/zone/${zoneName}`}
              >
                <CollectorTank
                  hasHelp={true}
                  className={style.stackTank}
                  zoneName={zoneName}
                  collectorTank={stack.collectorTank}
                  sensorsOnRight={true}
                  hidePumpCommands={true}
                  zoneReturnLabelClassName={undefined}
                  pumpSwitchProps={undefined}
                />
              </Link>
            ) : (
              <CollectorTank
                hasHelp={true}
                className={style.stackTank}
                zoneName={zoneName}
                collectorTank={stack.collectorTank}
                hidePumpCommands={false}
                sensorsOnRight={true}
                zoneReturnLabelClassName={undefined}
                pumpSwitchProps={undefined}
              />
            )}
          </>
        )}
      </div>

      <LevelCommands
        className={style.levelCommands}
        zoneName={zoneName}
        stackName={stackName}
        level={level}
        sensorData={level.beeSensor}
      />

      {!isNewMicrogreen && (
        <LevelBeeSensorGauges
          zoneName={zoneName}
          stackName={stackName}
          levelName={levelName}
          levelId={levelId}
          beeSensor={level.beeSensor}
          className={style.gaugeContainer}
        />
      )}
    </div>
  );
};

const LevelPage = ({
  facilityId,
  zoneName,
  stackName,
  levelName,
}: {
  facilityId: string;
  zoneName: string;
  stackName: string;
  levelName: string;
}) => {
  const result = useQuery(QUERY, {
    variables: { facilityId, zoneName, stackName, levelName },
  });

  const { data } = result;
  const { facility, facilities } = data || {};
  const { zone } = facility || {};
  const { stack } = zone || {};

  useEffect(() => {
    if (facility) {
      defaultFacilityId.set(facility.facilityId);
    }
  }, [facility]);

  return (
    <PageLayout
      heading={
        <FacilityHeading facility={facility} facilities={facilities || {}} />
      }
      subHeading={
        zone &&
        zone.stack && (
          <span>
            <Link to={`/facility/${facilityId}/zone/${zoneName}`}>
              Zone {zoneName}
              {` `}
            </Link>
            /{` `}
            <span>
              <Link
                to={`/facility/${facilityId}/zone/${zoneName}/stack/${stackName}`}
              >
                Stack {stackName}
              </Link>
              <span> / Level {`${levelName}`}</span>
            </span>
          </span>
        )
      }
    >
      <PageLoadingContainer
        resourceTypeName="Facility"
        result={result}
        resourceExists={!!facility}
        render={() =>
          !zone ? (
            <PageNotFound resourceTypeName={`Zone ${zoneName}`} />
          ) : !stack ? (
            <PageNotFound resourceTypeName={`Stack ${stackName}`} />
          ) : !stack.level ? (
            <PageNotFound resourceTypeName={`Level ${levelName}`} />
          ) : (
            <PageContent facilityId={facilityId} zone={zone} />
          )
        }
      />
    </PageLayout>
  );
};

export default LevelPage;
