import React from 'react';
import {
  faQuestionCircle,
  faThermometerHalf,
  faTint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

import { LevelBeeSensorValues } from '../../modules/bee-sensors';

import useLevelBeeSensorValuesModal from '../../hooks/useLevelBeeSensorHistoryModal';

import style from './style.module.scss';

interface Props {
  sensorValues: LevelBeeSensorValues;
  zoneName: string;
  stackName: string;
  levelName: string;
  levelId: string;
}

const BeeSensorDisplay = ({
  sensorValues,
  zoneName,
  stackName,
  levelName,
  levelId,
}: Props) => {
  const {
    openLevelBeeSensorValuesModal,
    LevelBeeSensorValuesModal,
    levelBeeSensorValuesModalProps,
  } = useLevelBeeSensorValuesModal({
    zoneName,
    stackName,
    levelName,
    levelId,
  });

  const Reading = ({
    value,
    unit,
    icon,
  }: {
    value: string;
    unit: string;
    icon: JSX.Element;
  }) => (
    <Button
      className={style.beeDisplayButton}
      onClick={openLevelBeeSensorValuesModal}
    >
      <div className={style.reading}>
        {icon}
        <p>
          {value ? (
            <>
              {value} <span className={style.unit}>{unit}</span>
            </>
          ) : (
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className={style.unknownValue}
            />
          )}
        </p>
      </div>
    </Button>
  );

  const { tempInF, humidityInPercentRh } = sensorValues || {};

  return (
    <>
      <LevelBeeSensorValuesModal {...levelBeeSensorValuesModalProps} />

      <Reading
        icon={
          <FontAwesomeIcon
            icon={faThermometerHalf}
            className={style.readingIcon}
          />
        }
        value={tempInF}
        unit={'ºF'}
      />

      <Reading
        icon={<FontAwesomeIcon icon={faTint} className={style.readingIcon} />}
        value={humidityInPercentRh}
        unit={'%'}
      />
    </>
  );
};

export default BeeSensorDisplay;
