import React from 'react';

interface ILightProps {
  on: boolean;
  error: boolean;
  onClick?: () => void;
  className?: string;
}

export default ({
  on = true,
  error = false,
  onClick,
  className,
}: ILightProps) => {
  if (!error) {
    return on ? (
      <svg
        className={className}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 5000 2500"
        x="0px"
        y="0px"
        style={{
          clipRule: 'evenodd',
          fill: '#fad63b',
          fillRule: 'evenodd',
          imageRendering: 'auto',
          shapeRendering: 'geometricPrecision',
          textRendering: 'geometricPrecision',
        }}
      >
        <rect id="rect2" x="0" y="-2500" style={{ fill: 'none' }} />
        <g transform="translate(0,-1250)">
          <path d="m 4239,1906 v -230 h 238 c 22,0 40,-18 40,-41 0,-22 -18,-40 -40,-40 H 524 c -23,0 -41,18 -41,40 0,23 18,41 41,41 h 258 v 230 c 0,99 85,180 189,180 h 3079 c 104,0 189,-81 189,-180 z m -2709,650 c 8,-13 30,-15 49,-4 20,12 29,32 22,45 l -333,579 c -8,13 -30,15 -49,4 -20,-11 -29,-31 -22,-45 z m 1993,19 c -7,-13 2,-33 21,-45 20,-11 42,-9 50,4 l 334,578 c 8,13 -1,33 -21,44 -19,11 -41,10 -49,-4 z m -1053,135 c 0,-16 18,-28 40,-28 23,0 41,12 41,28 l 1,667 c 0,15 -18,28 -40,28 -23,0 -41,-12 -41,-28 z" />
        </g>
      </svg>
    ) : (
      <svg
        className={className}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 5000 2500"
        x="0px"
        y="0px"
        id="svg8"
        style={{
          clipRule: 'evenodd',
          fill: error ? '#dc3545' : '#4d4d4d',
          fillRule: 'evenodd',
          imageRendering: 'auto',
          shapeRendering: 'geometricPrecision',
          textRendering: 'geometricPrecision',
        }}
      >
        <rect id="rect2" x="0" y="-2500" style={{ fill: 'none' }} />
        <g transform="translate(0,-1250)" id="g6">
          <path
            d="m 4239,1906 v -230 h 238 c 22,0 40,-18 40,-41 0,-22 -18,-40 -40,-40 H 524 c -23,0 -41,18 -41,40 0,23 18,41 41,41 h 258 v 230 c 0,99 85,180 189,180 h 3079 c 104,0 189,-81 189,-180 z"
            id="path4"
          />
        </g>
      </svg>
    );
  } else {
    return on ? (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50">
        <path
          d="M84.78,13.12V8.52h4.76a.81.81,0,0,0,0-1.62H10.48a.81.81,0,1,0,0,1.62h5.16v4.6a3.7035,3.7035,0,0,0,3.78,3.6H81A3.7035,3.7035,0,0,0,84.78,13.12Zm-54.18,13a.7332.7332,0,0,1,.98-.08c.4.24.58.64.44.9L25.36,38.52a.7332.7332,0,0,1-.98.08.7408.7408,0,0,1-.44-.9Zm39.86.38c-.14-.26.04-.66.42-.9a.756.756,0,0,1,1,.08l6.68,11.56c.16.26-.02.66-.42.88-.38.22-.82.2-.98-.08ZM49.4,29.2c0-.32.36-.56.8-.56.46,0,.82.24.82.56l.02,13.34c0,.3-.36.56-.8.56-.46,0-.82-.24-.82-.56Z"
          style={{ fill: '#fad63b', fillRule: 'evenodd' }}
        />
        <path
          d="M89.54,6.9a.81.81,0,0,1,0,1.62H84.78v4.6A3.7035,3.7035,0,0,1,81,16.72H19.42a3.7035,3.7035,0,0,1-3.78-3.6V8.52H10.48a.81.81,0,1,1,0-1.62H89.54M71.4015,25.4572a.5392.5392,0,0,1,.4785.2228l6.68,11.56c.16.26-.02.66-.42.88a1.0554,1.0554,0,0,1-.5184.1494A.5052.5052,0,0,1,77.16,38.04L70.46,26.5c-.14-.26.04-.66.42-.9a1.0988,1.0988,0,0,1,.5215-.1428m-40.3266.44a1.0224,1.0224,0,0,1,.5051.1428c.4.24.58.64.44.9L25.36,38.52a.5367.5367,0,0,1-.4749.2228A1.0224,1.0224,0,0,1,24.38,38.6a.7408.7408,0,0,1-.44-.9L30.6,26.12a.5367.5367,0,0,1,.4749-.2228M50.2,28.64c.46,0,.82.24.82.56l.02,13.34c0,.3-.36.56-.8.56-.46,0-.82-.24-.82-.56L49.4,29.2c0-.32.36-.56.8-.56M89.54,4.9H10.48a2.81,2.81,0,1,0,0,5.62h3.16v2.6a5.6986,5.6986,0,0,0,5.78,5.6H81a5.6986,5.6986,0,0,0,5.78-5.6v-2.6h2.76a2.81,2.81,0,0,0,0-5.62ZM71.4015,23.4572a3.096,3.096,0,0,0-1.4854.39l-.053.0291-.0511.0323a2.702,2.702,0,0,0-1.1129,3.5392l.0152.0282.0161.0278,6.7,11.54a2.4816,2.4816,0,0,0,2.1912,1.2252,3.0444,3.0444,0,0,0,1.52-.4185,2.8812,2.8812,0,0,0,1.3919-1.7551,2.42,2.42,0,0,0-.2505-1.8707L73.6117,24.6793l-.0139-.0239-.0145-.0236a2.5247,2.5247,0,0,0-2.1818-1.1746Zm-40.3265.44h0a2.5216,2.5216,0,0,0-2.1781,1.1746l-.0156.0253-.0148.0258-6.66,11.58-.0291.05-.0261.0522a2.7133,2.7133,0,0,0,1.2651,3.5468,2.97,2.97,0,0,0,1.4689.39,2.5214,2.5214,0,0,0,2.1782-1.1746l.0156-.0253.0148-.0258,6.66-11.58.014-.0243.0132-.0246A2.6872,2.6872,0,0,0,32.609,24.325a3.0449,3.0449,0,0,0-1.534-.4278ZM50.2,26.64a2.6594,2.6594,0,0,0-2.8,2.56l.02,13.343a2.8224,2.8224,0,0,0,5.62-.003l-.02-13.343A2.67,2.67,0,0,0,50.2,26.64Z"
          style={{ fill: '#dc3545' }}
        />

        <rect y="-50" width="100" height="100" style={{ fill: 'none' }} />
        <path
          d="M84.78,13.12V8.52h4.76a.81.81,0,0,0,0-1.62H10.48a.81.81,0,1,0,0,1.62h5.16v4.6a3.7035,3.7035,0,0,0,3.78,3.6H81A3.7035,3.7035,0,0,0,84.78,13.12Zm-54.18,13a.7332.7332,0,0,1,.98-.08c.4.24.58.64.44.9L25.36,38.52a.7332.7332,0,0,1-.98.08.7408.7408,0,0,1-.44-.9Zm39.86.38c-.14-.26.04-.66.42-.9a.756.756,0,0,1,1,.08l6.68,11.56c.16.26-.02.66-.42.88-.38.22-.82.2-.98-.08ZM49.4,29.2c0-.32.36-.56.8-.56.46,0,.82.24.82.56l.02,13.34c0,.3-.36.56-.8.56-.46,0-.82-.24-.82-.56Z"
          style={{ fill: '#fad63b', fillRule: 'evenodd' }}
        />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50">
        <rect y="-50" width="100" height="100" style={{ fill: 'none' }} />
        <g>
          <path
            d="M19.42,17.72a4.6977,4.6977,0,0,1-4.78-4.6V9.52H10.48a1.81,1.81,0,1,1,0-3.62H89.54a1.81,1.81,0,0,1,0,3.62H85.78v3.6A4.6982,4.6982,0,0,1,81,17.72Z"
            style={{ fill: '#4d4d4d', fillRule: 'evenodd' }}
          />
          <path
            d="M89.54,6.9a.81.81,0,0,1,0,1.62H84.78v4.6A3.7035,3.7035,0,0,1,81,16.72H19.42a3.7035,3.7035,0,0,1-3.78-3.6V8.52H10.48a.81.81,0,1,1,0-1.62H89.54m0-2H10.48a2.81,2.81,0,1,0,0,5.62h3.16v2.6a5.6986,5.6986,0,0,0,5.78,5.6H81a5.6986,5.6986,0,0,0,5.78-5.6v-2.6h2.76a2.81,2.81,0,0,0,0-5.62Z"
            style={{ fill: '#dc3545' }}
          />
        </g>
      </svg>
    );
  }
};
