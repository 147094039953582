import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  zoneId: string;
  onCompleted?: () => void;
}

interface Result {
  openActivateBypassValveStatePrompt: () => void;
  openDeactivateBypassValveStatePrompt: () => void;
  OzoneZoneBypassValveStatePrompts: typeof OzoneZoneBypassValveStatePrompts;
  ozoneZoneBypassValveStatePromptsProps: Props;
}

const UPDATE_OZONE_SYSTEM_ZONE_BYPASS_VALVE_STATE = gql`
  mutation updateOzoneSystemZoneBypassValveState(
    $input: UpdateOzoneSystemZoneBypassValveStateInputType!
  ) {
    updateOzoneSystemZoneBypassValveState(input: $input) {
      bypassValve {
        state
        ... on OzonePerZoneControlZoneBypassValveType {
          mode
        }
      }
    }
  }
`;

interface Props extends Input {
  deactivateBypassValvePromptIsOpen: boolean;
  activateBypassValvePromptIsOpen: boolean;
  closeDeactivateBypassPrompt: () => void;
  closeActivateBypassValvePrompt: () => void;
}

const OzoneZoneBypassValveStatePrompts = ({
  zoneName,
  zoneId,
  activateBypassValvePromptIsOpen,
  deactivateBypassValvePromptIsOpen,
  closeActivateBypassValvePrompt,
  closeDeactivateBypassPrompt,
  onCompleted,
}: Props) => {
  const [
    updateOzoneZoneBypassValveState,
    { error, loading: saving },
  ] = useMutation(UPDATE_OZONE_SYSTEM_ZONE_BYPASS_VALVE_STATE, {
    onCompleted: () => {
      closeActivateBypassValvePrompt();
      closeDeactivateBypassPrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <>
      <PromptModal
        title={`Activate the Ozone System Bypass for Zone ${zoneName}`}
        isOpen={activateBypassValvePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneZoneBypassValveState({
            variables: {
              input: { zoneId, state: 'BYPASS_ACTIVE' },
            },
          });
        }}
        onComplete={closeActivateBypassValvePrompt}
      >
        <p>
          Activating the ozone system bypass valve will cause water being
          flushed from the return tank to flow back into the zone tank.
        </p>

        <p>Active the ozone system bypass for Zone {zoneName}?</p>
      </PromptModal>

      <PromptModal
        title={`Deactivate the Ozone System Bypass for Zone ${zoneName}`}
        danger={true}
        isOpen={deactivateBypassValvePromptIsOpen}
        saving={saving}
        error={error}
        onSave={() => {
          updateOzoneZoneBypassValveState({
            variables: {
              input: {
                zoneId,
                state: 'BYPASS_INACTIVE',
              },
            },
          });
        }}
        onComplete={closeDeactivateBypassPrompt}
      >
        <p>
          Deactivating the ozone system bypass will cause water being flushed
          from the return tank to flow into the ozone tank.
        </p>

        <p>
          WARNING: Deactivating the ozone system bypass may cause the ozone tank
          to overflow.
        </p>

        <p>Deactivate the ozone system bypass for Zone {zoneName}?</p>
      </PromptModal>
    </>
  );
};

const useOzoneZoneBypassValveStatePrompts: (input: Input) => Result = ({
  zoneName,
  zoneId,
  onCompleted,
}: Input) => {
  const [
    activateBypassValvePromptIsOpen,
    setActivateBypassValvePromptIsOpen,
  ] = useState(false);

  const [
    deactivateBypassValvePromptIsOpen,
    setDeactivateBypassValvePromptIsOpen,
  ] = useState(false);

  return {
    openActivateBypassValveStatePrompt: useCallback(
      () => setActivateBypassValvePromptIsOpen(true),
      [setActivateBypassValvePromptIsOpen]
    ),
    openDeactivateBypassValveStatePrompt: useCallback(
      () => setDeactivateBypassValvePromptIsOpen(true),
      [setDeactivateBypassValvePromptIsOpen]
    ),
    OzoneZoneBypassValveStatePrompts,
    ozoneZoneBypassValveStatePromptsProps: {
      zoneName,
      zoneId,
      activateBypassValvePromptIsOpen,
      deactivateBypassValvePromptIsOpen,
      onCompleted,
      closeActivateBypassValvePrompt: useCallback(
        () => setActivateBypassValvePromptIsOpen(false),
        [setActivateBypassValvePromptIsOpen]
      ),
      closeDeactivateBypassPrompt: useCallback(
        () => setDeactivateBypassValvePromptIsOpen(false),
        [setDeactivateBypassValvePromptIsOpen]
      ),
    },
  };
};

export default useOzoneZoneBypassValveStatePrompts;
