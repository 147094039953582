import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import { SaveModal, SaveModalProps } from '../SaveModal';

import style from './style.module.scss';

interface PromptModalProps
  extends Omit<SaveModalProps, 'title' | 'saveButtonContent'> {
  title?: string;
  danger?: boolean;
}

export default ({
  className,
  title = 'Are You Sure?',
  isOpen,
  saving,
  onSave,
  error,
  onComplete,
  danger = false,
  children,
  ...props
}: PromptModalProps) => {
  return (
    <SaveModal
      className={[style.modal, className].join(' ')}
      title={
        <div className={style.titleContainer}>
          <div>
            <FontAwesomeIcon
              className={style.icon}
              icon={danger ? faExclamationTriangle : faQuestionCircle}
              fixedWidth={true}
            />
          </div>
          {title}
        </div>
      }
      isOpen={isOpen}
      saving={saving}
      onSave={onSave}
      error={error}
      onComplete={onComplete}
      saveButtonContent="OK"
      cancelButtonContent="Cancel"
      {...props}
    >
      {children}
    </SaveModal>
  );
};
