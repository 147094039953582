const getBorderStatusClass = ticketStatusSummary => {
  switch (ticketStatusSummary) {
    case 'NONE':
      return '-secondary border';
    case 'VERIFY':
      return '-success';
    case 'ACTIVE':
      return '-warning';
    default:
      return '-danger';
  }
};

export { getBorderStatusClass };
