/*
  A form and form hook for providing a user with the means to read and modify stack schedules.
 */

import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';

import { SaveModal, SaveModalProps } from '../../components/SaveModal';
import ScheduleLine from './components/ScheduleLine';

/* Definitions */

export interface LevelSchedule {
  rowIndex: number;
  days: number;
  timesPerDay: number;
  duration: number;
}

export interface TimeSpan {
  start: string;
  end: string;
}

interface Result {
  openModifyScheduleStackPrompt: (e: number) => void;
  ModifyScheduleStackPrompt: typeof ModifyStackSchedulePrompt;
  modifyScheduleStackPromptProps: Props;
}

interface Input {
  zoneId: number;
  onCompleted?: () => void;
}

interface Props extends Input {
  stackId: number;
  modifyStackSchedulePromptIsOpen: boolean;
  closeModifyScheduleStackPrompt: () => void;
}

/* Global Methods */

//Constructor for LevelSchedule.
export const newStackSchedule: (i: number) => LevelSchedule = i => {
  return {
    duration: 2, //Minutes
    timesPerDay: 999,
    days: 1,
    rowIndex: i,
  };
};

export const allocStackSchedule: () => LevelSchedule = () => {
  return {
    duration: 0, //Minutes
    timesPerDay: 0,
    days: 0,
    rowIndex: -1,
  };
};

//Clone constructor for LevelSchedule.
export const buildStackSchedule: (i: number, data: any) => LevelSchedule = (
  i,
  data
) => {
  return {
    duration: data.durationSeconds === 0 ? 0 : data.durationSeconds / 60,
    timesPerDay: data.timesPerDay,
    days: data.everyNDays,
    rowIndex: data.rowIndex,
  };
};

//An interface for sorting LevelSchedule.
export const stackScheduleComparable: (
  a: LevelSchedule,
  b: LevelSchedule
) => number = (a, b) => a.rowIndex - b.rowIndex;

//An interface for LevelSchedule comparisons.
export const isEqual: (self: LevelSchedule, other: LevelSchedule) => boolean = (
  self,
  other
) => {
  return (
    Number(self.rowIndex) === Number(other.rowIndex) &&
    Number(self.days) === Number(other.days) &&
    Number(self.timesPerDay) === Number(other.timesPerDay) &&
    Number(self.duration / 60) === Number(other.duration)
  );
};

/* GQL Queries */

const GET_STACK_SCHEDULE = gql`
  query readSchedules($stackId: ID!) {
    stackIrrigationSchedule(stackId: $stackId) {
      stackScheduleId
      levelSchedules {
        rowIndex
        durationSeconds
        everyNDays
        timesPerDay
      }
      timeSpan {
        start
        end
      }
      stackId
    }
    max: maxLevelsInStack(stackId: $stackId)
  }
`;

const SET_STACK_SCHEDULES = gql`
  mutation writeSchedules($input: timeSpan!) {
    scheduleStack(input: $input) {
      __typename
    }
  }
`;

/* React Components */

const ModifyStackSchedulePrompt = ({
  stackId,
  zoneId,
  modifyStackSchedulePromptIsOpen,
  closeModifyScheduleStackPrompt,
  onCompleted,
}: Props) => {
  //GQL hooks.
  const [
    setStackSchedules,
    { error: setStackSchedulesError, loading: setStackSchedulesLoading },
  ] = useMutation(SET_STACK_SCHEDULES);

  const existingSchedulesStateHook = useQuery(GET_STACK_SCHEDULE, {
    variables: { stackId },
  });

  //Local schedules state and effect for dialog toggle.
  const [schedules, setSchedules] = useState<LevelSchedule[]>([]);
  const [batchMarshal, setBatchMarshal] = useState<LevelSchedule>(
    newStackSchedule(0)
  );
  const [hideLevels, setHideLevels] = useState<boolean>(true);
  const [timeSpanStart, setTimeSpanStart] = useState<string>('00:00');
  const [timeSpanEnd, setTimeSpanEnd] = useState<string>('23:59');

  useEffect(() => {
    if (!modifyStackSchedulePromptIsOpen) return;
    setSchedules([]);
    existingSchedulesStateHook.refetch({ stackId });
  }, [modifyStackSchedulePromptIsOpen]);

  useEffect(() => {}, [batchMarshal]);

  useEffect(() => {
    if (
      existingSchedulesStateHook.error ||
      existingSchedulesStateHook.data == null
    )
      return;
    let next: LevelSchedule[] = [];

    const serverState =
      existingSchedulesStateHook.data?.stackIrrigationSchedule?.levelSchedules;
    if (serverState != null && serverState.length > 0) {
      next = serverState.map((i: any, index: number) =>
        buildStackSchedule(index, i)
      );

      next.sort(stackScheduleComparable);
      setSchedules(() => [...next]);
    } else if (existingSchedulesStateHook.data?.max != null) {
      // @ts-ignore
      let indexes = [...Array(existingSchedulesStateHook.data.max).keys()];
      let next = indexes.map((i: any) => newStackSchedule(i));
      next.sort(stackScheduleComparable);
      setSchedules(() => [...next]);
    }

    if (
      existingSchedulesStateHook.data?.stackIrrigationSchedule?.timeSpan != null
    ) {
      setTimeSpanStart(
        existingSchedulesStateHook.data.stackIrrigationSchedule.timeSpan.start
      );
      setTimeSpanEnd(
        existingSchedulesStateHook.data.stackIrrigationSchedule.timeSpan.end
      );
    }
  }, [existingSchedulesStateHook.data]);

  const toggleHideLevels = useCallback(
    e => {
      setHideLevels(e);
    },
    [hideLevels]
  );

  //Update schedule callback.
  const updateSchedule = (newState: LevelSchedule) => {
    //Match new state to current state by index.
    for (let i = 0; i < schedules.length; i++) {
      if (schedules[i].rowIndex != newState.rowIndex) continue;

      //Update by index, sort, and update current state.
      schedules[i] = newState;
      schedules.sort(stackScheduleComparable);
      setSchedules([...schedules]);

      //Short circuit once the index match is made.
      break;
    }
  };

  //Update all schedules callback
  const updateAllSchedules = (newState: LevelSchedule) => {
    //Stop React bouncing.
    if (stackId == 0) {
      return;
    }

    //Match new state to current state by index.
    for (let i = 0; i < schedules.length; i++) {
      if (Number(newState.days) > 0) schedules[i].days = Number(newState.days);
      if (Number(newState.duration) > 0)
        schedules[i].duration = Number(newState.duration);
      if (Number(newState.timesPerDay) > 0)
        schedules[i].timesPerDay = Number(newState.timesPerDay);
    }

    schedules.sort(stackScheduleComparable);
    setBatchMarshal(newState);
    setSchedules(schedules);
  };

  const saveStackSchedules = () => {
    //Stop React bouncing.
    if (stackId == 0) {
      return;
    }

    //Set schedules where modifications have been made or a server state does not exist.
    setStackSchedules({
      variables: {
        input: {
          zoneId: Number(zoneId),
          stackId: Number(stackId),
          levelIrrigationParameters: schedules.map(i => {
            return hideLevels
              ? {
                  index: Number(i.rowIndex),
                  durationSeconds: Number(schedules[0].duration) * 60,
                  everyNDays: Number(schedules[0].days),
                  timesPerDay: Number(schedules[0].timesPerDay),
                }
              : {
                  index: Number(i.rowIndex),
                  durationSeconds: Number(i.duration) * 60,
                  everyNDays: Number(i.days),
                  timesPerDay: Number(i.timesPerDay),
                };
          }),
          timeSpan: {
            id: 0,
            stackId: Number(stackId),
            start: timeSpanStart,
            end: timeSpanEnd,
          },
        },
      },
    }).then(() => existingSchedulesStateHook.refetch({ stackId }));

    //Close and call parent.
    closeModifyScheduleStackPrompt();
    onCompleted && onCompleted();
  };

  const startTimeCallback = useCallback(
    e => {
      const next = e.target.value;
      setTimeSpanStart(prev => next);
    },
    [timeSpanStart]
  );

  const endTimeCallback = useCallback(
    e => {
      const next = e.target.value;
      setTimeSpanEnd(prev => next);
    },
    [timeSpanEnd]
  );

  return (
    <SaveModal
      title={`Stack Schedule`}
      isOpen={modifyStackSchedulePromptIsOpen}
      saving={false}
      error={undefined}
      onSave={saveStackSchedules}
      onComplete={() => {
        setHideLevels(false);
        setTimeSpanStart('00:00');
        setTimeSpanEnd('23:59');
        setBatchMarshal(allocStackSchedule());
        closeModifyScheduleStackPrompt();
      }}
      saveButtonContent="Save"
      isFormValid={true}
      className="my-5"
    >
      {existingSchedulesStateHook.loading && <p>loading</p>}
      <div className="row pb-2">
        <div className="col-12 col-sm-6 px-3 py-2">
          <input
            type="time"
            className="form-control"
            value={timeSpanStart}
            onChange={startTimeCallback}
          />
        </div>
        <div className="col-12 col-sm-6 px-3 py-2">
          <input
            type="time"
            className="form-control"
            value={timeSpanEnd}
            onChange={endTimeCallback}
          />
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-12 col-sm-2"></div>
        <div className="col-12 col-sm-3 text-center">Every</div>
        <div className="col-12 col-sm-3 text-center">Times per Day</div>
        <div className="col-12 col-sm-4 text-center">Duration</div>
      </div>
      {schedules != null &&
        schedules.map((context, i) => (
          <div key={i}>
            {(i === 0 || !hideLevels) && (
              <ScheduleLine
                schedule={i === 0 ? batchMarshal : context}
                onChange={updateSchedule}
                label={null}
                hasToggle={i === 0}
                onToggle={toggleHideLevels}
              />
            )}
          </div>
        ))}
    </SaveModal>
  );
};

/* React Hooks */

const useModifyMicrogreensForm: (input: Input) => Result = ({
  zoneId,
  onCompleted,
}: Input) => {
  const [
    modifyStackSchedulePromptIsOpen,
    setModifyStackSchedulePromptIsOpen,
  ] = useState(false);

  //Create state for required stack parameter to open the form.
  const [stack, setStack] = useState<number>(0);

  return {
    openModifyScheduleStackPrompt: useCallback(
      (e: number) => {
        setStack(e);
        setModifyStackSchedulePromptIsOpen(true);
      },
      [
        setModifyStackSchedulePromptIsOpen,
        modifyStackSchedulePromptIsOpen,
        setStack,
        stack,
      ]
    ),
    ModifyScheduleStackPrompt: ModifyStackSchedulePrompt,
    modifyScheduleStackPromptProps: {
      stackId: stack,
      zoneId,
      modifyStackSchedulePromptIsOpen: modifyStackSchedulePromptIsOpen,
      onCompleted,
      closeModifyScheduleStackPrompt: useCallback(
        () => setModifyStackSchedulePromptIsOpen(false),
        [modifyStackSchedulePromptIsOpen]
      ),
    },
  };
};

export default useModifyMicrogreensForm;
