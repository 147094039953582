import React from 'react';
import { Input, Label } from 'reactstrap';

import { getInputValueAnd } from '../../modules/form-helpers';
import style from './style.module.scss';

interface Facility {
  facilityId: string;
  name: string;
}

interface FacilitySelection {
  facilityId: string;
  selected: boolean;
}

interface Props {
  id?: string;
  className?: string;
  selectedFacilityIds: Array<string>;
  availableFacilities: Array<Facility>;
  isAdministratorRole: boolean;
  invalid?: boolean;
  onFacilityIdSelectionChange?: (selection: FacilitySelection) => void;
}

export default ({
  id,
  className,
  isAdministratorRole,
  selectedFacilityIds,
  availableFacilities,
  onFacilityIdSelectionChange,
  invalid,
}: Props) => {
  return (
    <div
      id={id}
      className={[
        'form-control',
        isAdministratorRole ? 'disabled' : null,
        invalid ? 'is-invalid' : null,
        style.container,
        className,
      ].join(' ')}
    >
      {availableFacilities?.map(({ facilityId, name }) => (
        <Label key={facilityId} check>
          <Input
            type="checkbox"
            disabled={isAdministratorRole}
            checked={
              isAdministratorRole || selectedFacilityIds.includes(facilityId)
            }
            onChange={getInputValueAnd(
              (value: boolean) =>
                onFacilityIdSelectionChange &&
                onFacilityIdSelectionChange({
                  facilityId,
                  selected: value,
                })
            )}
          />{' '}
          {name}
        </Label>
      ))}
    </div>
  );
};
