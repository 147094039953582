import React from 'react';

import Pump from '../Pump';
import UnknownValueOverlay from '../UnknownValueOverlay';

import style from './style.module.scss';

import { Button } from 'reactstrap';

const PumpButton = ({
  className,
  unknownIconClassName,
  pumpState,
  disabled,
  onClick,
  title = '',
  manualMode = true,
  hasHelp = false,
}) => {
  const pumpOn = pumpState === 'ON';
  const hasUnknownValue = pumpState !== 'ON' && pumpState !== 'OFF';
  return (
    <UnknownValueOverlay
      hasHelp={hasHelp}
      hasUnknownValue={hasUnknownValue}
      className={[
        style.container,
        hasUnknownValue ? style.unknown : null,
        className,
      ].join(' ')}
      iconClassName={[style.unknownValueOverlayIcon, unknownIconClassName].join(
        ' '
      )}
      title={title}
    >
      <Button
        title={title}
        disabled={disabled}
        className={[
          pumpOn ? style.on : style.off,
          manualMode ? style.manualMode : style.automaticMode,
        ].join(' ')}
        onClick={onClick}
      >
        <Pump
          title={title}
          className={style.pump}
          unknownIconClassName={style.pumpUnknownIcon}
          pumpState={pumpState}
          pumpMode={null}
          onForcePumpResume={() => {}}
        />
      </Button>
    </UnknownValueOverlay>
  );
};

export default PumpButton;
