import gql from 'graphql-tag';

export const FRAGMENT_ZONE_COLLECTOR = gql`
  fragment CalibrateZoneCollectorTankForm on ZoneType {
    zoneId
    collectorTank {
      autoCalibrated
      calibrationPoints
    }
  }
`;
