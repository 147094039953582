import React from 'react';
import { Link, useLocation, Switch, Route, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faUser,
  faUsers,
  faUserTag,
  faCogs,
} from '@fortawesome/free-solid-svg-icons';

import history from '../../modules/history';

import PageLayout from '../../components/PageLayout';
import PageLoadingContainer from '../../components/PageLoadingContainer';
import ProfilePage from './components/ProfilePage';
import UsersPage from './components/UsersPage';
import FacilityConfigPage from './components/FacilityConfigPage';
import MenuButton from '../../components/MenuButton';
import FacilityIcon from '../../components/FacilityIcon';
import GeneralPage from './components/GralPage';

import style from './style.module.scss';
import RolesPage from './components/RolesPage';

const Heading = () => {
  return (
    <div className={style.heading}>
      <FontAwesomeIcon className={style.icon} icon={faCog} fixedWidth={true} />
      <span>Settings</span>
    </div>
  );
};

const Title = ({ title, icon: Icon }) => {
  return (
    <div className={style.titleAndIconContainer}>
      <span className={style.titleIcon}>
        <Icon />
      </span>

      <span className={style.title}>{title}</span>
    </div>
  );
};

const pages = [
  {
    title: 'Profile',
    icon: () => <FontAwesomeIcon icon={faUser} fixedWidth={true} />,
    pathname: '/settings/profile',
    component: ProfilePage,
  },
  {
    title: 'Users',
    icon: () => <FontAwesomeIcon icon={faUsers} fixedWidth={true} />,
    pathname: '/settings/users',
    component: UsersPage,
    isAllowedQueryFragmentName: `UserManagement`,
    isAllowedQueryFragment: gql`
      fragment UserManagement on SettingsType {
        userManagement {
          __typename
        }
      }
    `,
    isAllowed: settings => settings.userManagement,
  },
  {
    title: 'Roles',
    icon: () => <FontAwesomeIcon icon={faUserTag} fixedWidth={true} />,
    pathname: '/settings/roles',
    component: RolesPage,
    isAllowedQueryFragmentName: `RoleManagement`,
    isAllowedQueryFragment: gql`
      fragment RoleManagement on SettingsType {
        roleManagement {
          __typename
        }
      }
    `,
    isAllowed: settings => settings.roleManagement,
  },
  {
    title: 'Facility Config',
    icon: () => <FacilityIcon />,
    pathname: '/settings/facility',
    allowPathParams: true,
    component: FacilityConfigPage,
    isAllowedQueryFragmentName: `FacilityConfiguration`,
    isAllowedQueryFragment: gql`
      fragment FacilityConfiguration on SettingsType {
        facilityConfiguration {
          __typename
        }
      }
    `,
    isAllowed: settings => settings.facilityConfiguration,
  },
  {
    title: 'General Config',
    icon: () => <FontAwesomeIcon icon={faCogs} fixedWidth={true} />,
    pathname: '/settings/GralPage',
    component: GeneralPage,
  },
];

const SETTINGS_QUERY = gql`
  query {
    settings {
      __typename
      ${pages
        .map(page => page.isAllowedQueryFragmentName)
        .filter(x => x)
        .map(x => `...${x}`)
        .join('\n')}
    }
  }
  ${pages
    .map(page => page.isAllowedQueryFragment)
    .filter(x => x)
    .reduce(
      (acc, fragment) => gql`
        ${acc}
        ${fragment}
      `,
      ''
    )}
`;

const pageIsAllowed = settings => page =>
  page.isAllowed === undefined || page.isAllowed(settings);

export default () => {
  const result = useQuery(SETTINGS_QUERY);
  const location = useLocation();
  const { state } = location;
  const from =
    state && state.from && !state.from.startsWith('/settings/')
      ? location.state.from
      : '/facility';

  const { data } = result;
  const { settings } = data ? data : {};

  return (
    <PageLayout heading={<Heading />} autoScaleContent={false}>
      <PageLoadingContainer
        resourceTypeName="Settings"
        result={result}
        resourceExists={!!settings}
        render={() => (
          <div className={style.container}>
            <div className={style.navigation}>
              {pages.filter(pageIsAllowed(settings)).map(page =>
                location.pathname.startsWith(page.pathname) ? (
                  <div
                    key={page.pathname}
                    className={`${style.navigationItem} ${style.active}`}
                  >
                    <Title title={page.title} icon={page.icon} />
                  </div>
                ) : (
                  <Link
                    key={page.pathname}
                    className={style.navigationItem}
                    to={{ pathname: page.pathname, state: { from } }}
                  >
                    <Title title={page.title} icon={page.icon} />
                  </Link>
                )
              )}
            </div>

            <div className={style.content}>
              <Switch>
                {pages.filter(pageIsAllowed(settings)).map(page => (
                  <Route
                    key={page.pathname}
                    exact={!page.allowPathParams}
                    path={page.pathname}
                    render={() => {
                      return (
                        <>
                          <div className={style.titleContainer}>
                            <h4 className={style.title}>{page.title}</h4>

                            <MenuButton
                              className={style.closeButton}
                              isOpen={true}
                              onClick={() => history.push(from)}
                            />
                          </div>

                          <page.component />
                        </>
                      );
                    }}
                  />
                ))}

                <Redirect
                  to={{ pathname: pages[0].pathname, state: { from } }}
                />
              </Switch>
            </div>
          </div>
        )}
      />
    </PageLayout>
  );
};
