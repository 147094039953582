import React, { useCallback, useMemo } from 'react';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import {
  FormGroup,
  Input as FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { isEmpty, keysIn, pick, valuesIn } from 'lodash';

import { SaveModal } from '../../components/SaveModal';
import ModalTitle from '../../components/ModalTitle';
import ModalLoadingContainer from '../../components/ModalLoadingContainer';

import { getInputValueAnd, updateState } from '../../modules/form-helpers';
import { getValidationErrors } from '../../modules/errors';
import {
  LevelBeeSensorMeasurements,
  LevelBeeSensorValues,
} from '../../modules/bee-sensors';

import useModalVisible from '../useModalVisible';
import { UPDATE_LEVEL_SENSOR_CONFIGURATION } from '../useLevelAddSensorForm';

import style from './style.module.scss';

interface Input {
  stackName: string;
  levelName: string;
  levelId: string;
  onCompleted?: () => void;
  sensorData: any;
}

interface Result {
  openLevelCalibrateSensorForm: () => void;
  LevelCalibrateSensorForm: typeof LevelCalibrateSensorForm;
  levelCalibrateSensorFormProps: Props;
}

const LEVEL_QUERY = gql`
  query level($levelId: ID!) {
    level(levelId: $levelId) {
      levelId
      beeSensor {
        serialNumber
        currentValue {
          ...LevelBeeSensorRawValues
        }
        ...LevelBeeSensorCalibrationOffsets
      }
    }
  }
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorCurrentRawValue}
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorCalibrationOffsets}
`;

interface Props extends Input {
  levelCalibrateSensorFormIsOpen: boolean;
  closeLevelCalibrateSensorForm: () => void;
}

const createBlankDesiredValues: () => LevelBeeSensorValues = () => ({
  tempInF: '',
  humidityInPercentRh: '',
  pressureInHPa: '',
  co2InPpm: '',
});

const createUpdateBeeSensorThresholdsInput = (
  levelId: string,
  serialNumber: string,
  currentValue: LevelBeeSensorValues | null,
  desiredValues: LevelBeeSensorValues
) => {
  const {
    tempInF: currentTempInF,
    humidityInPercentRh: currentHumidityInPercentRh,
    pressureInHPa: currentPressureInHPa,
    co2InPpm: currentCo2InPpm,
  } = currentValue || {
    tempInF: '0.0',
    humidityInPercentRh: '0.0',
    pressureInHPa: '0.0',
    co2InPpm: '0.0',
  };

  const {
    tempInF: desiredTempInF,
    humidityInPercentRh: desiredHumidityInPercentRh,
    pressureInHPa: desiredPressureInHPa,
    co2InPpm: desiredCo2InPpm,
  } = desiredValues;

  const calibrationOffsets = {
    tempCalibrationOffsetInF: !!desiredTempInF
      ? parseFloat(desiredTempInF) - parseFloat(currentTempInF)
      : 0.0,
    humidityCalibrationOffsetInPercentRh: !!desiredHumidityInPercentRh
      ? parseFloat(desiredHumidityInPercentRh) -
        parseFloat(currentHumidityInPercentRh)
      : 0.0,
    pressureCalibrationOffsetInHPa: !!desiredPressureInHPa
      ? parseFloat(desiredPressureInHPa) - parseFloat(currentPressureInHPa)
      : 0.0,
    cO2CalibrationOffsetInPpm: !!desiredCo2InPpm
      ? parseFloat(desiredCo2InPpm) - parseFloat(currentCo2InPpm)
      : 0.0,
  };

  const configuration = {
    serialNumber,
    ...calibrationOffsets,
  };

  return {
    levelId,
    configuration,
  };
};

const LevelCalibrateSensorForm = ({
  stackName,
  levelName,
  levelId,
  levelCalibrateSensorFormIsOpen,
  closeLevelCalibrateSensorForm,
  sensorData,
  onCompleted,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();

  const result = useQuery(LEVEL_QUERY, {
    variables: { levelId },
    skip: !visible,
  });

  const { data, loading, refetch } = result;
  const level = data?.level || null;
  const beeSensor = level?.beeSensor || null;
  const { serialNumber, currentValue: rawValues } = beeSensor || {};

  const [
    updateLevelBeeSensorConfiguration,
    { error: saveError, loading: saving },
  ] = useMutation(UPDATE_LEVEL_SENSOR_CONFIGURATION, {
    onCompleted: () => {
      onCompleted && onCompleted();
    },
  });

  const [desiredValues, setDesiredValues] = useState(
    createBlankDesiredValues()
  );

  const [saveAttempted, setSaveAttempted] = useState(false);
  const [wasOpen, setWasOpen] = useState(false);

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  useEffect(() => {
    const reopened = levelCalibrateSensorFormIsOpen && wasOpen;
    if (reopened) {
      refetch();
    }
    setWasOpen(levelCalibrateSensorFormIsOpen);
  }, [levelCalibrateSensorFormIsOpen, refetch, wasOpen, setWasOpen]);

  useEffect(() => {
    if (beeSensor?.currentValue) {
      if (sensorData?.currentValue && valuesIn(desiredValues).every(isEmpty)) {
        setDesiredValues({
          ...(pick(
            sensorData?.currentValue,
            keysIn(desiredValues)
          ) as LevelBeeSensorValues),
        });
      }
    } else {
      if (!valuesIn(desiredValues).every(isEmpty)) {
        setDesiredValues(createBlankDesiredValues());
      }
    }
  }, [beeSensor, desiredValues, sensorData?.currentValue]);

  const validationErrors: any = {
    ...LevelBeeSensorMeasurements.all.reduce(
      (acc, measurement) => ({
        ...acc,
        ...(isEmpty(measurement.valueSelector(desiredValues)) &&
        !!measurement.valueSelector(sensorData?.currentValue)
          ? {
              [measurement.valueKey]: `${measurement.label} desired value must be specified.`,
            }
          : {}),
      }),
      {}
    ),
    ...(!isDirty &&
      saveAttempted &&
      saveError &&
      getValidationErrors(saveError)),
  };

  const isFormValid = !loading && isEmpty(validationErrors);

  return (
    <SaveModal
      className={style.modal}
      scrollable
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faCog} fixedWidth />}
          title={`Calibrate Stack ${stackName} / Level ${levelName} Sensor`}
        />
      }
      isOpen={levelCalibrateSensorFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      onComplete={closeLevelCalibrateSensorForm}
      onSave={() => {
        setIsDirty(false);
        setSaveAttempted(true);
        updateLevelBeeSensorConfiguration({
          variables: {
            input: createUpdateBeeSensorThresholdsInput(
              levelId,
              serialNumber,
              sensorData?.currentValue,
              desiredValues
            ),
          },
        });
        closeLevelCalibrateSensorForm();
      }}
      {...modalVisibleProps}
    >
      <ModalLoadingContainer
        className={style.loadingContainer}
        resourceTypeName="Level"
        result={result}
        resourceExists={!!level}
      >
        <div className={style.calibrationValuesContainer}>
          {LevelBeeSensorMeasurements.all.map(measurement => {
            const currentValueId = `${measurement.label}-current-id`;
            const desiredValueId = `${measurement.label}-desired-id`;
            const measurementExists = !!measurement.valueSelector(
              sensorData?.currentValue
            );

            return (
              <div
                className={style.calibrationValueContainer}
                key={measurement.label}
              >
                <div
                  className={style.label}
                  style={{
                    ['--metric-color' as any]: measurement.color,
                  }}
                >
                  {!measurementExists && (
                    <div className={style.unknownValue}>
                      <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                    </div>
                  )}
                  <div>{measurement.label}</div>

                  <div className={style.metricColor}></div>

                  <div className={style.unit}>{measurement.unit}</div>
                </div>
                <FormGroup>
                  <Label for={currentValueId}>Raw Reading</Label>

                  <InputGroup>
                    <FormInput
                      id={currentValueId}
                      type="number"
                      readOnly
                      value={
                        measurement.valueSelector(sensorData?.currentValue) ??
                        ''
                      }
                    />

                    <InputGroupAddon addonType="append">
                      <InputGroupText>{`${measurement.unit}`}</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <Label for={desiredValueId}>Should Be</Label>

                  <InputGroup>
                    <FormInput
                      id={desiredValueId}
                      type="number"
                      value={measurement.valueSelector(desiredValues) ?? ''}
                      invalid={
                        !!validationErrors[measurement.valueKey] ||
                        Number.isNaN(desiredValues.tempInF) ||
                        Number.isNaN(desiredValues.humidityInPercentRh) ||
                        Number.isNaN(desiredValues.pressureInHPa) ||
                        Number.isNaN(desiredValues.co2InPpm)
                      }
                      disabled={!measurementExists}
                      onChange={getInputValueAnd(
                        updateState({
                          valueState: [
                            measurement.valueSelector(desiredValues),
                            value => {
                              setDesiredValues({
                                ...desiredValues,
                                [measurement.valueKey]: value ?? '',
                              });
                            },
                          ],
                          isDirtyState,
                        })
                      )}
                    />

                    <InputGroupAddon addonType="append">
                      <InputGroupText>{`${measurement.unit}`}</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </div>
            );
          })}
        </div>
      </ModalLoadingContainer>
    </SaveModal>
  );
};

const useLevelCalibrateSensorForm: (input: Input) => Result = ({
  stackName,
  levelName,
  levelId,
  onCompleted,
  sensorData,
}) => {
  const [
    levelCalibrateSensorFormIsOpen,
    setLevelCalibrateSensorFormIsOpen,
  ] = useState(false);

  return {
    openLevelCalibrateSensorForm: useCallback(
      () => setLevelCalibrateSensorFormIsOpen(true),
      [setLevelCalibrateSensorFormIsOpen]
    ),
    LevelCalibrateSensorForm,
    levelCalibrateSensorFormProps: {
      stackName,
      levelName,
      levelId,
      levelCalibrateSensorFormIsOpen,
      sensorData,
      onCompleted,
      closeLevelCalibrateSensorForm: useCallback(
        () => setLevelCalibrateSensorFormIsOpen(false),
        [setLevelCalibrateSensorFormIsOpen]
      ),
    },
  };
};

export default useLevelCalibrateSensorForm;
