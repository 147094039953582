import React, { useState, useEffect, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Input as FormInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import CleanInPlaceIcon from '../../components/CleanInPlaceIcon';

import ModalTitle from '../../components/ModalTitle';
import { SaveModal } from '../../components/SaveModal';

import { getValidationErrors } from '../../modules/errors';
import {
  getInputValueAnd,
  convertValue,
  updateState,
} from '../../modules/form-helpers';

import style from './style.module.scss';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackCleanInPlaceForm: () => void;
  StackCleanInPlaceForm: typeof StackCleanInPlaceForm;
  stackCleanInPlaceFormProps: Props;
}

const PERFORM_STACK_CLEAN_IN_PLACE = gql`
  mutation performStackCleanInPlace(
    $input: PerformStackCleanInPlaceInputType!
  ) {
    performStackCleanInPlace(input: $input) {
      stack {
        stackId
        mode
      }
    }
  }
`;

const CONFIRMATION_TEXT = 'CLEAN';

interface Props extends Input {
  stackCleanInPlaceFormIsOpen: boolean;
  closeStackCleanInPlaceForm: () => void;
}

const StackCleanInPlaceForm = ({
  zoneName,
  stackName,
  stackId,
  stackCleanInPlaceFormIsOpen,
  closeStackCleanInPlaceForm,
  onCompleted,
}: Props) => {
  const [
    performStackCleanInPlace,
    { error: saveError, loading: saving },
  ] = useMutation(PERFORM_STACK_CLEAN_IN_PLACE, {
    onCompleted: () => {
      closeStackCleanInPlaceForm();
      onCompleted && onCompleted();
    },
  });

  const [wasOpen, setWasOpen] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const validationErrors: { secondsPerValve?: string } = {
    ...(!isDirty &&
      saveAttempted &&
      saveError &&
      getValidationErrors(saveError)),
  };

  const minutesPerValveState = useState(5);
  const [minutesPerValve, ,] = minutesPerValveState;

  const confirmationState = useState('');
  const [confirmation, setConfirmation] = confirmationState;

  const isFormValid =
    minutesPerValve >= 1 &&
    confirmation.toUpperCase() === CONFIRMATION_TEXT.toUpperCase();

  useEffect(() => {
    const reopened = stackCleanInPlaceFormIsOpen && !wasOpen;

    if (reopened) {
      setSaveAttempted(false);
      setConfirmation('');
    }

    setWasOpen(stackCleanInPlaceFormIsOpen);
  }, [stackCleanInPlaceFormIsOpen, wasOpen, setWasOpen, setConfirmation]);

  return (
    <SaveModal
      className={style.modal}
      title={
        <ModalTitle
          icon={<CleanInPlaceIcon className={style.titleIcon} />}
          title={`Clean Zone ${zoneName} / Stack ${stackName} In Place`}
        />
      }
      isOpen={stackCleanInPlaceFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      onComplete={closeStackCleanInPlaceForm}
      saveButtonContent="OK"
      onSave={() => {
        setIsDirty(false);
        setSaveAttempted(true);
        return performStackCleanInPlace({
          variables: {
            input: {
              stackId,
              secondsPerValve: minutesPerValve * 60,
            },
          },
        });
      }}
    >
      <div className={style.modalContentContainer}>
        <div className={style.inputSection}>
          <span>Clean each level for</span>

          <FormInput
            id="secondsPerValve"
            className={style.secondsPerValveInput}
            type="number"
            step="any"
            min="1"
            required
            value={minutesPerValve}
            onChange={getInputValueAnd(
              convertValue(
                parseInt,
                updateState({
                  valueState: minutesPerValveState,
                  isDirtyState,
                })
              )
            )}
            invalid={!!validationErrors.secondsPerValve}
          />

          <span>
            minute
            <span
              style={{
                visibility: minutesPerValve === 1 ? 'hidden' : undefined,
              }}
            >
              s
            </span>
          </span>
        </div>

        <div className={style.inputSection}>
          <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth size="lg" />

          <span>
            Due to its disruptive nature, confirm that you would like to perform
            the operation by typing "{CONFIRMATION_TEXT}" below.
          </span>
        </div>

        <div className={style.inputSection}>
          <FormInput
            type="text"
            required
            value={confirmation}
            onChange={getInputValueAnd(
              updateState({ valueState: confirmationState, isDirtyState })
            )}
          />
        </div>
      </div>
    </SaveModal>
  );
};

const useStackCleanInPlaceForm: (input: Input) => Result = ({
  zoneName,
  stackName,
  stackId,
  onCompleted,
}) => {
  const [
    stackCleanInPlaceFormIsOpen,
    setStackCleanInPlaceFormIsOpen,
  ] = useState(false);

  return {
    openStackCleanInPlaceForm: useCallback(
      () => setStackCleanInPlaceFormIsOpen(true),
      [setStackCleanInPlaceFormIsOpen]
    ),
    StackCleanInPlaceForm,
    stackCleanInPlaceFormProps: {
      zoneName,
      stackName,
      stackId,
      stackCleanInPlaceFormIsOpen,
      onCompleted,
      closeStackCleanInPlaceForm: useCallback(
        () => setStackCleanInPlaceFormIsOpen(false),
        [setStackCleanInPlaceFormIsOpen]
      ),
    },
  };
};

export default useStackCleanInPlaceForm;
