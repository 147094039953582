import React, { useState, useCallback, useEffect } from 'react';
import { useQuery, useSubscription, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { isEmpty, valuesIn, pick, keysIn } from 'lodash';
import { Label, Input as FormInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

import {
  NutrientMixerMeasurements,
  NutrientMixerThresholds,
} from '../../modules/nutrient-mixer';
import { getValidationErrors } from '../../modules/errors';
import { getInputValueAnd, updateState } from '../../modules/form-helpers';

import useModalVisible from '../useModalVisible';

import ModalLoadingContainer from '../../components/ModalLoadingContainer';
import Gauge from '../../components/Gauge';
import ModalTitle from '../../components/ModalTitle';
import { SaveModal } from '../../components/SaveModal';

import style from './style.module.scss';

interface Input {
  zoneName: string;
  zoneId: string;
  onCompleted?: () => void;
}

interface Result {
  openZoneNutrientMixerConfigureThresholdsForm: () => void;
  ZoneNutrientMixerConfigureThresholdsForm: typeof ZoneNutrientMixerConfigureThresholdsForm;
  zoneNutrientMixerConfigureThresholdsFormProps: Props;
}

const UPDATE_NUTRIENT_MIXER_THRESHOLDS = gql`
  mutation updateNutrientMixerThresholds(
    $input: UpdateNutrientMixerThresholdsInputType!
  ) {
    updateNutrientMixerThresholds(input: $input) {
      zone {
        zoneId
        nutrientMixer {
          thresholds {
            ...NutrientMixerThresholds
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
  ${NutrientMixerMeasurements.fragments.NutrientMixerThresholds}
`;

const zoneFragment = gql`
  fragment ZoneNutrientMixerConfigureThresholdsForm on ZoneType {
    zoneId
    nutrientMixer {
      currentValue {
        ...NutrientMixerCurrentValue
      }
    }
  }
  ${NutrientMixerMeasurements.fragments.NutrientMixerCurrentValue}
`;

const ZONE_QUERY = gql`
  query zone($zoneId: ID!) {
    zone(zoneId: $zoneId) {
      ...ZoneNutrientMixerConfigureThresholdsForm
      nutrientMixer {
        thresholds {
          ...NutrientMixerThresholds
        }
      }
    }
  }
  ${zoneFragment}
  ${NutrientMixerMeasurements.fragments.NutrientMixerThresholds}
`;

const ZONE_SUBSCRIPTION = gql`
  subscription zoneUpdated($zoneId: ID!) {
    zoneUpdated(zoneId: $zoneId) {
      zone {
        ...ZoneNutrientMixerConfigureThresholdsForm
      }
    }
  }
  ${zoneFragment}
`;

const createBlankThresholds: () => NutrientMixerThresholds = () => ({
  doHighAlertInMgPerL: '',
  doHighInMgPerL: '',
  doLowAlertInMgPerL: '',
  doLowInMgPerL: '',
  ecHighAlertInUsPerCm: '',
  ecHighInUsPerCm: '',
  ecLowAlertInUsPerCm: '',
  ecLowInUsPerCm: '',
  orpHighAlertInMv: '',
  orpHighInMv: '',
  orpLowAlertInMv: '',
  orpLowInMv: '',
  phHigh: '',
  phHighAlert: '',
  phLow: '',
  phLowAlert: '',
  tempHighAlertInF: '',
  tempHighInF: '',
  tempLowAlertInF: '',
  tempLowInF: '',
});
const parseFloatNaN = (value: string, defaultValue: number = 0) => {
  let parsed = Number.parseFloat(value);
  if (Number.isNaN(parsed) || parsed <= 0) return defaultValue;
  else return parsed;
};
const fahrenheitToCelsius = (value: number) => (value - 32) / 1.8;

const createUpdateNutrientMixerThresholdsInput = (
  zoneId: string,
  thresholds: NutrientMixerThresholds
) => {
  const {
    doHighAlertInMgPerL,
    doHighInMgPerL,
    doLowAlertInMgPerL,
    doLowInMgPerL,
    ecHighAlertInUsPerCm,
    ecHighInUsPerCm,
    ecLowAlertInUsPerCm,
    ecLowInUsPerCm,
    orpHighAlertInMv,
    orpHighInMv,
    orpLowAlertInMv,
    orpLowInMv,
    phHigh,
    phHighAlert,
    phLow,
    phLowAlert,
    tempHighAlertInF,
    tempHighInF,
    tempLowAlertInF,
    tempLowInF,
  } = thresholds;

  return {
    zoneId,
    doHighAlertInMgPerL: parseFloatNaN(doHighAlertInMgPerL, 25),
    doHighInMgPerL: parseFloatNaN(doHighInMgPerL, 12),
    doLowInMgPerL: parseFloatNaN(doLowInMgPerL, 3),
    doLowAlertInMgPerL: parseFloatNaN(doLowAlertInMgPerL, 2),
    ecHighAlertInUsPerCm: parseFloatNaN(ecHighAlertInUsPerCm, 2200),
    ecHighInUsPerCm: parseFloatNaN(ecHighInUsPerCm, 1900),
    ecLowInUsPerCm: parseFloatNaN(ecLowInUsPerCm, 1400),
    ecLowAlertInUsPerCm: parseFloatNaN(ecLowAlertInUsPerCm, 1200),
    orpHighAlertInMv: parseFloatNaN(orpHighAlertInMv, 700),
    orpHighInMv: parseFloatNaN(orpHighInMv, 650),
    orpLowInMv: parseFloatNaN(orpLowInMv, 200),
    orpLowAlertInMv: parseFloatNaN(orpLowAlertInMv, 120),
    phHighAlert: parseFloatNaN(phHighAlert, 8),
    phHigh: parseFloatNaN(phHigh, 5.9),
    phLow: parseFloatNaN(phLow, 5.6),
    phLowAlert: parseFloatNaN(phLowAlert, 5),
    tempHighAlertInC: fahrenheitToCelsius(parseFloatNaN(tempHighAlertInF, 80)),
    tempHighInC: fahrenheitToCelsius(parseFloatNaN(tempHighInF, 75)),
    tempLowInC: fahrenheitToCelsius(parseFloatNaN(tempLowInF, 70)),
    tempLowAlertInC: fahrenheitToCelsius(parseFloatNaN(tempLowAlertInF, 65)),
  };
};

interface Props extends Input {
  zoneNutrientMixerConfigureThresholdsFormIsOpen: boolean;
  closeZoneNutrientMixerConfigureThresholdsForm: () => void;
}

const ZoneNutrientMixerConfigureThresholdsForm = ({
  zoneName,
  zoneId,
  zoneNutrientMixerConfigureThresholdsFormIsOpen,
  closeZoneNutrientMixerConfigureThresholdsForm,
  onCompleted,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();

  const result = useQuery(ZONE_QUERY, {
    variables: { zoneId },
    skip: !visible,
  });

  useSubscription(ZONE_SUBSCRIPTION, {
    variables: { zoneId },
    skip: !visible,
  });

  const [
    updateNutrientMixerThresholds,
    { error: saveError, loading: saving },
  ] = useMutation(UPDATE_NUTRIENT_MIXER_THRESHOLDS, {
    onCompleted: () => {
      closeZoneNutrientMixerConfigureThresholdsForm();
      onCompleted && onCompleted();
    },
  });

  const { refetch } = result;
  const [wasOpen, setWasOpen] = useState(false);
  const [thresholds, setThresholds] = useState(createBlankThresholds());

  useEffect(() => {
    const reopened = zoneNutrientMixerConfigureThresholdsFormIsOpen && !wasOpen;

    if (reopened) {
      refetch();
    }

    setWasOpen(zoneNutrientMixerConfigureThresholdsFormIsOpen);
  }, [
    zoneNutrientMixerConfigureThresholdsFormIsOpen,
    refetch,
    wasOpen,
    setWasOpen,
  ]);

  const { data, loading } = result;
  const zone = data?.zone || null;
  const nutrientMixer = zone?.nutrientMixer || null;
  const currentValue = nutrientMixer?.currentValue || null;

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;
  const [saveAttempted, setSaveAttempted] = useState(false);

  useEffect(() => {
    if (nutrientMixer) {
      if (nutrientMixer?.thresholds && valuesIn(thresholds).every(isEmpty)) {
        setThresholds({
          ...(pick(
            nutrientMixer.thresholds,
            keysIn(thresholds)
          ) as NutrientMixerThresholds),
        });
      }
    } else {
      if (!valuesIn(thresholds).every(isEmpty)) {
        setThresholds(createBlankThresholds());
      }
    }
  }, [nutrientMixer, thresholds]);

  const validationErrors: any = {
    ...NutrientMixerMeasurements.all.reduce(
      (acc, measurement) => ({
        ...acc,
        ...(isEmpty(thresholds[measurement.lowAlertKey])
          ? {
              [measurement.lowAlertKey]: `${measurement.label} low alert value must be specified.`,
            }
          : {}),

        ...(parseFloatNaN(thresholds[measurement.lowAlertKey]) >=
        parseFloatNaN(thresholds[measurement.lowKey])
          ? {
              [measurement.lowKey]: `${measurement.label} low alert value cannot be greater than or equal to the low value.`,
              [measurement.lowAlertKey]: `${measurement.label} low alert value cannot be greater than or equal to the low value.`,
            }
          : {}),

        ...(isEmpty(thresholds[measurement.lowKey])
          ? {
              [measurement.lowKey]: `${measurement.label} low value must be specified.`,
            }
          : {}),

        ...(parseFloatNaN(thresholds[measurement.lowKey]) >=
        parseFloatNaN(thresholds[measurement.highKey])
          ? {
              [measurement.lowKey]: `${measurement.label} low value cannot be greater than or equal to the high value.`,
              [measurement.highKey]: `${measurement.label} low value cannot be greater than or equal to the high value.`,
            }
          : {}),

        ...(isEmpty(thresholds[measurement.highKey])
          ? {
              [measurement.highKey]: `${measurement.label} high value must be specified.`,
            }
          : {}),

        ...(parseFloatNaN(thresholds[measurement.highKey]) >=
        parseFloatNaN(thresholds[measurement.highAlertKey])
          ? {
              [measurement.highKey]: `${measurement.label} high value cannot be greater than or equal to the high alert value.`,
              [measurement.highAlertKey]: `${measurement.label} high value cannot be greater than or equal to the high alert value.`,
            }
          : {}),

        ...(isEmpty(thresholds[measurement.highAlertKey])
          ? {
              [measurement.highAlertKey]: `${measurement.label} high alert value must be specified.`,
            }
          : {}),
      }),
      {}
    ),

    ...(!isDirty &&
      saveAttempted &&
      saveError &&
      getValidationErrors(saveError)),
  };

  const isFormValid = !loading && isEmpty(validationErrors);

  return (
    <SaveModal
      className={style.modal}
      scrollable
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faCogs} />}
          title={`Configure Zone ${zoneName} Nutrient Mixer Thresholds`}
        />
      }
      isOpen={zoneNutrientMixerConfigureThresholdsFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      onComplete={closeZoneNutrientMixerConfigureThresholdsForm}
      onSave={() => {
        setIsDirty(false);
        setSaveAttempted(true);
        updateNutrientMixerThresholds({
          variables: {
            input: createUpdateNutrientMixerThresholdsInput(zoneId, thresholds),
          },
        });
      }}
      {...modalVisibleProps}
    >
      <ModalLoadingContainer
        className={style.measurementsContainer}
        resourceTypeName="Zone"
        result={result}
        resourceExists={!!zone}
      >
        <>
          {NutrientMixerMeasurements.all.map(measurement => {
            const lowAlertId = `${measurement.label}-low-alert`;
            const lowId = `${measurement.label}-low`;
            const highId = `${measurement.label}-high`;
            const highAlertId = `${measurement.label}-high-alert`;

            return (
              <div
                className={style.measurementContainer}
                key={measurement.label}
              >
                <Gauge
                  className={style.gauge}
                  label={measurement.label}
                  color={measurement.color}
                  unit={measurement.unit}
                  value={measurement.valueSelector(currentValue)}
                  lowCritical={measurement.lowAlertValueSelector(thresholds)}
                  low={measurement.lowValueSelector(thresholds)}
                  high={measurement.highValueSelector(thresholds)}
                  highCritical={measurement.highAlertValueSelector(thresholds)}
                />

                <div className={style.measurementThresholds}>
                  <div className={style.measurementThreshold}>
                    <Label for={lowAlertId}>Alert Low</Label>

                    <FormInput
                      id={lowAlertId}
                      type="number"
                      value={measurement.lowAlertValueSelector(thresholds)}
                      invalid={!!validationErrors[measurement.lowAlertKey]}
                      onChange={getInputValueAnd(
                        updateState({
                          valueState: [
                            measurement.lowAlertValueSelector(thresholds),
                            value => {
                              const threshold =
                                value === undefined
                                  ? Number.NaN
                                  : parseFloatNaN(value);

                              !Number.isNaN(threshold) &&
                                setThresholds(
                                  measurement.withLowAlertValue(
                                    thresholds,
                                    threshold
                                  )
                                );
                            },
                          ],
                          isDirtyState,
                        })
                      )}
                    />
                  </div>

                  <div className={style.measurementThreshold}>
                    <Label for={lowId}>Low</Label>

                    <FormInput
                      id={lowId}
                      type="number"
                      value={measurement.lowValueSelector(thresholds)}
                      invalid={!!validationErrors[measurement.lowKey]}
                      onChange={getInputValueAnd(
                        updateState({
                          valueState: [
                            measurement.lowValueSelector(thresholds),
                            value => {
                              const threshold =
                                value === undefined
                                  ? Number.NaN
                                  : parseFloatNaN(value);

                              !Number.isNaN(threshold) &&
                                setThresholds(
                                  measurement.withLowValue(
                                    thresholds,
                                    threshold
                                  )
                                );
                            },
                          ],
                          isDirtyState,
                        })
                      )}
                    />
                  </div>

                  <div className={style.measurementThreshold}>
                    <Label for={highId}>High</Label>

                    <FormInput
                      id={highId}
                      type="number"
                      value={measurement.highValueSelector(thresholds)}
                      invalid={!!validationErrors[measurement.highKey]}
                      onChange={getInputValueAnd(
                        updateState({
                          valueState: [
                            measurement.highValueSelector(thresholds),
                            value => {
                              const threshold =
                                value === undefined
                                  ? Number.NaN
                                  : parseFloatNaN(value);

                              !Number.isNaN(threshold) &&
                                setThresholds(
                                  measurement.withHighValue(
                                    thresholds,
                                    threshold
                                  )
                                );
                            },
                          ],
                          isDirtyState,
                        })
                      )}
                    />
                  </div>

                  <div className={style.measurementThreshold}>
                    <Label for={highAlertId}>Alert High</Label>

                    <FormInput
                      id={highAlertId}
                      type="number"
                      value={measurement.highAlertValueSelector(thresholds)}
                      invalid={!!validationErrors[measurement.highAlertKey]}
                      onChange={getInputValueAnd(
                        updateState({
                          valueState: [
                            measurement.highAlertValueSelector(thresholds),
                            value => {
                              const threshold =
                                value === undefined
                                  ? Number.NaN
                                  : parseFloatNaN(value);

                              !Number.isNaN(threshold) &&
                                setThresholds(
                                  measurement.withHighAlertValue(
                                    thresholds,
                                    threshold
                                  )
                                );
                            },
                          ],
                          isDirtyState,
                        })
                      )}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </ModalLoadingContainer>
    </SaveModal>
  );
};

const useZoneNutrientMixerConfigureThresholdsForm: (input: Input) => Result = ({
  zoneName,
  zoneId,
  onCompleted,
}) => {
  const [
    zoneNutrientMixerConfigureThresholdsFormIsOpen,
    setZoneNutrientMixerConfigureThresholdsFormIsOpen,
  ] = useState(false);

  return {
    openZoneNutrientMixerConfigureThresholdsForm: useCallback(
      () => setZoneNutrientMixerConfigureThresholdsFormIsOpen(true),
      [setZoneNutrientMixerConfigureThresholdsFormIsOpen]
    ),
    ZoneNutrientMixerConfigureThresholdsForm,
    zoneNutrientMixerConfigureThresholdsFormProps: {
      zoneName,
      zoneId,
      zoneNutrientMixerConfigureThresholdsFormIsOpen,
      onCompleted,
      closeZoneNutrientMixerConfigureThresholdsForm: useCallback(
        () => setZoneNutrientMixerConfigureThresholdsFormIsOpen(false),
        [setZoneNutrientMixerConfigureThresholdsFormIsOpen]
      ),
    },
  };
};

export default useZoneNutrientMixerConfigureThresholdsForm;
