import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

export interface NutrientMixerValues {
  doInMgPerL: string;
  ecInUsPerCm: string;
  orpInMv: string;
  ph: string;
  tempInF: string;
}

export interface NutrientMixerThresholds {
  doHighAlertInMgPerL: string;
  doHighInMgPerL: string;
  doLowAlertInMgPerL: string;
  doLowInMgPerL: string;
  ecHighAlertInUsPerCm: string;
  ecHighInUsPerCm: string;
  ecLowAlertInUsPerCm: string;
  ecLowInUsPerCm: string;
  orpHighAlertInMv: string;
  orpHighInMv: string;
  orpLowAlertInMv: string;
  orpLowInMv: string;
  phHigh: string;
  phHighAlert: string;
  phLow: string;
  phLowAlert: string;
  tempHighAlertInF: string;
  tempHighInF: string;
  tempLowAlertInF: string;
  tempLowInF: string;
}

class NutrientMixerMeasurement {
  label: string;
  unit?: string;
  color: string;
  valueKey: keyof NutrientMixerValues;
  lowAlertKey: keyof NutrientMixerThresholds;
  lowKey: keyof NutrientMixerThresholds;
  highKey: keyof NutrientMixerThresholds;
  highAlertKey: keyof NutrientMixerThresholds;

  constructor({
    label,
    unit,
    color,
    valueKey,
    lowAlertKey,
    lowKey,
    highKey,
    highAlertKey,
  }: {
    label: string;
    unit?: string;
    color: string;
    valueKey: keyof NutrientMixerValues;
    lowAlertKey: keyof NutrientMixerThresholds;
    lowKey: keyof NutrientMixerThresholds;
    highKey: keyof NutrientMixerThresholds;
    highAlertKey: keyof NutrientMixerThresholds;
  }) {
    this.label = label;
    this.unit = unit;
    this.color = color;
    this.valueKey = valueKey;
    this.lowAlertKey = lowAlertKey;
    this.lowKey = lowKey;
    this.highKey = highKey;
    this.highAlertKey = highAlertKey;
  }

  valueSelector = (values?: NutrientMixerValues) =>
    values && values[this.valueKey];

  lowAlertValueSelector = (thresholds?: NutrientMixerThresholds) =>
    thresholds && thresholds[this.lowAlertKey];

  withLowAlertValue = (thresholds: NutrientMixerThresholds, value: number) => ({
    ...thresholds,
    [this.lowAlertKey]: value.toString(),
  });

  lowValueSelector = (thresholds?: NutrientMixerThresholds) =>
    thresholds && thresholds[this.lowKey];

  withLowValue = (thresholds: NutrientMixerThresholds, value: number) => ({
    ...thresholds,
    [this.lowKey]: value.toString(),
  });

  highValueSelector = (thresholds?: NutrientMixerThresholds) =>
    thresholds && thresholds[this.highKey];

  withHighValue = (thresholds: NutrientMixerThresholds, value: number) => ({
    ...thresholds,
    [this.highKey]: value.toString(),
  });

  highAlertValueSelector = (thresholds?: NutrientMixerThresholds) =>
    thresholds && thresholds[this.highAlertKey];

  withHighAlertValue = (
    thresholds: NutrientMixerThresholds,
    value: number
  ) => ({ ...thresholds, [this.highAlertKey]: value.toString() });
}

export const NutrientMixerMeasurementColors: {
  ph: string;
  orp: string;
  ec: string;
  do: string;
  temp: string;
} = {
  ph: '#0e4d92',
  orp: '#ac3541',
  ec: '#3f704d',
  do: '#f9a602',
  temp: 'grey',
};

export const NutrientMixerMeasurements: {
  all: Array<NutrientMixerMeasurement>;
  fragments: {
    NutrientMixerCurrentValue: DocumentNode;
    NutrientMixerAggregatedValues: DocumentNode;
    NutrientMixerThresholds: DocumentNode;
  };
} = {
  all: [
    new NutrientMixerMeasurement({
      label: 'pH',
      unit: undefined,
      color: NutrientMixerMeasurementColors.ph,
      valueKey: 'ph',
      lowAlertKey: 'phLowAlert',
      lowKey: 'phLow',
      highKey: 'phHigh',
      highAlertKey: 'phHighAlert',
    }),

    new NutrientMixerMeasurement({
      label: 'ORP',
      unit: 'mV',
      color: NutrientMixerMeasurementColors.orp,
      valueKey: 'orpInMv',
      lowAlertKey: 'orpLowAlertInMv',
      lowKey: 'orpLowInMv',
      highKey: 'orpHighInMv',
      highAlertKey: 'orpHighAlertInMv',
    }),
  ],

  fragments: {
    NutrientMixerCurrentValue: gql`
      fragment NutrientMixerCurrentValue on NutrientMixerTelemetryEventType {
        orpInMv
        ph
      }
    `,
    NutrientMixerAggregatedValues: gql`
      fragment NutrientMixerAggregatedValues on NutrientMixerAggregatedDataType {
        minimum {
          orpInMv
          ph
        }
        average {
          orpInMv
          ph
        }
        maximum {
          orpInMv
          ph
        }
      }
    `,
    NutrientMixerThresholds: gql`
      fragment NutrientMixerThresholds on NutrientMixerThresholdsType {
        phLow
        phLowAlert
        phHigh
        phHighAlert
        orpLowInMv
        orpLowAlertInMv
        orpHighInMv
        orpHighAlertInMv
        ecLowInUsPerCm
        ecLowAlertInUsPerCm
        ecHighInUsPerCm
        ecHighAlertInUsPerCm
        doLowInMgPerL
        doLowAlertInMgPerL
        doHighInMgPerL
        doHighAlertInMgPerL
        tempLowInC
        tempLowAlertInC
        tempHighInC
        tempHighAlertInC
      }
    `,
  },
};
