import React from 'react';

import style from './style.module.scss';

interface Props extends React.HTMLAttributes<HTMLElement> {
  isOpen?: boolean;
}

export default ({ className, isOpen, onClick }: Props) => {
  return (
    <span
      className={[style.menuBtn, isOpen ? style.open : null, className].join(
        ' '
      )}
      onClick={onClick}
    >
      <span className={style.menuBtnIcon}></span>
    </span>
  );
};
