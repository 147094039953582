import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import qs from 'qs';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'reactstrap';

import { updateState } from '../../modules/form-helpers';

import LoginForm from '../../components/LoginForm';
import Loading from '../../components/Loading';
import ResultAlert from '../../components/ResultAlert';
import ErrorMessages from '../../components/ErrorMessages';
import NewPasswordFormGroup from '../../components/NewPasswordFormGroup';

import style from './style.module.scss';

const VALIDATE_RESET_PASSWORD_CODE = gql`
  mutation validateResetPasswordCode($code: String!) {
    validateResetPasswordCode(code: $code) {
      code
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword($code: String!, $newPassword: String!) {
    resetPassword(code: $code, newPassword: $newPassword) {
      code
    }
  }
`;

export default ({ location }) => {
  const newPasswordState = useState('');
  const [newPassword] = newPasswordState;

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const code =
    qs.parse(location && location.search, { ignoreQueryPrefix: true }).code ||
    '';

  const [
    validateResetPasswordCode,
    {
      data: validateResetPasswordCodeData,
      error: validateResetPasswordCodeError,
      loading: validateResetPasswordCodeLoading,
    },
  ] = useMutation(VALIDATE_RESET_PASSWORD_CODE);

  const [
    resetPassword,
    {
      data: resetPasswordData,
      error: resetPasswordError,
      loading: resetPasswordLoading,
    },
  ] = useMutation(RESET_PASSWORD);

  useEffect(() => {
    validateResetPasswordCode({ variables: { code } });
  }, [validateResetPasswordCode, code]);

  const codeIsValid =
    !validateResetPasswordCodeError && validateResetPasswordCodeData;

  const formIsValid = !!newPassword;
  const resetPasswordSucceeded = !resetPasswordError && resetPasswordData;

  return (
    <LoginForm
      onSubmit={e => {
        setIsDirty(false);
        resetPassword({
          variables: { code, newPassword },
        });
      }}
    >
      {resetPasswordSucceeded && (
        <div className={style.standaloneAlertContainer}>
          <ResultAlert className={style.alert} success={true}>
            You have successfully reset your password. Click the link below to
            login.
          </ResultAlert>

          <Link to="/login">Go back to login</Link>
        </div>
      )}

      {validateResetPasswordCodeLoading && <Loading />}

      {!validateResetPasswordCodeLoading && validateResetPasswordCodeError && (
        <div className={style.standaloneAlertContainer}>
          <ResultAlert className={style.alert} success={false}>
            <ErrorMessages error={validateResetPasswordCodeError} />
          </ResultAlert>

          <Link to="/login">Go back to login</Link>
        </div>
      )}

      {codeIsValid && !resetPasswordSucceeded && (
        <div>
          <h3 className={style.title}>Reset Password</h3>
          <fieldset disabled={resetPasswordLoading}>
            <NewPasswordFormGroup
              onPasswordChanged={updateState({
                isDirtyState,
                valueState: newPasswordState,
              })}
            />
          </fieldset>

          {resetPasswordError && !isDirty && (
            <ResultAlert className={style.alert} success={false}>
              <ErrorMessages error={resetPasswordError} />
            </ResultAlert>
          )}

          <div className={style.buttonGroup}>
            <Button color="primary" disabled={!formIsValid} type="submit">
              {resetPasswordLoading && <Loading />}
              <span
                style={{ visibility: resetPasswordLoading ? 'hidden' : null }}
              >
                Submit
              </span>
            </Button>
          </div>
        </div>
      )}
    </LoginForm>
  );
};
