import React, { useState, useCallback, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import gql from 'graphql-tag';
import { useQuery, useSubscription } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';

import {
  NutrientMixerMeasurements,
  NutrientMixerValues,
} from '../../modules/nutrient-mixer';

import useModalVisible from '../useModalVisible';

import PageLoadingContainer from '../../components/PageLoadingContainer';
import ModalTitle from '../../components/ModalTitle';
import Gauge from '../../components/Gauge';
import TimeSeriesChart from '../../components/TimeSeriesChart';
import ModalCloseButton from '../../components/ModalCloseButton';

import style from './style.module.scss';

interface Input {
  zoneName: string;
  zoneId: string;
}

interface Result {
  openZoneNutrientMixerHistoryModal: () => void;
  ZoneNutrientMixerHistoryModal: typeof ZoneNutrientMixerHistoryModal;
  zoneNutrientMixerHistoryModalProps: Props;
}

const zoneFragment = gql`
  fragment ZoneNutrientMixerHistoryModal on ZoneType {
    zoneId
    nutrientMixer {
      currentValue {
        ...NutrientMixerCurrentValue
      }
      aggregatedValues {
        timestamp
        ...NutrientMixerAggregatedValues
      }
      thresholds {
        ...NutrientMixerThresholds
      }
    }
  }
  ${NutrientMixerMeasurements.fragments.NutrientMixerCurrentValue}
  ${NutrientMixerMeasurements.fragments.NutrientMixerAggregatedValues}
  ${NutrientMixerMeasurements.fragments.NutrientMixerThresholds}
`;

const ZONE_QUERY = gql`
  query zone($zoneId: ID!) {
    zone(zoneId: $zoneId) {
      ...ZoneNutrientMixerHistoryModal
    }
  }
  ${zoneFragment}
`;

const ZONE_SUBSCRIPTION = gql`
  subscription zoneUpdated($zoneId: ID!) {
    zoneUpdated(zoneId: $zoneId) {
      zone {
        ...ZoneNutrientMixerHistoryModal
      }
    }
  }
  ${zoneFragment}
`;

interface Props extends Input {
  zoneNutrientMixerHistoryModalIsOpen: boolean;
  closeZoneNutrientMixerHistoryModal: () => void;
}

const ZoneNutrientMixerHistoryModal = ({
  zoneName,
  zoneId,
  zoneNutrientMixerHistoryModalIsOpen,
  closeZoneNutrientMixerHistoryModal,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();

  const result = useQuery(ZONE_QUERY, {
    variables: { zoneId },
    skip: !visible,
  });

  useSubscription(ZONE_SUBSCRIPTION, {
    variables: { zoneId },
    skip: !visible,
  });

  const { refetch } = result;
  const [wasOpen, setWasOpen] = useState(false);

  useEffect(() => {
    const reopened = zoneNutrientMixerHistoryModalIsOpen && !wasOpen;

    if (reopened) {
      refetch();
    }

    setWasOpen(zoneNutrientMixerHistoryModalIsOpen);
  }, [zoneNutrientMixerHistoryModalIsOpen, refetch, wasOpen, setWasOpen]);

  const { data } = result;
  const zone = data?.zone || null;
  const nutrientMixer = zone?.nutrientMixer || null;
  const aggregatedValues = nutrientMixer?.aggregatedValues || [];
  const thresholds = nutrientMixer?.thresholds || null;
  const currentValue = nutrientMixer?.currentValue || null;

  return (
    <Modal
      className={style.modal}
      isOpen={zoneNutrientMixerHistoryModalIsOpen}
      toggle={closeZoneNutrientMixerHistoryModal}
      {...modalVisibleProps}
    >
      <ModalCloseButton onClick={closeZoneNutrientMixerHistoryModal} />

      <ModalHeader>
        <ModalTitle
          icon={<FontAwesomeIcon icon={faHistory} fixedWidth={true} />}
          title={`Zone ${zoneName} Nutrient Mixer Reading History`}
        />
      </ModalHeader>

      <ModalBody className={style.modalBody}>
        <PageLoadingContainer
          resourceTypeName="Zone"
          result={result}
          resourceExists={!!zone}
          render={() => (
            <div className={style.charts}>
              {NutrientMixerMeasurements.all.map(measurement => (
                <div
                  className={style.gaugeChartContainer}
                  key={measurement.label}
                >
                  <TimeSeriesChart<NutrientMixerValues>
                    lineColor={measurement.color}
                    aggregatedValues={aggregatedValues}
                    aggregatedValueSelector={measurement.valueSelector}
                    lowCriticalValue={measurement.lowAlertValueSelector(
                      thresholds
                    )}
                    lowValue={measurement.lowValueSelector(thresholds)}
                    highValue={measurement.highValueSelector(thresholds)}
                    highCriticalValue={measurement.highAlertValueSelector(
                      thresholds
                    )}
                  />

                  <Gauge
                    className={style.gauge}
                    label={measurement.label}
                    color={measurement.color}
                    unit={measurement.unit}
                    value={measurement.valueSelector(currentValue)}
                    lowCritical={measurement.lowAlertValueSelector(thresholds)}
                    low={measurement.lowValueSelector(thresholds)}
                    high={measurement.highValueSelector(thresholds)}
                    highCritical={measurement.highAlertValueSelector(
                      thresholds
                    )}
                  />
                </div>
              ))}
            </div>
          )}
        />
      </ModalBody>
    </Modal>
  );
};

const useZoneNutrientMixerHistoryModal: (input: Input) => Result = ({
  zoneName,
  zoneId,
}) => {
  const [
    zoneNutrientMixerHistoryModalIsOpen,
    setZoneNutrientMixerHistoryModalIsOpen,
  ] = useState(false);

  return {
    openZoneNutrientMixerHistoryModal: useCallback(
      () => setZoneNutrientMixerHistoryModalIsOpen(true),
      [setZoneNutrientMixerHistoryModalIsOpen]
    ),
    ZoneNutrientMixerHistoryModal,
    zoneNutrientMixerHistoryModalProps: {
      zoneName,
      zoneId,
      zoneNutrientMixerHistoryModalIsOpen,
      closeZoneNutrientMixerHistoryModal: useCallback(
        () => setZoneNutrientMixerHistoryModalIsOpen(false),
        [setZoneNutrientMixerHistoryModalIsOpen]
      ),
    },
  };
};

export default useZoneNutrientMixerHistoryModal;
