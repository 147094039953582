import React, { Dispatch, FC, SetStateAction } from 'react';
import CalibrationForm from '../CalibrationForm';

import { Form } from './types';

interface Props {
  className: any;
  autoCalibrationPoints: any;
  autoCalibrateValidationErrors: any;
  isDirtyState: [boolean, Dispatch<SetStateAction<boolean>>];
  setAutoCalibrationPoints: (height: any) => void;
}

const AutoCalibrationForm: FC<Props> = ({
  className,
  autoCalibrationPoints,
  autoCalibrateValidationErrors,
  isDirtyState,
  setAutoCalibrationPoints,
}: Props): JSX.Element => {
  const forms: Form[] = [
    {
      className: className.sensorInput,
      pressureSensorHeight: autoCalibrationPoints.hPressureSensorHeight,
      sensorHeight: autoCalibrateValidationErrors.hSensorHeight,
      isDirtyState: isDirtyState,
      setAutoCalibrationPoints: setAutoCalibrationPoints,
      label: 'H Sensor Height',
      id: 'hPressureSensor',
    },
    {
      className: className.sensorInput,
      pressureSensorHeight: autoCalibrationPoints.hHPressureSensorHeight,
      sensorHeight: autoCalibrateValidationErrors.hHSensorHeight,
      isDirtyState: isDirtyState,
      setAutoCalibrationPoints: setAutoCalibrationPoints,
      label: 'HH Sensor Height',
      id: 'hHPressureSensor',
    },
    {
      className: className.sensorInput,
      pressureSensorHeight: autoCalibrationPoints.lPressureSensorHeight,
      sensorHeight: autoCalibrateValidationErrors.lSensorHeight,
      isDirtyState: isDirtyState,
      setAutoCalibrationPoints: setAutoCalibrationPoints,
      label: 'L Sensor Height',
      id: 'lPressureSensor',
    },
    {
      className: className.sensorInput,
      pressureSensorHeight: autoCalibrationPoints.lLPressureSensorHeight,
      sensorHeight: autoCalibrateValidationErrors.lLSensorHeight,
      isDirtyState: isDirtyState,
      setAutoCalibrationPoints: setAutoCalibrationPoints,
      label: 'LL Sensor Height',
      id: 'lLPressureSensor',
    },
  ];

  return (
    <>
      {forms.map((form: Form) => {
        return (
          <CalibrationForm
            key={form.id}
            className={form.className}
            pressureSensorHeight={form.pressureSensorHeight}
            sensorHeight={form.sensorHeight}
            isDirtyState={form.isDirtyState}
            setAutoCalibrationPoints={form.setAutoCalibrationPoints}
            label={form.label}
            id={form.id}
          />
        );
      })}
    </>
  );
};

export default AutoCalibrationForm;
