import { useState, useCallback } from 'react';

interface ModalVisibleProps {
  onOpened: () => void;
  onClosed: () => void;
}

const useModalVisible: () => [boolean, ModalVisibleProps] = () => {
  const [visible, setVisible] = useState(false);

  const modalProps = {
    onOpened: useCallback(() => setVisible(true), [setVisible]),

    onClosed: useCallback(() => setVisible(false), [setVisible]),
  };

  return [visible, modalProps];
};

export default useModalVisible;
