import React, { useCallback } from 'react';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { FormFeedback, Input as FormInput } from 'reactstrap';

import { SaveModal } from '../../components/SaveModal';
import ModalTitle from '../../components/ModalTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { getValidationErrors } from '../../modules/errors';
import { LevelBeeSensorMeasurements } from '../../modules/bee-sensors';

import {
  getInputValueAnd,
  updateState,
  useFocus,
} from '../../modules/form-helpers';

interface Input {
  zoneName: string;
  stackName: string;
  levelName: string;
  levelId: number;
  onCompleted?: () => void;
}

interface Result {
  openLevelAddSensorForm: () => void;
  LevelAddSensorForm: typeof LevelAddSensorForm;
  levelAddSensorFormProps: Props;
}

export const UPDATE_LEVEL_SENSOR_CONFIGURATION = gql`
  mutation updateLevelBeeSensorConfiguration(
    $input: UpdateLevelBeeSensorConfigurationInputType!
  ) {
    updateLevelBeeSensorConfiguration(input: $input) {
      level {
        hasBeeSensor
        beeSensor {
          serialNumber
          ...LevelBeeSensorCalibrationOffsets
          currentValue {
            ...LevelBeeSensorValues
          }
        }
      }
    }
  }
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorCurrentValue}
  ${LevelBeeSensorMeasurements.fragments.LevelBeeSensorCalibrationOffsets}
`;

interface Props extends Input {
  levelAddSensorFormIsOpen: boolean;
  closeLevelAddSensorForm: () => void;
}

const LevelAddSensorForm = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  levelAddSensorFormIsOpen,
  closeLevelAddSensorForm,
  onCompleted,
}: Props) => {
  const [
    updateLevelBeeSensorConfiguration,
    { error: saveError, loading: saving },
  ] = useMutation(UPDATE_LEVEL_SENSOR_CONFIGURATION, {
    onCompleted: () => {
      setSerialNumber('');
      closeLevelAddSensorForm();
      onCompleted && onCompleted();
    },
  });

  const validUUIDRegex = /^[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}$/i;
  const isValidUUID = (uuid: string): boolean => validUUIDRegex.test(uuid);

  const [wasOpen, setWasOpen] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  const serialNumberState = useState('');
  const [serialNumber, setSerialNumber] = serialNumberState;

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const isFormValid = isValidUUID(serialNumber);

  const { inputElement, focusElement } = useFocus();

  const validationErrors = {
    ...(isEmpty(serialNumber.trim()) && {
      serialNumber: 'Serial number is required',
    }),
    ...(!isEmpty(serialNumber.trim()) &&
      !isValidUUID(serialNumber.trim()) && {
        serialNumber: 'Invalid serial number format',
      }),
    ...(!isDirty &&
      saveAttempted &&
      saveError &&
      getValidationErrors(saveError)),
  };

  useEffect(() => {
    const reopened = levelAddSensorFormIsOpen && !wasOpen;

    if (reopened) {
      setSerialNumber('');
    }

    setWasOpen(levelAddSensorFormIsOpen);
  }, [levelAddSensorFormIsOpen, setSerialNumber, setWasOpen, wasOpen]);

  return (
    <SaveModal
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faCog} />}
          title={`Assign sensor to Level ${levelName} on Zone ${zoneName} / Stack ${stackName}`}
        />
      }
      isOpen={levelAddSensorFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      saveButtonContent="Save"
      onSave={() => {
        setIsDirty(false);
        setSaveAttempted(true);
        updateLevelBeeSensorConfiguration({
          variables: {
            input: {
              levelId,
              configuration: {
                serialNumber: serialNumber,
                tempCalibrationOffsetInF: 0.0,
                humidityCalibrationOffsetInPercentRh: 0.0,
                pressureCalibrationOffsetInHPa: 0.0,
                cO2CalibrationOffsetInPpm: 0.0,
              },
            },
          },
        });
      }}
      onComplete={closeLevelAddSensorForm}
      onOpened={() => focusElement()}
    >
      <p>
        Register a sensor for Level {levelName} on Zone {zoneName} / Stack{' '}
        {stackName} by entering its serial number.
      </p>

      <FormInput
        id="serialNumber"
        type="text"
        placeholder={'xxxxxxxxxxxx'}
        value={serialNumber}
        onChange={getInputValueAnd(
          updateState({
            valueState: serialNumberState,
            isDirtyState,
          })
        )}
        invalid={!!validationErrors.serialNumber}
        innerRef={inputElement}
      />

      <FormFeedback invalid={validationErrors.serialNumber}>
        {validationErrors.serialNumber}
      </FormFeedback>
    </SaveModal>
  );
};

const useLevelAddSensorForm: (input: Input) => Result = ({
  zoneName,
  stackName,
  levelName,
  levelId,
  onCompleted,
}) => {
  const [levelAddSensorFormIsOpen, setLevelAddSensorFormIsOpen] = useState(
    false
  );

  return {
    openLevelAddSensorForm: useCallback(
      () => setLevelAddSensorFormIsOpen(true),
      [setLevelAddSensorFormIsOpen]
    ),
    LevelAddSensorForm,
    levelAddSensorFormProps: {
      zoneName,
      stackName,
      levelName,
      levelId,
      levelAddSensorFormIsOpen,
      onCompleted,
      closeLevelAddSensorForm: useCallback(
        () => setLevelAddSensorFormIsOpen(false),
        [setLevelAddSensorFormIsOpen]
      ),
    },
  };
};

export default useLevelAddSensorForm;
