import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  //minutes: number;
  stackId: string;
  zoneName: string;
  stackName: string;
  onCompleted?: () => void;
}

interface Result {
  openDryStackAlertResetPrompt: () => void;
  DryStackAlertResetPrompt: typeof DryStackAlertResetPrompt;
  dryStackAlertResetPromptProps: Props;
}

export const RESET_DRY_STACK_ALERT = gql`
  mutation resetDryStackAlert($input: ResetDryStackAlertInputType!) {
    resetDryStackAlert(input: $input) {
      stack {
        stackId
      }
    }
  }
`;

interface Props extends Input {
  dryStackAlertResetPromptIsOpen: boolean;
  closeDryStackAlertResetPrompt: () => void;
}

const DryStackAlertResetPrompt = ({
  zoneName,
  stackName,
  //minutes,
  stackId,
  dryStackAlertResetPromptIsOpen,
  closeDryStackAlertResetPrompt,
  onCompleted,
}: Props) => {
  const [dryStackAlertReset, { error, loading: saving }] = useMutation(
    RESET_DRY_STACK_ALERT,
    {
      onCompleted: () => {
        closeDryStackAlertResetPrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Remove Dry Stack Alert for Zone ${zoneName} / Stack ${stackName}`}
      danger={true}
      isOpen={dryStackAlertResetPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        dryStackAlertReset({
          variables: {
            input: {
              stackId,
              //minutes,
            },
          },
        });
      }}
      onComplete={closeDryStackAlertResetPrompt}
    >
      <p>
        A dry stack was detected at Zone {`${zoneName} / Stack ${stackName}`}.
      </p>

      <p>Are you sure you want to dismiss the dry stack alert ?</p>
    </PromptModal>
  );
};

const useDryStackAlertResetPrompt: (input: Input) => Result = ({
  zoneName,
  stackName,
  //minutes,
  stackId,
  onCompleted,
}) => {
  const [
    dryStackAlertResetPromptIsOpen,
    setDryStackAlertResetPromptIsOpen,
  ] = useState(false);

  return {
    openDryStackAlertResetPrompt: useCallback(
      () => setDryStackAlertResetPromptIsOpen(true),
      [setDryStackAlertResetPromptIsOpen]
    ),
    DryStackAlertResetPrompt,
    dryStackAlertResetPromptProps: {
      zoneName,
      stackName,
      //minutes,
      stackId,
      dryStackAlertResetPromptIsOpen,
      onCompleted,
      closeDryStackAlertResetPrompt: useCallback(
        () => setDryStackAlertResetPromptIsOpen(false),
        [setDryStackAlertResetPromptIsOpen]
      ),
    },
  };
};

export default useDryStackAlertResetPrompt;
