import React from 'react';
import gql from 'graphql-tag';
import { Button } from 'reactstrap';

import useStackLightRecipeForm from '../../../../hooks/useStackLightRecipeForm';

import GrowWiseRecipe from '../../../../components/GrowWiseRecipe';

import ColorWheelImage from '../../../../assets/images/color-wheel.svg';
import style from './style.module.scss';

const ColorWheelIcon = () => (
  <img
    className={style.colorWheelImage}
    src={ColorWheelImage}
    alt="Color Wheel"
  />
);

const LightRecipe = ({ className, zoneName, stackName, stackId, growWise }) => {
  const {
    openStackLightRecipeForm,
    StackLightRecipeForm,
    stackLightRecipeFormProps,
  } = useStackLightRecipeForm({ zoneName, stackName, stackId });

  const { updateStackGrowWiseRecipeCommand, currentRecipe } = growWise;

  return (
    <>
      <StackLightRecipeForm {...stackLightRecipeFormProps} />

      <div className={[style.container, className].join(' ')}>
        {updateStackGrowWiseRecipeCommand.canExecute ? (
          <Button id="edit-recipe" onClick={openStackLightRecipeForm}>
            <ColorWheelIcon />
          </Button>
        ) : (
          <ColorWheelIcon />
        )}

        <label
          htmlFor="edit-recipe"
          className={[currentRecipe ? null : style.unknownRecipe].join(' ')}
        >
          {currentRecipe ? (
            <GrowWiseRecipe growWiseRecipe={currentRecipe} />
          ) : (
            'Unknown Recipe'
          )}
        </label>
      </div>
    </>
  );
};

LightRecipe.fragment = gql`
  fragment LightRecipe on StackGrowWiseType {
    currentRecipe {
      id
      ...GrowWiseRecipe
    }
    updateStackGrowWiseRecipeCommand {
      canExecute
    }
  }
  ${GrowWiseRecipe.fragment}
`;

export default LightRecipe;
