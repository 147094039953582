import React from 'react';

import style from './style.module.scss';

interface Props extends React.HTMLAttributes<SVGSVGElement> {
  className?: string;
}

export default ({ className, ...props }: Props) => {
  return (
    <svg
      className={[style.svg, className].join(' ')}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="1.5 1.5 24 24"
      {...props}
    >
      <g transform="translate(-1.2066101,0.68593252) scale(1.125 1.125)">
        <circle
          r="8.9475718"
          cy="17.330885"
          cx="1.2211981"
          style={{
            opacity: 1,
            fill: 'none',
            fillOpacity: 1,
            stroke: 'currentColor',
            strokeWidth: 1.30528598,
            strokeMiterlimit: 4,
            strokeDasharray: 'none',
            strokeOpacity: 1,
          }}
          transform="matrix(0.71186675,-0.70231455,0.71186675,0.70231455,0,0)"
        />
        <g transform="translate(0.5,0)">
          <path
            d="m 15.109536,12.100807 v 1.479071 c 0,0.249596 -0.204503,0.45135 -0.457486,0.45135 -0.252993,0 -0.45749,-0.201754 -0.45749,-0.45135 v -1.479071 c -0.272204,-0.156167 -0.457488,-0.444127 -0.457488,-0.777674 0,-0.333096 0.185288,-0.621056 0.457488,-0.777223 V 9.0663879 c 0,-0.2491442 0.204503,-0.4513483 0.45749,-0.4513483 0.252989,0 0.457486,0.2022041 0.457486,0.4513483 V 10.54591 c 0.272205,0.156168 0.457488,0.444127 0.457488,0.777223 0,0.333547 -0.18529,0.621507 -0.457488,0.777674 m 0.914976,-6.1938613 h -2.744927 c -0.25299,0 -0.457488,0.2022052 -0.457488,0.451349 V 16.28797 c 0,0.249599 0.204503,0.451351 0.457488,0.451351 h 2.744927 c 0.252991,0 0.457488,-0.201754 0.457488,-0.451351 V 6.3582945 c 0,-0.2491447 -0.204503,-0.451349 -0.457488,-0.451349"
            style={{
              fill: 'currentColor',
              fillOpacity: 1,
              fillRule: 'evenodd',
              stroke: 'none',
              strokeWidth: 0.45440793,
            }}
          />
          <rect
            style={{
              opacity: 1,
              fill: 'currentColor',
              fillOpacity: 1,
              stroke: 'none',
              strokeWidth: 0.792,
              strokeMiterlimit: 4,
              strokeDasharray: 'none',
              strokeOpacity: 1,
            }}
            width="4.3864589"
            height="1.1505467"
            x="7.0312524"
            y="10.738794"
            ry="0.13262975"
          />
          <rect
            style={{
              opacity: 1,
              fill: 'currentColor',
              fillOpacity: 1,
              stroke: 'none',
              strokeWidth: 0.792,
              strokeMiterlimit: 4,
              strokeDasharray: 'none',
              strokeOpacity: 1,
            }}
            width="4.3864589"
            height="1.1505467"
            x="10.475089"
            y="1.8392965"
            ry="0.13262975"
            transform="rotate(30)"
          />
          <rect
            style={{
              opacity: 1,
              fill: 'currentColor',
              fillOpacity: 1,
              stroke: 'none',
              strokeWidth: 0.792,
              strokeMiterlimit: 4,
              strokeDasharray: 'none',
              strokeOpacity: 1,
            }}
            width="4.3864589"
            height="1.1505467"
            x="-0.86570191"
            y="16.581078"
            ry="0.13262975"
            transform="rotate(-30)"
          />
        </g>
      </g>
    </svg>
  );
};
