import React from 'react';

import style from './style.module.scss';

interface Props extends React.HTMLAttributes<HTMLElement> {
  innerClassName?: string;
}

export default ({ className, innerClassName }: Props) => {
  return (
    <svg
      className={[style.svg, className].join(' ')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 295.73716 331.6359"
      version="1.1"
    >
      <g transform="translate(54.130483,57.716752)">
        <g
          style={{
            clipRule: 'evenodd',
            fill: 'currentColor',
            fillRule: 'evenodd',
          }}
          transform="matrix(1.7094633,0,0,1.7094633,-54.130483,-57.716752)"
        >
          <path
            className={[style.inner, innerClassName].join(' ')}
            style={{ fill: 'currentColor', strokeWidth: 0.455824 }}
            d="M 85.020754,185.6719 C 83.373475,185.28162 13.235575,145.06071 11.571755,143.55223 8.8395969,141.07516 8.9940764,143.86498 8.9940764,97.000718 8.9940764,50.136329 8.8392231,52.933152 11.571755,50.444999 13.522938,48.668317 83.462,8.7151902 85.372305,8.285973 86.5585,8.0194526 87.858095,8.0378358 88.968519,8.3368425 91.281205,8.9595842 159.87258,48.496415 161.80903,50.32292 c 3.30357,3.116028 3.06315,-0.316108 3.20021,45.68258 0.0867,29.08743 -0.026,41.95119 -0.37906,43.2622 -1.05921,3.93359 -2.41023,4.8301 -38.48933,25.54053 -18.55204,10.64939 -34.346341,19.66987 -35.09845,20.04549 -1.830946,0.91439 -4.236188,1.24121 -6.021646,0.81818 z"
          />
          <path d="m 97,3 66,38 c 7,4 10,10 10,18 v 76 c 0,8 -3,14 -10,18 l -66,38 c -6,4 -14,4 -21,0 L 10,153 C 4,149 0,143 0,135 V 59 C 0,51 4,45 10,41 L 76,3 c 7,-4 15,-4 21,0 z m -5,9 C 89,10 85,10 82,12 L 16,50 c -4,2 -5,5 -5,9 v 76 c 0,4 1,7 5,9 l 66,38 c 3,2 7,2 10,0 l 66,-38 c 3,-2 5,-5 5,-9 V 59 c 0,-4 -2,-7 -5,-9 z" />
        </g>
      </g>
    </svg>
  );
};
