import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  zoneName: string;
  stackName: string;
  stackId: string;
  onCompleted?: () => void;
}

interface Result {
  openStackLeakResetPrompt: () => void;
  StackLeakResetPrompt: typeof StackLeakResetPrompt;
  stackLeakResetPromptProps: Props;
}

export const RESET_STACK_LEAK = gql`
  mutation resetStackLeak($input: ResetStackLeakInputType!) {
    resetStackLeak(input: $input) {
      stack {
        stackId
        leakDetected
      }
    }
  }
`;

interface Props extends Input {
  stackLeakResetPromptIsOpen: boolean;
  closeStackLeakResetPrompt: () => void;
}

const StackLeakResetPrompt = ({
  zoneName,
  stackName,
  stackId,
  stackLeakResetPromptIsOpen,
  closeStackLeakResetPrompt,
  onCompleted,
}: Props) => {
  const [stackLeakReset, { error, loading: saving }] = useMutation(
    RESET_STACK_LEAK,
    {
      onCompleted: () => {
        closeStackLeakResetPrompt();
        onCompleted && onCompleted();
      },
    }
  );

  return (
    <PromptModal
      title={`Reset Leak Alert for Zone ${zoneName} / Stack ${stackName}`}
      danger={true}
      isOpen={stackLeakResetPromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        stackLeakReset({
          variables: {
            input: {
              stackId,
            },
          },
        });
      }}
      onComplete={closeStackLeakResetPrompt}
    >
      <p>A leak was detected at Zone {`${zoneName} / Stack ${stackName}`}.</p>

      <p>
        You should only reset the alert if you have physically verified that
        there is no longer a leak.
      </p>

      <p>{`Are you sure you want to reset the leak alert for Zone ${zoneName} / Stack ${stackName}?`}</p>
    </PromptModal>
  );
};

const useStackLeakResetPrompt: (input: Input) => Result = ({
  zoneName,
  stackName,
  stackId,
  onCompleted,
}) => {
  const [stackLeakResetPromptIsOpen, setStackLeakResetPromptIsOpen] = useState(
    false
  );

  return {
    openStackLeakResetPrompt: useCallback(
      () => setStackLeakResetPromptIsOpen(true),
      [setStackLeakResetPromptIsOpen]
    ),
    StackLeakResetPrompt,
    stackLeakResetPromptProps: {
      zoneName,
      stackName,
      stackId,
      stackLeakResetPromptIsOpen,
      onCompleted,
      closeStackLeakResetPrompt: useCallback(
        () => setStackLeakResetPromptIsOpen(false),
        [setStackLeakResetPromptIsOpen]
      ),
    },
  };
};

export default useStackLeakResetPrompt;
