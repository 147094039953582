import React from 'react';

import style from './style.module.scss';

interface Props {
  className?: string;
}

export default ({ className }: Props) => {
  return (
    <svg
      className={[style.svg, className].join(' ')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.27 10.93"
      version="1.1"
    >
      <path d="M 8.53125 0.79882812 L 4.6757812 0.82421875 L 1.96875 3.5703125 L 1.9941406 7.4238281 L 4.7402344 10.130859 L 8.59375 10.105469 L 11.300781 7.3613281 L 11.275391 3.5058594 L 8.53125 0.79882812 z M 6.6582031 1.7695312 L 7.890625 3.0546875 L 6.6308594 4.3652344 L 6.6308594 3.4160156 A 2.2098995 2.2098995 0 0 0 4.5097656 5.0449219 A 2.3561811 2.3561811 0 0 0 5.4863281 7.7304688 A 2.1785534 2.1785534 0 0 0 8.6210938 6.7949219 A 2.2673673 2.2673673 0 0 0 8.8417969 6.0585938 A 2.4920143 2.4920143 0 0 0 8.7578125 5.0449219 L 9.4101562 5.0449219 A 3.1346092 3.1346092 0 0 1 9.2167969 7.0039062 A 2.8315969 2.8315969 0 0 1 6.03125 8.6347656 A 2.9883273 2.9883273 0 0 1 3.8730469 5.0136719 A 2.9151865 2.9151865 0 0 1 4.3945312 3.8808594 A 2.8054752 2.8054752 0 0 1 6.640625 2.7460938 L 6.640625 1.7753906 L 6.6582031 1.7695312 z " />
    </svg>
  );
};
