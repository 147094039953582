import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import gql from 'graphql-tag';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCog } from '@fortawesome/free-solid-svg-icons';

import useStackModePrompts from '../../hooks/useStackModePrompts';
import useStackCancelHarvestSchedulePrompt from '../../hooks/useStackCancelHarvestSchedulePrompt';
import useStackLeakResetPrompt from '../../hooks/useStackLeakResetPrompt';
import useStackAttachedCollectorTankPumpTimeoutResetPrompt from '../../hooks/useStackAttachedCollectorTankPumpTimeoutResetPrompt';
import useStackValveFailureResetPrompt from '../../hooks/useStackValveFailureResetPrompt';
import useDryStackAlertResetPrompt from '../../hooks/useDryStackAlertResetPrompt';
import useStackCancelCleanInPlacePrompt from '../../hooks/useStackCancelCleanInPlacePrompt';

import CollectorTank from '../CollectorTank';
import CommunicationErrorImage from '../../assets/images/CommunicationError.svg';
import HarvestScheduledImage from '../../assets/images/HarvestScheduled.svg';
import HarvestActiveImage from '../../assets/images/HarvestActive.svg';
import GreenCheckImage from '../../assets/images/greenCheck.svg';
import IrrigationOffImage from '../../assets/images/IrrigationOff.svg';
import IrrigationOnImage from '../../assets/images/IrrigationOn.svg';
import DryStackImage from '../../assets/images/dry-stack.svg';
import Light from '../../components/Light';
import WaterLeakIcon from '../../components/WaterLeakIcon';
import PumpTimeoutIcon from '../../components/PumpTimeoutIcon';
import ValveTestIcon from '../../components/ValveTestIcon';
import ValveFailedIcon from '../../components/ValveFailedIcon';
import CleanInPlaceIcon from '../../components/CleanInPlaceIcon';

import style from './style.module.scss';
import HarvestAlertIcon from '../HarvestAlertIcon';
import { Descriptions } from '../../modules/Descriptions';
import MicrogreenCollectorTank from '../MicrogreenCollectorTank';

export const UPDATE_STACK_LIGHT_STATE = gql`
  mutation updateStackLightState($stackId: ID!, $state: LightState!) {
    updateStackLightState(stackId: $stackId, state: $state) {
      stackId
      light {
        isOn
        schedule {
          enabled
          activeTimeBlockIndex
          timeBlocks
        }
      }
    }
  }
`;

const Indicator = ({
  className,
  command,
  onClick,
  children,
  title,
  dataPlacement,
  dataToggle,
}) => {
  return (
    <div
      className={className}
      onClick={command?.canExecute ? onClick : undefined}
      style={{
        cursor: command?.canExecute ? 'pointer' : undefined,
      }}
      title={title}
      data-toggle={dataToggle}
      data-placement={dataPlacement}
    >
      {children}
    </div>
  );
};

const StackStatus = ({
  link,
  zoneName,
  stack,
  className,
  tankClassName,
  hideCommands,
  hasPumpPermission = true,
  hideCollectorTank,
  isMicrogreen,
  subzones,
  subzoneSelectionCallback,
  hasHelp,
  scheduleMicrogreenStack,
  showStackActions = true,
  tankStatusOverride = null,
}) => {
  const {
    openStackAutomaticModePrompt,
    openStackMaintenanceModePrompt,
    StackModePrompts,
    stackModePromptsProps,
  } = useStackModePrompts({
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  const {
    openCancelHarvestPrompt,
    CancelStackHarvestSchedulePrompt,
    cancelStackHarvestSchedulePromptProps,
  } = useStackCancelHarvestSchedulePrompt({
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  const {
    openStackLeakResetPrompt,
    StackLeakResetPrompt,
    stackLeakResetPromptProps,
  } = useStackLeakResetPrompt({
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  const {
    openStackAttachedCollectorTankPumpTimeoutResetPrompt,
    StackAttachedCollectorTankPumpTimeoutResetPrompt,
    stackAttachedCollectorTankPumpTimeoutResetPromptProps,
  } = useStackAttachedCollectorTankPumpTimeoutResetPrompt({
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  const {
    openStackValveFailureResetPrompt,
    StackValveFailureResetPrompt,
    stackValveFailureResetPromptProps,
  } = useStackValveFailureResetPrompt({
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  const resetDryStackDetected = () => {
    setStateDryStackDetected(false);
  };

  const {
    openDryStackAlertResetPrompt,
    DryStackAlertResetPrompt,
    dryStackAlertResetPromptProps,
  } = useDryStackAlertResetPrompt({
    // TODO: minutes: 5,  this hardcoded minutes variable will be the elapsed time since the user removed the alert on the stack
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
    onCompleted: resetDryStackDetected,
  });

  const {
    openStackCancelCleanInPlacePrompt,
    StackCancelCleanInPlacePrompt,
    stackCancelCleanInPlacePromptProps,
  } = useStackCancelCleanInPlacePrompt({
    zoneName: zoneName,
    stackName: stack.name,
    stackId: stack.stackId,
  });

  const showCommands = !hideCommands;

  const {
    light,
    mode,
    communicationError,
    leakDetected,
    valveFailureDetected,
    dryStackDetected,
    collectorTank,
    harvestSchedule,
    harvestScheduleAlert,
    updateStackModeCommand,
    updateStackHarvestScheduleTimeCommand,
    resetStackLeakCommand,
    resetDryStackCommand,
    resetStackValveFailureCommand,
    cancelStackCleanInPlaceCommand,
  } = stack;
  const pumpTimeoutDetected = !!collectorTank?.stackAttachedCollectorTankPumpTimeoutDetected;
  const resetPumpTimeoutCommand = collectorTank?.resetPumpTimeoutCommand || {};
  const { isOn: lightIsOn, schedule } = light;
  const { enabled: scheduleIsEnabled } = schedule;

  const [stateDryStackDetected, setStateDryStackDetected] = useState(
    dryStackDetected
  );

  const isNewCleanInPlace =
    stack.mode === 'CLEAN_IN_PLACE' &&
    (stack.stackKind === 3 ||
      String(stack.stackKind).toLowerCase() === 'newmicrogreens');

  const hasError =
    leakDetected ||
    pumpTimeoutDetected ||
    valveFailureDetected ||
    stateDryStackDetected ||
    harvestScheduleAlert ||
    communicationError;
  return (
    <div className={className}>
      {showCommands && (
        <>
          <StackModePrompts stackMode={stack.mode} {...stackModePromptsProps} />

          <CancelStackHarvestSchedulePrompt
            {...cancelStackHarvestSchedulePromptProps}
          />

          <StackLeakResetPrompt {...stackLeakResetPromptProps} />

          <StackAttachedCollectorTankPumpTimeoutResetPrompt
            {...stackAttachedCollectorTankPumpTimeoutResetPromptProps}
          />

          <StackValveFailureResetPrompt
            {...stackValveFailureResetPromptProps}
          />

          <DryStackAlertResetPrompt {...dryStackAlertResetPromptProps} />

          <StackCancelCleanInPlacePrompt
            {...stackCancelCleanInPlacePromptProps}
          />
        </>
      )}

      <Link to={showCommands ? '#' : link}>
        <div className={style.stackStatus}>
          <Indicator
            className={`${style.stackLight} ${
              hasHelp ? 'nav-help-tooltip' : ''
            }`}
            dataToggle="tooltip"
            dataPlacement="top"
            title={Descriptions.growLightMode}
          >
            <Light on={lightIsOn} error={!scheduleIsEnabled} />
          </Indicator>

          {mode === 'AUTOMATIC' ? (
            <Indicator
              dataToggle="tooltip"
              dataPlacement="left"
              title={Descriptions.stackIrrigationMode}
              className={`${style.stackIrrigation} ${
                hasHelp ? 'nav-help-tooltip' : ''
              }`}
              command={showCommands && updateStackModeCommand}
              onClick={
                isNewCleanInPlace ? () => {} : openStackMaintenanceModePrompt
              }
            >
              <img src={IrrigationOnImage} alt="" />
              {isNewCleanInPlace && (
                <CleanInPlaceIcon
                  style={{
                    width: 'auto',
                    height: '5em',
                    position: 'absolute',
                    left: '2.75em',
                    top: '6em',
                    color: '#00daff',
                  }}
                />
              )}
            </Indicator>
          ) : (
            <Indicator
              dataToggle="tooltip"
              dataPlacement="right"
              title={Descriptions.stackIrrigationMode}
              className={`${style.stackIrrigation} ${
                hasHelp ? 'nav-help-tooltip' : ''
              }`}
              command={showCommands && updateStackModeCommand}
              onClick={
                isNewCleanInPlace ? () => {} : openStackAutomaticModePrompt
              }
            >
              <img src={IrrigationOffImage} alt="" />
              {isNewCleanInPlace && (
                <CleanInPlaceIcon
                  style={{
                    width: 'auto',
                    height: '5em',
                    position: 'absolute',
                    left: '2.75em',
                    top: '6em',
                    color: '#00daff',
                  }}
                />
              )}
            </Indicator>
          )}

          <div className={style.stackErrors}>
            {!hasError &&
              !harvestSchedule &&
              mode !== 'HARVEST' &&
              mode !== 'CLEAN_IN_PLACE' &&
              mode !== 'VALVE_TEST' && (
                <Indicator
                  dataToggle="tooltip"
                  dataPlacement="left"
                  className={`${hasHelp ? 'nav-help-tooltip' : ''}`}
                  title={Descriptions.communicationGood}
                >
                  <img src={GreenCheckImage} alt="OK" />
                </Indicator>
              )}

            {leakDetected && (
              <Indicator
                dataToggle="tooltip"
                dataPlacement="right"
                title={Descriptions.stackLeakDetected}
                command={showCommands && resetStackLeakCommand}
                onClick={openStackLeakResetPrompt}
                className={`${hasHelp ? 'nav-help-tooltip' : ''}`}
              >
                <WaterLeakIcon className={style.waterLeakIcon} />
              </Indicator>
            )}

            {pumpTimeoutDetected && (
              <Indicator
                dataToggle="tooltip"
                dataPlacement="left"
                title={Descriptions.stackPumpTimeout}
                command={showCommands && resetPumpTimeoutCommand}
                onClick={openStackAttachedCollectorTankPumpTimeoutResetPrompt}
                className={`${hasHelp ? 'nav-help-tooltip' : ''}`}
              >
                <PumpTimeoutIcon className={style.pumpTimeoutIcon} />
              </Indicator>
            )}

            {valveFailureDetected && (
              <Indicator
                dataToggle="tooltip"
                dataPlacement="right"
                title={Descriptions.stackValeFailure}
                command={showCommands && resetStackValveFailureCommand}
                onClick={openStackValveFailureResetPrompt}
                className={`${hasHelp ? 'nav-help-tooltip' : ''}`}
              >
                <ValveFailedIcon className={style.valveFailedIcon} />
              </Indicator>
            )}

            {stateDryStackDetected && (
              <Indicator
                dataToggle="tooltip"
                dataPlacement="left"
                title={Descriptions.dryStackStatus}
                command={showCommands && resetDryStackCommand}
                onClick={openDryStackAlertResetPrompt}
                className={`${hasHelp ? 'nav-help-tooltip' : ''}`}
              >
                <img src={DryStackImage} style={{ height: '85%' }} alt="" />
              </Indicator>
            )}

            {communicationError && (
              <Indicator
                dataToggle="tooltip"
                dataPlacement="right"
                title={Descriptions.communicationError}
                className={`${hasHelp ? 'nav-help-tooltip' : ''}`}
              >
                <img src={CommunicationErrorImage} alt="Communication Error" />
              </Indicator>
            )}

            {harvestSchedule && mode !== 'HARVEST' && (
              <Indicator
                dataToggle="tooltip"
                dataPlacement="left"
                title={Descriptions.harvestStatus}
                className={`${style.harvestIndicator} ${
                  hasHelp ? 'nav-help-tooltip' : ''
                }`}
                command={showCommands && updateStackHarvestScheduleTimeCommand}
                onClick={openCancelHarvestPrompt}
              >
                <img src={HarvestScheduledImage} alt="Harvest Scheduled" />

                {showCommands && (
                  <div
                    className={style.harvestScheduleInfoText}
                    dangerouslySetInnerHTML={{
                      __html: `${moment
                        .tz(
                          harvestSchedule.harvestBeginLocalTime,
                          harvestSchedule.localTimezone
                        )
                        .calendar({
                          sameDay: '[Today] [at] <br /> LT',
                          nextDay: '[Tomorrow] [at] <br /> LT',
                        })} ${moment
                        .tz(harvestSchedule.localTimezone)
                        .zoneAbbr()}`,
                    }}
                  />
                )}
              </Indicator>
            )}

            {mode === 'HARVEST' && !harvestScheduleAlert && (
              <Indicator
                dataToggle="tooltip"
                className={`${hasHelp ? 'nav-help-tooltip' : ''}`}
                dataPlacement="right"
                title={Descriptions.harvestStatus}
                command={showCommands && updateStackModeCommand}
                onClick={openStackAutomaticModePrompt}
              >
                <img src={HarvestActiveImage} alt="Harvest Active" />
              </Indicator>
            )}

            {mode === 'HARVEST' && harvestScheduleAlert && (
              <Indicator
                className={`${hasHelp ? 'nav-help-tooltip' : ''}`}
                dataToggle="tooltip"
                dataPlacement="top"
                title={Descriptions.harvestStatus}
                command={showCommands && updateStackModeCommand}
                onClick={openStackAutomaticModePrompt}
              >
                <HarvestAlertIcon
                  className={style.harvestAlert}
                  alt="Caution! Harvest Active for more than 24 hours."
                />
              </Indicator>
            )}

            {mode === 'CLEAN_IN_PLACE' && !isNewCleanInPlace && (
              <Indicator
                dataToggle="tooltip"
                className={`${hasHelp ? 'nav-help-tooltip' : ''}`}
                dataPlacement="left"
                title={Descriptions.cleanInPlace}
                command={showCommands && cancelStackCleanInPlaceCommand}
                onClick={openStackCancelCleanInPlacePrompt}
              >
                <CleanInPlaceIcon className={style.cleanInPlaceIcon} />
              </Indicator>
            )}

            {mode === 'VALVE_TEST' && (
              <Indicator
                dataToggle="tooltip"
                dataPlacement="right"
                title={Descriptions.valveTest}
                className={` ${hasHelp ? 'nav-help-tooltip' : ''}`}
              >
                <ValveTestIcon className={style.valveTestIcon} animate />
              </Indicator>
            )}
          </div>

          {hideCollectorTank === false && !isNewCleanInPlace ? (
            isMicrogreen ? (
              <MicrogreenCollectorTank
                dataPlacement="bottom"
                className={[style.tank, tankClassName].join(' ')}
                zoneName={zoneName}
                stackId={stack.stackId}
                collectorTank={stack.collectorTank}
                tankStatusOverride={tankStatusOverride}
                hidePumpCommands={hideCommands || !hasPumpPermission}
                label=""
              />
            ) : stack.collectorTank.zone ? (
              <Link
                style={{ width: '100%' }}
                to={`/facility/${stack.collectorTank.zone?.facilityId}/zone/${zoneName}`}
              >
                <CollectorTank
                  dataPlacement="bottom"
                  hasHelp={hasHelp}
                  className={[style.tank, tankClassName].join(' ')}
                  zoneName={zoneName}
                  collectorTank={stack.collectorTank}
                  hidePumpCommands={true}
                />
              </Link>
            ) : (
              <CollectorTank
                dataPlacement="bottom"
                hasHelp={hasHelp}
                className={[style.tank, tankClassName].join(' ')}
                zoneName={zoneName}
                collectorTank={stack.collectorTank}
                hidePumpCommands={hideCommands || isMicrogreen}
              />
            )
          ) : null}
          <div className={style.htClearfix}>
            <></>
          </div>
        </div>
      </Link>

      {isMicrogreen === true && showStackActions && (
        <div
          className={`${style.ht_c}  ${hasHelp ? 'nav-help-tooltip' : ''}`}
          data-toggle="tooltip"
          data-placement="bottom"
          title={Descriptions.scheduleMicrogreensStack}
        >
          <button
            className="btn btn-sm btn-secondary m-0 rounded"
            onClick={() => scheduleMicrogreenStack(stack.stackId)}
          >
            <FontAwesomeIcon icon={faClock} fixedWidth />
          </button>
        </div>
      )}

      {subzones != null && showStackActions && (
        <div
          className={style.ht}
          style={{
            transform:
              subzones.filter(i => i.stacks.includes(+stack.stackId)).length > 3
                ? 'translate(0, 0.2em)'
                : 'translate(0, 0)',
          }}
        >
          {subzones
            .filter(i => i.stacks.includes(+stack.stackId))
            .map((subzone, i) => {
              return (
                <div key={i} style={{ display: 'inline' }}>
                  <div
                    className={style.szBlip}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: subzone.color,
                    }}
                    onClick={subzoneSelectionCallback(subzone)}
                    value={subzone.zoneSubzoneId}
                  >
                    <span>{subzone.name[0].toUpperCase()}</span>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

StackStatus.fragment = gql`
  fragment StackStatus on StackType {
    stackId
    name
    mode
    isMicrogreen
    stackKind
    leakDetected
    valveFailureDetected
    dryStackDetected
    valveOpenFailureDetected
    communicationError
    harvestScheduleAlert
    updateStackModeCommand {
      canExecute
    }
    resetStackLeakCommand {
      canExecute
    }
    resetStackValveFailureCommand {
      canExecute
    }
    resetDryStackCommand {
      canExecute
    }
    harvestSchedule {
      localTimezone
      harvestBeginLocalTime
    }
    updateStackHarvestScheduleTimeCommand {
      canExecute
    }
    cancelStackCleanInPlaceCommand {
      canExecute
    }
    light {
      isOn
      schedule {
        enabled
      }
      updateStackLightStateCommand {
        canExecute
      }
    }
    collectorTank {
      ...CollectorTank
      waterLevel
      pumpState
      resetPumpTimeoutCommand {
        canExecute
      }

      ... on StackAttachedCollectorTankType {
        stackAttachedCollectorTankPumpTimeoutDetected: pumpTimeoutDetected
      }

      ... on ZoneAttachedCollectorTankType {
        zone {
          zoneId
          facilityId
        }
      }
    }
  }
  ${CollectorTank.fragment}
`;

export default StackStatus;
