export const Descriptions = {
  bulkUpdateLights: 'Modify grow lights for all stacks in a zone.',
  pumpRoomButton:
    'The section of the physical farm that deals with water collection, treatment, and distribution.',
  zoneButton:
    'A section of the physical farm that hosts crops in a unit of stacks.',
  zoneCollectorTank: 'A tank that collects used water from a zone.',
  zonePumpTimeout: 'The pump for the zone collection tank has timed out.',
  strainerClogDetected: 'A clog has been detected in the strainer.',
  reverseOsmosisSystem:
    'A reverse osmosis system which purifies and produces city or recycled water.',
  reverseOsmosisPump:
    'A pump that creates flow from the reverse osmosis system to the farm.',
  reverseOsmosisValve:
    'A valve which opens a zone supply tank to collect water from the reverse osmosis system.',
  zoneSupplyTank: 'A tank which supplies water to a zone on the farm.',
  miniZoneIcon: 'Stacks within the zone water is being supplied to.',
  zoneReturnValve: 'A valve that controls flow of used water from the zone.',
  ozoneBypassValve: 'A valve which allows the ozone tank to be bypassed.',
  ozoneTank: 'A collection tank that cleans used water.',
  flushOzoneTankValve:
    'A valve which allows water to flow from the ozone tank to a zone supply tank.',
  miniStack: 'A stack of plants in the physical farm.',
  scheduleMicrogreensStack: `Irrigation schedule for microgreens stack.`,
  hyMixerGauges: 'Measurements from the HyMixer system.',
  subzonesButton: 'Irrigation zones within a given zone.',
  nutrientMixerValve:
    'A valve which controls the flow of nutrients from the nutrient pump.',
  nutrientMixer:
    'Nutrient pumps which control flow of nutrients into zone distributed water.',
  growLightModeSelector: 'Settings for grow lights.',
  scheduleHarvestButton:
    'Schedule a physical harvest of the plants in the stack.',
  levelValve: 'A valve that controls flow of water into a given stack level.',
  levelDrainValve:
    'A valve that controls flow of water out of a given stack level.',
  levelPlants: 'Physical plants in a given level.',
  levelSettings: 'Configure level settings.',
  addSensorButton: 'Add a sensor to the stack level.',
  sensorGauges: 'Metrics from the stack level sensors.',
  communicationError:
    'The stack is having trouble communicating with HyCubeOS.',
  communicationGood: 'The stack has healthy communications with HyCubeOS.',
  stackIrrigationMode: 'Status of irrigation mode for stack level.',
  growLightMode: 'Status of grow light mode.',
  harvestStatus: 'Status of harvest mode.',
  dryStackStatus: 'Status of dry stack alert.',
  stackLeakDetected: 'Status of stack leak detection.',
  stackPumpTimeout: 'Status of stack pump timeout.',
  stackValeFailure: 'Status of stack valve failure detection.',
  cleanInPlace: 'Status of stack clean-in-place.',
  valveTest: 'Status of stack valve test.',
};
