import React, { useCallback, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import PromptModal from '../components/PromptModal';

interface Input {
  roleName: string;
  roleId: string;
  onCompleted?: () => void;
}

interface Result {
  openRoleDeletePrompt: () => void;
  RoleDeletePrompt: typeof RoleDeletePrompt;
  roleDeletePromptProps: Props;
}

const DELETE_ROLE = gql`
  mutation deleteRole($input: DeleteRoleInputType!) {
    deleteRole(input: $input) {
      role {
        roleId
        deleted
      }
    }
  }
`;

interface Props extends Input {
  roleDeletePromptIsOpen: boolean;
  closeRoleDeletePrompt: () => void;
}

const RoleDeletePrompt = ({
  roleName,
  roleId,
  roleDeletePromptIsOpen,
  closeRoleDeletePrompt,
  onCompleted,
}: Props) => {
  const [roleDelete, { error, loading: saving }] = useMutation(DELETE_ROLE, {
    onCompleted: () => {
      closeRoleDeletePrompt();
      onCompleted && onCompleted();
    },
  });

  return (
    <PromptModal
      title={`Delete role ${roleName}?`}
      isOpen={roleDeletePromptIsOpen}
      saving={saving}
      error={error}
      onSave={() => {
        roleDelete({
          variables: {
            input: {
              roleId,
            },
          },
        });
      }}
      onComplete={closeRoleDeletePrompt}
      danger
    >
      <p>
        {`Any user with the role ${roleName} will lose all permissions until they are assigned a new role.`}
      </p>

      <p>You may restore this role in the future from the settings screen.</p>

      <p>{`Delete role ${roleName}?`}</p>
    </PromptModal>
  );
};

const useRoleDeletePrompt: (input: Input) => Result = ({
  roleName,
  roleId,
  onCompleted,
}) => {
  const [roleDeletePromptIsOpen, setRoleDeletePromptIsOpen] = useState(false);

  return {
    openRoleDeletePrompt: useCallback(() => setRoleDeletePromptIsOpen(true), [
      setRoleDeletePromptIsOpen,
    ]),
    RoleDeletePrompt,
    roleDeletePromptProps: {
      roleName,
      roleId,
      roleDeletePromptIsOpen,
      onCompleted,
      closeRoleDeletePrompt: useCallback(
        () => setRoleDeletePromptIsOpen(false),
        [setRoleDeletePromptIsOpen]
      ),
    },
  };
};

export default useRoleDeletePrompt;
