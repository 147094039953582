import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { FormGroup, Input as FormInput, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';

import { getValidationErrors } from '../../modules/errors';
import { getInputValueAnd, updateState } from '../../modules/form-helpers';

import ModalTitle from '../../components/ModalTitle';
import NewPasswordFormGroup from '../../components/NewPasswordFormGroup';
import { SaveModal } from '../../components/SaveModal';

import style from './style.module.scss';

interface Input {
  onCompleted?: () => void;
}

interface Result {
  openProfileChangePasswordForm: () => void;
  ProfileChangePasswordForm: typeof ProfileChangePasswordForm;
  profileChangePasswordFormProps: Props;
}

const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      message
    }
  }
`;

interface Props extends Input {
  profileChangePasswordFormIsOpen: boolean;
  closeProfileChangePasswordForm: () => void;
}

const ProfileChangePasswordForm = ({
  profileChangePasswordFormIsOpen,
  closeProfileChangePasswordForm,
  onCompleted,
}: Props) => {
  const [changePassword, { data, error, loading: saving }] = useMutation(
    CHANGE_PASSWORD
  );

  const currentPasswordState = useState('');
  const [currentPassword, setCurrentPassword] = currentPasswordState;

  const newPasswordState = useState('');
  const [newPassword, setNewPassword] = newPasswordState;

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const [wasOpen, setWasOpen] = useState(false);

  useEffect(() => {
    const reopened = profileChangePasswordFormIsOpen && !wasOpen;

    if (reopened) {
      setCurrentPassword('');
      setNewPassword('');
    }

    setWasOpen(profileChangePasswordFormIsOpen);
  }, [
    profileChangePasswordFormIsOpen,
    wasOpen,
    setWasOpen,
    setCurrentPassword,
    setNewPassword,
  ]);

  const hasFailed = !isDirty && error;
  const isFormValid = !hasFailed && !!currentPassword && !!newPassword;

  const validationErrors = getValidationErrors(error);

  return (
    <SaveModal
      className={style.modal}
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faKey} />}
          title="Change Password"
        />
      }
      isOpen={profileChangePasswordFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={error}
      isDirty={isDirty}
      onComplete={closeProfileChangePasswordForm}
      saveButtonContent="Submit"
      successContent={
        data && data.changePassword && data.changePassword.message
      }
      onSave={() => {
        setIsDirty(false);
        changePassword({
          variables: {
            currentPassword,
            newPassword,
          },
        });
      }}
    >
      {
        <>
          <FormGroup>
            <Label for="currentPassword">Current Password</Label>
            <FormInput
              id="currentPassword"
              type="password"
              required
              value={currentPassword}
              onChange={getInputValueAnd(
                updateState({ valueState: currentPasswordState, isDirtyState })
              )}
              invalid={!isDirty && validationErrors.currentPassword}
            />
          </FormGroup>

          <NewPasswordFormGroup
            onPasswordChanged={updateState({
              valueState: newPasswordState,
              isDirtyState,
            })}
          />
        </>
      }
    </SaveModal>
  );
};

const useProfileChangePasswordForm: (input: Input) => Result = ({
  onCompleted,
}) => {
  const [
    profileChangePasswordFormIsOpen,
    setProfileChangePasswordFormIsOpen,
  ] = useState(false);

  return {
    openProfileChangePasswordForm: useCallback(
      () => setProfileChangePasswordFormIsOpen(true),
      [setProfileChangePasswordFormIsOpen]
    ),
    ProfileChangePasswordForm,
    profileChangePasswordFormProps: {
      profileChangePasswordFormIsOpen,
      onCompleted,
      closeProfileChangePasswordForm: useCallback(
        () => setProfileChangePasswordFormIsOpen(false),
        [setProfileChangePasswordFormIsOpen]
      ),
    },
  };
};

export default useProfileChangePasswordForm;
