import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import { Label, Input, Button } from 'reactstrap';

import PageLoadingContainer from '../../../../components/PageLoadingContainer';

import style from './style.module.scss';

const CONFIGURATION_QUERY = gql`
  query {
    settings {
      generalConfiguration {
        fieldName
        displayName
        fieldValue
      }
    }
  }
`;

const UPDATE_CONFIGURATION_VALUES = gql`
  mutation updateConfigValues($input: [fieldValue!]!) {
    updateConfigValues(input: $input) {
      configs {
        fieldName
        displayName
        fieldValue
      }
    }
  }
`;

export default () => {
  var result = useQuery(CONFIGURATION_QUERY);
  const [updateStackLightState] = useMutation(UPDATE_CONFIGURATION_VALUES, {
    onCompleted: data => {
      setLstConfig(data.updateConfigValues.configs);
    },
  });

  const [isEdited, setIsEdited] = useState(false);
  const [lstConfig, setLstConfig] = useState([]);

  //Update local state list configuration contiguously when the value of the query state changes.
  useEffect(() => {
    const next = result.data?.settings?.generalConfiguration || [];
    setLstConfig(next);
  }, [result]);

  function changingConfig(e, fieldName, fieldValue) {
    const funcionalidad = lstConfig.map((i, j) => {
      if (i.fieldName === fieldName) {
        i.fieldValue = e.target.value;
      }
      return i;
    });
    setLstConfig(funcionalidad);
  }

  const UpdateConfigs = function UpdateValues(lista) {
    setIsEdited(!isEdited);

    const input = lista.map(i => {
      return {
        fieldName: i.fieldName,
        displayName: i.displayName,
        fieldValue: i.fieldValue,
      };
    });

    if (isEdited) {
      updateStackLightState({
        variables: { input },
      });
    }
  };

  return (
    <PageLoadingContainer
      resourceTypeName="Gral Page"
      result={result}
      resourceExists={!!lstConfig}
      render={() => {
        return (
          <div className={style.container}>
            {lstConfig &&
              lstConfig.map(({ fieldName, displayName, fieldValue }) => (
                <div
                  key={fieldName}
                  className={(style.container, style.inputFieldStyle)}
                >
                  <Label for={fieldName}>{displayName}</Label>
                  <Input
                    id={fieldName}
                    defaultValue={fieldValue}
                    type="number"
                    readOnly={!isEdited}
                    onChange={e => {
                      changingConfig(e, fieldName);
                    }}
                  />
                </div>
              ))}

            <div className={style.buttonContainer}>
              <Button onClick={() => UpdateConfigs(lstConfig)}>
                {isEdited && 'Save changes'}
                {!isEdited && 'Edit'}
              </Button>
            </div>
          </div>
        );
      }}
    />
  );
};
