import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import { Col, FormGroup, Input as FormInput, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';

import { SaveModal } from '../../components/SaveModal';
import ModalTitle from '../../components/ModalTitle';
import ModalLoadingContainer from '../../components/ModalLoadingContainer';
import FormPermissionsSelector from '../../components/FormPermissionsSelector';
import useModalVisible from '../useModalVisible';

import { getValidationErrors } from '../../modules/errors';
import { updateState } from '../../modules/form-helpers';

import style from './style.module.scss';

interface Input {
  roleId: string;
  onCompleted?: () => void;
}

interface Result {
  openRoleEditForm: () => void;
  RoleEditForm: typeof RoleEditForm;
  roleEditFormProps: Props;
}

const UPDATE_ROLE = gql`
  mutation updateRole($input: UpdateRoleInputType!) {
    updateRole(input: $input) {
      role {
        roleId
      }
    }
  }
`;

const ROLE_QUERY = gql`
  query role($roleId: ID!) {
    settings {
      roleManagement {
        role(roleId: $roleId) {
          roleId
          name
          permissions {
            permissionId
            name
          }
        }
        permissions {
          permissionId
          name
          description
        }
      }
    }
  }
`;

interface Props extends Input {
  roleEditFormIsOpen: boolean;
  closeRoleEditForm: () => void;
}

const RoleEditForm = ({
  roleId,
  roleEditFormIsOpen,
  closeRoleEditForm,
  onCompleted,
}: Props) => {
  const [visible, modalVisibleProps] = useModalVisible();

  const loadingResult = useQuery(ROLE_QUERY, {
    skip: !visible,
    variables: { roleId },
  });

  const { data, loading, refetch } = loadingResult;

  const [updateRole, { error: saveError, loading: saving }] = useMutation(
    UPDATE_ROLE,
    {
      onCompleted: () => {
        closeRoleEditForm();
        onCompleted && onCompleted();
      },
    }
  );

  const [roleModifications, setRoleModifications] = useState({
    permissionIds: [] as Array<string>,
  });

  const [wasOpen, setWasOpen] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  const role = data?.settings?.roleManagement?.role;

  useEffect(() => {
    const reopenedWithSameRole =
      roleEditFormIsOpen && !wasOpen && role && role?.roleId === roleId;

    if (reopenedWithSameRole) {
      refetch();
    }

    setWasOpen(roleEditFormIsOpen);
  }, [roleEditFormIsOpen, role, roleId, refetch, wasOpen, setWasOpen]);

  useEffect(() => {
    setRoleModifications({
      permissionIds:
        role?.permissions === undefined
          ? []
          : role?.permissions.map(
              (x: { permissionId: string }) => x.permissionId
            ),
    });
    setSaveAttempted(false);
  }, [role]);

  const allPermissions = data?.settings?.roleManagement?.permissions || [];

  const isDirtyState = useState(false);
  const [isDirty, setIsDirty] = isDirtyState;

  const validationErrors: any = {
    ...(!isDirty && saveAttempted && getValidationErrors(saveError)),
  };

  const isFormValid = !loading && isEmpty(validationErrors);

  return (
    <SaveModal
      scrollable
      className={style.modal}
      title={
        <ModalTitle
          icon={<FontAwesomeIcon icon={faEdit} />}
          title="Edit Role"
        />
      }
      isOpen={roleEditFormIsOpen}
      isFormValid={isFormValid}
      saving={saving}
      error={saveError}
      isDirty={isDirty}
      onComplete={closeRoleEditForm}
      onSave={() => {
        setIsDirty(false);
        setSaveAttempted(true);
        updateRole({
          variables: {
            input: {
              roleId: role?.roleId,
              roleName: role?.roleName,
              permissionIds: roleModifications.permissionIds,
            },
          },
        });
      }}
      {...modalVisibleProps}
    >
      <ModalLoadingContainer
        className={style.modalContentContainer}
        resourceTypeName="Role"
        resourceExists={!!role}
        result={loadingResult}
      >
        <>
          <Row form>
            <Col sm={6}>
              <FormGroup>
                <Label for="roleName">Role Title</Label>
                <FormInput
                  id="roleName"
                  type="text"
                  readOnly
                  value={role?.name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={12}>
              <FormGroup className={style.permissionFormGroup}>
                <Label for="permissions">Permissions</Label>
                <FormPermissionsSelector
                  id="permissions"
                  enabledPermissionIds={roleModifications.permissionIds}
                  allPermissions={allPermissions}
                  onPermissionIdSelectionChange={({
                    permissionId,
                    selected,
                  }) => {
                    updateState({
                      valueState: [
                        roleModifications.permissionIds.includes(permissionId),
                        (value: boolean) =>
                          setRoleModifications({
                            ...roleModifications,
                            permissionIds: value
                              ? [
                                  ...roleModifications.permissionIds,
                                  permissionId,
                                ]
                              : roleModifications.permissionIds.filter(
                                  x => x !== permissionId
                                ),
                          }),
                      ],
                      isDirtyState,
                    })(selected);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      </ModalLoadingContainer>
    </SaveModal>
  );
};

const useRoleEditForm: (input: Input) => Result = ({ roleId, onCompleted }) => {
  const [roleEditFormIsOpen, setRoleEditFormIsOpen] = useState(false);

  return {
    openRoleEditForm: useCallback(() => setRoleEditFormIsOpen(true), [
      setRoleEditFormIsOpen,
    ]),
    RoleEditForm,
    roleEditFormProps: {
      roleId,
      roleEditFormIsOpen,
      onCompleted,
      closeRoleEditForm: useCallback(() => setRoleEditFormIsOpen(false), [
        setRoleEditFormIsOpen,
      ]),
    },
  };
};

export default useRoleEditForm;
