import React from 'react';
import gql from 'graphql-tag';

import style from './style.module.scss';

import NamedTank from '../../../../../../components/NamedTank';
import { Descriptions } from '../../../../../../modules/Descriptions';

interface IProps {
  className: string;
  zoneDistributionTank: ITank;
  hasHelp: boolean;
  zoneId: number;
  pumpMode: any;
}

interface ITank {
  name: string;
  waterLevel: 'EMPTY' | 'LOW' | 'NOMINAL' | 'FULL' | 'OVERFLOWING';
  pumpState: 'OFF' | 'ON';
  gallons: string;
}

const ZoneDistributionTank = ({
  className,
  zoneDistributionTank,
  hasHelp,
  zoneId,
  pumpMode,
}: IProps) => {
  const { name, waterLevel, pumpState, gallons } = zoneDistributionTank;

  return (
    <div
      className={[style.container, className].join(' ')}
      title={Descriptions.zoneSupplyTank}
    >
      <NamedTank
        dataPlacement="top"
        hasHelp={hasHelp}
        title={Descriptions.zoneSupplyTank}
        entityType="Supply"
        entityName={name}
        waterLevel={waterLevel}
        pumpState={pumpState}
        gallons={gallons}
        biggerTank={false}
        pumpMode={pumpMode}
        zoneId={zoneId}
      />
    </div>
  );
};

ZoneDistributionTank.fragment = gql`
  fragment ZoneDistributionTank on ZoneDistributionTankType {
    zoneDistributionTankId
    name
    pumpState
    waterLevel
    gallons
  }
`;

export default ZoneDistributionTank;
