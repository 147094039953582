import React from 'react';
import Package from '../../../package.json';

interface IProps {
  className: string;
}

export default ({ className }: IProps) => {
  return <p>UI-Version: {Package.version}</p>;
};
